import * as React from 'react';
import SisDefineCodes from '.';
import { WizardProps } from '../../components-v2/Wizard';

const SisCourseMappingDefineCodes = (props: WizardProps): React.ReactElement => {
  return (
    <SisDefineCodes
      className="noDefineCodes subSections"
      codesToDefine={[]}
      saveMapping={async () => {
        console.log('No code to save for this mapping');
        return true;
      }}
      getMappings={() => {
        console.log('No code to get for this mapping');
      }}
      {...props}
    />
  );
};

export default SisCourseMappingDefineCodes;
