import React from 'react';
import { Row, Col, Button, Typography } from 'antd';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import { styles } from '../../../assets/Icon/CustomIcon';
import StudentsIdentityWarningsTab from './StudentsIdentityWarningsTab';
import { showNotification, NotificationTypes } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { IntegrationContext } from './../../utils/context';

const { Text } = Typography;

interface DataCorruptionAlertProps {
  job: any;
}

const DataCorruptionAlert: React.FC<DataCorruptionAlertProps> = ({ job }) => {
  const [isDisabling, setIsDisabling] = React.useState(false);
  const [cronType, setCronType] = React.useState<string>('');
  const [isStopButtonDisabled, setIsStopButtonDisabled] = React.useState(false);
  const [isMostRecent, setIsMostRecent] = React.useState(job?.mostRecent);
  const dataType = job?.dataType;

  const { userFullName } = React.useContext(IntegrationContext);

  const getJobType = () => {
    let jobType = '';
    if (job?.dataType === 'Student Records') {
      jobType = 'students';
    } else if (job?.dataType === 'Student Records (Alumni)') {
      jobType = 'alumni';
    }
    return jobType;
  }

  const disableImport = async () => {
    const jobType = getJobType();
    try {
      setIsStopButtonDisabled(true);
      await apiClient.patch(`/data-ingest/ingress/${jobType}/disable`, {
        isDisabled: true,
      });
      showNotification(NotificationTypes.success, `Successfully Disabled ${dataType}`, '');
      return;
    } catch (error) {
      showNotification(NotificationTypes.error, `Error Disabling ${dataType}`, 'Server Error');
    }
  };

  const startImport = async () => {
    const isNameIdentityChangeAuthorized = true;
    const isTestImport = false;
    const jobType = getJobType();

    try {
      setIsDisabling(true);
      await apiClient.post('/data-ingest/sis/start', {
        type: jobType,
        isTestImport,
        isNameIdentityChangeAuthorized,
        cron: cronType,
        author: userFullName,
      });
      setIsMostRecent(false);
      setIsDisabling(false);
      showNotification(NotificationTypes.success, `Successfully Started ${dataType}`, '');
      return;
    } catch (error) {
      showNotification(NotificationTypes.error, `Error Starting ${dataType}`, 'Server Error');
    }
  };

  const fetchIngressAndJobs = async (): Promise<void> => {
    const jobType = getJobType();
    try {
      const { data } = await apiClient.get(`/data-ingest/ingress/${jobType}`);
      const jobEnabled = !data?.isDisabled && data.cron !== 'ONE_TIME_IMPORT';
      setIsStopButtonDisabled(!jobEnabled);
      setCronType(data.cron);
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting SIS Import Settings',
        'Failure in getting data from server.',
      );
    }
  };
  React.useEffect(() => {
    void fetchIngressAndJobs();
  }, []);
  return (
    <div>
      {/* Icon and Message */}
      <Row align="middle" gutter={[8, 0]} style={{ marginBottom: '14px' }}>
        <Col>
          <AlertErrorIcon customStyles={{ ...styles, width: '30px', marginTop: '16px', paddingRight: '0' }} />
        </Col>
        <Col>
          <Text strong style={{ fontSize: '16px', marginRight: '10px' }}>
            Data Corruption Threat
          </Text>
        </Col>
      </Row>

      <Row style={{ marginBottom: '8px' }}>
        <Col>
          <Text strong style={{ fontSize: '14px', display: 'block', marginBottom: '8px' }}>
            More than {job?.identityChange?.max} updates have been detected for both first name and last name.
          </Text>
        </Col>
      </Row>

      {/* Detailed Message and Buttons in the Same Line */}
      {isMostRecent ? (
        <>
          <Row align="middle" wrap={false}>
            <Col flex="auto">
              <Text style={{ fontSize: '14px', display: 'inline-block', marginRight: '16px' }}>
                Click on proceed to update or disable the sync and contact Naviance customer support.
              </Text>
            </Col>
            <Col>
              <Button disabled={isDisabling} type="primary" onClick={startImport} style={{ marginRight: '8px' }}>
                Proceed
              </Button>
            </Col>
            <Col>
              <Button
                disabled={isStopButtonDisabled}
                type="default"
                onClick={disableImport}
                style={{ color: '#0958d9' }}
              >
                Disable Sync
              </Button>
            </Col>
          </Row>
        </>
      ) : (
        <></>
      )}
      <Row style={{ marginTop: '16px' }}>
        <Col>
          <StudentsIdentityWarningsTab jobGuid={job.id} />
        </Col>
      </Row>
    </div>
  );
};

export default DataCorruptionAlert;
