import apiClient from './apiClient';

export interface ExtendedTableData {
  label: string;
  value: string;
}

export interface ExtendedDataAndLabel {
  data: ExtendedTableData[];
  label: string;
}

export const getExtensionsConfig = (parameterGroup: any, fieldName: string): any => {
  const extensionConfig = (parameterGroup as any).psStudentDataSyncConfig.extensions.find((extension: any) => {
    return extension.columns.find((column: any) => {
      return column.outputName === fieldName;
    });
  });
  const column = extensionConfig?.columns?.find((column: any) => {
    return column.outputName === fieldName;
  });
  return {
    name: extensionConfig?.name,
    columns: [column],
  };
};

export const getSisExtensionsData = async (extensionConfig: any): Promise<ExtendedTableData[]> => {
  const payload = {
    tableName: extensionConfig.name,
    columnName: extensionConfig.columns[0].name,
  };
  const { data } = await apiClient.post('/data-ingest/sis/pull-extension-data', payload);
  const tableData = data
    .filter((record) => {
      const table = record.tables[payload.tableName.toLowerCase()];
      const column = payload.columnName;
      return table[column] || table[column.toLowerCase()];
    })
    .map((record) => {
      const table = record.tables[payload.tableName.toLowerCase()];
      const extensionLabelOrValue = table[payload.columnName] || table[payload.columnName.toLowerCase()];
      return {
        label: extensionLabelOrValue,
        value: extensionLabelOrValue,
      };
    });

  // get unique values
  const extensionTableData = [
    ...new Map(tableData.map((item) => [item['value'], item])).values(),
  ] as ExtendedTableData[];

  return extensionTableData;
};

export const getSisExtensionFieldLabel = (extensionConfig: any): string => {
  return `${extensionConfig.name}.${extensionConfig.columns[0].name}`;
};

export const getSisExtenstionDataAndLabel = async (extensionConfig: any): Promise<ExtendedDataAndLabel> => {
  const data = await getSisExtensionsData(extensionConfig);
  const label = getSisExtensionFieldLabel(extensionConfig);
  return { data, label };
};
