import React from 'react';

interface LabelProps {
  forId: string;
  text: string;
}

const Label = ({ forId, text }: LabelProps): React.ReactElement => (
  <>
    <label htmlFor={forId}>{text}</label>
    <br />
  </>
);

export default Label;
