// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from 'react';

// components
import { Tag, Select } from 'antd';

// icons
import { CloseOutlined } from '@ant-design/icons';

// local
import { NotificationTypes, showNotification } from '../../components/Notifications';

// utils
import apiClient from '../../utils/apiClient';
import { PSError } from '../../utils/constants';

// TS
export interface CreditTypeProps {
  creditTypes?: string[];
  saveCreditTypes: (credits: string[]) => void;
  tableColumn?: string;
}

// Config
const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

const CreditTypeDefineCode = ({
  creditTypes,
  saveCreditTypes,
  tableColumn = 'courses',
}: CreditTypeProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [creditTypesList, setCreditTypesList] = React.useState([]);

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    try {
      const { data } = await apiClient.get(`/data-ingest/sis/credit-types/${tableColumn}`);
      const credits = data.map((credit_types) => credit_types.credittype);
      setCreditTypesList(credits);
    } catch (error) {
      console.error('Unable to fetch credit types');
      if (error.message !== PSError) {
        showNotification(NotificationTypes.error, 'Error Getting credit types', 'Failure in getting data from server.');
      }
    }
    if (isMountedRef.current) setLoading(false);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const tagRender = (props) => {
    const { value, onClose } = props;
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={(event) => {
          event.preventDefault();
          event.stopPropagation();
        }}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  const changeCreditTypeMapping = (value: string[]) => {
    saveCreditTypes(value);
  };

  return (
    <div className="subSection mainMatchFieldsSection">
      <h2 className="infoHeading uploadHeading">Credit Type</h2>
      <span className="pagepara" style={{ marginRight: '4px' }}>
        <span className="pagepara">
          This value is based on <b>[Courses]Credit_Type </b>
        </span>
        <br />
        <span className="pagepara">Import courses with credit type values: </span>
      </span>

      <Select
        size="large"
        mode="multiple"
        showArrow={true}
        loading={loading}
        allowClear
        tagRender={tagRender}
        tokenSeparators={[',']}
        style={{ width: 250 }}
        onChange={changeCreditTypeMapping}
        className="select select-responsive"
        defaultValue={creditTypes || []}
        data-test-id={'credit_type_define_codes'}
      >
        {creditTypesList.map((credit: string) => (
          <Option
            value={credit}
            className="selectOptions"
            key={`sis-credit-type-1-${credit}`}
            data-test-id={`credit_id_option_${credit}`}
          >
            {credit?.toUpperCase()}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default CreditTypeDefineCode;
