import { Col, Divider, Row, Space, Menu, Dropdown, Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import AlertErrorIcon from '../../../../assets/Icon/AlertErrorIcon';
import AlertYellowIcon from '../../../../assets/Icon/AlertYellowIcon';
import CheckmarkCircleIcon from '../../../../assets/Icon/CheckmarkCircleIcon';
import GearIcon from '../../../../assets/Icon/GearIcon';
import MoreVerticalIcon from '../../../../assets/Icon/MoreVerticalICon';
import Select from '../../../components-v2/Select';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';

const DataImport: React.FC<null> = () => {
  const history = useHistory();
  const [importRecords, setImportRecords] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { Option } = Select;
  const DataImportTableColumns = [
    {
      title: 'Import Name',
      dataIndex: 'importName',
      key: 'importName',
      render: (name) => <span title={name}>{name}</span>,
    },
    {
      title: 'Import Type',
      dataIndex: 'importType',
      key: 'importType',
      render: (type) => <span title={type}>{type}</span>,
    },
    {
      title: 'Start Time',
      dataIndex: 'start',
      key: 'start',
      render: (date) => new Date(date).toLocaleString(),
    },
    {
      title: 'End Time',
      dataIndex: 'end',
      key: 'end',
      render: (date) => (date ? new Date(date).toLocaleString() : ''),
    },
    {
      title: 'Source',
      dataIndex: 'source',
      key: 'source',
      render: (source) => formatSource(source),
    },
    {
      title: '# of rows',
      dataIndex: 'noOfRows',
      key: 'noOfRows',
      render: (rows) => (rows === -1 ? '-' : rows),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, row) => <span>{getIconAndStatus(status, row.navianceStatus)}</span>,
    },
    {
      title: 'Alerts',
      dataIndex: 'noOfAlerts',
      key: 'noOfAlerts',
      render: (rows) => (rows === -1 ? '-' : rows),
    },
    {
      title: 'More',
      dataIndex: 'more',
      key: 'more',
      render: () => (
        <Dropdown overlay={userMenu}>
          <a href="#" onClick={(e) => e.preventDefault()}>
            <MoreVerticalIcon />
          </a>
        </Dropdown>
      ),
    },
  ];

  const userMenu = (
    <Menu>
      <Menu.Item key="log">View log</Menu.Item>
      <Menu.Item key="file">View file</Menu.Item>
      <Menu.Item key="edit">Edit</Menu.Item>
      <Menu.Item key="save">Save</Menu.Item>
    </Menu>
  );

  const getIconAndStatus = (status, navianceStatus) => {
    if (status === 'STARTED' && navianceStatus === '') {
      return <>Validation in progress</>;
    } else if (status === 'ABORTED') {
      return (
        <>
          <AlertErrorIcon /> Import Cancelled
        </>
      );
    } else if (status === 'FAILED' && navianceStatus !== 'CRITICAL_ERROR') {
      return (
        <>
          <AlertErrorIcon /> System Error
        </>
      );
    } else if (status === 'COMPLETED') {
      return (
        <> {navianceStatus === 'CRITICAL_ERROR' ? <AlertYellowIcon /> : <CheckmarkCircleIcon />} Import Completed </>
      );
    }
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{status}</>;
  };

  const fetchJobs = async () => {
    try {
      const { data } = await apiClient.get('/data-ingest/jobs');
      setImportRecords(data.jobs.filter((row) => !`${row.importType}`.endsWith('-sis-config')));
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Jobs', 'Failure in getting data from server.');
    } finally {
      setLoading(false);
    }
  };

  const formatSource = (source) => {
    switch (source.toLocaleLowerCase()) {
      case 'manual':
        return 'Manual';
      case 'sftp':
        return 'SFTP';
      case 'curl':
        return 'cURL';
      default:
        return source;
    }
  };

  React.useEffect(() => {
    void fetchJobs();
  }, []);

  return (
    <div className="new-data-import mainMatchFieldsSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleField" data-cy="data-import-heading" level={1}>
              Data Import
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              <button className="button" onClick={() => history.push('/scholarships/data-import-configuration')}>
                Configure <GearIcon />
              </button>
              <button onClick={() => history.push('/scholarships/new-data-import')} className="continue-btn">
                New Data Import
              </button>
            </Space>
          </Col>
        </Row>
        <Divider className="divider-data" />
      </div>
      <div className="mainWhitebgWrapper">
        <Row className="mainPerformTimeWrapper">
          <Col span={24}>
            <Row>
              <Col span={24}>
                <p className="pagepara">
                  Perform a one-time import or setup an automated import. Monitor import activity and receive data
                  import notifications by email.
                </p>
              </Col>
              <Col span={24}>
                <span className="labelName">Data Import Details</span>
              </Col>
            </Row>

            <Row className="mainSelectRow" gutter={16}>
              <Col span={6}>
                <p className="selectLabel">Import Type</p>
                <Select placeholder="Select Import Type" className="select">
                  {['any', 'one', 'two'].map((each, key) => {
                    return (
                      <Option className="selectOptions" key={key} value={each}>
                        {each}
                      </Option>
                    );
                  })}
                </Select>
              </Col>

              <Col span={6}>
                <p className="selectLabel">Import Status</p>
                <Select placeholder="Select Import Type" className="select">
                  {['any', 'one', 'two'].map((each, key) => {
                    return (
                      <Option className="selectOptions" key={key} value={each}>
                        {each}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            <Row justify="start">
              <Col span={24}>
                <div className="mainDataImportTable">
                  <Table
                    rowKey="id"
                    loading={loading}
                    columns={DataImportTableColumns}
                    dataSource={importRecords}
                  ></Table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default DataImport;
