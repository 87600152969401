/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../../../assets/Icon/CloseIcon';
import GearIcon from '../../../../assets/Icon/GearIcon';
import Col from '../../../components-v2/Col';
import Divider from '../../../components-v2/Divider';
import Row from '../../../components-v2/Row';
import Select from '../../../components-v2/Select';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';
import { CHANNEL_CONFIGURATIONS, GET_CHANNEL_ENDPOINT } from '../../../utils/constants';
import { getRequiredFields } from '../../../utils/utils';

export interface IMeta {
  latestHeaders?: string[];
  importType?: string;
  importName?: string;
}
export interface IFieldMapping {
  field?: string;
  mapping?: string;
}

const MatchFields = (): React.ReactElement => {
  const history = useHistory();
  const { Option } = Select;
  const [meta, setMeta] = React.useState<IMeta>({ latestHeaders: [] });
  const [requiredFieldsData, setRequiredFieldsData] = React.useState<string[]>([]);
  const [fieldMapping, setFieldMapping] = React.useState<IFieldMapping[]>([]);
  const [navianceMatchingFields, setNavianceMatchingFields] = React.useState<string[]>(null);
  const [requiredFieldsMissing, setRequiredFieldsMissing] = React.useState(0);
  const [unmatchedFields, setUnmatchedFields] = React.useState(0);
  const [channelGuid, setChannelGuid] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    localStorage.removeItem('latestHeaders');
    void fetchChannelConfigs();
  }, []);

  React.useEffect(() => {
    const enteredData = [];
    fieldMapping.some((mapping) => {
      if (requiredFieldsData.includes(mapping.field)) {
        enteredData.push(mapping.field);
      }
      return null;
    });
    setRequiredFieldsMissing(requiredFieldsData.length - enteredData.length);
    setUnmatchedFields(meta?.latestHeaders?.length - fieldMapping.length);
  }, [fieldMapping]);

  React.useEffect(() => {
    if (localStorage.getItem('latestHeaders')) {
      setMeta({
        latestHeaders: localStorage.getItem('latestHeaders').split(','),
      });
      localStorage.removeItem('latestHeaders');
    }
  }, [meta]);

  const fetchChannelConfigs = async () => {
    setLoading(true);
    try {
      const importType = sessionStorage.getItem('importType');
      const channel = await apiClient.get(CHANNEL_CONFIGURATIONS, {
        params: { importType },
      });
      const requiredFields = getRequiredFields(channel);
      const keys = [];
      for (const key in requiredFields) {
        const obj = requiredFields[key];
        if (!obj.optional) {
          keys.push(key.toString());
        }
      }
      setChannelGuid(channel.data.channelGuid);
      setRequiredFieldsData(keys);
      setNavianceMatchingFields(channel.data.childSteps.translate.parameters.translateConfig.indexMappings);
      setMeta(channel.data.meta);
      setUnmatchedFields(channel.data.meta.latestHeaders.length - fieldMapping.length);
      setRequiredFieldsMissing(keys.length);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Failed to get mapping data', 'Service Failure: Failed to get data');
    } finally {
      setLoading(false);
    }
  };

  const clearFieldsHandler = () => {
    setFieldMapping([]);
    localStorage.setItem('latestHeaders', meta.latestHeaders.toString());
    setMeta({ latestHeaders: [] });
  };

  const continueBtn = async () => {
    const enteredData = [];
    if (requiredFieldsData.length === 0 && fieldMapping.length === 0) {
      showNotification(
        NotificationTypes.warning,
        'Provide Required Fields',
        'Please wait while all required fields are fetched from server',
      );
      return;
    }
    fieldMapping.some((mapping) => {
      if (requiredFieldsData.includes(mapping.field)) {
        enteredData.push(mapping.field);
      }
      return null;
    });
    if (enteredData.length !== requiredFieldsData.length) {
      showNotification(
        NotificationTypes.warning,
        'Provide Required Fields',
        'Please provide mapping for all required fields',
      );
      return;
    } else {
      const mappings = [];
      const headerMappings = {};
      fieldMapping.forEach((mapping) => {
        mappings.push(mapping.mapping);
        headerMappings[mapping.field] = mapping.mapping;
      });
      setLoading(true);
      const patchBody = {
        step: 'translate',
        configName: 'translateConfig',
        updates: [
          { propertyName: 'indexMappings', value: mappings.map((item, index) => ({ [index]: item })) },
          { propertyName: 'headerMappings', value: headerMappings },
        ],
      };
      const importType = sessionStorage.getItem('importType');
      apiClient
        .patch(`${GET_CHANNEL_ENDPOINT}/${channelGuid}?importType=${importType}`, { body: patchBody })
        .then((data) => {
          if (data) {
            showNotification(NotificationTypes.success, 'Success', 'Mapping updated successfully');
            history.push('/scholarships/new-data-import/define-codes');
          }
        })
        .catch((_error) => {
          showNotification(NotificationTypes.error, 'Failed to update mapping data', 'Service Failure');
          setLoading(false);
        });
    }
  };

  const renderTable = () => {
    if (meta.latestHeaders.length === 0) {
      return (
        <Col span={24}>
          <div className="spinner">
            <Spin size="large" />
          </div>
        </Col>
      );
    } else {
      const mappings: string[] = meta.latestHeaders;
      return (
        <Col span={24}>
          <table>
            <thead>
              <tr>
                <th>Field</th>
                <th>Your Data Field</th>
                <th>Matching Naviance Field</th>
                <th className="clearField">
                  <span onClick={clearFieldsHandler}>Clear Fields</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {mappings.map((fieldType, key) => {
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{fieldType}</td>
                    <td colSpan={2}>
                      <Select
                        placeholder="Select Mapping"
                        className="select"
                        defaultValue={'not used'}
                        showSearch={true}
                        onChange={(value) => {
                          if (value === 'not used') {
                            const mappingsArr = fieldMapping.filter((mapping) => mapping.field !== fieldType);
                            setFieldMapping([...mappingsArr]);
                            return;
                          }
                          const mappingsArr = fieldMapping.filter((mapping) => mapping.field !== fieldType);
                          setFieldMapping([...mappingsArr, { field: fieldType, mapping: value }]);
                        }}
                      >
                        <Option value={'not used'}>not used</Option>
                        {navianceMatchingFields.map((matchingField, keyForOptions) => {
                          if (requiredFieldsData.includes(matchingField)) {
                            return (
                              <Option
                                data-ingest-uion
                                key={'options' + keyForOptions}
                                value={matchingField}
                                className="selectOptions"
                              >
                                {matchingField}
                                <span style={{ color: '#dc4926' }}> (required)</span>
                              </Option>
                            );
                          } else {
                            return (
                              <Option
                                data-ingest-uion
                                key={'options' + keyForOptions}
                                value={matchingField}
                                className="selectOptions"
                              >
                                {matchingField}
                              </Option>
                            );
                          }
                        })}
                      </Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      );
    }
  };

  return (
    <div className="new-data-import mainMatchFieldsSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleStudent" data-cy="data-import-heading" level={1}>
              Add Students
            </Title>
            <Title className="titleField" data-cy="data-import-heading" level={2}>
              Match Fields
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              <button className="button" onClick={() => history.push('/scholarships/data-import-configuration')}>
                Configure <GearIcon />
              </button>
              <button className="button" onClick={() => history.push('/scholarships')}>
                Cancel
              </button>
              <button className="continue-btn" disabled={loading}>
                Continue
              </button>
            </Space>
          </Col>
        </Row>
        <Divider />
      </div>
      <div className="mainWhitebgWrapper">
        <div className="imprNameSection">
          <Row>
            <Col span={24}>
              <Title className="titleImprName" data-cy="data-import-heading" level={3}>
                Import Name
              </Title>
            </Col>

            <Col span={24}>
              <Title className="titleImpralumni" data-cy="data-import-heading" level={3}>
                Alumni Import
              </Title>
            </Col>

            <Col span={24}>
              <Title className="titleMatchField" data-cy="data-import-heading" level={3}>
                Matching Fields
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ul className="listFields">
                <li>Naviance data fields must align with fields in your file.</li>
                <li>Examples of data used in each field in your file is provided.</li>
                <li>
                  Suggested matches are provided at the top of the dropdown when selecting a matching Naviance field.
                </li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col span={17}>
              <Row justify="end" gutter={30}>
                <Col span="auto">
                  <span className="reqField">
                    <strong>{requiredFieldsMissing}</strong> Required Fields Missing
                  </span>
                </Col>
                <Col span="auto">
                  <span className="matField">
                    <strong>{fieldMapping.length}</strong> Fields Matched
                  </span>
                </Col>
                <Col span="auto">
                  <span className="ummField">
                    <CloseIcon />
                    <strong>{unmatchedFields}</strong> Fields Unmatched
                  </span>
                </Col>
              </Row>
              <Row>{renderTable()}</Row>
            </Col>
          </Row>
          <Row className="btnsSection">
            <Col span={24}>
              <Space>
                <button className="button" onClick={() => history.push('/scholarships')}>
                  Cancel
                </button>
                <button className="continue-btn" disabled={loading} onClick={continueBtn}>
                  Continue
                </button>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MatchFields;
