import React, { useState } from 'react';

import { WizardProps } from '../../components-v2/Wizard';
import SisSyncSettings from '.';
import { FrequencyOptions } from '../../components-v2/PSDataImportStudentRecords/dataUpdateFrequency';

const SisCourseCatalogSyncSettings = (props: WizardProps): React.ReactElement => {
  const frequencies: FrequencyOptions = [
    {
      value: 'ONE_TIME_IMPORT',
      label: 'One-Time Import',
    },
  ];

  const defaultFrequency = 'ONE_TIME_IMPORT';
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [shouldInactivateRecords, setShouldInactivateRecords] = useState(false);
  const [shouldOverwriteCourseName, setShouldOverwriteCourseName] = useState(false);

  return (
    <SisSyncSettings
      frequencies={frequencies}
      defaultFrequency={defaultFrequency}
      shouldInactivateRecords={shouldInactivateRecords}
      overwriteNavData={overwriteNavData}
      shouldOverwriteCourseName={shouldOverwriteCourseName}
      flagNames={['shouldInactivateRecords', 'overwriteNavData', 'shouldOverwriteCourseName']} //need to be in same order with flag setter
      setFlagCallees={[setShouldInactivateRecords, setOverwriteNavData, setShouldOverwriteCourseName]} //need to be in same order with flag setter
      type="course-catalog"
      showRadio={true}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/course-catalog'}
      includeInactivateRule={true}
      includeOverwriteRule={true}
      includeOverwriteEmailRule={false}
      includeOverwriteCourseNameRule={true}
      includeSchools={true}
      fetchAvailableSchoolMappings={true}
      {...props}
    />
  );
};

export default SisCourseCatalogSyncSettings;
