import React, { useState, useRef } from 'react';

import type { CronExpressions } from '../../../../../../libs/common-interfaces/constants';
import { WizardProps } from '../../components-v2/Wizard';
import SisStartImport from '.';

const SisCourseCurrentStartImport = (props: WizardProps): React.ReactElement => {
  const isMountedRef = useRef(null);

  const frequencies: { k: keyof typeof CronExpressions; v: string }[] = [
    {
      k: 'ONE_TIME_IMPORT',
      v: 'One-Time Import',
    },
  ];

  const defaultFrequency = 'ONE_TIME_IMPORT';
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [shouldInactivateRecords, setShouldInactivateRecords] = useState(false);

  return (
    <SisStartImport
      type="course-current"
      typeLabel="Student Course Data (Current Courses)"
      includeSchools={false}
      {...props}
    />
  );
};

export default SisCourseCurrentStartImport;
