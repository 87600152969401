import { Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import AlertYellowIcon from '../../../../assets/Icon/AlertYellowIcon';
import CloseIconNoBg from '../../../../assets/Icon/CloseIconNoBg';
import Col from '../../../components-v2/Col';
import Divider from '../../../components-v2/Divider';
import Row from '../../../components-v2/Row';
import Drawer from '../../../components/Drawer';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';
import { TENANT_USER_ENDPOINT } from '../../../utils/constants';
import EmailDrawerContent from './EmailDrawerContent';

const Configurations = (): React.ReactElement => {
  const [drawer, setDrawer] = React.useState<null | 'email' | 'cred'>(null);
  const [showWarning, setShowWarning] = React.useState<boolean>(true);
  const [passwordInfo, setPasswordInfo] = React.useState({
    username: '',
    password: '',
  });
  const [isPasswordVisibl, displayPassword] = React.useState(false);
  const history = useHistory();
  const renderDrawer = () => {
    const credDrawerContent = (): React.ReactElement => {
      return (
        <div className="mainDrawer">
          <Row>
            <Col span={24}>
              <Title className="drawerTitle" data-cy="data-import-heading" level={1}>
                Generate Credentials for SFTP or cURL
              </Title>
              <Title className="drawerSubTitle" data-cy="data-import-heading" level={3}>
                Generate Credentials
              </Title>
            </Col>
            <Col span={24}>
              <p className="drawerDescription">
                Use button to generate credentials. You may copy from each field to provide to SFTP or cURL client.
              </p>
            </Col>
            <Col span={24}>
              <Row align="middle">
                <Col span={9}>
                  <p className="drawerLableText">Username</p>
                </Col>
                <Col span={18}>
                  <Space>
                    <input className="drawerInput" type="text" placeholder="" value={passwordInfo.username} readOnly />
                    <button className="button" onClick={() => navigator.clipboard.writeText(passwordInfo.username)}>
                      COPY
                    </button>
                  </Space>
                </Col>
              </Row>

              <Row className="drawerTopGutter" align="middle">
                <Col span={9}>
                  <p className="drawerLableText">
                    Password{' '}
                    <a className="drawerShowLink" onClick={() => displayPassword(true)}>
                      Show
                    </a>
                  </p>
                </Col>
                <Col span={18}>
                  <Space>
                    <input
                      className="drawerInput"
                      type={isPasswordVisibl ? 'text' : 'password'}
                      placeholder=""
                      value={passwordInfo.password}
                      readOnly
                    />
                    <button className="button" onClick={() => navigator.clipboard.writeText(passwordInfo.password)}>
                      COPY
                    </button>
                  </Space>
                </Col>
              </Row>
            </Col>
            <Col className="drawerTopGutter" span={24}>
              {showWarning && (
                <Row justify="space-between" align="top">
                  <Col span={18}>
                    <Space className="note" align="start" size="middle">
                      <strong>
                        <Space align="start">
                          <AlertYellowIcon />
                          <span>Note:</span>
                        </Space>
                      </strong>

                      <span className="drawerLableText">
                        Once you regenerate credentials your previous password will no longer be available (username
                        will stay the same).
                      </span>
                    </Space>
                  </Col>
                  <Col span={2}>
                    <a
                      className="drawerAlertClose"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowWarning(false);
                      }}
                    >
                      <CloseIconNoBg />
                    </a>
                  </Col>
                </Row>
              )}
            </Col>
            <Col className="drawerTopGutter gencred" span={24}>
              <Space size="large">
                <button className="continue-btn" onClick={rotatePassword}>
                  Generate Credentials
                </button>
                <a className="drawerShowLink">Revoke</a>
              </Space>
              <p className="drawerLableText">Last generated: 01/06/2021</p>
            </Col>
            <Col span={24}></Col>
            <Col className="drawerTopGutter" span={24}>
              <Space align="center">
                <button className="button">Cancel</button>
                <button className="buttonSolid">Save</button>
              </Space>
            </Col>
          </Row>
        </div>
      );
    };
    if (drawer) {
      return (
        <Drawer
          visible={true}
          onClose={closeDrawer}
          content={drawer === 'cred' ? credDrawerContent() : <EmailDrawerContent />}
        />
      );
    }
  };
  const rotatePassword = async () => {
    try {
      const rotatedPassword = await apiClient.get(`${TENANT_USER_ENDPOINT}/rotate-password`);
      setPasswordInfo(rotatedPassword.data);
      showNotification(NotificationTypes.success, 'Success', 'Password rotated successfully');
    } catch (error) {
      showNotification(NotificationTypes.error, 'Failed to rotate a password', 'Service Failure');
      console.log('error occured while trying to rotate password of user');
    }
  };
  const closeDrawer = () => {
    setDrawer(null);
  };
  return (
    <div className="new-data-import mainMatchFieldsSection mainConfigSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleStudent" data-cy="data-import-heading" level={1}>
              Data Import
            </Title>
            <Title className="titleField" data-cy="data-import-heading" level={2}>
              Configuration
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              <button className="continue-btn" onClick={() => history.push('/scholarships/new-data-import')}>
                New Data Import
              </button>
            </Space>
          </Col>
        </Row>
        <Divider className="divider-data" />
      </div>

      <div className="mainPerformTimeWrapper">
        <Row>
          <Col span={20}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDrawer('cred');
              }}
              className="configLink"
            >
              Generate Credentials for SFTP or CURL
            </a>
            <i className="updateTime">&nbsp;&nbsp; Last updated:12/12/2020 3:12 PM</i>
            <p className="configText">
              Generate username/password or provide a public key for your desired SFTP or cURL client.
            </p>
          </Col>
          <Col span={20}>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setDrawer('email');
              }}
              className="configLink"
            >
              Manage Import Email Notifications
            </a>
            <p className="configText">
              Indicate one or more recipents of data import notifications for each import name
            </p>
          </Col>
          <Col span={20}>
            <a className="configLink">Saved Import Settings</a>
            <p className="configText">All import field settings are saved and can be reused for future imports</p>
          </Col>
          {renderDrawer()}
        </Row>
      </div>
    </div>
  );
};

export default Configurations;
