import * as React from 'react';
import { Row, Col, Table, Select } from 'antd';
import type { CodeSet } from '../../../../../../libs/common-interfaces';
import { availableCodes, changeGradeMapping } from './../../utils/utils';
import { cleverGradeCodes, navGradeData } from '../../utils/constants';

interface CodeSetDisplay extends CodeSet {
  used?: boolean;
}

interface GradeCodeMappingProps {
  gradeMapping: Record<string, string[]>;
  setGradeMapping: (setGradeMapping: Record<string, string[]>) => void;
}

const GradeCodeMapping = ({ gradeMapping, setGradeMapping }: GradeCodeMappingProps): React.ReactElement => {
  const { Option } = Select;

  const availableGradeCodes = (): CodeSetDisplay[] => availableCodes<CodeSetDisplay>(gradeMapping, cleverGradeCodes);

  const [gradeCodes, setGradeCodes] = React.useState<CodeSetDisplay[]>(availableGradeCodes());

  React.useEffect(() => {
    setGradeCodes(availableGradeCodes());
  }, [gradeMapping]);

  const gradeTableColumns = [
    {
      title: 'Naviance Field: Grade',
      width: '40%',
      render: (data) => <>{data.displayValue}</>,
    },
    {
      title: 'Clever Code: Grade',
      width: '60%',
      render: (data) => {
        return (
          <Select
            className="clever select select-responsive"
            mode="multiple"
            value={gradeMapping[data.code]}
            onChange={(val) => changeGradeMapping(data.code, val, gradeMapping, setGradeMapping)}
            data-test-id={`grade_select_${data.code}`}
          >
            {gradeCodes.map((grade, ind) => (
              <Option
                className="selectOptions"
                key={`sis-${grade.code}-${ind}`}
                value={grade.code}
                data-test-id={`grade_option_${grade.code}`}
                disabled={grade.used && !gradeMapping[data.code]?.includes(grade.code)}
              >
                {grade.displayValue}
              </Option>
            ))}
          </Select>
        );
      },
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18} style={{ marginBottom: 0 }}>
        <h2 className="infoHeading uploadHeading" data-test-id="defineCodesGradeHeader">
          Non-Standard Grades
        </h2>
      </Col>
      <Col span={16} style={{ marginBottom: 7}}>
        <span>
          For non-standard grade only. Such as prekindergarten, postgraduate and ungraded.
        </span>
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          columns={gradeTableColumns}
          dataSource={navGradeData}
          pagination={false}
          // loading={loading}
        />
      </Col>
    </Row>
  );
};

export default GradeCodeMapping;
