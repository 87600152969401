import * as React from 'react';
import SisCourseCatalogFieldMatching from '../SisFieldMatching/SisCourseCatalogFieldMatching';
import SisCourseCatalogSyncSettings from '../SisSyncSettings/SisCourseCatalogSyncSettings';
import SisCourseCatalogDefineCodes from '../SisDefineCodes/SisCourseCatalogDefineCodes';
import SisImport from '.';
import SisCourseCatalogStartImport from '../SisStartImport/SisCourseCatalogStartImport';

export const CourseCatalogSteps = [
  { title: 'Match Fields', content: SisCourseCatalogFieldMatching, path: '/import-wizard/course-catalog/match-fields' },
  { title: 'Define Codes', content: SisCourseCatalogDefineCodes, path: '/import-wizard/course-catalog/define-code' },
  {
    title: 'Sync Settings',
    content: SisCourseCatalogSyncSettings,
    path: '/import-wizard/course-catalog/sync-settings',
  },
  {
    title: 'Test / Start Import',
    content: SisCourseCatalogStartImport,
    path: '/import-wizard/course-catalog/test-start-import',
  },
];

const SisCourseCatalog = (): React.ReactElement => {
  return <SisImport steps={CourseCatalogSteps} />;
};

export default SisCourseCatalog;
