import * as React from 'react';
import { Row, Col, Table, Select } from 'antd';
import type { RaceEthnicityCode, SisDistrictRaceEthnicity } from '../../../../../../libs/common-interfaces';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { cleverRaceCodes, navEthnicityData, PSError } from '../../utils/constants';
import { IntegrationContext } from '../../utils/context';
import { availableCodes, changeMapping } from './../../utils/utils';

interface RaceDisplay extends RaceEthnicityCode {
  used?: boolean;
}

interface EthnicityCodeMappingProps {
  mappingType: string;
  ethnicityMapping: Record<string, (string | number)[]>;
  setEthnicityMapping: (ethnicityMapping: Record<string, (string | number)[]>) => void;
}

const EthnicityCodeMapping = ({
  mappingType,
  ethnicityMapping,
  setEthnicityMapping,
}: EthnicityCodeMappingProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);

  const [defaultRaceCodes, setDefaultRaceCodes] = React.useState<RaceDisplay[]>([]);
  const [loading, setLoading] = React.useState(false);
  const context = React.useContext(IntegrationContext);

  const availableRaceCodes = (): RaceDisplay[] => availableCodes<RaceDisplay>(ethnicityMapping, defaultRaceCodes);

  const [raceCodes, setRaceCodes] = React.useState(availableRaceCodes());

  React.useEffect(() => {
    setRaceCodes(availableRaceCodes());
  }, [ethnicityMapping, defaultRaceCodes]);

  const getRaceCodes = async () => {
    try {
      if (context.isCleverIntegration) {
        setDefaultRaceCodes(cleverRaceCodes);
        return;
      }
      const { data }: { data: SisDistrictRaceEthnicity } = await apiClient.get('/data-ingest/sis/race-codes');
      if (isMountedRef.current) setDefaultRaceCodes(data.codes);
    } catch (error) {
      console.error(error.message);
      if (error.message !== PSError) {
        showNotification(NotificationTypes.error, 'Error Getting Race Codes', 'Failure in getting data from server.');
      }
    }
  };

  const getEthnicityCodes = async () => {
    try {
      const { data }: { data: SisDistrictRaceEthnicity } = await apiClient.get('/data-ingest/sis/ethnicity-codes');
      if (isMountedRef.current) setDefaultRaceCodes(data.codes);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Race Codes', 'Failure in getting data from server.');
    }
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    if (mappingType === 'race' || mappingType === 'federalRace') {
      await getRaceCodes();
    }
    if (mappingType === 'ethnicity' || mappingType === 'ethnicCode') {
      await getEthnicityCodes();
    }
    if (isMountedRef.current) setLoading(false);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const ethnicityTableColumns = [
    {
      title: `${context.isCleverIntegration ? 'Naviance Field: Ethnicity' : 'Naviance Code'}`,
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: `${context.isCleverIntegration ? 'Clever Code: Race and Hispanic_ethnicity' : 'Select SIS Codes'}`,
      width: '60%',
      render: (data) => (
        <Select
          className="select select-responsive"
          mode="multiple"
          allowClear
          value={ethnicityMapping[data.code]}
          onChange={(val) => changeMapping(data.code, val, ethnicityMapping, setEthnicityMapping)}
          data-test-id={`ethnicity_select_${data.code}`}
        >
          {raceCodes.map((race, ind) => (
            <Option
              className="selectOptions"
              key={`sis-${race.code}-${ind}`}
              value={race.code}
              data-test-id={`ethnicity_option_${`${race.code}`}`}
              disabled={race.used && !ethnicityMapping[data.code]?.includes(race.code)}
            >
              {race.code} {context.isCleverIntegration ? '' : `- ${race.description}`}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  const ethnicCodesToBeFiltered = ['HI', 'MR'];
  const ethnicCodesToBeFilteredClever = ['HI'];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        {(mappingType === 'race' || mappingType === 'federalRace') && (
          <h2 className="infoHeading uploadHeading" data-test-id='defineCodesEthnicCodeHeader'>Federal Race Category</h2>
        )}
        {mappingType === 'ethnicity' && <h2 className="infoHeading uploadHeading" data-test-id='defineCodesEthnicCodeHeader'>Scheduling/Reporting Ethnicity</h2>}
        {mappingType === 'ethnicCode' && <h2 className="infoHeading uploadHeading" data-test-id='defineCodesEthnicCodeHeader'>Race Category</h2>}
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          columns={ethnicityTableColumns}
          dataSource={
            mappingType === 'ethnicity' || mappingType === 'federalRace'
              ? navEthnicityData
              : navEthnicityData.filter((ethicData) =>
                  context.isCleverIntegration
                    ? !ethnicCodesToBeFilteredClever.includes(ethicData.code)
                    : !ethnicCodesToBeFiltered.includes(ethicData.code),
                )
          }
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default EthnicityCodeMapping;
