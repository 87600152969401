import * as React from 'react';
import { Select } from 'antd';
import apiClient from '../../utils/apiClient';
import { showNotification, NotificationTypes } from '../../components/Notifications';

interface TermDefineCodesProps {
  terms: string[];
  setTerms: (setTerms: string[]) => void;
  termValue: string;
  termConfigEndpoint: string;
}

const TermDefineCodes = ({
  terms,
  termValue,
  setTerms,
  termConfigEndpoint,
}: TermDefineCodesProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);

  const [loading, setLoading] = React.useState<boolean>(false);
  const [abbreviationOptions, setAbbreviationOptions] = React.useState<string[]>([]);

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    const { data: options } = await apiClient.get<string[]>(termConfigEndpoint, {});
    setAbbreviationOptions(options);
    if (terms && terms.length > 0) {
      setTerms(terms);
    } else {
      const defaultValues = [];
      options.includes('S1') ? defaultValues.push('S1') : false;
      options.includes('S2') ? defaultValues.push('S2') : false;
      setTerms(defaultValues);
    }
    if (isMountedRef.current) setLoading(false);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  return (
    <div className="subSection mainMatchFieldsSection mainSelectRow">
      <h2 className="infoHeading uploadHeading">Term</h2>
      <span className="pagepara">
        This value is based on <b>{termValue}</b>
      </span>
      <br />
      <span className="pagepara">
        Import courses with term values:{' '}
        <Select
          className="select select-responsive"
          mode="multiple"
          allowClear
          style={{ width: 350 }}
          value={terms}
          onChange={(val) => {
            setTerms(val);
          }}
          data-test-id={'terms_define_codes'}
          loading={loading}
        >
          {abbreviationOptions.map((termAbbrev: string, index: number) => (
            <Option
              className="selectOptions"
              key={`sis-cr_opt-1-${index}`}
              value={termAbbrev}
              data-test-id={`crdt_option_${index}`}
              disabled={false}
            >
              {`${termAbbrev}`}
            </Option>
          ))}
        </Select>
      </span>
    </div>
  );
};

export default TermDefineCodes;
