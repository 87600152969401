import * as React from 'react';
import { Row, Col, Table, Select } from 'antd';
import { showNotification, NotificationTypes } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import type { CodeSet, SisDistrictCodeSets } from '../../../../../../libs/common-interfaces';
import { IntegrationContext } from '../../utils/context';
import { availableCodes, changeMapping, getCodeMappings } from './../../utils/utils';
import { cleverGenderCodes, navGenderData } from '../../utils/constants';

interface CodeSetDisplay extends CodeSet {
  used?: boolean;
}

interface GenderCodeMappingProps {
  genderMapping: Record<string, (string | number)[]>;
  setGenderMapping: (setGenderMapping: Record<string, (string | number)[]>) => void;
}

const GenderCodeMapping = ({ genderMapping, setGenderMapping }: GenderCodeMappingProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);

  const [defaultGenderCodes, setDefaultGenderCodes] = React.useState<CodeSetDisplay[]>([]);
  const [loading, setLoading] = React.useState(false);
  const context = React.useContext(IntegrationContext);

  const availableGenderCodes = (): CodeSetDisplay[] => availableCodes<CodeSetDisplay>(genderMapping, defaultGenderCodes);

  const [genderCodes, setGenderCodes] = React.useState<CodeSetDisplay[]>(availableGenderCodes());

  React.useEffect(() => {
    setGenderCodes(availableGenderCodes());
  }, [genderMapping, defaultGenderCodes]);

  const getCodeSet = async () => {
    try {
      if (context.isCleverIntegration) {
        setDefaultGenderCodes(cleverGenderCodes);
        return;
      }
      const { data }: { data: SisDistrictCodeSets } = await apiClient.get(`/data-ingest/sis/genders`);
      const codeMappings = getCodeMappings<CodeSetDisplay>(data.codesets);
      if (isMountedRef.current) setDefaultGenderCodes(Object.values(codeMappings));
    } catch (err) {
      console.error(err.message);
      showNotification(
        NotificationTypes.error,
        'Error Getting Gender Code Set',
        'Failure in getting data from server.',
      );
    }
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    await getCodeSet();
    if (isMountedRef.current) setLoading(false);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const genderTableColumns = [
    {
      title: `${context.isCleverIntegration ? 'Naviance Field: Gender' : 'Naviance Code'}`,
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: `${context.isCleverIntegration ? 'Clever Code: Gender' : 'Select SIS Codes'}`,
      width: '60%',
      render: (data) => (
        <Select
          className="select select-responsive"
          mode="multiple"
          allowClear
          value={genderMapping[data.code]}
          onChange={(val) => changeMapping(data.code, val, genderMapping, setGenderMapping)}
          data-test-id={`gender_select_${data.code}`}
        >
          {genderCodes.map((gender, ind) => (
            <Option
              className="selectOptions"
              key={`sis-${gender.code}-${ind}`}
              value={gender.code}
              data-test-id={`gender_option_${gender.code}`}
              disabled={gender.used && !genderMapping[data.code]?.includes(gender.code)}
            >
              {gender.code === '' ? 'U' : gender.code} - {gender.displayValue}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 className="infoHeading uploadHeading" data-test-id='defineCodesGenderHeader'>Gender</h2>
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          columns={genderTableColumns}
          dataSource={navGenderData}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default GenderCodeMapping;
