import React, { useContext } from 'react';
import { Space } from 'antd';
import { useHistory } from 'react-router-dom';
import GearIcon from '../../../../assets/Icon/GearIcon';
import { IntegrationContext } from '../../../utils/context';

const ConfigureButton = (): React.ReactElement => {
  const history = useHistory();
  const integrationContext = useContext(IntegrationContext);
  return (
    <Space>
      <button className="button" onClick={() => history.push('/configure')} data-test-id="configuration_btn">
        Configure <GearIcon />
      </button>
    </Space>
  );
};
export default ConfigureButton;
