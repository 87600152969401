import React from 'react';
import { Col, Row, Table, TablePaginationConfig, Typography, Divider } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import apiClient from '../../utils/apiClient';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import { styles } from '../../../assets/Icon/CustomIcon';
import { format } from 'path';
const { Text } = Typography;

export interface IdentityWarningTab {
  hsStudentId: string;
  oldLastName: string;
  oldFirstName: string;
  newLastName: string;
  newFirstName: string;
}
export interface StudentsIdentityWarningsTab {
  jobGuid: string;
}

const StudentsIdentityWarningsTab = ({ jobGuid }: StudentsIdentityWarningsTab): React.ReactElement => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);
  const [pageSize, setPageSize] = React.useState<number>(10);
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [data, setData] = React.useState([]);

  const pagination = {
    position: ['bottomCenter'],
    size: 'small',
    total: totalRecords,
    showSizeChanger: true,
    showQuickJumper: true,
    current: currentPageNum,
    pageSize: pageSize,
    showTotal: (total) => `${total} results`,
    pageSizeOptions: [10, 15],
    onShowSizeChange: async (currentPage, size) => {
      setPageSize(size);
      setCurrentPageNum(currentPage);
      await fetchData(currentPage, size);
    },
    onChange: async (page, size) => {
      setPageSize(size);
      setCurrentPageNum(page);
      await fetchData(page, size);
    },
  } as TablePaginationConfig;

  const formatData = (results: any) => {
    const formattedData = results?.map((item) => {
      const parsedJson = JSON.parse(item.JSON_OBJECT);
      const { hsStudentId, firstName, lastName } = parsedJson;
      return {
        hsStudentId,
        oldFirstName: firstName[1],
        oldLastName: lastName[1],
        newFirstName: firstName[0],
        newLastName: lastName[0],
      };
    });
    setData(formattedData);
  }

  const fetchData = async (page = 1, size = 100) => {
    try {
      setLoading(true);
      const { data } = await apiClient.post(`/data-ingest/jobs/filteredOutput`, {
        jobGuid,
        fileName: 'toIdentityChange',
        filters: [],
        page,
        size,
      });

      if (data?.total) {
        setTotalRecords(data?.total);
      }
      formatData(data?.results);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const columns: ColumnsType<IdentityWarningTab> = [
    {
      title: (
        <Row>
          <Col>
            <Text>Existing first name and last name</Text>
          </Col>
        </Row>
      ),
      children: [
        {
          title: 'Last name',
          dataIndex: 'oldLastName',
          key: 'Last name',
          width: 100,
          fixed: 'left',
        },
        {
          title: 'First name',
          dataIndex: 'oldFirstName',
          key: 'First name',
          fixed: 'left',
        },
        {
          title: 'Student ID',
          dataIndex: 'hsStudentId',
          key: 'Student ID',
          fixed: 'left',
        },
      ],
    },
    {
      title: ' ',
      children: [{}, {}],
    },
    {
      title: (
        <Row>
          <Col>
            <AlertErrorIcon customStyles={{ ...styles, width: '30px', marginTop: '2px', paddingRight: '0' }} />
          </Col>
          <Text>Expected update for first name and last name</Text>
          <Col></Col>
        </Row>
      ),
      children: [
        {
          title: 'Last name',
          dataIndex: 'newLastName',
          key: 'Last name',
          width: 100,
          fixed: 'left',
        },
        {
          title: 'First name',
          dataIndex: 'newFirstName',
          key: 'First name',
          fixed: 'left',
        },
        {
          title: 'Student ID',
          dataIndex: 'hsStudentId',
          key: 'Student ID',
          fixed: 'left',
        },
      ],
    },
  ];

  return (
    <>
      <Divider className="divider-data" style={{ borderTopWidth: 1 }} />
      <Row>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            pagination={pagination}
            scroll={{ x: 'max-content' }}
          />
        </Col>
      </Row>
    </>
  );
};

export default StudentsIdentityWarningsTab;
