import React, { ReactElement, useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import routes from '../utils/routeConstants';
import { IntegrationContext } from './../utils/context';

export const Routes = (): ReactElement => {
  const integrationContext = useContext(IntegrationContext);
  const routeEntries = Object.entries(routes(integrationContext));

  return (
    <Switch>
      {routeEntries.map(([key, route]) => (
        <Route
          key={key}
          exact
          path={key}
          render={(routeProps) => <route.page {...routeProps} {...(route.props || {})} />}
        />
      ))}
      <Route path="*">
        <Redirect to="/history" />
      </Route>
    </Switch>
  );
};

export default Routes;
