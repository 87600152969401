import * as React from 'react';
import { Table } from 'antd';
export interface subjectAreaDetails {
  subject_area: string;
  totalRecords: number;
  active: number;
  added: number;
  updated: number;
  deactivated: number;
  no_changes: number;
  alerts: number;
  reactivated: number;
}
export interface subjectAreaDetailsDataObj {
  dataSource: subjectAreaDetails[];
}
const SubjectAreaDetails = (props: subjectAreaDetailsDataObj): React.ReactElement => {
  const columns = [
    {
      title: 'Subject Area',
      dataIndex: 'subject_area',
      key: 'Subject Area',
    },
    {
      title: 'Total Records',
      dataIndex: 'totalRecords',
      key: 'Total Records',
    },
    {
      title: 'Active(No Errors)',
      dataIndex: 'active',
      key: 'Invalid',
    },
    {
      title: 'Added',
      dataIndex: 'added',
      key: 'Added',
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'Updated',
    },
    {
      title: 'Deactivated',
      dataIndex: 'deactivated',
      key: 'Deactivated',
    },
    {
      title: 'Reactivated',
      dataIndex: 'reactivated',
      key: 'Reactivated',
    },
    {
      title: 'No Changes',
      dataIndex: 'no_changes',
      key: 'No Changes',
    },
    {
      title: 'Alerts',
      dataIndex: 'alerts',
      key: 'Alerts',
    },
  ];

  return (
    <div>
      <h3>Records Per Subject Area</h3>
      <Table
        dataSource={props.dataSource}
        columns={columns}
        pagination={false}
        summary={(pageData) => {
          let totalRecordColSum = 0;
          let totalActiveColSum = 0;
          let totalAddedColSum = 0;
          let totalUpdatedColSum = 0;
          let totalDeactivateColSum = 0;
          let totalReactivatedColSum = 0;
          let totalNoChangeColSum = 0;
          let totalAlertsColSum = 0;

          pageData.forEach((element) => {
            totalRecordColSum += element.totalRecords;
            totalActiveColSum += element.active;
            totalAddedColSum += element.added;
            totalUpdatedColSum += element.updated;
            totalDeactivateColSum += element.deactivated;
            totalReactivatedColSum += element.reactivated;
            totalNoChangeColSum += element.active-(element.added+element.updated);
            totalAlertsColSum += element.alerts;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalRecordColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalActiveColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalAddedColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalUpdatedColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalDeactivateColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalReactivatedColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalNoChangeColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalAlertsColSum}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default SubjectAreaDetails;
