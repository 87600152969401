import React, { useEffect, useState, FormEvent } from 'react';
import { Radio } from 'antd';
import { LabeledValue, SelectValue } from 'antd/es/select';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Row from '../../components-v2/Row';
import Col from '../../components-v2/Col';
import Label from './Label';
import Select, { Option } from './Select';
import SchoolOption from './SchoolOption';
import './styles.scss';
import type { NavSchoolsType } from '../../../../../../libs/common-interfaces';

export type schoolIds = string[] | null;

export interface PlanOfStudyType {
  value: string;
  label: string;
}

export interface CourseRequestParameterGroup {
  academicYear: SelectValue;
  classYearFrom: SelectValue;
  classYearTo: SelectValue;
  planOfStudy: SelectValue;
  schools: schoolIds;
  planStatus: PLAN_STATUS | null;
}

export type FieldName = keyof CourseRequestParameterGroup;

export interface SyncCourseRequestFormProps {
  academicYears: LabeledValue[];
  classYearFromList: LabeledValue[];
  classYearToList: LabeledValue[];
  plansOfStudy: PlanOfStudyType[];
  schoolList: NavSchoolsType[];
  courseRequestSettings: CourseRequestParameterGroup;
  loading: boolean;
  disableStart: boolean;

  onSubmit(event: FormEvent<HTMLFormElement>): void;

  onChange(field: FieldName, value: SelectValue): void;
}

const selectControlStyles = { marginBottom: '1rem' };

const submitButtonStyles = { marginTop: '3rem', width: '100%' };

enum SchoolsRadioStates {
  All,
  Some,
}

export enum PLAN_STATUS {
  IN_PROGRESS = 'In Progress',
  AWAITING_SCHOOL_APPROVAL = 'Awaiting School Approval',
  AWAITING_PARENT_APPROVAL = 'Awaiting Parent Approval',
  NOT_APPROVED_BY_PARENT = 'Not Approved By Parent',
  APPROVED = 'Approved',
}

const statusList: LabeledValue[] = [
  {
    label: 'All',
    value: null,
  },
  {
    label: 'In Progress',
    value: PLAN_STATUS.IN_PROGRESS,
  },
  {
    label: 'Awaiting School Approval',
    value: PLAN_STATUS.AWAITING_SCHOOL_APPROVAL,
  },
  {
    label: 'Awaiting Parent Approval',
    value: PLAN_STATUS.AWAITING_PARENT_APPROVAL,
  },
  {
    label: 'Not Approved by Parents',
    value: PLAN_STATUS.NOT_APPROVED_BY_PARENT,
  },
  {
    label: 'Approved',
    value: PLAN_STATUS.APPROVED,
  },
];

const defaultPlanOfStudy: LabeledValue = {
  key: 'any',
  label: 'Any',
  value: null,
};

const SyncCourseRequestForm = (props: SyncCourseRequestFormProps): React.ReactElement => {
  const {
    loading,
    disableStart,
    academicYears,
    schoolList,
    classYearFromList,
    classYearToList,
    plansOfStudy,
    courseRequestSettings,
    onSubmit,
    onChange,
  } = props;

  const [schoolsRadio, setSchoolsRadio] = useState(SchoolsRadioStates.All);

  const handleSchoolsFilter = (event: CheckboxChangeEvent) => {
    const { value } = event.target;
    setSchoolsRadio(value);

    if (value === SchoolsRadioStates.All) {
      onChange('schools', []);
    }
  };

  useEffect(() => {
    const schoolsRadioValue =
      courseRequestSettings['schools']?.length > 0 ? SchoolsRadioStates.Some : SchoolsRadioStates.All;
    setSchoolsRadio(schoolsRadioValue);
  }, [courseRequestSettings['schools']]);

  const isAllSchoolsSelected = schoolsRadio === SchoolsRadioStates.All;
  return (
    <form onSubmit={onSubmit}>
      <section>
        <Row>
          <Col span={24}>
            {/*Courses to Be Taken During*/}
            <Row style={selectControlStyles}>
              <Col xs={24} sm={21} md={22} lg={24}>
                <Label forId="academic-years" text="Courses to Be Taken During" />
                <Select
                  id="academic-years"
                  value={courseRequestSettings['academicYear']}
                  onChange={(value) => onChange('academicYear', value)}
                >
                  {academicYears.map((academicYear) => {
                    return (
                      <Option key={academicYear.key ?? academicYear.value} value={academicYear.value}>
                        {academicYear.label}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            {/*Class Year (Grades)*/}
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Label forId="class-year-from" text="Class Year (Grades)" />
                  </Col>
                </Row>
                <Row align="middle" style={selectControlStyles}>
                  <Col xs={12} sm={10} lg={12}>
                    <Select
                      id="class-year-from"
                      value={courseRequestSettings['classYearFrom']}
                      onChange={(value) => onChange('classYearFrom', value)}
                    >
                      {classYearFromList.map((option) => {
                        return (
                          <Option key={option.key ?? option.value} value={option.value}>
                            {option.label}
                          </Option>
                        );
                      })}
                    </Select>
                  </Col>
                  <Col span={1} style={{ textAlign: 'center' }}>
                    <span>to</span>
                  </Col>
                  <Col xs={11} sm={10} md={11}>
                    <Select
                      id="class-year-to"
                      value={courseRequestSettings['classYearTo']}
                      onChange={(value) => onChange('classYearTo', value)}
                    >
                      {classYearToList
                        .filter((option) => option.value >= courseRequestSettings['classYearFrom'])
                        .map((option) => {
                          return (
                            <Option key={option.key ?? option.value} value={option.value}>
                              {option.label}
                            </Option>
                          );
                        })}
                    </Select>
                  </Col>
                </Row>
              </Col>
            </Row>

            {/*Plan of Study*/}
            <Row style={selectControlStyles}>
              <Col xs={24} sm={21} md={22} lg={24}>
                <Label forId="plans-of-study" text="Plan of Study" />
                <Select
                  loading={loading}
                  id="plans-of-study"
                  defaultValue={null}
                  value={courseRequestSettings['planOfStudy']}
                  onChange={(value) => onChange('planOfStudy', value)}
                >
                  {[defaultPlanOfStudy].concat(plansOfStudy).map((option) => {
                    return (
                      <Option key={option.key ?? option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>

            {/*Plan Status*/}
            <Row style={selectControlStyles}>
              <Col xs={24} sm={21} md={22} lg={24}>
                <Label forId="plan-status" text="Plan Status" />
                <Select
                  id="plan-status"
                  value={courseRequestSettings['planStatus']}
                  defaultValue={null}
                  onChange={(value) => onChange('planStatus', value)}
                >
                  {statusList.map((option) => {
                    return (
                      <Option key={option.key ?? option.value} value={option.value}>
                        {option.label}
                      </Option>
                    );
                  })}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>

      <section>
        <Row>
          <Col sm={17} md={21} lg={24}>
            <h3>Available at Schools</h3>
            <Row>
              <Radio.Group
                name="schoolsFilter"
                data-test-id="schools-filter"
                value={schoolsRadio}
                onChange={handleSchoolsFilter}
                style={{ width: '100%' }}
              >
                <Row>
                  <Col sm={12}>
                    <Radio value={SchoolsRadioStates.All}>{`All Mapped Schools (${schoolList?.length || 0})`}</Radio>
                  </Col>
                  <Col sm={12}>
                    <Radio value={SchoolsRadioStates.Some}>Specify Schools</Radio>
                  </Col>
                </Row>
              </Radio.Group>
            </Row>
          </Col>
        </Row>
        {!isAllSchoolsSelected && (
          <Row>
            <Col xs={24} sm={21} md={22} lg={24}>
              <Select
                mode="multiple"
                id="schools-list"
                loading={loading}
                placeholder="Specify School"
                value={courseRequestSettings['schools'] ?? []}
                onChange={(value) => onChange('schools', value)}
                optionLabelProp="label"
                maxTagCount="responsive"
              >
                {schoolList.map((navSchool) => {
                  return (
                    <Option key={navSchool.nid} value={navSchool.nid} label={navSchool.name}>
                      <SchoolOption
                        option={{
                          label: navSchool.name,
                          value: navSchool.nid,
                        }}
                        schools={courseRequestSettings['schools']}
                      />
                    </Option>
                  );
                })}
              </Select>
            </Col>
          </Row>
        )}
      </section>

      {/*Submit button*/}
      <Row>
        <Col xs={24} sm={12} md={11} lg={13}>
          <button disabled={disableStart} type="submit" className="button button-blue" style={submitButtonStyles}>
            Start One-Time Sync
          </button>
        </Col>
      </Row>
    </form>
  );
};

export default SyncCourseRequestForm;
