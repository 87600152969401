import * as React from 'react';
import { Col, Table, Select, Tag, Row, Tooltip, Switch, Checkbox } from 'antd';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import {
  StaffUserType,
  districtJobFunctions,
  schoolJobFunctions,
  cleverSchoolUserTypes,
  cleverDistrictUserTypes,
  JobFunctions,
  StaffListType,
} from '../../utils/constants';
import apiClient from '../../utils/apiClient';
import { IntegrationContext } from '../../utils/context';
import { CloseOutlined, ExclamationCircleFilled, InfoCircleFilled } from '@ant-design/icons';
import {
  changeTitlesMapping,
  changeCleverUserTypeMapping,
  changeJobFunctionMapping,
  setDefaultTeacherMapping,
  handlePastedText,  
} from '../../utils/cleverStaffUtils';
import { QuestionCircleFilled } from '@ant-design/icons';
import { featureFlags } from '../../utils/featureFlags';

interface CleverRoleAndJobFunctionProps {
  roleMapping: Record<string, any>;
  setRoleMapping: (setRoleMapping: Record<string, any>) => void;
  staffUserType: StaffUserType;
  setNavRolesMap: (setNavRolesMap: any) => void;
  switchChecked?: boolean;
  setSwitchChecked?: (setSwitchChecked: boolean) => void;
}

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

const CleverRoleAndJobFunction = ({
  roleMapping,
  setRoleMapping,
  staffUserType,
  setNavRolesMap,
  switchChecked,
  setSwitchChecked,
}: CleverRoleAndJobFunctionProps): React.ReactElement => {
  const isMountedRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [navRoles, setNavRoles] = React.useState([]);
  const [titleUserTypeMap, setTitleUserTypeMap] = React.useState({});
  const [validationErrorRows, setValidationErrorRows] = React.useState({});
  const { districtId } = React.useContext(IntegrationContext);
  const [isValidSelection, setIsValidSelection] = React.useState(0);
  const toBeMovedandDisabled = ['Teacher (D)'];
  const textForAutoMapping = 'This role will be automatically mapped from Clever';

  let cleverUserTypes;
  let jobFunctions;

  if (staffUserType === StaffUserType.school) {
    cleverUserTypes = cleverSchoolUserTypes;
    jobFunctions = schoolJobFunctions;
  } else {
    cleverUserTypes = cleverDistrictUserTypes;
    jobFunctions = districtJobFunctions;
  }

  const getNavianceRolesMap = (data): any => {
    const navRolesMap = {};
    data.forEach((role) => {
      if (navRolesMap[role.name]) {
        navRolesMap[role.name] = [
          ...navRolesMap[role.name],
          {
            institutionId: role.institutionId,
            id: role.id,
          },
        ];
      } else {
        navRolesMap[role.name] = [
          {
            institutionId: role.institutionId,
            id: role.id,
          },
        ];
      }
    });
    return navRolesMap;
  };

  const getNavianceRolesList = async () => {
    try {
      setLoading(true);
      let { data } = await apiClient.get(`/roles/userroles?userType=${staffUserType}`);
      // Fix for not showing roles for inactive school or schools with no account
      if (staffUserType === StaffUserType.school) {
        const navSchools = await apiClient.get('/highschools/v2?byType=district&includeInactive=false');
        const activeNavSchools = navSchools.data.map((navSchool) => navSchool.nid);
        activeNavSchools.push(districtId);
        data = data.filter((record) => activeNavSchools.includes(record.institutionId));
      }

      const navRolesMap = getNavianceRolesMap(data);

      if (staffUserType === StaffUserType.school && !navRolesMap['Teacher (D)']) {
        navRolesMap['Teacher (D)'] = [
          {
            institutionId: districtId,
            id: 'PlaceHolderId',
          },
        ];
      }

      // To remove role Mapping which doesn't exist anymore
      for (const key in roleMapping) {
        if (!navRolesMap[key] && key !== 'Teacher (D)') {
          delete roleMapping[key];
        }
      }

      const cleverTitleUserTypeMap = {};
      // create the map for titleUserTypeMap
      for (const value of Object.values(roleMapping)) {
        if (value.cleverUserType) {
          for (const title of (value as any)?.titles) {
            cleverTitleUserTypeMap[`${title}-${value.cleverUserType}`] = true;
          }
        }
      }
      setTitleUserTypeMap(cleverTitleUserTypeMap);
      setNavRolesMap(navRolesMap);
      const navianceRoles = Object.keys(navRolesMap).map((key) => {
        return {
          label: key,
          value: key,
        };
      });
      let newMapping = navianceRoles;
      // Add Teacher (D) role for school user type and move it to the top along with disabling the same
      if (staffUserType === StaffUserType.school) {
        // Add Teacher (D) role if not present for UI and add it to the top
        if (!navianceRoles.find((role) => role.label === 'Teacher (D)')) {
          navianceRoles.push({ label: 'Teacher (D)', value: 'Teacher (D)' });
        }
        newMapping = moveOnTop(navianceRoles, toBeMovedandDisabled);
        setNavRoles(newMapping);
        // Set the role mapping for Teacher (D) role if not already exists
        if (switchChecked && !roleMapping['Teacher (D)']) {
          setRoleMapping({
            ...roleMapping,
            ...{
              'Teacher (D)': {
                titles: [],
                cleverUserType: 'Teacher',
                jobFunction: 15,
              },
            },
          });
          setSwitchChecked(true);
        }
      } else {
        setNavRoles(newMapping);
        setRoleMapping(roleMapping);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      showNotification(
        NotificationTypes.error,
        `Error Getting ${staffUserType} Level Roles`,
        'Failure in getting data from server.',
      );
    }
  };

  // Move the given roles to the top of the list
  const moveOnTop = (navianceRoles, toBeMoved) => {
    const newMapping = navianceRoles.filter((role) => !toBeMoved.includes(role.label));
    const movedRoles = navianceRoles.filter((role) => toBeMoved.includes(role.label));
    movedRoles.push(...newMapping);
    return movedRoles;
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    getNavianceRolesList();
    return () => (isMountedRef.current = false);
  }, []);

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  const roleTableColumns = [
    {
      title: 'Naviance role',
      width: '20%',
      render: (data) => (
        <div style={{ marginTop: '1em' }}>
          <>
            {data.label}
            {toBeMovedandDisabled.includes(data.label) && (
              <>
                <Tooltip title={textForAutoMapping}>
                  <span>
                    {'  '}
                    <QuestionCircleFilled />
                  </span>
                </Tooltip>
                <br />
                <div style={{ marginTop: '5px', marginBottom: '10px' }}>
                  <Switch
                    defaultChecked
                    checked={switchChecked}
                    onChange={(val) =>
                      setDefaultTeacherMapping(
                        val,
                        setSwitchChecked,
                        roleMapping,
                        setRoleMapping,
                        setLoading,
                        titleUserTypeMap,
                        setTitleUserTypeMap,
                      )
                    }
                  />{' '}
                  Auto Map
                </div>
              </>
            )}
          </>
        </div>
      ),
    },
    {
      title: (
        <span>
          Clever title
          <Tooltip title="Clever titles are case sensitive. Enter the title as it appears in Clever.">
            <InfoCircleFilled style={{ marginLeft: '8px', cursor: 'pointer' }} />
          </Tooltip>
        </span>
      ),
      width: '30%',
      render: (data) => (
        <>
          <div
            onPaste={(event) =>
              handlePastedText(
                event,
                data.value,
                roleMapping,
                changeTitlesMapping,
                setRoleMapping,
                titleUserTypeMap,
                setTitleUserTypeMap,
                setValidationErrorRows,
                setLoading,
                staffUserType,
              )
            }
          >
            <Select
              mode="tags"
              size="large"
              tagRender={tagRender}
              showArrow
              allowClear
              {...(validationErrorRows[data.value]
                ? { status: 'error', suffixIcon: <ExclamationCircleFilled style={{ color: '#FF0000' }} /> }
                : {})}
              value={roleMapping[data.value]?.titles}
              placeholder="Enter title"
              style={{ width: '100%' }}
              onChange={(val) =>
                changeTitlesMapping(
                  data.value,
                  val,
                  roleMapping,
                  setRoleMapping,
                  titleUserTypeMap,
                  setTitleUserTypeMap,
                  setValidationErrorRows,
                  setLoading,
                  staffUserType,
                )
              }
              dropdownStyle={{ display: 'none' }}
              options={[]}
              disabled={toBeMovedandDisabled.includes(data.label) && switchChecked}
            />
          </div>
          {validationErrorRows[data.value] && (
            <div>
              <p style={{ color: '#FF0000', marginTop: '0.4em', width: '148%' }}>
                One or more titles were already mapped to this user type. Select a different user type or remove the
                duplicate title to ensure accounts are created correctly.
              </p>
            </div>
          )}
        </>
      ),
    },
    {
      title: (
        <span>
          Clever user type
          <Tooltip title="Staff user data comes from the Clever staff file. Teacher user data comes from the Clever teacher file.">
            <InfoCircleFilled style={{ marginLeft: '8px', cursor: 'pointer' }} />
          </Tooltip>
        </span>
      ),
      width: '15%',
      render: (data) => (
        <Select
          key={isValidSelection}
          className="select select-responsive"
          data-test-id={`${staffUserType}_role_select_${data.value}`}
          placeholder="Select"
          {...(validationErrorRows[data.value]
            ? { status: 'error', suffixIcon: <ExclamationCircleFilled style={{ color: '#FF0000' }} /> }
            : {})}
          disabled={!(roleMapping[data.value]?.titles && roleMapping[data.value]?.titles?.length)}
          value={roleMapping[data.value]?.cleverUserType || undefined}
          onChange={(val) =>
            changeCleverUserTypeMapping(
              data.value,
              val,
              roleMapping,
              staffUserType,
              setRoleMapping,
              titleUserTypeMap,
              setTitleUserTypeMap,
              setValidationErrorRows,
              isValidSelection,
              setIsValidSelection,
              setLoading,
            )
          }
          options={cleverUserTypes.map((role) => ({
            label: role.label,
            value: role.value,
          }))}
        />
      ),
    },
    {
      title: (
        <span>
          Job function
          <Tooltip title="As assigned in Naviance user accounts. Selecting Counselor or Teacher adds the user to the respective list in Naviance.">
            <InfoCircleFilled style={{ marginLeft: '8px', cursor: 'pointer' }} />
          </Tooltip>
        </span>
      ),
      width: '20%',
      render: (data) => (
        <>
          <Select
            className="select select-responsive"
            data-test-id={`${staffUserType}_jobFunction_select_${data.value}`}
            placeholder="Select"
            value={
              roleMapping[data.value]?.jobFunction || roleMapping[data.value]?.jobFunction === 0
                ? roleMapping[data.value]?.jobFunction
                : undefined
            }
            disabled={!(roleMapping[data.value]?.titles && roleMapping[data.value]?.titles?.length)}
            {...(roleMapping[data.value]?.titles &&
            roleMapping[data.value]?.titles?.length &&
            roleMapping[data.value]?.jobFunction !== 0 &&
            !roleMapping[data.value]?.jobFunction
              ? { status: 'error', suffixIcon: <ExclamationCircleFilled style={{ color: '#FF0000' }} /> }
              : {})}
            onChange={(val) =>
              changeJobFunctionMapping(data.value, val, roleMapping, staffUserType, setRoleMapping, setLoading, setValidationErrorRows)
            }
            options={jobFunctions.map((jobFunction) => ({
              label: jobFunction.description,
              value: jobFunction.code,
            }))}
          />
          {roleMapping[data.value]?.titles?.length &&
          roleMapping[data.value]?.jobFunction !== 0 &&
          !roleMapping[data.value]?.jobFunction ? (
            <div>
              <p style={{ color: '#FF0000', marginTop: '0.4em' }}>Required field</p>
            </div>
          ) : (
            <></>
          )}
        </>
      ),
    },
  ];

  if (staffUserType === StaffUserType.school && featureFlags['feature.dataIngest.staffPermissionMapCode']) {
    roleTableColumns.push({
      title: (
        <span>
          Permissions
          <Tooltip title="Staff in the Teacher List will available for students to select when requesting Letters of Recommendation. Staff in the Counselor List can be assigned as a student's counselor and will also be available as a filter option in certain reports.">
            <InfoCircleFilled style={{ marginLeft: '8px', cursor: 'pointer' }} />
          </Tooltip>
        </span>
      ),
      width: '15%',
      render: (data) => (
        <div style={{ marginTop: '1em' }}>
          <Checkbox
            checked={roleMapping[data.value]?.isTeacher ?? (roleMapping[data.value]?.jobFunction === JobFunctions.SCHOOL_TEACHER || roleMapping[data.value]?.cleverUserType === StaffListType.teacher)}
            data-test-id="TeacherList"
            disabled={!(roleMapping[data.value]?.titles && roleMapping[data.value]?.titles?.length)}
            onChange={(e) => {
              setRoleMapping({
                ...roleMapping,
                [data.value]: {
                  ...roleMapping[data.value],
                  isTeacher: Number(e.target.checked),
                },
              });
            }}
          >
            Teacher
          </Checkbox>
          <Checkbox
            checked={roleMapping[data.value]?.isCounselor ?? (roleMapping[data.value]?.jobFunction === JobFunctions.SCHOOL_COUNSELOR)}
            data-test-id="CounselorList"
            disabled={!(roleMapping[data.value]?.titles && roleMapping[data.value]?.titles?.length)}
            onChange={(e) => {
              setRoleMapping({
                ...roleMapping,
                [data.value]: {
                  ...roleMapping[data.value],
                  isCounselor: Number(e.target.checked),
                },
              });
            }}
          >
            Counselor
          </Checkbox>
        </div>
      ),
    });
  }

  return (
    <Row>
      <Col span={featureFlags['feature.dataIngest.staffPermissionMapCode'] ? 24 : 18}>
        <Table
          className="cleverStaffDefinedCodes"
          columns={roleTableColumns}
          dataSource={navRoles}
          pagination={false}
          loading={loading}
          rowKey="code"
        />
      </Col>
    </Row>
  );
};

export default CleverRoleAndJobFunction;
