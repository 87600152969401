import { Col, Row } from 'antd';
import * as React from 'react';
import { getJobStatusMsg } from '../../utils/jobStatus';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { API_URL } from '../../utils/constants';

const parentImportData = {
  parentDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
  ) => {
    const dataToUse = job?.importResults;
    const details = [
      {
        name: 'Records Added',
        key: 'Records Added',
        value: dataToUse.create || 0,
      },
      {
        name: 'Records Updated',
        key: 'Records Updated',
        value: dataToUse.update || 0,
      },
      {
        name: 'Records Made Inactive',
        key: 'Records Made Inactive',
        value: dataToUse.deactivated || 0,
      },
      {
        name: 'Records with No Changes',
        key: 'Records with No Changes',
        value: dataToUse.noChanges || 0,
      },
    ];

    const alerts = {
      name: 'Alerts',
      key: 'Alerts',
      value: (
        <Row>
          {hasIssues && (
            <Col span={1}>
              <AlertYellowIcon />
            </Col>
          )}
          <Col span={22}>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>
                  {(job?.recordsSkipped || 0) + (job?.recordsNotSkipped || 0)}
                </span>
              </Col>
            </Row>
            {
              (Number(job?.toReconcile) > 0) && (
                <Row>
                  <Col>
                    <span style={{ fontWeight: 'bold' }}>{job?.toReconcile} To Reconcile</span>
                  </Col>
                </Row>
              )
            }
            {
              (Number(job?.validation?.invalidCount) > 0) && (
                <Row>
                  <Col span={24}>
                    <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.invalidCount} Errors:`}</span> Records Skipped
                    <ul className="custom-bullet">
                      {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                        return (
                          <li key={category}>
                            <span style={{ fontWeight: 'bold' }}>
                              {`${errorRowsPerCategory[category]} ${category}${errorColumnsPerCategory[category]?.length ? ": " : ""}`}
                            </span>
                            {errorColumnsPerCategory[category]?.join(', ')}
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              )
            }
            {
              (Number(job?.recordsNotSkipped) > 0) && (
                <Row>
                  <Col span={24}>
                    <span style={{ fontWeight: 'bold' }}>{`${job?.recordsNotSkipped} Warnings:`}</span> Records Not
                    Skipped
                    <ul className="custom-bullet">
                      {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                        return (
                          <li key={category}>
                            <span style={{ fontWeight: 'bold' }}>
                              {`${warningRowsPerCategory[category]} ${category}${warningColumnsPerCategory[category]?.length ? ": " : ""}`}
                            </span>
                            {warningColumnsPerCategory[category]?.join(', ')}
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                </Row>
              )
            }
            <Row>
              <a href={`${API_URL}/data-ingest/sis/jobs/${job?.id}/download/logs`} data-test-id={`match-export-link`}>
                Export Log for additional details
              </a>
            </Row>
          </Col>
        </Row>
      ),
    };

    details.push(alerts);
    return details;
  },
  parentSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: job.sis_source === 'clever' ? 'Clever' : job.sis_source || 'PowerSchool SIS',
      },
      {
        name: 'Number of Records',
        key: 'Number of Records',
        value: job?.validation?.totalDataCount ? job?.validation?.totalDataCount : 0,
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },
  undoData: (job) => {
    return [
      {
        name: 'Parent Undone',
        key: 'Parent Undone',
        value: job?.undo.undone,
      },
      {
        name: 'Parent Skipped',
        key: 'Parent Skipped',
        value: job?.undo.skipped,
      },
      {
        name: 'Parent Reactivated',
        key: 'Parent Reactivated',
        value: job?.undo.reactivated,
      },
    ];
  },
};

export default parentImportData;
