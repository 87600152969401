import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisStartImport from '.';

const SisStudentStartImport = (props: WizardProps): React.ReactElement => {
  return (
    <SisStartImport
      type="students"
      defaultFrequency="TWENTY_FOUR_HOURS"
      typeLabel="Student Records"
      includeSchools={true}
      enableAskImportPopUp={true}
      {...props}
    />
  );
};

export default SisStudentStartImport;
