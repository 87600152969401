import * as React from 'react';
import { Table } from 'antd';
import { Select } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import type { NavSchoolsType } from '../../../../../../libs/common-interfaces';
import { IntegrationContext } from './../../utils/context';

const SchoolMatch = ({ navSchools, schoolMapping, schoolsToMatch, onMappingChange, loading }): React.ReactElement => {
  const { Option } = Select;
  const { isCleverIntegration } = React.useContext(IntegrationContext);

  const DataImportTableColumns: ColumnsType<NavSchoolsType> = [
    {
      title: 'Naviance School',
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (name, record) => (
        <span title={name} data-test-id={`naviance_school_${record.nid}`}>
          {record.isAccount === 2 ? '(Inactive) ' : ''}
          {name}
        </span>
      ),
    },
    {
      title: `${isCleverIntegration ? 'Matching Clever' : 'SIS'} School`,
      dataIndex: 'nid',
      key: 'nid',
      width: '40%',
      render: (nid) => (
        <Select
          placeholder={`Select ${isCleverIntegration ? 'Matching Clever' : 'SIS'} School`}
          value={schoolMapping[nid]?.sisId}
          mode="multiple"
          className="select schoolMatching"
          onChange={(val) => onMappingChange(nid, { ...schoolMapping[nid], sisId: val })}
          data-test-id={`${nid}_sis_select`}
          allowClear
          showSearch
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {schoolsToMatch
            .sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
            .map((each, key) => {
              return (
                <Option
                  className="selectOptions"
                  key={`${nid}-${key}`}
                  value={each.school_number}
                  data-test-id={`${nid}_sis_option_${each.school_number}`}
                >
                  {`${each.name} (${each.school_number})`}
                </Option>
              );
            })}
        </Select>
      ),
    },
  ];

  return (
    <div>
      <h1>School Matching</h1>
      <p>
        Match schools between {isCleverIntegration ? 'Clever' : 'SIS'} and Naviance so accounts and other data are integrated with the correct school in Naviance.
      </p>
      <Table
        rowKey="nid"
        columns={DataImportTableColumns}
        dataSource={navSchools.sort((a, b) => (a.isAccount > b.isAccount ? 1 : a.isAccount < b.isAccount ? -1 : 0))}
        data-test-id="sis_school_mapping_table"
        pagination={false}
        loading={loading}
        locale={{
          emptyText: (
            <div style={{ margin: '20px' }}>
              <h1>Data Not Connected</h1>
              <p>You must first complete your</p>
              <p>
                <b>{isCleverIntegration ? 'Clever' : 'SIS'} Integration Settings</b> above.
              </p>
            </div>
          ),
        }}
      ></Table>
    </div>
  );
};

export default SchoolMatch;
