import * as React from 'react';
import { Col, Table, Select } from 'antd';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { getSisExtenstionDataAndLabel } from '../../utils/getSisExtensionsData';
import { availableSisCodes, changeMapping } from '../../utils/utils';
import { AvailableCodes, naviancePrefixes } from '../../utils/constants';
import { any } from '@hapi/joi';

interface PrefixProps {
  prefixMapping: Record<string, (string | number)[]>;
  setPrefixMapping: (setPrefixMapping: Record<string, (string | number)[]>) => void;
  prefixExtensionConfig: any;
  dataTestId: string;
}

const Prefix = ({
  prefixMapping,
  setPrefixMapping,
  prefixExtensionConfig,
  dataTestId
}: PrefixProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);
  const [prefixLabel, setPrefixLabel] = React.useState('');
  const [defaultSisPrefixes, setDefaultSisPrefixes] = React.useState([]);
  const [sisPrefixes, setSisPrefixes] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const availableSisPrefixes = (): AvailableCodes[] => availableSisCodes<AvailableCodes>(prefixMapping, defaultSisPrefixes);

  const getSisPrefixes = async () => {
    try {
      setLoading(true);
      const { data, label } = await getSisExtenstionDataAndLabel(prefixExtensionConfig);
      setDefaultSisPrefixes(data);
      setPrefixLabel(label);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
      showNotification(
        NotificationTypes.error,
        `Error Getting SIS Prefixes`,
        'Failure in getting data from server.',
      );
    }
  };

  React.useEffect(() => {
    setSisPrefixes(availableSisPrefixes());
  }, [prefixMapping, defaultSisPrefixes]);

  React.useEffect(()=>{
    isMountedRef.current = true;
    getSisPrefixes();
    return () => (isMountedRef.current = false);
  },[]);

  const prefixTableColumns = [
    {
      title: 'Naviance Field: Prefix',
      width: '33%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: `SIS Code(s): ${prefixLabel}`,
      width: '33%',
      render: (data) => (
        <Select
          className="select select-responsive"
          mode="multiple"
          allowClear
          data-test-id={`sis_prefix_select_${data.code}`}
          value={prefixMapping[data.code]}
          onChange={(val) => changeMapping(data.code, val, prefixMapping, setPrefixMapping)}
        >
          {sisPrefixes.map((sisPrefix, ind) => (
            <Option
              className="selectOptions"
              key={`sis-${sisPrefix.value}-${ind}`}
              value={sisPrefix.value}
              data-test-id={`prefix_option_${sisPrefix.value}`}
              disabled={sisPrefix.used && !prefixMapping[data.code]?.includes(sisPrefix.value)}
            >
              {sisPrefix.label}
            </Option>
          ))}
        </Select>
      )
    },
  ];

  return (
    <>
      <Col span={18}>
        <h2 className="infoHeading uploadHeading" data-test-id={dataTestId}>Prefix</h2>
        Match the Prefix in Naviance to the SIS code(s) from <b>{prefixLabel}</b>
        <br />
        If matching multiple SIS codes to a Naviance field, then separate with commas.
      </Col>
      <Col span={18}>
        <Table
          dataSource={naviancePrefixes}
          pagination={false}
          columns={prefixTableColumns}
          loading={loading}
          rowKey="code"
        />
      </Col>
    </>
  );
}

export default Prefix;