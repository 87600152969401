/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import CloseIcon from '../../../../assets/Icon/CloseIcon';
import GearIcon from '../../../../assets/Icon/GearIcon';
import Col from '../../../components-v2/Col';
import Divider from '../../../components-v2/Divider';
import Row from '../../../components-v2/Row';
import Select from '../../../components-v2/Select';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';
import { CHANNEL_CONFIGURATIONS, GET_CHANNEL_ENDPOINT, ImportTypeParam } from '../../../utils/constants';
import { getRequiredFields } from '../../../utils/utils';

export interface IMeta {
  latestHeaders?: string[];
  importType?: string;
  importName?: string;
}
export interface IFieldMapping {
  field?: string;
  mapping?: string;
}

export interface IProps {
  importType: string;
  renderNextComponent: (componentToRender: string) => void;
  onCancel: () => void;
}

const MatchFieldsComponent = (props: IProps): React.ReactElement => {
  const history = useHistory();
  const { Option } = Select;
  const [meta, setMeta] = React.useState<IMeta>({ latestHeaders: [] });
  const [requiredFieldsData, setRequiredFieldsData] = React.useState<string[]>([]);
  const [fieldMapping, setFieldMapping] = React.useState<IFieldMapping[]>([]);
  const [navianceMatchingFields, setNavianceMatchingFields] = React.useState<string[]>(null);
  const [requiredFieldsMissing, setRequiredFieldsMissing] = React.useState(0);
  const [unmatchedFields, setUnmatchedFields] = React.useState(0);
  const [channelGuid, setChannelGuid] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [allSelects, setAllSelects] = React.useState(null);
  const [indexMappings, setIndexMappings] = React.useState({});
  const [headerMappings, setHeaderMappings] = React.useState({});
  const [headerlessFile, setHeaderlessFile] = React.useState(true);
  React.useEffect(() => {
    localStorage.removeItem('latestHeaders');
    void fetchChannelConfigs();
  }, []);

  React.useEffect(() => {
    const enteredData = [];
    fieldMapping.some((mapping) => {
      if (requiredFieldsData.includes(mapping.mapping)) {
        enteredData.push(mapping.field);
      }
      return null;
    });
    setRequiredFieldsMissing(requiredFieldsData.length - enteredData.length);
    setUnmatchedFields(meta?.latestHeaders?.length - fieldMapping.length);
  }, [fieldMapping, allSelects]);

  React.useEffect(() => {
    if (
      navianceMatchingFields &&
      navianceMatchingFields.length > 0 &&
      meta.latestHeaders &&
      meta.latestHeaders.length > 0
    ) {
      let mappingKey;
      if (!headerlessFile) {
        mappingKey = headerMappings;
      } else {
        mappingKey = indexMappings;
      }
      const firstImport = sessionStorage.getItem('firstImport');
      if (firstImport === 'true') {
        autoFieldMatching();
      } else {
        columnMapping(mappingKey);
      }
    }
  }, [navianceMatchingFields, meta, indexMappings, headerMappings, headerlessFile]);

  React.useEffect(() => {
    if (localStorage.getItem('latestHeaders')) {
      setMeta({ latestHeaders: localStorage.getItem('latestHeaders').split(',') });
      localStorage.removeItem('latestHeaders');
    }
  }, [meta]);

  const fetchChannelConfigs = async () => {
    setLoading(true);
    try {
      const importType = props.importType;
      const channel = await apiClient.get(CHANNEL_CONFIGURATIONS, { params: { importType } });
      const requiredFields = getRequiredFields(channel);
      const keys = [];
      const allKeys = [];
      for (const key in requiredFields) {
        const obj = requiredFields[key];
        if (obj.required === true) {
          keys.push(key.toString());
        }
        allKeys.push(key.toString());
      }
      setIndexMappings(channel.data.childSteps.translate.parameters.translateConfig.indexMappings);
      setHeaderMappings(channel.data.childSteps.translate.parameters.translateConfig.headerMappings);
      setHeaderlessFile(channel.data.childSteps.translate.parameters.translateConfig.headerlessFile);
      setChannelGuid(channel.data.channelGuid);
      setRequiredFieldsData(keys);
      setNavianceMatchingFields(
        allKeys.sort((opt1, opt2) => opt1.localeCompare(opt2, undefined, { numeric: true, sensitivity: 'base' })),
      );
      const latestHeadersArray: [string] = channel.data.meta.latestHeaders;
      const latestHeadersObj = {};
      latestHeadersArray.map((e) => {
        latestHeadersObj[`${e}`] = 'not used';
      });
      setAllSelects(latestHeadersObj);
      setMeta(channel.data.meta);
      setUnmatchedFields(channel.data.meta.latestHeaders.length - fieldMapping.length);
      setRequiredFieldsMissing(keys.length);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Failed to get mapping data', 'Service Failure: Failed to get data');
    } finally {
      setLoading(false);
    }
  };
  const autoFieldMatching = () => {
    const mappings: string[] = meta.latestHeaders;
    const valObj = {};
    const mappedObjArr = [];
    for (const field in mappings) {
      for (const navField in navianceMatchingFields) {
        if (mappings[field].toLowerCase() === navianceMatchingFields[navField].toLowerCase()) {
          valObj[mappings[field]] = navianceMatchingFields[navField];
          mappedObjArr.push({ field: mappings[field], mapping: navianceMatchingFields[navField] });
        }
      }
    }
    setFieldMapping(mappedObjArr);
    setAllSelects({ ...allSelects, ...valObj });
  };

  const columnMapping = (mappingKey: string[] | { [key: string]: string }) => {
    if (mappingKey) {
      const mappings: string[] = meta.latestHeaders;
      const valObj = {};
      const mappedObjArr = [];
      for (const field in mappings) {
        for (const item in mappingKey) {
          if (mappings[field].toLowerCase() === item.toLowerCase()) {
            for (const navField in navianceMatchingFields) {
              if (
                (Array.isArray(mappingKey) && item.toLowerCase() === navianceMatchingFields[navField].toLowerCase()) ||
                (!Array.isArray(mappingKey) &&
                  mappingKey[item].toLowerCase() === navianceMatchingFields[navField].toLowerCase())
              ) {
                valObj[mappings[field]] = navianceMatchingFields[navField];
                mappedObjArr.push({ field: mappings[field], mapping: navianceMatchingFields[navField] });
              }
            }
          }
        }
      }
      setFieldMapping(mappedObjArr);
      setAllSelects({ ...allSelects, ...valObj });
    }
  };

  const clearFieldsHandler = () => {
    setFieldMapping([]);
    const placeholderObj = {};
    for (const prop in allSelects) {
      placeholderObj[prop] = 'not used';
    }
    setAllSelects(placeholderObj);
  };

  const continueButtonHandler = async () => {
    const enteredData = [];
    if (requiredFieldsData.length === 0 && fieldMapping.length === 0) {
      showNotification(
        NotificationTypes.warning,
        'Provide Required Fields',
        'Please wait while all required fields are fetched from server',
      );
      return;
    }
    fieldMapping.some((mapping) => {
      if (requiredFieldsData.includes(mapping.mapping)) {
        enteredData.push(mapping.field);
      }
      return null;
    });
    if (enteredData.length !== requiredFieldsData.length) {
      showNotification(
        NotificationTypes.warning,
        'Provide Required Fields',
        'Please provide mapping for all required fields',
      );
      return;
    } else {
      const mappings = [];
      const headerMappingsData = {};
      fieldMapping.forEach((mapping) => {
        mappings.push(mapping.mapping);
        headerMappingsData[mapping.field] = mapping.mapping;
      });
      setLoading(true);
      const patchBody = {
        step: 'translate',
        configName: 'translateConfig',
        updates: [
          { propertyName: 'indexMappings', value: mappings.map((item, index) => ({ [index]: item })) },
          { propertyName: 'headerMappings', value: headerMappingsData },
        ],
      };
      apiClient
        .patch(`${GET_CHANNEL_ENDPOINT}/${channelGuid}?importType=${props.importType}`, { body: patchBody })
        .then((data) => {
          if (data) {
            showNotification(NotificationTypes.success, 'Success', 'Mapping updated successfully');
            // Send message to parentComponent
            props.renderNextComponent('DefineCodes');
          }
        })
        .catch((error) => {
          showNotification(NotificationTypes.error, 'Failed to update mapping data', 'Service Failure');
          setLoading(false);
        });
    }
  };

  const renderTable = () => {
    if (meta.latestHeaders.length === 0) {
      return (
        <Col span={24}>
          <div className="spinner">
            <Spin size="large" />
          </div>
        </Col>
      );
    } else {
      const mappings: string[] = meta.latestHeaders;
      return (
        <Col span={24}>
          <table style={{ paddingBottom: '20px' }}>
            <thead>
              <tr>
                <th>Field</th>
                <th>Your Data Field</th>
                <th>Matching Naviance Field</th>
                <th className="clearField">
                  <span onClick={clearFieldsHandler}>Clear Fields</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {mappings.map((fieldType, key) => {
                return (
                  <tr key={key}>
                    <td>{key + 1}</td>
                    <td>{fieldType}</td>
                    <td colSpan={2}>
                      <Select
                        placeholder="Select Mapping"
                        className="select"
                        showSearch={true}
                        value={allSelects[fieldType]}
                        defaultValue={'not used'}
                        onChange={(value) => {
                          fieldMapping.map((e) => {
                            if (e.mapping === value) {
                              value = 'not used';
                              return;
                            }
                          });
                          setAllSelects({ ...allSelects, [fieldType]: value });
                          if (value === 'not used') {
                            const mappingsArr = fieldMapping.filter((mapping) => mapping.field !== fieldType);
                            setFieldMapping([...mappingsArr]);
                            return;
                          }
                          const mappingsArr = fieldMapping.filter((mapping) => mapping.field !== fieldType);
                          setFieldMapping([...mappingsArr, { field: fieldType, mapping: value }]);
                        }}
                      >
                        <Option value={'not used'}>not used</Option>
                        {navianceMatchingFields.map((matchingField, keyForOptions) => {
                          if (requiredFieldsData.includes(matchingField)) {
                            return (
                              <Option
                                data-ingest-uion
                                key={'options' + keyForOptions}
                                value={matchingField}
                                className="selectOptions"
                              >
                                {matchingField}
                                <span style={{ color: '#dc4926' }}> (required)</span>
                              </Option>
                            );
                          } else {
                            return (
                              <Option
                                data-ingest-uion
                                key={'options' + keyForOptions}
                                value={matchingField}
                                className="selectOptions"
                              >
                                {matchingField}
                              </Option>
                            );
                          }
                        })}
                      </Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      );
    }
  };

  return (
    <div className="new-data-import mainMatchFieldsSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleStudent" data-cy="data-import-heading" level={1}>
              Scholarship Data Import
            </Title>
            <Title className="titleField" data-cy="data-import-heading" level={2}>
              Scholarships Data Import - Match Fields
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              {props.importType !== ImportTypeParam.Scholarship && (
                <button className="button" onClick={() => history.push('/scholarships/data-import-configuration')}>
                  Configure <GearIcon />
                </button>
              )}
              <button className="button" onClick={() => props.onCancel()}>
                Cancel
              </button>
              <button className="continue-btn" disabled={loading} onClick={continueButtonHandler}>
                Continue
              </button>
            </Space>
          </Col>
        </Row>
        <Divider />
      </div>
      <div className="mainWhitebgWrapper">
        <div className="imprNameSection">
          <Row>
            <Col span={24}>
              <Title className="titleMatchField" data-cy="data-import-heading" level={3}>
                Matching Fields
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ul className="listFields">
                <li>Naviance data fields must align with fields in your file.</li>
                <li>Examples of data used in each field in your file is provided.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col span={17}>
              <Row justify="end" gutter={30}>
                <Col span="auto">
                  <span className="reqField">
                    <strong>{requiredFieldsMissing}</strong> Required Fields Missing
                  </span>
                </Col>
                <Col span="auto">
                  <span className="matField">
                    <strong>{fieldMapping.length}</strong> Fields Matched
                  </span>
                </Col>
                <Col span="auto">
                  <span className="ummField">
                    <CloseIcon />
                    <strong>{unmatchedFields}</strong> Fields Unmatched
                  </span>
                </Col>
              </Row>
              <Row>{renderTable()}</Row>
            </Col>
          </Row>
          <Row>
            <Col span={17}>
              <span className="reviewNappingText">Please review your field mapping before pressing “Continue”.</span>
            </Col>
          </Row>
          <Row className="btnsSection">
            <Col span={24}>
              <Space>
                <button className="button" onClick={() => props.onCancel()}>
                  Cancel
                </button>
                <button className="continue-btn" disabled={loading} onClick={continueButtonHandler}>
                  Continue
                </button>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MatchFieldsComponent;
