import * as React from 'react';
import SisCourseMappingFieldMatching from '../SisFieldMatching/SisCourseMappingFieldMatching';
import SisStartImport from '../SisStartImport';
import SisCourseMappingDefineCodes from '../SisDefineCodes/SisCourseMappingDefineCodes';
import SisCourseMappingSyncSetting from '../SisSyncSettings/SisCourseMappingSyncSetting';
import SisCourseMappingStartImport from '../SisStartImport/SisCourseMappingStartImport';
import SisImport from '.';

export const CourseMappingSteps = [
  { title: 'Match Fields', content: SisCourseMappingFieldMatching, path: '/import-wizard/course-mapping/match-fields' },
  { title: 'Define Codes', content: SisCourseMappingDefineCodes, path: '/import-wizard/course-mapping/define-code' },
  { title: 'Sync Settings', content: SisCourseMappingSyncSetting, path: '/import-wizard/course-mapping/sync-settings' },
  {
    title: 'Start Import',
    content: SisCourseMappingStartImport,
    path: '/import-wizard/course-mapping/test-start-import',
  },
];

const SisCourseMapping = (): React.ReactElement => {
  return <SisImport steps={CourseMappingSteps} />;
};

export default SisCourseMapping;
