import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import {
  defaultNavCourseHistory,
  courseHistoryRequiredFields,
  courseHistoryAdditionalFields,
  courseHistoryRecommendedFields,
} from '../../utils/fieldMapUtils';

const SisCourseHistoryFieldMatching = (props: WizardProps): React.ReactElement => {
  const Header = () => {
    return (
      <>
        <h3>Define the import by matching the Naviance field to the corresponding SIS data field.</h3>
        <h3>For fields that are not syncing, you can manually import data using the Naviance SchoolSync</h3>
      </>
    );
  };

  const sections = [
    {
      tableHeader: <h2>Required Fields</h2>,
      defaultFields: courseHistoryRequiredFields,
      defaultBlankValue: '(Do not import)',
    },
    // {
    //   tableHeader: <h2>Recommended Fields</h2>,
    //   defaultFields: courseHistoryRecommendedFields,
    //   defaultBlankValue: '(Do not import)',
    // },
    {
      tableHeader: <h2>Additional Fields</h2>,
      defaultFields: courseHistoryAdditionalFields,
      defaultBlankValue: '(Do not import)',
    },
  ];

  return (
    <SisFieldMatching
      defaultMapping={defaultNavCourseHistory}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/course-history'}
      header={<Header />}
      sections={sections}
      {...props}
    />
  );
};

export default SisCourseHistoryFieldMatching;
