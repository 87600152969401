import React from 'react';
import CustomIcon, { styles } from '../CustomIcon';

const CloseICon = () => (
  <CustomIcon style={styles}>
    <g id="NEW-DATA-IMPORT-FLOW" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="newImport1_matchFields3" transform="translate(-837.000000, -398.000000)" fill="#DA1E28">
        <g id="Group-10" transform="translate(509.000000, 396.000000)">
          <g id="Group-8" transform="translate(328.000000, 0.000000)">
            <g id="Spectrum/16/CloseCircle_18_N" transform="translate(0.000000, 2.000000)">
              <path
                d="M13.0284445,2.97155553 C10.2514342,0.19444289 5.74892798,0.194359715 2.97181509,2.97136976 C0.194702203,5.7483798 0.194618629,10.250886 2.97162843,13.0279991 C5.74863822,15.8051123 10.2511444,15.8051962 13.0282578,13.0281867 L13.0284445,13.028 C15.8053826,10.2509596 15.8053826,5.74859598 13.0284445,2.97155553 Z M11.8342222,11.2071111 L11.2057778,11.8342222 C11.1059317,11.9344156 10.9703732,11.9908414 10.828924,11.9910872 C10.6874747,11.9913315 10.5517215,11.9353757 10.4515289,11.8355289 L10.4502222,11.8342222 L8,9.38266667 L5.54844443,11.8342222 C5.44859834,11.9344156 5.31303988,11.9908414 5.17159061,11.9910872 C5.03014134,11.9913315 4.89438818,11.9353757 4.79419554,11.8355289 L4.79288888,11.8342222 L4.16666665,11.2053333 C4.06647327,11.1054873 4.01004749,10.9699288 4.00978826,10.8284795 C4.00955739,10.6870303 4.06551315,10.5512771 4.16535998,10.4510845 L4.16666665,10.4497778 L6.61733333,8 L4.16666665,5.54844443 C4.06646973,5.44860188 4.01003914,5.3130454 4.00978826,5.17159613 C4.00953904,5.03014686 4.06549002,4.89439172 4.16533332,4.79419554 L4.16666665,4.79288888 L4.7951111,4.16444443 C5.00302687,3.9558202 5.34069593,3.9552352 5.54933332,4.16313776 L5.55066666,4.16444443 L8,6.61688888 L10.4515556,4.16577776 C10.5514017,4.06558438 10.6869601,4.0091586 10.8284094,4.00891355 C10.9698587,4.0086685 11.1056118,4.06462426 11.2058045,4.1644711 L11.2071111,4.16577776 L11.8355556,4.79422221 C11.935749,4.8940683 11.9921747,5.02962677 11.9924206,5.17107603 C11.9926648,5.3125253 11.9367091,5.44827846 11.8368622,5.5484711 L11.8355556,5.54977777 L9.38266667,8 L11.8342222,10.4515556 C11.9344156,10.5514017 11.9908414,10.6869601 11.9910864,10.8284094 C11.9913315,10.9698587 11.9353757,11.1056118 11.8355289,11.2058045 L11.8342222,11.2071111 Z"
                id="24-CloseCircle_18_N"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </g>
  </CustomIcon>
);

export default CloseICon;
