const css = `.emailRecipientSelector.ant-select-multiple .ant-select-selector {
  min-height: 200px;
  align-items: flex-start;
  background-color: #e6e9f0;
  border-bottom: 1px solid;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHBzL2RhdGEtaW5nZXN0LXVpL3NyYy9hcHAvY29udGFpbmVycy9JbXBvcnROb3RpZmljYXRpb25zIiwic291cmNlcyI6WyJzdHlsZXMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFO0VBQ0E7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmVtYWlsUmVjaXBpZW50U2VsZWN0b3IuYW50LXNlbGVjdC1tdWx0aXBsZSAuYW50LXNlbGVjdC1zZWxlY3RvciB7XG4gIG1pbi1oZWlnaHQ6IDIwMHB4O1xuICBhbGlnbi1pdGVtczogZmxleC1zdGFydDtcbiAgYmFja2dyb3VuZC1jb2xvcjogI2U2ZTlmMDtcbiAgYm9yZGVyLWJvdHRvbTogMXB4IHNvbGlkO1xufVxuIl19 */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
