import { Upload, Space, Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import React, { useState } from 'react';
import Checkbox from '../../components-v2/Checkbox';
import Col from '../../components-v2/Col';
import Divider from '../../components-v2/Divider';
import Radio from '../../components-v2/Radio/index';
import Row from '../../components-v2/Row';
import Select from '../../components-v2/Select';
import { ImportTypeParam, ImportTypeFileNames, CoursePlannerImportData } from '../../utils/constants';
import { getImportName } from '../../utils/utils';

const templateUrl = 'https://help.powerschool.com/t5/Naviance-Documents/QuickDoc-Course-Planner-Templates/ta-p/444511';
interface Props {
  importData: CoursePlannerImportData;
  handleContinue: (uploadData) => void;
  handleCancel: () => void;
  handleChange: (key, value) => void;
}

const importTypeLabels = {
  [ImportTypeParam.CourseCatalog]: 'Course Catalog',
  [ImportTypeParam.CourseMapping]: 'Course Mapping',
  [ImportTypeParam.StudentCourseData]: 'Student Course Data',
};

const MAX_FILE_SIZE_BYTES = 1e7; // 10MB

const UploadPage: React.FC<Props> = ({ importData, handleContinue, handleCancel, handleChange }) => {
  const { Option } = Select;
  const [errorMessage, setErrorMessage] = useState('');
  const validateFile = (key, value) => {
    if (value.file.size >= MAX_FILE_SIZE_BYTES) {
      setErrorMessage('File size should be less than 10MB.');
      return;
    }
    const fileName = (value.file as File).name;
    const expectedFileName = ImportTypeFileNames[importData.importType];
    if (!fileName.startsWith(expectedFileName)) {
      const extension = fileName.includes('.') ? fileName.split('.').slice(-1)[0] : '.csv';
      setErrorMessage(`File must be named ${expectedFileName}.${extension}`);
      return;
    }

    setErrorMessage('');
    handleChange('file', value);
  };
  return (
    <div className="new-data-import mainMatchFieldsSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleField" data-cy="data-import-heading" level={1}>
              {getImportName(importData.importType)}
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              <button className="button" onClick={() => handleCancel()}>
                Cancel
              </button>
              <button className="continue-btn" onClick={handleContinue} disabled={importData.loading}>
                Continue
              </button>
            </Space>
          </Col>
        </Row>
        <Divider className="divider-data" />
      </div>
      <div className="mainWhitebgWrapper">
        <Row className="mainPerformTimeWrapper">
          <Col span={17}>
            <div className="section">
              <Row>
                Import type<span style={{ color: 'red' }}>&nbsp;*</span>
              </Row>
              <Row>
                <Col span={10}>
                  <Select
                    placeholder="Select Import Type"
                    className="select"
                    value={importTypeLabels[importData.importType]}
                    disabled
                  >
                    {Object.keys(ImportTypeParam).map((key) => {
                      return (
                        <Option key={key} value={importTypeLabels[ImportTypeParam[key]]}>
                          {importTypeLabels[ImportTypeParam[key]]}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            </div>
            <div className="section mainUploadFileSection">
              <Row>
                <Col span="20">
                  <p className="uploadPara">
                    Upload your file by clicking the "Add File" button and selecting the text file containing your data.{' '}
                  </p>
                </Col>
                <Col span="18">
                  <strong>
                    <a href={templateUrl} rel="noreferrer" target="_blank">
                      Click Here
                    </a>{' '}
                    if you want more detailed instructions.
                  </strong>
                </Col>
              </Row>

              <Row className="uploadSection">
                <Col span="18">
                  <h2 className="uploadHeading">Upload File</h2>
                </Col>

                <Col span="18">
                  <span className="uploadcont">
                    File must be named {ImportTypeFileNames[importData.importType]}. Only file types .csv, .txt, and
                    .zip accepted.
                  </span>
                </Col>

                <Col span="18">
                  <span className="uploadcont">10 MB Max file size</span>
                </Col>

                <Col span="18">
                  <span className="uploadcont">Average processing time is currently less than 1 minute</span>
                </Col>

                <Col span="18">
                  <div className="fileUploadbtn">
                    <Row align="middle">
                      <Col span="3">
                        <Upload
                          showUploadList={false}
                          accept=".csv, .txt, .zip"
                          customRequest={(e) => validateFile('file', e)}
                        >
                          <Button>Add File</Button>
                        </Upload>
                      </Col>
                      <Col span="8">
                        <span style={{ marginLeft: '7px' }}>
                          {importData.file === null ? 'no file chosen' : importData.file.name}
                        </span>
                        <div
                          className="validation-error-message"
                          style={errorMessage !== '' ? {} : { display: 'none' }}
                        >
                          <AlertErrorIcon></AlertErrorIcon>
                          <span>{errorMessage}</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section">
              <Row justify="start">
                <Col span={18}>
                  <span>Does the first row of your data contain column headings?</span>
                </Col>
                <Col span={18}>
                  <div className="radioValue">
                    <Radio.Group value={'yes'} disabled>
                      <Col span="auto">
                        <Radio className="radio-item" value={'yes'}>
                          Yes
                        </Radio>
                        <Radio className="radio-item" value={'no'}>
                          No
                        </Radio>
                      </Col>
                    </Radio.Group>
                  </div>
                </Col>

                {importData.importType === ImportTypeParam.StudentCourseData && (
                  <Col span={24}>
                    <h2 className="infoHeading uploadHeading">Import Rules</h2>
                    <Col span="24">
                      <Checkbox
                        className="checkbox"
                        checked={importData.importSettings.overrideData}
                        onChange={(e) => handleChange('importSettings', { overrideData: e.target.checked })}
                      >
                        Overwrite existing data for students in file
                      </Checkbox>
                    </Col>
                  </Col>
                )}
              </Row>
            </div>
            <div className="btnsSection">
              <Space>
                <button className="button" onClick={() => handleCancel()}>
                  Cancel
                </button>
                <button className="continue-btn" onClick={handleContinue} disabled={importData.loading}>
                  Continue
                </button>
              </Space>
            </div>
          </Col>
          <Col span={7}>
            <div className="" style={{ textAlign: 'right' }}>
              <a href={templateUrl} rel="noreferrer" target="_blank">
                <button className="button template-available-btn">Template Available</button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default UploadPage;
