import { StaffUserType, StaffListType, JobFunctions } from './constants';
import { featureFlags } from './featureFlags';

export const cleverUserTypes = ['District Admin', 'Teacher', 'Staff'];

export const changeJobFunctionMapping = (
  navkey: string,
  val: number,
  mapping: any,
  staffUserType: string,
  setMapping: any,
  setLoading: (loading: boolean) => void,
  setValidationErrorRows: (validationErrorRows: any) => void,
): void => {
  setLoading(true);
  const updatedMapping = { ...mapping };
  if (val || val === 0) {
    updatedMapping[navkey] = {
      ...updatedMapping[navkey],
      jobFunction: val,
    };
    if (
      staffUserType === StaffUserType.school &&
      (val === JobFunctions.SCHOOL_TEACHER || val === JobFunctions.SCHOOL_COUNSELOR)
    ) {
      updatedMapping[navkey] = {
        ...updatedMapping[navkey],
        ...(val === JobFunctions.SCHOOL_TEACHER && featureFlags['feature.dataIngest.staffPermissionMapCode']
          ? { isTeacher: 1 }
          : {}),
        ...(val === JobFunctions.SCHOOL_COUNSELOR && featureFlags['feature.dataIngest.staffPermissionMapCode']
          ? { isCounselor: 1 }
          : {}),
      };
    }
  } else {
    delete updatedMapping[navkey]?.jobFunction;
  }
  setValidationErrorRows({});
  setMapping(updatedMapping);
  setLoading(false);
};

export const setDefaultTeacherMapping = (
  val: boolean,
  setSwitchChecked: (switchChecked: boolean) => void,
  mapping: any,
  setMapping: (mapping: { [key: string]: any }) => void,
  setLoading: (loading: boolean) => void,
  titleUserTypeMap: { [key: string]: boolean },
  setTitleUserTypeMap: (titleUserTypeMap: { [key: string]: boolean }) => void,
): void => {
  setLoading(true);
  if (val) {
    const updatedTitleUserTypeMap = { ...titleUserTypeMap };

    if (mapping['Teacher (D)']?.titles?.length && mapping['Teacher (D)']?.cleverUserType) {
      for (const title of mapping['Teacher (D)']?.titles) {
        const key = `${title}-${mapping['Teacher (D)']?.cleverUserType}`;
        if (updatedTitleUserTypeMap[key]) {
          delete updatedTitleUserTypeMap[key];
        }
      }
    }
    setTitleUserTypeMap(updatedTitleUserTypeMap);

    const updatedMapping = {
      ...mapping,
      ...{
        'Teacher (D)': {
          titles: [],
          cleverUserType: 'Teacher',
          jobFunction: 15,
        },
      },
    };
    setMapping(updatedMapping);
  } else {
    // Delete the exsiting mapping for Teacher (D) to let user map the custom values based on their requirement.
    const updatedMapping = { ...mapping };
    delete updatedMapping['Teacher (D)'];
    setMapping(updatedMapping);
  }

  setSwitchChecked(val);
  setLoading(false);
};

const convertTitleToLowercase = (titleUserTypeMap, cleverUserTypes) => {
  return Object.keys(titleUserTypeMap).reduce((acc, key) => {
    // From the key of existing titleUserTypeMap find the index of cleverUserType
    const cleverUserTypeIndex = cleverUserTypes.reduce((foundIndex, userType) => {
      // If foundIndex is still -1 (not found yet), check for userType in key
      return foundIndex === -1 && key.endsWith(`-${userType}`) ? key.lastIndexOf(`${userType}`) : foundIndex;
    }, -1);
    // Extract the title from the key and convert it to lowercase
    const title = key.slice(0, cleverUserTypeIndex - 1);
    const cleverUserType = key.slice(cleverUserTypeIndex, key.length);
    acc[`${title.toLowerCase()}-${cleverUserType}`] = true;
    return acc;
  }, {});
};

export const changeTitlesMapping = (
  navkey: string,
  val: string[],
  mapping: any,
  setMapping: any,
  titleUserTypeMap: any,
  setTitleUserTypeMap: (titleUserTypeMap: any) => void,
  setValidationErrorRows: (validationErrorRows: any) => void,
  setLoading: (loading: boolean) => void,
  staffUserType: string,
  cleverUserTypes: string[],
  pasteEvent: any = undefined,
): void => {
  setLoading(true);
  const updatedMapping = { ...mapping };
  const updatedTitleUserTypeMap = { ...titleUserTypeMap }; 

  const existingLowerCaseTitleMap = convertTitleToLowercase(titleUserTypeMap, cleverUserTypes);

  const isCaseInsensitiveFeature = featureFlags['feature.dataIngest.caseInsensitiveCleverTitles'];

  const newTitleInserted = val.length > (updatedMapping[navkey]?.titles?.length || 0);
  const titleRemoved = val.length < updatedMapping[navkey]?.titles?.length;
  // for title if any entry is removed, remove the title and corresponding cleverUserType from titleUserTypeMap
  if (titleRemoved) {
    const titlesMap = {};
    const titles = val;
    for (const title of titles) {
      titlesMap[title] = true;
    }
    const existingTitles = updatedMapping[navkey]?.titles;
    for (const title of existingTitles) {
      if (!titlesMap[title]) {
        delete updatedTitleUserTypeMap[`${title}-${updatedMapping[navkey]?.cleverUserType}`];
      }
    }
  }

  // Auto populate clever user type(District Admin) if one title is selected for district users
  // If school user auto populate clever user type(Staff) if one title is selected
  if (newTitleInserted && val.length === 1) {
    updatedMapping[navkey] = {
      ...updatedMapping[navkey],
      cleverUserType: staffUserType === 'district' ? 'District Admin' : 'Staff',
      ...(staffUserType === 'district' && { jobFunction: 0 }),
    };
  }

  // validation: For every new entry check if title and cleverUserType combination is unique and mark for validation error if not.
  const processedTitle = val[val.length - 1];
  if (
    updatedMapping[navkey]?.cleverUserType &&
    newTitleInserted &&
    (isCaseInsensitiveFeature
      ? existingLowerCaseTitleMap[`${processedTitle.toLowerCase()}-${updatedMapping[navkey]?.cleverUserType}`]
      : titleUserTypeMap[`${processedTitle}-${updatedMapping[navkey]?.cleverUserType}`])
  ) {
    setValidationErrorRows({
      [navkey]: {
        titles: true,
      },
    });
    setLoading(false);
    return;
  } else {
    // When being invoked from on paste and on change needs to be prevented
    pasteEvent && pasteEvent.preventDefault();
  }

  // for title if everything is cleared then remove the entire object with navkey from mapping
  if (val?.length === 0) {
    delete updatedMapping[navkey];
  } else {
    updatedMapping[navkey] = {
      ...updatedMapping[navkey],
      titles: val,
    };

    if (newTitleInserted && updatedMapping[navkey]?.cleverUserType) {
      updatedTitleUserTypeMap[`${processedTitle}-${updatedMapping[navkey]?.cleverUserType}`] = true;
    }
  }
  setTitleUserTypeMap(updatedTitleUserTypeMap);
  setValidationErrorRows({});
  setMapping(updatedMapping);
  setLoading(false);
};

export const handlePastedText = (
  event,
  dataValue,
  roleMapping,
  changeTitlesMapping,
  setRoleMapping,
  titleUserTypeMap,
  setTitleUserTypeMap,
  setValidationErrorRows,
  setLoading,
  staffUserType,
  cleverUserTypes,
) => {
  const pastedText = event.clipboardData.getData('Text');

  if (pastedText) {
    const newTags = pastedText
      .split('\n')
      .map((tag) => tag.trim())
      .filter((tag) => (tag && tag !== "'" && tag !== '"' ? true : false));

    console.log('newTags', newTags);

    changeTitlesMapping(
      dataValue,
      Array.from(new Set([...(roleMapping[dataValue]?.titles || []), ...newTags])),
      roleMapping,
      setRoleMapping,
      titleUserTypeMap,
      setTitleUserTypeMap,
      setValidationErrorRows,
      setLoading,
      staffUserType,
      cleverUserTypes,
      event
    );
  } else {
    event.preventDefault();
  }
};

export const changeCleverUserTypeMapping = (
  navkey: string | undefined,
  val: string | undefined,
  mapping: { [key: string]: any },
  staffUserType: string,
  setMapping: (mapping: { [key: string]: any }) => void,
  titleUserTypeMap: { [key: string]: boolean },
  setTitleUserTypeMap: (setNavRolesMap: { [key: string]: boolean }) => void,
  setValidationErrorRows: (setValidationErrorRows: { [key: string]: any }) => void,
  isValidSelection: number,
  setisValidSelection: (isValidSelection: number) => void,
  setLoading: (loading: boolean) => void,
): void => {
  if (!navkey) return; // returning immediately if navkey is undefined.

  setLoading(true);
  const isCaseInsensitiveFeature = featureFlags['feature.dataIngest.caseInsensitiveCleverTitles'];

  const existingLowerCaseTitleMap = convertTitleToLowercase(titleUserTypeMap, cleverUserTypes);

  const updatedMapping = { ...mapping };
  const updatedTitleUserTypeMap = { ...titleUserTypeMap };
  if (!updatedMapping[navkey]) {
    updatedMapping[navkey] = {};
  }

  if (val) {
    for (const title of updatedMapping[navkey]?.titles || []) {
      if (isCaseInsensitiveFeature ? existingLowerCaseTitleMap[`${title.toLowerCase()}-${val}`] : titleUserTypeMap[`${title}-${val}`]) {
        setValidationErrorRows({
          [navkey]: {
            cleverUserType: true,
          },
        });
        setisValidSelection(isValidSelection + 1);
        setLoading(false);
        return;
      }
    }

    const valueChanged = updatedMapping[navkey]?.cleverUserType !== val;

    // if value is updated clear the previous titleUserTypeMap entries
    if (valueChanged) {
      updateTitleUserTypeMap(
        updatedTitleUserTypeMap,
        updatedMapping[navkey]?.titles,
        updatedMapping[navkey]?.cleverUserType,
      );
    }

    updatedMapping[navkey] = {
      ...updatedMapping[navkey],
      cleverUserType: val,
    };

    if (staffUserType === StaffUserType.school && (val === StaffListType.teacher || val === StaffListType.counselor)) {
      updatedMapping[navkey] = {
        ...updatedMapping[navkey],
        ...(val === StaffListType.teacher && featureFlags['feature.dataIngest.staffPermissionMapCode']
          ? { isTeacher: 1 }
          : {}),
        ...(val === StaffListType.counselor && featureFlags['feature.dataIngest.staffPermissionMapCode']
          ? { isCounselor: 1 }
          : {}),
      };
    }

    // update the new ones
    updateTitleUserTypeMap(updatedTitleUserTypeMap, updatedMapping[navkey]?.titles, val, true);
  } else {
    updateTitleUserTypeMap(
      updatedTitleUserTypeMap,
      updatedMapping[navkey]?.titles,
      updatedMapping[navkey]?.cleverUserType,
    );
    delete updatedMapping[navkey]?.cleverUserType;
  }

  setisValidSelection(isValidSelection + 1);
  setTitleUserTypeMap(updatedTitleUserTypeMap);
  setValidationErrorRows({});
  setMapping(updatedMapping);
  setLoading(false);
};

// helper function to add/delete from titleUserTypeMap.
export const updateTitleUserTypeMap = (
  titleUserTypeMap: { [key: string]: boolean },
  titles: string[] | undefined,
  userType: string | undefined,
  add = false,
): void => {
  if (titles && userType) {
    for (const title of titles) {
      const key = `${title}-${userType}`;
      if (add) {
        titleUserTypeMap[key] = true;
      } else {
        delete titleUserTypeMap[key];
      }
    }
  }
};

export const getNavianceRolesMap = (data): any => {
  const navRolesMap = {};
  data.forEach((role) => {
    if (navRolesMap[role.name]) {
      navRolesMap[role.name] = [
        ...navRolesMap[role.name],
        {
          institutionId: role.institutionId,
          id: role.id,
        },
      ];
    } else {
      navRolesMap[role.name] = [
        {
          institutionId: role.institutionId,
          id: role.id,
        },
      ];
    }
  });
  return navRolesMap;
};

export const getRolesMap = (schoolRoles: any[], districtRoles: any[]): Record<number, string> => {
  const rolesMap = {};
  schoolRoles.forEach((role) => {
    rolesMap[role.id] = role.name;
  });
  districtRoles.forEach((role) => {
    rolesMap[role.id] = role.name;
  });
  return rolesMap;
};
