import React from 'react';
import CustomIcon, { styles } from '../CustomIcon';

const CloseIconNoBg = () => (
  <CustomIcon style={styles}>
    <rect id="Canvas" opacity="0" width="18" height="18" />
    <path
      fill="#141497"
      d="M13.2425,3.343,9,7.586,4.7575,3.343a.5.5,0,0,0-.707,0L3.343,4.05a.5.5,0,0,0,0,.707L7.586,9,3.343,13.2425a.5.5,0,0,0,0,.707l.707.7075a.5.5,0,0,0,.707,0L9,10.414l4.2425,4.243a.5.5,0,0,0,.707,0l.7075-.707a.5.5,0,0,0,0-.707L10.414,9l4.243-4.2425a.5.5,0,0,0,0-.707L13.95,3.343a.5.5,0,0,0-.70711-.00039Z"
    />
  </CustomIcon>
);

export default CloseIconNoBg;
