import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Table, Select, Tag, Radio } from 'antd';
import { changeMapping } from '../../utils/utils';
import { navFerpaBlockData } from '../../utils/constants';

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

interface FerpaBlockCodeMappingProps {
  ferpaBlockMapping: Record<string, (string | number)[]>;
  setFerpaBlockMapping: (ferpaBlockMapping: Record<string, (string | number)[]>) => void;
  blankFerpaValueAsNo: boolean;
  setBlankFerpaValueAsNo: (blankFerpaValueAsNo: boolean) => void;
}

const FerpaBlockCodeMapping = ({
  ferpaBlockMapping,
  setFerpaBlockMapping,
  blankFerpaValueAsNo,
  setBlankFerpaValueAsNo,
}: FerpaBlockCodeMappingProps): React.ReactElement => {
  const isMountedRef = React.useRef(null);

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    // void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const ferpaBlockTableColumns = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: 'Enter SIS Codes',
      width: '60%',
      render: (data) => (
        <Select
          mode="tags"
          size="large"
          tagRender={tagRender}
          defaultValue={ferpaBlockMapping[data.code]}
          style={{ width: '100%' }}
          options={[]}
          tokenSeparators={[',']}
          dropdownStyle={{ display: 'none' }}
          placeholder="Add custom codes"
          onChange={(change) => {
            changeMapping(data.code, change, ferpaBlockMapping, setFerpaBlockMapping);
          }}
        />
      ),
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 style={{ marginBottom: '0px' }}>Ferpa Block Requested</h2>
        <div>If matching multiple SIS codes to Naviance codes, then separate with commas</div>
        <div style={{ marginBottom: '10px', marginTop: '5px' }}>
          Save blank fields as FERPA Block Requested = <br></br>
          <Radio.Group onChange={(e) => setBlankFerpaValueAsNo(e.target.value)} value={blankFerpaValueAsNo}>
            <Radio value={true}>No</Radio>
            <Radio value={false}>Yes</Radio>
          </Radio.Group>
        </div>
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          columns={ferpaBlockTableColumns}
          dataSource={navFerpaBlockData}
          pagination={false}
        />
      </Col>
    </Row>
  );
};

export default FerpaBlockCodeMapping;
