import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisStartImport from '.';

const SisAlumniStartImport = (props: WizardProps): React.ReactElement => {
  return (
    <SisStartImport
      type="alumni"
      typeLabel="Student Records (Alumni)"
      defaultFrequency="ONE_TIME_IMPORT"
      includeSchools={true}
      {...props}
    />
  );
};

export default SisAlumniStartImport;
