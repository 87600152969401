import { Col, Modal, Row } from 'antd';
import React from 'react'
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { getIngressType } from '../../utils/utils';

interface UndoModalProps {
  undoModal : string;
  setUndoModal: (jobGuid: string) => void;
  undoType: string;
  setUndoType: (undoType: string) => void;
}

const UndoModal = ( {undoModal, setUndoModal, undoType, setUndoType} : UndoModalProps ) : React.ReactElement => {
  const [undoConfirmation, setUndoConfirmation] = React.useState('');
  const [confirmLoading, setConfirmLoading] = React.useState(false);

  const handleOk = async () => {
    setConfirmLoading(true);
    const type = getIngressType(undoType);
    const { data } = await apiClient.post('/data-ingest/sis/undo', { undoGuid: undoModal, type });
    setConfirmLoading(false);
    setUndoModal('');
    setUndoType('');
    showNotification(NotificationTypes.info, 'Undo Status', data.status);
  };

  const handleCancel = () => {
    setUndoModal('');
    setUndoType('');
  };

  return (
    <Modal
      title="Confirmation"
      visible={!!undoModal}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
      okButtonProps={{ disabled: undoConfirmation !== 'UNDO' }}
    >
      <>
        <p>
          Please Type <b>UNDO</b> to continue the undo process.
        </p>
        <Row align="middle">
          <Col span={24}>
            <input
              className="drawerInput drawerInputFull"
              type="text"
              value={undoConfirmation}
              onChange={(e) => setUndoConfirmation(e.target.value)}
              data-test-id="input_undo_confirm"
            />
          </Col>
        </Row>
      </>
    </Modal>
  );
};

export default UndoModal;