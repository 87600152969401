import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import {
  defaultNavStaffMapping,
  navianceStaffPSFields,
  navianceCleverStaffFields,
  defaultCleverStaffMapping,
} from '../../utils/fieldMapUtils';
import SisMatchDescriptionHeader from '../../components-v2/SisMatchDescriptionHeader';
import SisWizardHeader from '../../components-v2/SisWizardHeader';
import { IntegrationContext } from './../../utils/context';

const SisStaffRecordsMatching = (props: WizardProps): React.ReactElement => {
  const { isCleverIntegration } = React.useContext(IntegrationContext);

  const sections = [
    {
      tableHeader: <SisWizardHeader text={'Staff records'} />,
      defaultFields: isCleverIntegration ? navianceCleverStaffFields : navianceStaffPSFields,
      defaultBlankValue: '(Do not import)',
    },
  ];

  const customHeaders = {
    roleDefId: 'roledefid',
    staffStatus: 'staffstatus',
    isDistrict: 'isdistrict',
    isCounselor: 'iscounselor',
    schoolid: 'schoolid',
    entityId: 'schoolid',
    'SIS RoledefName': 'SIS RoledefName',
    'Nav Rolename': 'Nav Rolename',
  };

  const cleverCustomHeaders = {
    isDistrict: 'isdistrict',
    schoolid: 'school',
    entityId: 'school',
    cleverUserType: 'cleverUserType',
    'Nav Rolename': 'Nav Rolename',
    isTeacher: 'isteacher',
    isCounselor: 'iscounselor',
    jobFunction: 'jobFunction',
    isActive: 'isActive',
    'Job Function Name': 'Job Function Name',
  };

  return (
    <SisFieldMatching
      defaultMapping={isCleverIntegration ? defaultCleverStaffMapping : defaultNavStaffMapping}
      specificMapping={isCleverIntegration ? { ...cleverCustomHeaders } : { ...customHeaders }}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/staff-recordstranslateConfig'}
      extensionsEndpoint={'data-ingest/sis/staff-extension-tables'}
      removeUnmappedHeaders={isCleverIntegration ? true : false}
      removeEmptyHeaders={false}
      sections={sections}
      header={<SisMatchDescriptionHeader />}
      {...props}
    />
  );
};

export default SisStaffRecordsMatching;
