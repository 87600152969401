import * as React from 'react';
import SisCurrentCoursesDefineCodes from '../SisDefineCodes/SisCurrentCoursesDefineCodes';
import SisCurrentCoursesFieldMatching from '../SisFieldMatching/SisCurrentCoursesFieldMatching';
import SisCurrentCoursesStartImport from '../SisStartImport/SisCourseCurrentStartImport';
import SisImport from '.';
import SisCurrentCoursesSyncSettings from '../SisSyncSettings/SisCurrentCoursesSyncSettings';

export const StudentCourseDataSteps = [
  {
    title: 'Match Fields',
    content: SisCurrentCoursesFieldMatching,
    path: '/import-wizard/student-course/match-fields',
  },
  { title: 'Define Codes', content: SisCurrentCoursesDefineCodes, path: '/import-wizard/student-course/define-code' },
  {
    title: 'Sync Settings',
    content: SisCurrentCoursesSyncSettings,
    path: '/import-wizard/student-course/sync-settings',
  },
  {
    title: 'Start Import',
    content: SisCurrentCoursesStartImport,
    path: '/import-wizard/student-course/test-start-import',
  },
];

const SisCurrentCourses = (): React.ReactElement => {
  return <SisImport steps={StudentCourseDataSteps} />;
};

export default SisCurrentCourses;
