import * as React from 'react';
import { IntegrationContext } from '../../utils/context';

const SisMatchDescriptionHeader = (): React.ReactElement => {
  const { isCleverIntegration } = React.useContext(IntegrationContext);
  return (
    <>
      {isCleverIntegration ? (
        <>
          <h3>Define the import by matching the Naviance field to the corresponding Clever field.</h3>
          <br />
        </>
      ) : (
        <>
          <h3>Define the import by matching the Naviance field to corresponding SIS data field.</h3>
          <br />
        </>
      )}
    </>
  );
};

export default SisMatchDescriptionHeader;
