export enum CronExpressions {
  THIRTY_MINUTES = '0/30 * * * ? *',
  ONE_HOUR = '0/60 * * * ? *',
  FOUR_HOURS = '0 0/4 * * ? *',
  TWELVE_HOURS = '0 0/12 * * ? *',
  TWENTY_FOUR_HOURS = '0 0 * * ? *',
  ONE_TIME_IMPORT = '',
  ALUMNI_TRACKER = '0 0 1 APR,AUG,NOV ? *',
  ONE_WEEK = '0 0/168 * * ? *',
}

export type CronExpressionNames = keyof typeof CronExpressions;

export enum Frequency {
  TWENTY_FOUR_HOURS = 'TWENTY_FOUR_HOURS',
}

export enum JobMethodEnum {
  psSis = 'PowerSchool SIS',
  eSP = 'eSchoolPlus SIS',
  clever = 'clever',
}

export enum TenantDDBTypes {
  psSis = 'StudentSis',
  eSP = 'ESPStudentSis',
  clever = 'CleverStudentSis',
}

export enum SISCourseImportTypes {
  COURSE_CATALOG = 'course-catalog',
  COURSE_HISTORY = 'course-history',
  COURSE_MAPPING = 'course-mapping',
  CURRENT_COURSES = 'course-current',
}

export const scedMapping = {
  1: 'English Language and Literature',
  2: 'Mathematics',
  3: 'Life and Physical Sciences',
  4: 'Social Sciences and History',
  5: 'Visual and Performing Arts',
  6: 'Foreign Language and Literature',
  7: 'Religious Education and Theology',
  8: 'Physical, Health, and Safety Education',
  9: 'Military Science',
  10: 'Information Technology',
  11: 'Communication and Audio\\/Visual Technology',
  12: 'Business and Marketing',
  13: 'Manufacturing',
  14: 'Health Care Sciences',
  15: 'Public, Protective, and Government Services',
  16: 'Hospitality and Tourism',
  17: 'Architecture and Construction',
  18: 'Agriculture, Food, and Natural Resources',
  19: 'Human Services',
  20: 'Transportation, Distribution and Logistics',
  21: 'Engineering and Technology',
  22: 'Miscellaneous',
  23: 'Non-Subject-Specific',
  24: 'World Languages',
};

export const csscMapping = {
  1: 'AGRIBUSINESS AND AGRICULTURAL PRODUCTION',
  2: 'AGRICULTURAL SCIENCES',
  3: 'RENEWABLE NATURAL RESOURCES',
  4: 'ARCHITECTURE AND ENVIRONMENTAL DESIGN',
  5: 'AREA AND ETHNIC STUDIES',
  6: 'BUSINESS AND MANAGEMENT',
  7: 'BUSINESS AND OFFICE',
  8: 'MARKETING AND DISTRIBUTION',
  9: 'COMMUNICATIONS',
  10: 'COMMUNICATION TECHNOLOGIES',
  11: 'COMPUTER AND INFORMATION SCIENCES',
  12: 'CONSUMER, PERSONAL, AND MISCELLANEOUS SERVICES',
  13: 'EDUCATION',
  14: 'ENGINEERING',
  15: 'ENGINEERING AND ENGINEERING-RELATED TECHNOLOGIES',
  16: 'FOREIGN LANGUAGES',
  17: 'ALLIED HEALTH',
  18: 'HEALTH SCIENCES',
  19: 'HOME ECONOMICS.',
  20: 'VOCATIONAL HOME ECONOMICS',
  21: 'INDUSTRIAL ARTS',
  22: 'LAW',
  23: 'LETTERS/ENGLISH',
  24: 'LIBERAL/GENERAL STUDIES',
  25: 'LIBRARY AND ARCHIVAL SCIENCES',
  26: 'LIFE SCIENCES',
  27: 'MATHEMATICS',
  28: 'MILITARY SCIENCES',
  29: 'MILITARY TECHNOLOGIES',
  30: 'MULTI/INTERDISCIPLINARY STUDIES',
  31: 'PARKS AND RECREATION',
  32: 'BASIC SKILLS',
  33: 'CITIZENSHIP/CIVIC ACTIVITIES',
  34: 'HEALTH RELATED ACTIVITIES',
  35: 'INTERPERSONAL SKILLS',
  36: 'LEISURE AND RECREATIONAL ACTIVITIES',
  37: 'PERSONAL AWARENESS',
  38: 'PHILOSOPY AND RELIGION',
  39: 'THEOLOGY',
  40: 'PHYSICAL SCIENCES',
  41: 'SCIENCE TECHNOLOGIES',
  42: 'PSYCHOLOGY',
  43: 'PROTECTIVE SERVICES',
  44: 'PUBLIC AFFAIRS',
  45: 'SOCIAL SCIENCES',
  46: 'CONSTRUCTION TRADES',
  47: 'MECHANICS AND REPAIRERS',
  48: 'PRECISION PRODUCTION',
  49: 'TRANSPORTATION AND MATERIAL MOVING',
  50: 'VISUAL AND PERFORMING ARTS',
  51: 'EXECUTIVE INTERNSHIP',
  52: 'GENERAL (INCLUDING PRE-VOCATIONAL PROGRAMS) EMH',
  54: 'SPECIAL EDUCATION',
  55: 'SPECIAL EDUCATION - VOCATIONAL, CAREER PREPARATION, CAREER EXPLORATION',
  56: 'SPECIAL EDUCATION - RESOURCE CURRICULUM',
};

export const SisPluginBuildVersions = [
  '22.7.0',
];
