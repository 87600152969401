import type { SisTableColumn } from '../../../../../../libs/common-interfaces';
import Space from 'antd/lib/space';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import Col from '../Col';
import Row from '../Row';
import Select from '../Select';
import Banner from '../../components/Drawer/banner';
import apiClient from '../../utils/apiClient';

interface ExtensionTable {
  coretablepk: string;
  dbtablename: string;
  foreignkey: string;
  name: string;
}

export interface CustomFieldDrawerProps {
  save: (tableName: string, fieldName: string, primaryKey: string) => void;
  tableName?: string;
  fieldName?: string;
  navianceField: string;
  extensionsEndpoint?: string;
}

const CustomFieldDrawer = (props: CustomFieldDrawerProps): React.ReactElement => {
  const { Option } = Select;

  const [message, setMessage] = React.useState<string | null>(null);
  const [isErrorMsg, setIsErrorMsg] = React.useState(false);

  const [extTables, setExtTables] = React.useState<ExtensionTable[]>([]);
  const [tableFields, setTableFields] = React.useState<SisTableColumn[]>([]);
  const [selectedTable, setSelectedTable] = React.useState<string>();
  const [selectedField, setSelectedField] = React.useState<string>();
  const [tablePrimaryKey, setTablePrimaryKey] = React.useState<string>('');

  const selectTable = async (tableName: string, preSelectedFieldName?: string) => {
    try {
      setSelectedTable(tableName);
      setSelectedField(null);

      const { data } = await apiClient.get(`/data-ingest/sis/table-meta/${tableName}`);
      const sortedData = data.columns.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0,
      );

      setTableFields(sortedData);
      setTablePrimaryKey(data.primaryKey);
      setIsErrorMsg(false);

      if (preSelectedFieldName) {
        setSelectedField(preSelectedFieldName);
      }
    } catch (err) {
      console.error(err);
      setMessage('Error fetching table columns');
      setIsErrorMsg(true);
    }
  };

  const canSave = () => {
    return !selectTable || !selectedField;
  };

  const save = async () => {
    props.save(selectedTable, selectedField, tablePrimaryKey);
  };

  const getExtensionTables = async () => {
    try {
      const { data } = await apiClient.get(
        `/${props.extensionsEndpoint ?? 'data-ingest/sis/student-extension-tables'}`,
      );
      const sortedData = data.sort((a, b) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? -1 : a.name.toLowerCase() < b.name.toLowerCase() ? 1 : 0,
      );

      setExtTables(sortedData);
      setIsErrorMsg(false);

      if (props.tableName) {
        selectTable(props.tableName, props.fieldName);
      }
    } catch (err) {
      console.error(err);
      setMessage('Error fetching extension tables');
      setIsErrorMsg(true);
    }
  };

  React.useEffect(() => {
    void getExtensionTables();
  }, []);

  return (
    <div className="mainDrawer">
      {message && <Banner message={message} handleClose={() => setMessage(null)} isErrorMsg={isErrorMsg} />}
      <Row>
        <Col span={24}>
          <Title className="drawerTitle" level={1} data-test-id="drawer_title">
            Specify custom SIS data field
          </Title>
        </Col>
      </Row>

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row align="middle" gutter={[24, 16]}>
          <Col className="" span={24}>
            <p className="drawerLableText boldDrawerLabelText" data-test-id="table_selection_label">
              Naviance Field
            </p>
          </Col>
          <Col className="gutter-row" span={24}>
            <Title level={5}>{props.navianceField}</Title>
          </Col>
          <Col className="gutter-row" span={24}>
            <p className="drawerLableText boldDrawerLabelText" data-test-id="table_selection_label">
              Table
            </p>
          </Col>
          <Col className="gutter-row" span={24}>
            <Select
              className="select"
              placeholder="Select a table"
              value={selectedTable}
              onChange={(tableName) => selectTable(tableName)}
              data-test-id="table_select"
              style={{ width: '60%' }}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {extTables.map((table) => (
                <Option
                  className="selectOptions"
                  key={`table-${table.dbtablename}`}
                  value={table.dbtablename}
                  data-test-id={`${table.dbtablename}_option`}
                >
                  {table.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
        <Row align="middle" gutter={[24, 16]}>
          <Col className="gutter-row" span={24}>
            <p className="drawerLableText boldDrawerLabelText" data-test-id="fields_selection_label">
              Available Fields
            </p>
          </Col>
          <Col className="gutter-row" span={24}>
            <Select
              className="select"
              placeholder="Select a field"
              value={selectedField}
              onChange={(fieldName) => setSelectedField(fieldName)}
              data-test-id="field_select"
              style={{ width: '60%' }}
              disabled={!selectedTable}
              showSearch
              filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            >
              {tableFields.map((field) => (
                <Option
                  className="selectOptions"
                  key={`field-${field.name}`}
                  value={field.name}
                  data-test-id={`${field.name}_option`}
                >
                  {field.name}
                </Option>
              ))}
            </Select>
          </Col>
        </Row>
      </Space>
      <Row>
        <Col className="drawerTopGutter" span={24}>
          <Space align="center">
            <button className="buttonSolid" onClick={() => save()} data-test-id="save_btn" disabled={canSave()}>
              Save
            </button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default CustomFieldDrawer;
