// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import * as React from 'react';

// components
import { Select } from 'antd';

// local
import { GradeLevelParameterGroup } from '../../containers/SisDefineCodes/common';

// TS
type GradeRange = Array<[number, string]>;
export interface GradeLevelDefineCodeProps {
  gradeLevelRange: GradeLevelParameterGroup;
  triggerSetState: () => void;
  minFromGrade?: number;
}

// Config
export const gradeRangeData: GradeRange = [
  [1, '1st'],
  [2, '2nd'],
  [3, '3rd'],
  [4, '4th'],
  [5, '5th'],
  [6, '6th'],
  [7, '7th'],
  [8, '8th'],
  [9, '9th'],
  [10, '10th'],
  [11, '11th'],
  [12, '12th'],
];

function getFromGradeRange(minFromGrade?: number): GradeRange {
  if (!minFromGrade) {
    return gradeRangeData;
  }

  return gradeRangeData.slice(minFromGrade - 1);
}

const GradeLevelDefineCode = (props: GradeLevelDefineCodeProps): React.ReactElement => {
  const { gradeLevelRange, triggerSetState, minFromGrade } = props;
  const fromRangeData = getFromGradeRange(minFromGrade);
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [gradeLevelSettings, setGradeLevelSettings] = React.useState<GradeLevelParameterGroup>({
    fromGrade: 0,
    toGrade: 0,
  });
  const [toIndexRange, setToIndexRange] = React.useState(gradeRangeData);

  React.useEffect(() => {
    setGradeLevelSettings({
      fromGrade: gradeLevelRange.fromGrade,
      toGrade: gradeLevelRange.toGrade,
    });
  }, []);

  React.useEffect(() => {
    const toRangeData = gradeRangeData.slice(Math.max(gradeLevelSettings.fromGrade - 1, 0));
    setToIndexRange(toRangeData);
  }, [gradeLevelSettings]);

  const onOptionChange = (grade: number, isFromGradeTriggered = false) => {
    const updatedGradeLevel: GradeLevelParameterGroup = JSON.parse(JSON.stringify(gradeLevelSettings));

    if (isFromGradeTriggered) {
      updatedGradeLevel.fromGrade = grade;
      // if we have a "from" grade greater than "to" grade then reset "to" grade
      if (updatedGradeLevel.toGrade < updatedGradeLevel.fromGrade) {
        updatedGradeLevel.toGrade = grade;
      }
    } else {
      updatedGradeLevel.toGrade = grade;
    }

    gradeLevelRange.toGrade = updatedGradeLevel.toGrade;
    gradeLevelRange.fromGrade = updatedGradeLevel.fromGrade;

    setGradeLevelSettings({ ...updatedGradeLevel });
    triggerSetState();
  };

  return (
    <div className="subSection mainMatchFieldsSection">
      <h2 className="infoHeading uploadHeading">Grade Level</h2>
      <span className="pagepara" style={{ marginRight: '4px' }}>
        Import grades starting from
      </span>
      <Select
        className="select select-responsive"
        style={{ width: 100 }}
        value={gradeLevelSettings.fromGrade}
        onChange={(val) => {
          onOptionChange(val, true);
        }}
        loading={loading}
        data-test-id={'grade-level-from'}
      >
        {fromRangeData.map(([value, display]) => (
          <Option
            className="selectOptions"
            key={`sis-grade-id-${value}`}
            value={value}
            data-test-id={`state_grade_value_${value}`}
          >
            {display}
          </Option>
        ))}
      </Select>
      <span className="pagepara" style={{ margin: '0px 4px' }}>
        to
      </span>
      <Select
        className="select select-responsive"
        style={{ width: 100 }}
        value={gradeLevelSettings.toGrade}
        onChange={(val) => {
          onOptionChange(val);
        }}
        data-test-id={'grade-level-to'}
        loading={loading}
      >
        {toIndexRange.map(([value, display]) => (
          <Option
            className="selectOptions"
            key={`sis-grade-id-${value}`}
            value={value}
            data-test-id={`state_grade_value_${value}`}
          >
            {display}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default GradeLevelDefineCode;
