export const UndoMessages = {
  COMPLETE: 'Undo Complete',
  FAILED: 'Undo Failed',
};

export const StatusMessages = {
  VALIDATION_IN_PROGRESS: 'Validation in progress',
  IMPORT_IN_PROGRESS: 'Import in progress',
  IMPORT_COMPLETED: 'Import Completed',
  IMPORT_COMPLETED_WITH_ALERTS: 'Import Completed with Alerts',
  CRITICAL_ERROR: 'Critical Error - Import Cancelled',

  TESTING_COMPLETED: 'Testing Completed',
  TESTING_COMPLETED_WITH_ALERTS: 'Testing Completed with Alerts',

  SYNC_VALIDATION_IN_PROGRESS: 'Sync Validation in Progress',
  SYNC_COMPLETED_WITH_ALERTS: 'Sync Completed with Alerts',
  SYNC_IN_PROGRESS: 'Sync in progress',
  LEGACY_CLEVER_ENABLED: 'Legacy Clever Student Import needs to be disabled before using modern Clever Student Import'
};

export const getJobStatusMsg = (job: any): string => {
  if (job.undo && job.undo.status) {
    return UndoMessages[job.undo.status] ?? 'Undo in progress';
  }
  return StatusMessages[job.navianceStatus] ?? job.navianceStatus;
};
