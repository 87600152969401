import * as React from 'react';
import { Table } from 'antd';
import { featureFlags } from '../../../utils/featureFlags';
export interface classDetailsData {
  year: number;
  totalRecords: number;
  invalid: number;
  activeRecords: number;
  created: number;
  updated: number;
  noChanges: number;
  transfered: number;
  reconcile: number;
  missing: number;
  deactivateInNaviance: number;
  notTransfered: number;
  reactivated: number;
}
export interface classDetailsDataObj {
  dataSource: classDetailsData[];
  showTitle: boolean;
  setSelectedClassYear?: (selectedClassYear: number) => void;
  setOpen?: (open: boolean) => void;
}
const ClassDetails = (props: classDetailsDataObj): React.ReactElement => {
  const openDrawer = (classYear) => {
    props.setSelectedClassYear(classYear);
    props.setOpen(true);
  };

  const columns = [
    {
      title: 'Class Year',
      dataIndex: 'year',
      key: 'ClassYear',
    },
    {
      title: 'Total Records',
      dataIndex: 'totalRecords',
      key: 'Total Records',
    },
    {
      title: 'Invalid',
      dataIndex: 'invalid',
      key: 'Invalid',
    },
    {
      title: 'Active Records',
      dataIndex: 'activeRecords',
      key: 'Active Records',
    },
    {
      title: 'Create',
      dataIndex: 'created',
      key: 'Create',
    },
    {
      title: 'Update(Transfer)',
      dataIndex: 'updateWithTransfer',
      key: 'Update(Transfer)',
    },
    {
      title: 'No Changes',
      dataIndex: 'noChanges',
      key: 'No Changes',
    },
    {
      title: 'Reconcile',
      dataIndex: 'reconcile',
      key: 'Reconcile',
    },
    {
      title: 'Missing',
      dataIndex: 'missing',
      key: 'Missing',
    },
    ...(featureFlags['feature.dataIngest.showDeactivatedStudentsDrawer'] ? [{
      title: 'Deactivate In Naviance',
      render: (row) => (
        <>
          {
            row.deactivateInNaviance > 0 ? (
              <a onClick={() => openDrawer(row.year)}>
                {row.deactivateInNaviance}
              </a>
            ) : (
              <span>{row.deactivateInNaviance}</span>
            )
          }
        </>
      ),
    }]
      : [{
        title: 'Deactivate In Naviance',
        dataIndex: 'deactivateInNaviance',
        key: 'Deactivate In Naviance',
      }]
    ),
    {
      title: 'Reactivated',
      dataIndex: 'reactivated',
      key: 'Reactivated',
    },
    {
      title: 'Not Transfered',
      dataIndex: 'notTransfered',
      key: 'Not Transfered',
    },
  ];

  return (
    <div>
      {props.showTitle && <h3>Records Per Class Year</h3>}
      <Table
        dataSource={props.dataSource}
        columns={columns}
        pagination={false}
        summary={(pageData) => {
          let totalRecordColSum = 0;
          let totalInvalidColSum = 0;
          let totalActiveRecordColSum = 0;
          let totalCreateRecordColSum = 0;
          let totalUpdateColSum = 0;
          let totalTransferColSum = 0;
          let totalNoChangeColSum = 0;
          let totalReconcileColSum = 0;
          let totalMissingSum = 0;
          let totalDeactivateColSum = 0;
          let totalReactivatedColSum = 0;
          let totalNotTranferedColSum = 0;

          pageData.forEach((element) => {
            totalRecordColSum += element.totalRecords;
            totalInvalidColSum += element.invalid;
            totalActiveRecordColSum += element.activeRecords;
            totalCreateRecordColSum += element.created;
            totalUpdateColSum += element.updated;
            totalTransferColSum += element.transfered;
            totalNoChangeColSum += element.noChanges;
            totalReconcileColSum += element.reconcile;
            totalMissingSum += element.missing;
            totalDeactivateColSum += element.deactivateInNaviance;
            totalReactivatedColSum += element.reactivated;
            totalNotTranferedColSum += element.notTransfered;
          });

          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={1}>Total</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalRecordColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalInvalidColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalActiveRecordColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalCreateRecordColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>
                  {totalUpdateColSum} ({totalTransferColSum})
                </Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalNoChangeColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalReconcileColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalMissingSum}</Table.Summary.Cell>
                {totalDeactivateColSum ? (
                  <Table.Summary.Cell index={1}>
                    <a onClick={() => openDrawer(1)}>{totalDeactivateColSum}</a>
                  </Table.Summary.Cell>
                ) : (
                  <Table.Summary.Cell index={1}>{totalDeactivateColSum}</Table.Summary.Cell>
                )}
                <Table.Summary.Cell index={1}>{totalReactivatedColSum}</Table.Summary.Cell>
                <Table.Summary.Cell index={1}>{totalNotTranferedColSum}</Table.Summary.Cell>
              </Table.Summary.Row>
            </>
          );
        }}
      />
    </div>
  );
};

export default ClassDetails;
