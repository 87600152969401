import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { ImportTypeParam } from '../../../utils/constants';
import DefineCodes from '../DefineCodes';
import MatchFieldsComponent from '../MatchFields/MatchFields';
import FileUpload from './FileUpload';
import apiClient from '../../../utils/apiClient';

type Props = {
  importType: ImportTypeParam;
};

type Pages = 'FileUpload' | 'DefineCodes' | 'MatchFields';

const Import: FC<Props> = ({ importType }) => {
  const history = useHistory();
  const [renderingComponent, setComponentToRender] = React.useState<Pages>('FileUpload');
  const [isDistrict, setIsDistrict] = React.useState<any>();
  React.useEffect(() => {
    apiClient.get(`/user`).then((resp) => setIsDistrict(!resp.data.highschoolId));
  }, []);

  const renderNextComponent = (componentToRender: Pages) => {
    setComponentToRender(componentToRender);
  };
  const onCancel = () => {
    if (importType === ImportTypeParam.Scholarship) {
      window.location.href = `${window.location.origin}/${isDistrict ? 'district/' : ''}setupmain/index.php`;
    } else {
      history.push('/scholarships');
    }
  };

  const renderComponent = () => {
    switch (renderingComponent) {
      case 'FileUpload':
        return <FileUpload onCancel={onCancel} importType={importType} renderNextComponent={renderNextComponent} />;

      case 'MatchFields':
        return (
          <MatchFieldsComponent onCancel={onCancel} importType={importType} renderNextComponent={renderNextComponent} />
        );

      case 'DefineCodes':
        return <DefineCodes onCancel={onCancel} importType={importType} isDistrict={isDistrict} />;

      default:
        return <FileUpload onCancel={onCancel} importType={importType} renderNextComponent={renderNextComponent} />;
    }
  };

  return <div>{renderComponent()}</div>;
};

export default Import;
