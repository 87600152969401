import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import { courseMappingFields, defaultNavCourseMapping } from '../../utils/fieldMapUtils';

const SisCourseMappingFieldMatching = (props: WizardProps): React.ReactElement => {
  const Header = () => {
    return <h3>Define the import by matching the Naviance field to the corresponding SIS data field.</h3>;
  };

  const sections = [
    {
      defaultFields: courseMappingFields,
      defaultBlankValue: '(Do not import)',
    },
  ];

  return (
    <SisFieldMatching
      defaultMapping={defaultNavCourseMapping}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/course-mapping'}
      header={<Header />}
      sections={sections}
      {...props}
    />
  );
};

export default SisCourseMappingFieldMatching;
