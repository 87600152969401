import { Col, Row, Badge, Typography, Tag, Button } from 'antd';
import React from 'react';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { featureFlags } from '../../utils/featureFlags';
import { getJobStatusMsg } from '../../utils/jobStatus';
import { API_URL } from '../../utils/constants';
import { ExclamationCircleOutlined, WarningOutlined, SyncOutlined } from '@ant-design/icons';
import Link from 'antd/lib/typography/Link';
import { ClassYearSummaryRecord } from '../../../../../../libs/common-interfaces';

const { Text } = Typography;

const NameComponent = ({
  job,
  setActiveKey,
  styles,
  errorColumnsPerCategory,
  errorRowsPerCategory,
  warningColumnsPerCategory,
  warningRowsPerCategory,
  targetRef,
}): React.ReactElement => {
  const scrollToComponent = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <>
      {/* Alerts Header */}
      <Row>
        <div style={styles.header}>
          <Text strong>Alerts</Text>
          <Badge
            count={`${(job?.recordsSkipped || 0) + (job?.recordsNotSkipped || 0)}`}
            style={styles.badge}
            overflowCount={99999}
            showZero
          />
        </div>
      </Row>
      {/* Main Container */}
      <Row>
        <div style={styles.container}>
          <Row style={{ ...styles.section, ...{ borderBottom: '1px solid #d6d6d6' } }}>
            <Col span={23}>
              <div style={styles.rowDiv}>
                <div style={styles.col1Div}>
                  <ExclamationCircleOutlined style={{ ...styles.icon, ...styles.errors }} />
                  {job?.recordsSkipped ? (
                    <Link
                      strong
                      style={styles.errors}
                      onClick={() => {
                        scrollToComponent();
                        setActiveKey('2');
                      }}
                      underline={true}
                    >
                      Errors
                    </Link>
                  ) : (
                    <Text strong style={styles.errors}>
                      Errors
                    </Text>
                  )}{' '}
                  <Badge
                    count={`${
                      (job?.recordsSkipped || 0) +
                      ((featureFlags['feature.dataIngest.identityProtectionStudent'] &&
                        job?.identityChange?.warningCount) ||
                        0)
                    }`}
                    style={styles.badge}
                    overflowCount={99999}
                    showZero
                  />
                </div>
                <div>
                  {job?.identityChange?.warningCount != 0 &&
                  job?.identityChange?.warningCount >= job?.identityChange?.max &&
                  featureFlags['feature.dataIngest.identityProtectionStudent'] ? (
                    <>
                      <Text>Data corruption threat detected</Text>
                      <br />
                      <Text>
                        {job?.identityChange?.warningCount} updates for both first name and last name detected
                      </Text>
                    </>
                  ) : (
                    <Text>Records skipped and students not transferred</Text>
                  )}
                  {Number(job?.notTransfered) > 0 && <Text> {job?.notTransfered} Student Not Transferred</Text>}
                  <br />
                  {Number(job?.validation?.invalidCount) > 0 && (
                    <Row>
                      <Text>
                        {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                          return (
                            <>
                              <span style={{ fontWeight: 'bold' }}>
                                {`${errorRowsPerCategory[category]} ${category}${
                                  errorColumnsPerCategory[category]?.length ? ': ' : ''
                                }`}
                              </span>
                              {errorColumnsPerCategory[category]?.join(', ')}
                              <br />
                            </>
                          );
                        })}
                      </Text>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ ...styles.section, ...{ borderBottom: '1px solid #d6d6d6' } }}>
            <Col span={23}>
              <div style={styles.rowDiv}>
                <div style={styles.col1Div}>
                  <WarningOutlined style={{ ...styles.icon, ...styles.warnings }} />
                  {job?.recordsNotSkipped ? (
                    <Link
                      strong
                      style={styles.warnings}
                      onClick={() => {
                        scrollToComponent();
                        setActiveKey('3');
                      }}
                      underline={true}
                    >
                      Warnings
                    </Link>
                  ) : (
                    <Text strong style={styles.warnings}>
                      Warnings
                    </Text>
                  )}{' '}
                  <Badge count={`${job?.recordsNotSkipped || 0}`} style={styles.badge} showZero overflowCount={99999} />
                </div>
                <div>
                  <Text>Records not skipped</Text>
                  <br />
                  {Number(job?.recordsNotSkipped) > 0 && (
                    <Row>
                      <Text>
                        {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                          return (
                            <>
                              <span style={{ fontWeight: 'bold' }}>
                                {`${warningRowsPerCategory[category]} ${category}${
                                  warningColumnsPerCategory[category]?.length ? ': ' : ''
                                }`}
                              </span>
                              {warningColumnsPerCategory[category]?.join(', ')}
                              <br />
                            </>
                          );
                        })}
                      </Text>
                    </Row>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row style={styles.section}>
            <Col span={23}>
              <div style={styles.rowDiv}>
                <div style={styles.col1Div}>
                  <SyncOutlined style={{ ...styles.icon, ...styles.reconciliations }} />
                  {job?.toReconcile ? (
                    <Link
                      strong
                      style={styles.reconciliations}
                      onClick={() => {
                        scrollToComponent();
                        setActiveKey('4');
                      }}
                      underline={true}
                    >
                      Reconciliations
                    </Link>
                  ) : (
                    <Text strong style={styles.warnings}>
                      Reconciliations
                    </Text>
                  )}{' '}
                  <Badge count={job?.toReconcile || 0} style={styles.badge} showZero overflowCount={99999} />
                </div>
                <div>
                  <Text>{job?.toReconcile} records to reconcile</Text>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </>
  );
};

const studentImportData = {
  studentDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
    history,
    setActiveKey,
    targetRef,
  ) => {
    const styles = {
      header: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative', // Positioning the Alerts header
      },
      container: {
        backgroundColor: '#f5f7fa',
        borderRadius: '8px',
        marginTop: '16px',
        width: '-webkit-fill-available',
      },
      section: {
        marginLeft: '10px',
        marginRight: '10px',
        display: 'flex',
        justifyContent: 'space-between',
      },
      icon: {
        fontSize: '18px',
        marginRight: '8px',
      },
      badge: {
        backgroundColor: '#1890ff',
        marginLeft: '8px',
        marginRight: '8px',
      },
      errors: {
        color: '#ff4d4f',
      },
      warnings: {
        color: '#faad14',
      },
      reconciliations: {
        color: '#1890ff',
      },
      rowDiv: {
        display: 'flex',
        margin: '15px 0px',
      },
      col1Div: {
        width: '25%',
      },
      col2Div: {
        width: '80%',
      },
    };
    const details = [];

    const unMappedCodes = {
      name: 'Unmapped Codes',
      key: 'Unmapped Codes',
      value: (
        <>
          <Row>
            <Col span={2}>
              <AlertYellowIcon />
            </Col>
            <Col span={22}>
              <span>
                {(job?.unMappedCodes?.counselors.length || 0) + (job?.unMappedCodes?.diplomaTypes.length || 0)}
              </span>
            </Col>
          </Row>
          {job?.unMappedCodes?.diplomaTypes?.length > 0 ? (
            <Row>
              <Col span={2}></Col>
              <Col span={22}>
                <span style={{ fontWeight: 'bold' }}>
                  {job?.unMappedCodes?.diplomaTypes.length || 0} Unmapped Codes:
                </span>{' '}
                DiplomaType
                <ul className="custom-bullet">
                  {job?.unMappedCodes?.diplomaTypes.map((diplomaType) => {
                    return (
                      <li key={diplomaType}>
                        <span>{diplomaType}</span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {job?.unMappedCodes?.counselors?.length > 0 ? (
            <Row>
              <Col span={2}></Col>
              <Col span={22}>
                <span style={{ fontWeight: 'bold' }}>{job?.unMappedCodes?.counselors.length || 0} Unmapped Codes:</span>{' '}
                Counselors
                <ul className="custom-bullet">
                  {job?.unMappedCodes?.counselors.map((counselor) => {
                    return (
                      <li key={counselor}>
                        <span>{counselor}</span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </>
      ),
    };

  const unMappedCodesNew = {
    name: (
      <>
        <Row>
          <div style={styles.header}>
            <Text strong>Unmapped Codes</Text>
            <Badge
              count={(job?.unMappedCodes?.counselors?.length || 0) + (job?.unMappedCodes?.diplomaTypes?.length || 0)}
              style={styles.badge}
              overflowCount={99999}
              showZero
            />
          </div>
        </Row>
        <Row>
          <div style={styles.container}>
            {/* Diploma Types Section */}
            {job?.unMappedCodes?.diplomaTypes?.length > 0 && (
              <Row style={{ ...styles.section, borderBottom: '1px solid #d6d6d6' }}>
                <Col span={24}>
                  <div style={styles.rowDiv}>
                    <div style={{ ...styles.col1Div, display: 'flex' }}>
                      <Text strong>Diploma Types</Text>
                      <Badge
                        count={job?.unMappedCodes?.diplomaTypes?.length || 0}
                        style={styles.badge}
                        overflowCount={99999}
                        showZero
                      />
                    </div>
                    <div style={{ ...styles.col2Div }}>
                      {job?.unMappedCodes?.diplomaTypes.map((diplomaType) => (
                        <Tag style={{ padding: 6, marginTop: 6, borderRadius: 8 }} color="gold" key={diplomaType}>
                          <span>{diplomaType}</span>
                        </Tag>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            )}

            {/* Counselors Section */}
            {job?.unMappedCodes?.counselors?.length > 0 && (
              <Row style={{ ...styles.section, borderBottom: '1px solid #d6d6d6' }}>
                <Col span={24}>
                  <div style={styles.rowDiv}>
                    <div style={{ ...styles.col1Div, display: 'flex' }}>
                      <Text strong>Counselors</Text>
                      <Badge
                        count={job?.unMappedCodes?.counselors?.length || 0}
                        style={styles.badge}
                        overflowCount={99999}
                        showZero
                      />
                    </div>
                    <div style={{ ...styles.col2Div }}>
                      {job?.unMappedCodes?.counselors.map((counselor) => (
                        <Tag style={{ padding: 6, marginTop: 6, borderRadius: 8 }} color="gold" key={counselor}>
                          <span>{counselor}</span>
                        </Tag>
                      ))}
                    </div>
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </Row>
      </>
    ),
    key: 'Unmapped Codes',
    value: <></>,
  };

    const fieldsSharedChanges = {
      name: 'Field Shared Changes',
      key: 'Field Shared Changes',
      value: (
        <>
          {job?.fieldsSharedChanges?.addedFields && (
            <>
              <Row>
                <Col>
                  <b>Fields added from previous mapping</b>
                </Col>
              </Row>
              {job?.fieldsSharedChanges?.addedFields.map((field) => {
                return (
                  <Row key={field}>
                    <Col span={1}></Col>
                    <Col>
                      <span>{field}</span>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
          {job?.fieldsSharedChanges?.removedFields && (
            <>
              <Row>
                <Col>
                  <b>Fields removed from previous mapping</b>
                </Col>
              </Row>
              {job?.fieldsSharedChanges?.removedFields.map((field) => {
                return (
                  <Row key={field}>
                    <Col span={1}></Col>
                    <Col>
                      <span>{field}</span>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </>
      ),
    };

    const fieldsSharedChangesNew = {
      name: (
        <Row>
          <div style={styles.header}>
            <Text strong>Field Shared Changes</Text>
            <Badge
              count={
                (job?.fieldsSharedChanges?.addedFields?.length || 0) +
                (job?.fieldsSharedChanges?.removedFields?.length || 0)
              }
              style={styles.badge}
              showZero
            />
          </div>
        </Row>
      ),
      key: 'Field Shared Changes',
      value: <></>,
    };

    const alerts = {
      name: 'Alerts',
      key: 'Alerts',
      value: (
        <Row>
          {hasIssues && (
            <Col span={2}>
              <AlertYellowIcon />
            </Col>
          )}
          <Col span={22}>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>{(job?.recordsSkipped || 0) + (job?.recordsNotSkipped || 0)}</span>
              </Col>
            </Row>
            {Number(job?.toReconcile) > 0 && (
              <Row>
                <Col>
                  <span style={{ fontWeight: 'bold' }}>{job?.toReconcile} To Reconcile</span>
                </Col>
              </Row>
            )}
            {Number(job.notTransfered) > 0 && (
              <Row>
                <Col>
                  <span style={{ fontWeight: 'bold' }}>{job?.notTransfered} Student Not Transferred</span>
                </Col>
              </Row>
            )}
            {Number(job?.validation?.invalidCount) > 0 && (
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.invalidCount} Errors:`}</span> Records
                  Skipped
                  <ul className="custom-bullet">
                    {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${errorRowsPerCategory[category]} ${category}${
                              errorColumnsPerCategory[category]?.length ? ': ' : ''
                            }`}
                          </span>
                          {errorColumnsPerCategory[category]?.join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            )}
            {Number(job?.recordsNotSkipped) > 0 && (
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${job?.recordsNotSkipped} Warnings:`}</span> Records Not
                  Skipped
                  <ul className="custom-bullet">
                    {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${warningRowsPerCategory[category]} ${category}${
                              warningColumnsPerCategory[category]?.length ? ': ' : ''
                            }`}
                          </span>
                          {warningColumnsPerCategory[category]?.join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            )}
            <Row>
              <a href={`${API_URL}/data-ingest/sis/jobs/${job?.id}/download/logs`} data-test-id={`match-export-link`}>
                Export Log for additional details
              </a>
            </Row>
          </Col>
        </Row>
      ),
    };

    const alertsNew = {
      name: (
        <NameComponent
          job={job}
          setActiveKey={setActiveKey}
          styles={styles}
          errorColumnsPerCategory={errorColumnsPerCategory}
          errorRowsPerCategory={errorRowsPerCategory}
          warningColumnsPerCategory={warningColumnsPerCategory}
          warningRowsPerCategory={warningRowsPerCategory}
          targetRef={targetRef}
        />
      ),
      key: 'Alerts',
      value: <></>,
    };

    const stuedentUpdateLog = {
      name: 'Last Potential Update Resolved',
      key: 'PotentialUpdatesResolved',
      value: (
        <Row>
          <Col span={22}>
            <Row>
              <Col>
                <a className="backbtn" onClick={() => history.push('/studentUpdates')}>
                  View Student Updates Log
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    };

    const stuedentUpdateLogNew = {
      name: (
        <Row>
          <div style={styles.header}>
            <Col span={15}>
              <Text strong>Last Potential Update Resolved</Text>
            </Col>
            <Col span={15}>
              <a className="backbtn" onClick={() => history.push('/studentUpdates')}>
                View Student Updates Log
              </a>
            </Col>
          </div>
        </Row>
      ),
      key: "PotentialUpdatesResolved 'Last Potential Update Resolved'",
      value: <></>,
    };

    console.log('featureFlags', featureFlags['feature.dataIngest.jobSummaryAlertsNew']);
    if (featureFlags['feature.dataIngest.jobSummaryAlertsNew']) {
      if (job?.unMappedCodes && (job.unMappedCodes.counselors.length || job.unMappedCodes.diplomaTypes.length)) {
        details.push(unMappedCodesNew);
      }
      if (job?.fieldsSharedChanges) {
        details.push(fieldsSharedChangesNew);
      }
      details.push(alertsNew);
      details.push(stuedentUpdateLogNew);
    } else {
      if (job?.unMappedCodes) {
        details.push(unMappedCodes);
      }
      if (job?.fieldsSharedChanges) {
        details.push(fieldsSharedChanges);
      }
      details.push(alerts);
      details.push(stuedentUpdateLog);
    }
    return details;
  },

  undoData: (job) => {
    return [
      {
        name: 'Students Undone',
        key: 'Students Undone',
        value: job?.undo.undone,
      },
      {
        name: 'Students Skipped',
        key: 'Students Skipped',
        value: job?.undo.skipped,
      },
      {
        name: 'Students Reactivated',
        key: 'Students Reactivated',
        value: job?.undo.reactivated,
      },
    ];
  },

  studentSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: job.sis_source === 'clever' ? 'Clever' : job.sis_source || 'PowerSchool SIS',
      },
      {
        name: 'Number of Records',
        key: 'Number of Records',
        value: job?.validation?.totalDataCount ? job?.validation?.totalDataCount + job?.notTransfered : 0,
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },

  classDetailsData: (readLegacyData: ClassYearSummaryRecord[]): any => {
    // const readLegacyData = job.importResults.importResultsClassWise ? job.importResults.importResultsClassWise : [];

    if (readLegacyData?.length === 0) {
      return [];
    }

    const years = [];

    readLegacyData.forEach((element) => {
      years.push(element.year);
    });

    const uniqueYears = [...new Set(years)];

    const importDetails = [];

    uniqueYears.forEach((element) => {
      const index = readLegacyData.findIndex((el) => el.year == element);
      const temp: any = {};
      temp.totalRecords = readLegacyData[index].totalRecords ?? 0;
      temp.year = element ?? 0;
      temp.invalid = readLegacyData[index].invalid ?? 0;
      temp.updated = readLegacyData[index].updated ?? 0;
      temp.created = readLegacyData[index].created ?? 0;
      temp.noChanges = readLegacyData[index].noChanges ?? 0;
      temp.transfered = readLegacyData[index].transfered ?? 0;
      temp.reconcile = readLegacyData[index].reconcile ?? 0;
      temp.missing = readLegacyData[index].missing ?? 0;
      temp.deactivateInNaviance = readLegacyData[index].deactivateInNaviance ?? 0;
      temp.reactivated = readLegacyData[index].reactivated ?? 0;
      temp.notTransfered = readLegacyData[index].notTransfered ?? 0;
      importDetails.push(temp);
    });

    const importDetailsSorted = importDetails.sort(function (a: any, b: any) {
      return a.year - b.year;
    });

    const finalImportDetails = [];

    importDetailsSorted.forEach((element) => {
      const temp = {
        ...element,
      };
      temp.activeRecords = element.created + element.updated + element.noChanges + element.reconcile;
      temp.updateWithTransfer = `${element.updated} (${element.transfered})`;
      finalImportDetails.push(temp);
    });

    return finalImportDetails;
  },

  studentUpdatedResolvedData: (job, history, context) => {
    return [
      {
        name: 'Last Completed by',
        key: 'Last Completed by',
        value: job?.updatesResolved?.author,
      },
      {
        name: 'Last Completed on',
        key: 'Last Completed on',
        value: job?.updatesResolved?.end ? new Date(job?.updatesResolved?.end).toLocaleString() : '',
      },
      {
        name: 'Potential Updates Resolved',
        key: 'Potential Updates Resolved',
        value: (
          <Row>
            <Col span={22}>
              <Row>
                <Col>{job?.updatesResolved.recordsMatched + job?.updatesResolved.newRecordsAdded || 0}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.recordsMatched || 0
                  } records matched`}</span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.newRecordsAdded || 0
                  } records added`}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
      {
        name: 'Student Updates Log',
        key: 'Student Updates Log',
        value: (
          <a className="backbtn" onClick={() => history.push('/studentUpdates')}>
            View Student Updates Log
          </a>
        ),
      },
    ];
  },
};

export default studentImportData;
