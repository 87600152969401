import * as React from 'react';
import { Col, Row, Select, Table, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import {
  CourseCatalogDefineCodesParameterGroup,
  InstructionalLevelParameterGroup,
} from '../../containers/SisDefineCodes/SisCourseCatalogDefineCodes';
import Input from '../Input';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import InstructionLevelCustomCompo from '../../components-v2/SisDefineCodes/customInstructionalLevel';
import { featureFlags } from '../../utils/featureFlags';

export interface InstructionalLevelDefineCodeProps {
  defineCodesSettings: CourseCatalogDefineCodesParameterGroup;
  triggerSetState: () => void;
}

export interface NavianceDefinedInstructionalLevelItem {
  rowId: number;
  description: string;
  id: string;
}

export interface NavianceDefinedInstructionalLevelItemEx extends NavianceDefinedInstructionalLevelItem {
  tableIndex?: number;
}
const tagColor = '#f5f5f5';
const tagTextColor = '#141497';
export const navianceDefinedInstructionalLevel: NavianceDefinedInstructionalLevelItemEx[] = [
  { rowId: 0, description: 'Advanced Placement', id: 'advancePlacement' },
  { rowId: 1, description: 'Basic', id: 'basic' },
  { rowId: 2, description: 'English Learner', id: 'englishLearner' },
  { rowId: 3, description: 'Dual Enrollment', id: 'duelEnrollment' },
  { rowId: 4, description: 'General', id: 'general' },
  { rowId: 5, description: 'Gifted and Talented / Advanced Academic', id: 'talentedOrAdvancedAcademic' },
  { rowId: 6, description: 'Honors Level', id: 'honorsLevel' },
  { rowId: 7, description: 'High School Equivalent', id: 'HSEquivalent' },
  { rowId: 8, description: 'International Baccalaureate', id: 'intBaccalaureate' },
  { rowId: 9, description: 'Remedial', id: 'remedial' },
  { rowId: 10, description: 'Students with Disabilities', id: 'studentsWithDisabilities' },
  { rowId: 11, description: 'Untracked', id: 'untracked' },
];

const InstructionalLevelDefineCode = ({
  defineCodesSettings,
  triggerSetState,
}: InstructionalLevelDefineCodeProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);
  const instructionLevelCustom = false;
  const indexRangeData: Array<[number, string]> = [
    [1, '1st'],
    [2, '2nd'],
    [3, '3rd'],
    [4, '4th'],
    [5, '5th'],
    [6, '6th'],
    [7, '7th'],
    [8, '8th'],
    [9, '9th'],
    [10, '10th'],
    [11, '11th'],
    [12, '12th'],
    [13, '13th'],
    [14, '14th'],
  ];
  const [indexRange, setIndexRange] = React.useState(indexRangeData);
  const [instructionalLevelCustom, setCustomInstructionalLevel] = React.useState(instructionLevelCustom);
  const [instructionalLevelSettings, setInstructionalLevelSettings] = React.useState<InstructionalLevelParameterGroup>([
    { digit: indexRange[0][0], mapping: {} },
  ]);

  const [loading, setLoading] = React.useState(false);

  const saveState = () => {
    defineCodesSettings.instructionalLevel = instructionalLevelSettings;
    triggerSetState();
  };

  const onMenuChange = (digit: number, tableIndex: number) => {
    const previouslySelectedIndex = instructionalLevelSettings[tableIndex].digit;
    const currentSelecedIndex = digit - 1;
    addIndexRange(previouslySelectedIndex);

    instructionalLevelSettings[tableIndex].digit = currentSelecedIndex;
    removeIndex(currentSelecedIndex);
    saveState();
  };

  const onInputChange = (e, navItem: NavianceDefinedInstructionalLevelItemEx) => {
    const existingInstrucLevelMapping =
      instructionalLevelSettings[navItem.tableIndex].mapping[navItem.rowId]?.sisCode.length;
    //if item is removed dont go for checking mapping status for duplicates
    if (!existingInstrucLevelMapping || existingInstrucLevelMapping < e.length) {
      const checkMapping = getMappingStatus(instructionalLevelSettings, e);
      if (checkMapping !== null) {
        showNotification(
          NotificationTypes.error,
          `Alert ${checkMapping.value} level is already mapped to the SIS code`,
          'Delete the mapping and try again',
        );
      }
    }
    //if all the elements deleted then remove that element from instruction level mapping
    if (e.length == 0) delete instructionalLevelSettings[navItem.tableIndex].mapping[navItem.rowId];
    else
      instructionalLevelSettings[navItem.tableIndex].mapping[navItem.rowId] = {
        sisCode: e,
        navDisplay: navItem.description,
      };
    saveState();
  };

  const getMappingStatus = (instructionalLevelSettings: InstructionalLevelParameterGroup, valueArry) => {
    let result = null;
    instructionalLevelSettings.filter((instruction) => {
      for (const index in instruction.mapping) {
        if (
          instruction.mapping[index]['sisCode'].includes(valueArry[valueArry.length - 1]) &&
          instruction.mapping[index]['sisCode'] !== ''
        )
          return (result = { value: instruction.mapping[index]['navDisplay'] });
      }

      return result;
    });
    return result;
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    if (isMountedRef.current) setLoading(false);
  };

  const addAdditionalCustomization = async () => {
    const newInstructionalLevel = [...instructionalLevelSettings];
    removeIndex(newInstructionalLevel[newInstructionalLevel.length - 1].digit);

    newInstructionalLevel.push({ digit: indexRange[0][0], mapping: {} });
    setInstructionalLevelSettings(newInstructionalLevel);
    saveState();
    removeIndex(indexRange[0][0]);
  };

  const removeIndex = async (indexToRemoved) => {
    const newIndexRange = indexRange.filter((range) => {
      if (range[0] === indexToRemoved) return false;
      else return true;
    });

    setIndexRange(newIndexRange);
  };

  const removeCustomization = async (tableIndex: number) => {
    const newInstructionalLevel = [...instructionalLevelSettings];
    newInstructionalLevel.splice(tableIndex, 1);
    const indexDropped = instructionalLevelSettings[tableIndex].digit;

    addIndexRange(indexDropped);
    setInstructionalLevelSettings(newInstructionalLevel);
    saveState();
  };

  const getSmallerIndex = (indexValue: number) => {
    let count = -1;
    indexRange.map((element, index) => {
      if (element[0] === indexValue) count = -15;
      if (element[0] > indexValue) {
        return index;
      } else {
        count += 1;
      }
      return count;
    });
    return count + 1;
  };

  const addIndexRange = async (indexValue) => {
    const getIndexValue = getSmallerIndex(indexValue);
    if (getIndexValue >= 0) {
      indexRange.splice(getIndexValue, 0, indexRangeData[indexValue - 1]);
      setIndexRange(indexRange);
    }
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();

    //for digits part and to assign default value in that case
    if (
      defineCodesSettings.instructionalLevelFromTranslateConfig == 'digits_part_of_course_number' &&
      defineCodesSettings.instructionalLevel.length == 0
    ) {
      setInstructionalLevelSettings(instructionalLevelSettings);
    } else {
      setInstructionalLevelSettings(defineCodesSettings.instructionalLevel);
      initializeIndexRangeFromSave();
    }

    if (defineCodesSettings.instructionalLevelFromTranslateConfig == 'Instructional_Level')
      setCustomInstructionalLevel(true);

    return () => (isMountedRef.current = false);
  }, []);

  const initializeIndexRangeFromSave = () => {
    let allIndexRange = [...indexRangeData];
    defineCodesSettings.instructionalLevel.map((ranges) => {
      allIndexRange = allIndexRange.filter((index) => {
        if (index[0] !== ranges.digit) return true;
        else return false;
      });
    });

    setIndexRange(allIndexRange);
  };
  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  const instructionalLevelTableColumns = (tableIndex: number) => {
    return [
      {
        title: 'Naviance Code',
        width: '40%',
        render: (data) => <>{data.description}</>,
      },
      {
        title: 'Enter SIS Code',
        width: '60%',
        render: (navItem: NavianceDefinedInstructionalLevelItemEx) => {
          return (
            <Select
              mode="tags"
              size="large"
              tokenSeparators={[',']}
              dropdownStyle={{ display: 'none' }}
              tagRender={tagRender}
              value={
                instructionalLevelSettings[tableIndex].mapping[navItem.rowId]?.navDisplay === navItem.description
                  ? instructionalLevelSettings[tableIndex].mapping[navItem.rowId]?.sisCode
                  : []
              }
              style={{ width: '100%' }}
              options={[]}
              data-test-id={navItem.id}
              onChange={(evt) => {
                onInputChange(evt, navItem);
              }}
            />
          );
        },
      },
    ];
  };
  return (
    <div className="subSection mainMatchFieldsSection">
      <h2 className="infoHeading uploadHeading">Instructional Level</h2>

      <Row className="">
        {!instructionalLevelCustom && (
          <Col span={24}>
            <div className="pagepara">
              Based on certain digits in the <b>Course_Number</b>, selected on the previous page, you can specify the
              instructional level mapping in Naviance.
              <br />
              <br />
            </div>
          </Col>
        )}
        {!instructionalLevelCustom &&
          instructionalLevelSettings.map(({ digit, mapping }, tableIndex) => (
            <Col key={`sis-col-il-${tableIndex}`} span={24}>
              <div className="pagepara">
                <Select
                  key={`sis-select-il-${tableIndex}`}
                  className="select select-responsive"
                  style={{ width: 100 }}
                  value={indexRangeData[digit - 1][1]}
                  onChange={(val) => {
                    onMenuChange(indexRangeData[val][0], tableIndex);
                  }}
                  data-test-id={''}
                >
                  {indexRange.map(([value, display]) => (
                    <Option
                      className="selectOptions"
                      key={`sis-state-id-1-${value}`}
                      value={value}
                      data-test-id={`state_id_option_${value}`}
                      disabled={false}
                    >
                      {display}
                    </Option>
                  ))}
                </Select>{' '}
                digit of Course_Number
                {tableIndex !== 0 && (
                  <button
                    className="button"
                    style={{ float: 'right', marginTop: '5px' }}
                    onClick={() => removeCustomization(tableIndex)}
                    data-test-id="reset_map_btn"
                  >
                    Remove Additional Customization <span style={{ marginLeft: '10px', fontSize: 'large' }}>⊖</span>
                  </button>
                )}
                <br />
                <br />
              </div>

              <div className="pagepara">
                <Table
                  key={`sis-tb-il-${tableIndex}`}
                  className={'counselorTable'}
                  rowKey="rowId"
                  columns={instructionalLevelTableColumns(tableIndex)}
                  dataSource={navianceDefinedInstructionalLevel.map((item) => {
                    return { ...item, tableIndex };
                  })}
                  pagination={false}
                  loading={loading}
                />
              </div>
              <br />
              <br />
            </Col>
          ))}
        {!instructionalLevelCustom && (
          <Col span="auto">
            <button
              className="button"
              style={{ marginTop: '23px' }}
              onClick={() => addAdditionalCustomization()}
              data-test-id="reset_map_btn"
            >
              Add Additional Customization <span style={{ marginLeft: '10px', fontSize: 'large' }}>+</span>
            </button>
          </Col>
        )}
        {featureFlags['feature.dataIngest.cplan.courseCatalogCustomInstructionalLevel'] && instructionalLevelCustom && (
          <InstructionLevelCustomCompo
            key="define-instruction"
            defineCodesSettings={defineCodesSettings}
            setInstructionalLevelSettings={setInstructionalLevelSettings}
            dataSource={navianceDefinedInstructionalLevel}
          />
        )}
      </Row>
    </div>
  );
};
export default InstructionalLevelDefineCode;
