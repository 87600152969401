import * as React from 'react';
import CourseHistoryAcademicYear from '../../components-v2/SisDefineCodes/courseHistoryAcademicYear';
import CreditTypeDefineCode from '../../components-v2/SisDefineCodes/creditTypeDefineCode';
import WriteableTermDefineCodes from '../../components-v2/SisDefineCodes/writeableTerm';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import SisDefineCodes from '.';
import { WizardProps } from '../../components-v2/Wizard';
import { ValueMappingItem } from './SisCourseCatalogDefineCodes';
import GradeLevelDefineCode from '../../components-v2/SisDefineCodes/gradeLevelDefineCode';
import { GradeLevelParameterGroup } from './common';
import { getCurrentAcademicYear } from '../../utils/utils';

export const courseHistoryParameterGroupUrl = 'data-ingest/sis/parameter-group/course-history';
export const courseHistoryTermsUrl = 'data-ingest/sis/ps-distinct-storedgrades-term-abbreviations';
export const termColumnHeaderName = 'Term';
export const creditTypeColumnHeaderName = 'Credit_Type';
const defaultGradeLevelRange: GradeLevelParameterGroup = {
  fromGrade: 9,
  toGrade: 12,
};

export interface ValueMappings {
  [columnName: string]: ValueMappingItem[];
}

export interface TranslateConfigParameterGroup {
  valueMappings: ValueMappings;
  // eslint-disable-next-line @typescript-eslint/ban-types
  headerMappings: object;
  removeEmptyHeaders: boolean;
  removeUnmappedHeaders: boolean;
}

export interface ValidateValueConfig {
  validValues: string[];
  validWithWarningValues: string[];
}

export interface ValidateColumnValidation {
  [columnName: string]: ValidateValueConfig;
}

export interface ValidateConfigParameterGroup {
  columns: ValidateColumnValidation;
}

function rangeToList(gradeLevelRange: GradeLevelParameterGroup): string[] {
  return Array(gradeLevelRange.toGrade - (gradeLevelRange.fromGrade - 1))
    .fill(gradeLevelRange.fromGrade)
    .map((x, y) => (x + y).toString());
}

function listToRange(gradeLevels?: number[]): GradeLevelParameterGroup {
  if (!gradeLevels || gradeLevels.length <= 0) {
    return defaultGradeLevelRange;
  }
  return {
    fromGrade: Math.min(...gradeLevels),
    toGrade: Math.max(...gradeLevels),
  };
}

const SisCourseHistoryDefineCodes = (props: WizardProps): React.ReactElement => {
  const [translateConfig, setTranslateConfig] = React.useState<TranslateConfigParameterGroup>({
    headerMappings: {},
    valueMappings: {},
    removeEmptyHeaders: false,
    removeUnmappedHeaders: false,
  });
  const [validateConfig, setValidateConfig] = React.useState<ValidateConfigParameterGroup>({
    columns: {
      noSuchColumn: {
        validValues: [],
        validWithWarningValues: [],
      },
    },
  });
  const [allowedCreditTypes, setAllowedCreditTypes] = React.useState<string[]>([]);
  const [allowedTerms, setAllowedTerms] = React.useState<string[]>([]);
  const [useTerms, setUseTerms] = React.useState<boolean>(false);
  const [useCreditTypes, setUseCreditTypes] = React.useState<boolean>(false);
  const [fromAcademicYear, setFromAcademicYear] = React.useState<string | number>(getCurrentAcademicYear());
  const [gradeLevelRange, setGradeLevelRange] = React.useState<GradeLevelParameterGroup>(defaultGradeLevelRange);
  const setGradesRange = () => {
    setGradeLevelRange((previousRange) => {
      // Weirdly, we are leveraging objects mutability so at this point
      // previousRange already have the props updated
      return {
        ...previousRange,
      };
    });
  };

  const processConfig = () => {
    /**/
  };

  const saveMapping = async (): Promise<boolean> => {
    // console.log(`validateConfig ${JSON.stringify(validateConfig)}`);
    // console.log(`terms ${allowedTerms}`);

    let termValidation: ValidateValueConfig = validateConfig?.columns?.[termColumnHeaderName] ?? {
      validValues: [],
      validWithWarningValues: [],
    };
    if (useTerms && ( !allowedTerms || allowedTerms.length === 0 )) {
      showNotification(NotificationTypes.error, 'Term Cannot Be Empty', 'Term not selected!.');
      return;
      // delete validateConfig.columns[termColumnHeaderName];
    } else {
      if (termValidation == null) {
        termValidation = { validValues: [], validWithWarningValues: [] };
      } else {
        termValidation.validValues = [];
      }
      for (const term of allowedTerms) {
        termValidation.validValues.push(term);
      }
    }

    let creditTypeValidation: ValidateValueConfig = validateConfig?.columns?.[creditTypeColumnHeaderName] ?? {
      validValues: [],
      validWithWarningValues: [],
    };
    if (allowedCreditTypes.length === 0 && validateConfig?.columns) {
      delete validateConfig.columns[creditTypeColumnHeaderName];
    } else {
      if (creditTypeValidation == null) {
        creditTypeValidation = { validValues: [], validWithWarningValues: [] };
      } else {
        creditTypeValidation.validValues = [];
      }
      for (const creditType of allowedCreditTypes) {
        creditTypeValidation.validValues.push(creditType);
      }
    }

    const termColumnValidation: ValidateColumnValidation = {};
    termColumnValidation[termColumnHeaderName] = termValidation;

    const creditTypeColumnValidation: ValidateColumnValidation = {};
    creditTypeColumnValidation[creditTypeColumnHeaderName] = creditTypeValidation;

    const newValidateConfig = {
      ...validateConfig,
      columns: { ...termColumnValidation, ...creditTypeColumnValidation },
    };

    const spreadOutTranslateConfig = { ...translateConfig };

    const parameterGroup = {
      defineCodes: {
        fromAcademicYear,
        storeCodes: allowedTerms,
        creditTypes: allowedCreditTypes,
        gradeLevels: rangeToList(gradeLevelRange),
      },
    };

    const { data } = await apiClient.patch(courseHistoryParameterGroupUrl, {
      ...parameterGroup,
      translateConfig: spreadOutTranslateConfig,
    });

    setTranslateConfig(data.translateConfig);
    setValidateConfig(data.validateConfig);
    return true;
  };

  const getMappings = async () => {
    try {
      const { data } = await apiClient.get(courseHistoryParameterGroupUrl);
      setFromAcademicYear(data?.defineCodes?.fromAcademicYear ?? getCurrentAcademicYear());
      setGradeLevelRange(listToRange(data?.defineCodes?.gradeLevels));

      const {
        translateConfig = {
          valueMappings: {},
          headerMappings: {},
          removeEmptyHeaders: false,
          removeUnmappedHeaders: false,
        },
        validateConfig = { columns: {} },
      }: { translateConfig: TranslateConfigParameterGroup; validateConfig: ValidateConfigParameterGroup } = data;

      setTranslateConfig(translateConfig);
      setValidateConfig(validateConfig);

      if (translateConfig.headerMappings) {
        for (const columnValue of Object.values(translateConfig.headerMappings)) {
          if (columnValue === termColumnHeaderName) {
            setUseTerms(true);
            setAllowedTerms(data?.defineCodes?.storeCodes);
          } else if (columnValue === creditTypeColumnHeaderName) {
            setUseCreditTypes(true);
            setAllowedCreditTypes(data?.defineCodes?.creditTypes || []);
          }
        }
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Mappings', 'Failure in getting data from server.');
    }
  };

  React.useEffect(() => {
    processConfig();
  }, [translateConfig]);

  const creditTypeDefineCodes = React.useMemo(() => {
    return (
      <CreditTypeDefineCode
        key="sis-credit-type"
        saveCreditTypes={(credits) => {
          setAllowedCreditTypes(credits);
        }}
        creditTypes={allowedCreditTypes}
        tableColumn="storedgrade"
      />
    );
  }, [allowedCreditTypes]);

  const sections = [
    <CourseHistoryAcademicYear
      key="sis-chay-id-def-code"
      fromAcademicYear={fromAcademicYear}
      setFromAcademicYear={(val) => setFromAcademicYear(val)}
    />,
    <GradeLevelDefineCode
      key="sis-grade-level-def-code"
      triggerSetState={setGradesRange}
      gradeLevelRange={gradeLevelRange}
      minFromGrade={6}
    />,
  ];
  if (useTerms) {
    sections.push(
      <WriteableTermDefineCodes
        terms={allowedTerms}
        termValue="[StoredGrades]StoreCode"
        setTerms={(val) => {
          setAllowedTerms(val);
        }}
        key={'term-def-code'}
      />,
    );
  }
  if (useCreditTypes) {
    sections.push(creditTypeDefineCodes);
  }
  return <SisDefineCodes codesToDefine={sections} saveMapping={saveMapping} getMappings={getMappings} {...props} />;
};

export default SisCourseHistoryDefineCodes;
