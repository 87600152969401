import { Badge, Col, Row, Tabs } from 'antd';
import React from 'react';
import ClassDetails, { classDetailsData } from './ClassDetails/ClassDetails';
import { SisStudentReconciled, SisStaffReconciled, SisParentReconciled, SisStudentJobSummary } from '../../../../../../libs/common-interfaces';
import PSDataImportJobReconcile from './PSDataImportJobReconcile';
import PSDataImportStudentJobReconcile from './PSDataImportStudentJobReconcile';
import StudentsErrorsAndWarningsTab, { TabType } from './StudentsErrorsAndWarningsTab';

interface SummaryTabsProps {
    classDetailsData: classDetailsData[];
    activeKey: string;
    setActiveKey: (updated: string) => void;
    jobMeta: SisStudentJobSummary;
    setReconciled: (updatedReconciled: SisStudentReconciled | SisStaffReconciled | SisParentReconciled) => void;
    postReconciled: () => void;
    reconcileDisabled: boolean;
    setReconcileDisabled: (updated: boolean) => void;
    dataType: string;
    setSelectedClassYear?: (selectedClassYear: number) => void;
    setOpen?: (open: boolean) => void;
}

const styles = {
    badge: {
        backgroundColor: "#1890ff",
        marginLeft: "8px",
        marginRight: "8px",
    },
}

const SummaryTabs = ({
    classDetailsData,
    activeKey,
    setActiveKey,
    jobMeta,
    setReconciled,
    postReconciled,
    reconcileDisabled,
    setReconcileDisabled,
    dataType,
    setSelectedClassYear,
    setOpen,
}: SummaryTabsProps): React.ReactElement => {
    return (
        <Tabs
            activeKey={activeKey}
            onChange={(updateActiveKey) => setActiveKey(updateActiveKey)}
            items={[
                {
                    key: '1',
                    label: 'Records by class year',
                    children: (
                        <Row>
                            <Col span={14}>
                                <ClassDetails
                                    dataSource={classDetailsData}
                                    showTitle={false}
                                    setSelectedClassYear={setSelectedClassYear}
                                    setOpen={setOpen}>
                                </ClassDetails>
                            </Col>
                        </Row>
                    ),
                },
                ...(jobMeta?.recordsSkipped ?
                    [{
                        key: '2',
                        label: <>
                            Errors
                            <Badge
                                count={jobMeta?.recordsSkipped}
                                style={styles.badge}
                                overflowCount={99999}
                                showZero
                            />
                        </>,
                        children: (
                            <StudentsErrorsAndWarningsTab
                                jobGuid={jobMeta.id}
                                type={TabType.Error}
                                filters={jobMeta?.errorFilters}
                                dataType={dataType}
                            />
                        ),
                    }] : []),
                ...(jobMeta?.recordsNotSkipped ? [{
                    key: '3',
                    label: <>
                        Warnings
                        <Badge
                            count={`${(jobMeta?.recordsNotSkipped || 0)}`}
                            style={styles.badge}
                            overflowCount={99999}
                            showZero
                        />
                    </>,
                    children: (
                        <StudentsErrorsAndWarningsTab
                            jobGuid={jobMeta.id}
                            type={TabType.Warning}
                            filters={jobMeta?.warningFilters}
                            dataType={dataType}
                        />
                    ),
                }] : []),
                ...(jobMeta?.status !== 'FAILED' &&
                    jobMeta?.mostRecent &&
                    jobMeta?.toReconcile &&
                    jobMeta?.isReconcilable
                    ?
                    [{
                        key: '4',
                        label: <>
                            Reconcile
                            <Badge
                                count={`${(jobMeta?.toReconcile || 0)}`}
                                style={styles.badge}
                                overflowCount={99999}
                                showZero
                            />
                        </>,
                        children: (
                            <>
                                {dataType === 'students' || dataType === 'alumni' ?
                                    <PSDataImportStudentJobReconcile
                                        jobGuid={jobMeta.id}
                                        update={(updated) => setReconciled(updated)}
                                        post={() => postReconciled()}
                                        isTestImport={jobMeta?.isTestImport}
                                        reconcileDisabled={reconcileDisabled}
                                        setReconcileDisabled={setReconcileDisabled}
                                        totalToReconcile={jobMeta?.toReconcile}
                                    />
                                    : <PSDataImportJobReconcile
                                        jobGuid={jobMeta.id}
                                        update={(updated) => setReconciled(updated)}
                                        post={() => postReconciled()}
                                        isTestImport={jobMeta?.isTestImport}
                                        reconcileDisabled={reconcileDisabled}
                                        setReconcileDisabled={setReconcileDisabled}
                                        dataType={dataType}
                                    />
                                }
                            </>
                        )
                    }]
                    : []
                )
            ]}
        />
    );
}

export default SummaryTabs;
