import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';

interface ConfigButtonProps {
  id: string;
  to: string;
  children: React.ReactNode;
}

const ConfigButton = ({ id, to, children }: ConfigButtonProps): React.ReactElement => {
  const history = useHistory();
  return (
    <Button
      type="link"
      style={{ paddingLeft: 0, marginLeft: 0 }}
      onClick={(e) => {
        e.preventDefault();
        history.push(to);
      }}
      className="configLink"
      data-test-id={`${id}_link`}
    >
      {children}
    </Button>
  );
};

export default ConfigButton;
