const css = `@charset "UTF-8";
.custom-bullet {
  list-style: none;
  margin-bottom: 0;
}

.custom-bullet li::before {
  content: "•";
  color: #fcd703;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.course-bullet {
  list-style: none;
  margin-bottom: 0;
}

.course-bullet li::before {
  content: "•";
  color: #fcd703;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -4em;
}

.remove-bullet li::before {
  content: "•";
  color: white;
  margin-left: -5em;
}

.imported-courses-list {
  margin: 0;
  list-style: none;
}

.export-link {
  float: right;
  margin-top: 1em;
  text-decoration: underline;
}

.deactivatedDrawer {
  z-index: 1040;
}

.collapsible .ant-collapse-header-text {
  width: 100%;
}

.collapsible h5.ant-typography {
  margin-bottom: 0;
}

.collapsible .panelHeader {
  display: flex;
  align-items: center;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL2FwcC9hcHBzL2RhdGEtaW5nZXN0LXVpL3NyYy9hcHAvY29udGFpbmVycy9QU0RhdGFJbXBvcnRKb2JTdW1tYXJ5Iiwic291cmNlcyI6WyJzdHlsZXMuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO0FBQUE7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7OztBQUdGO0VBQ0U7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5jdXN0b20tYnVsbGV0IHtcbiAgbGlzdC1zdHlsZTogbm9uZTtcbiAgbWFyZ2luLWJvdHRvbTogMDtcbn1cblxuLmN1c3RvbS1idWxsZXQgbGk6OmJlZm9yZSB7XG4gIGNvbnRlbnQ6ICdcXDIwMjInO1xuICBjb2xvcjogI2ZjZDcwMztcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgd2lkdGg6IDFlbTtcbiAgbWFyZ2luLWxlZnQ6IC0xZW07XG59XG5cbi5jb3Vyc2UtYnVsbGV0IHtcbiAgbGlzdC1zdHlsZTogbm9uZTtcbiAgbWFyZ2luLWJvdHRvbTogMDtcbn1cblxuLmNvdXJzZS1idWxsZXQgbGk6OmJlZm9yZSB7XG4gIGNvbnRlbnQ6ICdcXDIwMjInO1xuICBjb2xvcjogI2ZjZDcwMztcbiAgZm9udC13ZWlnaHQ6IGJvbGQ7XG4gIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgd2lkdGg6IDFlbTtcbiAgbWFyZ2luLWxlZnQ6IC00ZW07XG59XG5cbi5yZW1vdmUtYnVsbGV0IGxpOjpiZWZvcmUge1xuICBjb250ZW50OiAnXFwyMDIyJztcbiAgY29sb3I6IHdoaXRlO1xuICBtYXJnaW4tbGVmdDogLTVlbTtcbn1cblxuLmltcG9ydGVkLWNvdXJzZXMtbGlzdCB7XG4gIG1hcmdpbjogMDtcbiAgbGlzdC1zdHlsZTogbm9uZTtcbn1cblxuLmV4cG9ydC1saW5rIHtcbiAgZmxvYXQ6IHJpZ2h0O1xuICBtYXJnaW4tdG9wOiAxZW07XG4gIHRleHQtZGVjb3JhdGlvbjogdW5kZXJsaW5lO1xufVxuXG4uZGVhY3RpdmF0ZWREcmF3ZXIge1xuICB6LWluZGV4OiAxMDQwO1xufVxuXG4uY29sbGFwc2libGUgLmFudC1jb2xsYXBzZS1oZWFkZXItdGV4dCB7XG4gIHdpZHRoOiAxMDAlO1xufVxuXG4uY29sbGFwc2libGUgaDUuYW50LXR5cG9ncmFwaHkge1xuICBtYXJnaW4tYm90dG9tOiAwO1xufVxuXG4uY29sbGFwc2libGUgLnBhbmVsSGVhZGVyIHtcbiAgZGlzcGxheTogZmxleDtcbiAgYWxpZ24taXRlbXM6IGNlbnRlcjtcbn0iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
