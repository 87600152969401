import type { SisConnectionStatus } from '../../../../../../libs/common-interfaces';
import * as React from 'react';
import apiClient from '../../utils/apiClient';
import { Tag } from 'antd';
import { CheckCircleOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { IntegrationContext } from '../../utils/context';

interface SisConnectionProps {
  reload?: boolean;
  afterReload?: () => void;
  loadingPlaceHolder?: boolean;
}
const SisConnection = (props: SisConnectionProps): React.ReactElement => {
  const { isCleverIntegration } = React.useContext(IntegrationContext);

  const [connection, setConnection] = React.useState<SisConnectionStatus>({
    configured: false,
    working: false,
  });
  
  const [loadingStatus, setLoadingStatus] = React.useState(true);

  const fetchConnectionStatus = async () => {
    try {
      const { data } = await apiClient.get(`/data-ingest/sis/test-connection`);
      const { data: meta } = await apiClient.get(`/data-ingest/sis/tenant-meta`);
      
      //set connection stae only when the page in sync with meta sisIntegration
      if (!isCleverIntegration && meta.sisIntegration === 'clever') {
        setConnection({ working: false, configured: false });
      } else if (isCleverIntegration && meta?.sisIntegration !== 'clever') {
        setConnection({ working: false, configured: false });
      }
      else {
        setConnection(data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingStatus(false);
    }
  };

  React.useEffect(() => {
    void fetchConnectionStatus();
  }, []);

  React.useEffect(() => {
    if (props.reload) {
      setLoadingStatus(true);
      void fetchConnectionStatus().finally(props.afterReload);
    }
  }, [props.reload]);

  const getAlert = () => {  
    if (loadingStatus || props.loadingPlaceHolder) {
      return (
        <Tag icon={<LoadingOutlined />} color="default">
          Loading
        </Tag>
      );
    }
    if (connection.configured && connection.working) {
      return (
        <Tag
          icon={<CheckCircleOutlined />}
          color={connection.cleverIssue || connection.cleverNoData ? 'warning' : 'success'}
        >
          {connection.cleverIssue
            ? 'Connected with Data Issue'
            : connection.cleverNoData
              ? 'Connected with No Data'
              : 'Connected'}
        </Tag>
      );
    }
    if (connection.configured) {
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="warning">
          Configured but something is wrong
        </Tag>
      );
    }
    if (isCleverIntegration) {
      return (
        <Tag icon={<ExclamationCircleOutlined />} color="default">
          Pending approval or request to connect in Clever
        </Tag>
      );
    }

    return (
      <Tag icon={<ExclamationCircleOutlined />} color="default">
        Pending Setup
      </Tag>
    );
  };

  return <>{getAlert()}</>;
};

export default SisConnection;
