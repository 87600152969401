import { Drawer as AntDrawer } from 'antd';
import React from 'react';

const Drawer = (props): React.ReactElement => {
  return (
    <AntDrawer
      title="Close"
      placement="right"
      closable={true}
      onClose={props.onClose}
      visible={props.visible}
      width={556}
      className="mainDrawerSection"
      data-test-id="main_drawer"
    >
      {props.content}
    </AntDrawer>
  );
};

export default Drawer;
