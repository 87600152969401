import React, { useState } from 'react';

import { WizardProps } from '../../components-v2/Wizard';
import SisSyncSettings from '.';
import { courseImportFrequencies } from './constants';

const SisCurrentCoursesSyncSettings = (props: WizardProps): React.ReactElement => {
  const defaultFrequency = 'ONE_TIME_IMPORT';
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [overwriteCurrentCourseData, setOverwriteCurrentCourseData] = useState(false);

  return (
    <SisSyncSettings
      frequencies={courseImportFrequencies}
      defaultFrequency={defaultFrequency}
      overwriteNavData={overwriteNavData}
      overwriteCurrentCourseData={overwriteCurrentCourseData}
      flagNames={['overwriteNavData', 'overwriteCurrentCourseData']} //need to be in same order with flag setter
      setFlagCallees={[setOverwriteNavData, setOverwriteCurrentCourseData]} //need to be in same order with flag setter
      type="course-current"
      showRadio={false}
      includeSchools={true}
      includeInactivateRule={false}
      includeOverwriteRule={true}
      includeOverwriteEmailRule={false}
      includeCurrentCourseData={true}
      fetchAvailableSchoolMappings={true}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/course-current'}
      {...props}
    />
  );
};

export default SisCurrentCoursesSyncSettings;
