import { Col, Row, Table, TablePaginationConfig } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import apiClient from '../../utils/apiClient';
import MainTemplate from '../Shell/MainTemplate';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import LeftAngleIcon from '../../../assets/Icon/LeftAngleIcon';
import { featureFlags } from '../../utils/featureFlags';

export enum ImportType {
  STUDENT = 'student',
  STAFF = 'staff',
  PARENT = 'parent',
}

interface UpdatesLogProps {
  type: ImportType;
}

interface ReconcileHistoryResponse {
  page: number;
  pages: number;
  search: string;
  sort: string;
  sortDir: string;
  total: string;
  records: Record<string, string | number | boolean>[];
}

export const getSchools = async (
  type: ImportType,
  setSchools: React.Dispatch<React.SetStateAction<any[]>>,
): Promise<void> => {
  try {
    const { data } = await apiClient.get(`/highschools/v2?byType=district&includeInactive=true`);
    setSchools(data);
  } catch (error) {
    showNotification(
      NotificationTypes.error,
      `Error Getting ${type === ImportType.STUDENT ? 'Student' : 'Staff'} Logs`,
      'Failure in getting data from server.',
    );
  }
};

export const fetchUpdatesLogs = async ({
  page,
  size,
  type,
  setLoading,
  setUpdateRecords,
  setTotalRecords,
}: {
  page?: number;
  size?: number;
  type: ImportType;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setUpdateRecords: React.Dispatch<React.SetStateAction<any[]>>;
  setTotalRecords: React.Dispatch<React.SetStateAction<number>>;
}): Promise<void> => {
  if (!page) {
    page = 1;
  }
  if (!size) {
    size = 10;
  }
  try {
    setLoading(true);
    const useModernUrl = !!featureFlags['feature.dataIngest.modernDragonStudents'];
    let { data } = await apiClient.get(
      `/data-ingest/sis/reconcileHistory?page=${page}&size=${size}&type=${type}&useModernUrl=${useModernUrl}`,
    );
    data = mapReconcilehistory(data, type);
    setUpdateRecords(data.records || []);
    setTotalRecords(data?.total || 0);
  } catch (error) {
    showNotification(
      NotificationTypes.error,
      `Error Getting ${type === ImportType.STUDENT ? 'Student' : 'Staff'} Update Logs`,
      'Failure in getting data from server.',
    );
  } finally {
    setLoading(false);
  }
};

export const mapReconcilehistory = (data: ReconcileHistoryResponse, type: ImportType): Record<string, any> => {
  return {
    page: data.page as number,
    pages: data.pages as number,
    search: data.search as string,
    sort: data.sort as string,
    sortDir: data.sortDir as string,
    total: data.total as string,
    records: data.records.map(
      (record: Record<string, string | number | boolean>): Record<string, string | number | boolean> => {
        if (type === ImportType.STUDENT) {
          return {
            classYear: record.classYear as number,
            firstName: record.firstName as string,
            lastName: record.lastName as string,
            hsStudentId: record.hsStudentId as string,
            school: record.Naviance_School_ID as string,
            isActive: record.isActive as boolean,
            reconciledOn: record.reconciledOn as string,
            reconciledBy: record.reconciledBy as string,
            reconcileType: record.reconciledSK as string,
          };
        } else if (type === ImportType.STAFF) {
          return {
            dcid: record.dcid as string,
            firstName: record.firstName as string,
            lastName: record.lastName as string,
            role: record.role as string,
            school: record.Naviance_School_ID as string,
            isActive: record.isActive as boolean,
            reconciledOn: record.reconciledOn as string,
            reconciledBy: record.reconciledBy as string,
            reconcileType: record.reconciledSK as string,
          };
        }
      },
    ),
  };
};

const UpdatesLog = (props: UpdatesLogProps): React.ReactElement => {
  const { type } = props;
  const history = useHistory();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [schools, setSchools] = React.useState([]);
  const [updateRecords, setUpdateRecords] = React.useState([]);

  const pagination = {
    total: totalRecords,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSizeOptions: ['10', '50', '100'],
    onShowSizeChange: async (currentPage, size) =>
      await fetchUpdatesLogs({ page: currentPage, size, type, setLoading, setUpdateRecords, setTotalRecords }),
    onChange: async (page, size) =>
      await fetchUpdatesLogs({ page, size, type, setLoading, setUpdateRecords, setTotalRecords }),
  } as TablePaginationConfig;

  const updatesLogTableColumns = (type: ImportType) => [
    {
      title: 'Updated On',
      dataIndex: 'reconciledOn',
      key: 'reconciledOn',
      width: '12%',
      render: (reconciledOn) => (
        <span data-test-id="reconcile_date">{reconciledOn ? new Date(reconciledOn * 1000).toLocaleString() : ''}</span>
      ),
    },
    {
      title: 'Updated By',
      dataIndex: 'reconciledBy',
      key: 'reconciledBy',
      width: '15%',
      render: (reconciledBy) => <span data-test-id="reconciled_by">{reconciledBy}</span>,
    },
    {
      title: 'Update',
      dataIndex: 'reconcileType',
      key: 'reconcileType',
      width: '8%',
      render: (reconcileType) => (
        <span data-test-id="reconcile_type">
          {reconcileType.includes('CREATED') ? 'Created' : reconcileType.includes('MATCHED') ? 'Matched' : ''}
        </span>
      ),
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
      width: '15%',
      render: (school) => <span data-test-id="school">{schools.find((x) => x.nid === school)?.name || ''}</span>,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '10%',
      render: (lastName) => <span data-test-id="lastName">{lastName}</span>,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '10%',
      render: (firstName) => <span data-test-id="firstName">{firstName}</span>,
    },
    ...(type === ImportType.STUDENT
      ? [
          {
            title: 'Student ID',
            dataIndex: 'hsStudentId',
            key: 'hsStudentId',
            width: '10%',
            render: (hsStudentId) => <span data-test-id="hsStudentId">{hsStudentId}</span>,
          },
          {
            title: 'Class Year',
            dataIndex: 'classYear',
            key: 'classYear',
            width: '10%',
            render: (classYear) => <span data-test-id="classYear">{classYear}</span>,
          },
        ]
      : [
          {
            title: 'Unique Staff ID',
            dataIndex: 'dcid',
            key: 'dcid',
            width: '10%',
            render: (dcid) => <span data-test-id="dcid">{dcid}</span>,
          },
          {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            width: '10%',
            render: (role) => <span data-test-id="role">{role}</span>,
          },
        ]),
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '10%',
      render: (isActive) => <span data-test-id="isActive">{isActive ? 'Active' : 'Inactive'}</span>,
    },
  ];

  React.useEffect(() => {
    const loadData = async () => {
      await getSchools(type, setSchools);
      await fetchUpdatesLogs({ type, setLoading, setUpdateRecords, setTotalRecords });
    };
    loadData();
  }, []);

  return (
    <MainTemplate
      title={`${type === ImportType.STUDENT ? 'Student' : 'Staff'} Updates Log`}
      titleTestId="updates_log_title"
    >
      <>
        <Row justify="space-between" align="middle">
          <Col span={4}>
            <div style={{ float: 'left', width: '30px' }}>
              <LeftAngleIcon />
            </div>
            <div style={{ float: 'left' }}>
              <a className="backbtn" onClick={() => history.goBack()}>
                Back to View Log
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>Potential updates resolved between Naviance and PowerSchool products.</p>
            <p>This page will display 1 year of past updates.</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="mainDataImportTable sisImportTable">
              <Table
                rowKey={`${type === ImportType.STUDENT ? 'hsStudentId' : 'dcid'}`}
                loading={loading}
                columns={updatesLogTableColumns(type)}
                dataSource={updateRecords}
                pagination={pagination}
                locale={{
                  emptyText: (
                    <div style={{ margin: '20px' }}>
                      <h1>No data found</h1>
                    </div>
                  ),
                }}
              ></Table>
            </div>
          </Col>
        </Row>
      </>
    </MainTemplate>
  );
};
export default UpdatesLog;
