import React, { useState } from 'react';

import { WizardProps } from '../../components-v2/Wizard';
import SisSyncSettings from '.';
import { courseImportFrequencies } from './constants';

const SisCourseHistorySyncSettings = (props: WizardProps): React.ReactElement => {
  const defaultFrequency = 'ONE_TIME_IMPORT';
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [overwriteHistoryCourseData, setOverwriteHistoryCourseData] = useState(false);

  return (
    <SisSyncSettings
      frequencies={courseImportFrequencies}
      defaultFrequency={defaultFrequency}
      overwriteNavData={overwriteNavData}
      overwriteHistoryCourseData={overwriteHistoryCourseData}
      flagNames={['overwriteNavData', 'overwriteHistoryCourseData']} //need to be in same order with flag setter
      setFlagCallees={[setOverwriteNavData, setOverwriteHistoryCourseData]} //need to be in same order with flag setter
      type="course-history"
      showRadio={false}
      includeSchools={true}
      includeInactivateRule={false}
      includeOverwriteRule={true}
      includeHistoryCourseData={true}
      includeOverwriteEmailRule={false}
      fetchAvailableSchoolMappings={true}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/course-history'}
      {...props}
    />
  );
};

export default SisCourseHistorySyncSettings;
