import './app.scss';

import React, { ReactElement, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Shell from './containers/Shell';
import { IntegrationContext } from './utils/context';
import fetchPluginVersion from './utils/pluginVersion';
import fetchSisServerStatus from './utils/sisServerStatus';
import { checkLegacyClever } from './utils/utils';
import { getFeatureFlags, featureFlags } from './utils/featureFlags';
import apiClient from './utils/apiClient';

export const App = (): ReactElement => {
  const [pluginDetails, setPluginDetails] = useState({});
  const [sisServerDetails, setSisServerDetails] = useState({});
  const [sisError, setSisError] = useState(false);
  const [isLegacyCleverEnabled, setIsLegacyCleverEnabled] = React.useState<boolean>(false);
  const [isDistrict, setIsDistrict] = useState<any>();
  const [districtId, setDistrictId] = useState<any>();
  const [userFullName, setUserFullName] = useState<any>();
  const [schoolMap, setSchoolMap] = useState<any>({});
  let isCleverIntegration = false;
  let dataImportPath = '/powerschool-data-integration';

  useEffect(() => {
    const init = async () => {
      const [pluginDetails, sisServerDetails, user, schools] = await Promise.all([
        fetchPluginVersion(true),
        fetchSisServerStatus(),
        apiClient.get(`/user/info`),
        apiClient.get('/highschools/v2?byType=district&includeInactive=true'),
        getFeatureFlags(),
      ]);

      const tempSchoolMap = schools?.data?.reduce?.((acc, school) => {
        acc[school.nid] = school.name;
        return acc;
      }, {});

      setSchoolMap(tempSchoolMap);
      setIsDistrict(!user.data.highschoolId);
      setDistrictId(user.data.districtId);
      setUserFullName(user?.data?.fullName ?? '');
      setPluginDetails(pluginDetails);
      setSisServerDetails(sisServerDetails);
      if (!pluginDetails || !sisServerDetails) {
        setSisError(true);
      }
      setIsLegacyCleverEnabled(checkLegacyClever(isCleverIntegration, featureFlags));
    };
    init();
  }, []);

  if (location.pathname.includes('clever-data-integration')) {
    isCleverIntegration = true;
    dataImportPath = '/clever-data-integration';
  }
  return (
    <Router basename={dataImportPath}>
      <IntegrationContext.Provider
        value={{
          isCleverIntegration,
          dataImportPath,
          pluginDetails,
          sisServerDetails,
          sisError,
          isLegacyCleverEnabled,
          districtId,
          userFullName,
          schoolMap,
        }}
      >
        <Shell isDistrict={isDistrict} />
      </IntegrationContext.Provider>
    </Router>
  );
};

export default App;
