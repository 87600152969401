export interface CustomMapping {
  tableName: string;
  fieldName: string;
  displayName: string;
  dcIdColumn: string;
}

export const addlEthnCodeMappingHeaders: Record<string, string> = {
  race_code_list: 'raceCodeList',
  fedethnicity: 'fedethnicity',
  ethnicity: 'ethnicity',
  fedracedecline: 'fedracedecline',
};

export const addlESPEthnCodeMappingHeaders: Record<string, string> = {
  hispanic: 'hispanic',
};

export const addlCleverEthnCodeMappingHeaders: Record<string, string> = {
  hispanic_ethnicity: 'hispanic',
};

export const ethnicCodeValueMappings: Record<string, string>[] = [
  {
    fromValue: 'B',
    toValue: 'BA',
  },
  {
    fromValue: 'I',
    toValue: 'AI',
  },
  {
    fromValue: 'A',
    toValue: 'AS',
  },
  {
    fromValue: 'W',
    toValue: 'WH',
  },
  {
    fromValue: 'H',
    toValue: 'HI',
  },
  {
    fromValue: 'O',
    toValue: 'OT',
  },
  {
    fromValue: 'P',
    toValue: 'PI',
  },
];

export const defaultPsStudentMapping = (latestPluginInstalled = false): Record<string, string> => {
  return {
    birthDate: 'dob',
    city: latestPluginInstalled ? 'personaddress_city' : 'mailing_city',
    classYear: 'classof',
    email: 'email',
    enrollmentStatus: 'enroll_status',
    ethnicCode: 'ethnicity_code_mapping',
    firstName: 'first_name',
    gender: 'gender',
    homePhone: 'home_phone',
    homeRoom: 'home_room',
    hsStudentId: 'student_number',
    lastName: 'last_name',
    middleName: 'middle_name',
    School_ID: 'schoolid',
    state: latestPluginInstalled ? 'personaddress_state' : 'mailing_state',
    stateStudentId: 'state_studentnumber',
    streetAddress: latestPluginInstalled ? 'personaddress_street' : 'mailing_street',
    zip: latestPluginInstalled ? 'personaddress_zip' : 'mailing_zip',
  };
};

export const defaultSiSMapping: Record<string, string> = {
  Teacher_Name: 'teacher_name',
  Section_Id: 'sectionid',
  Percent: 'percent',
  Store_Code: 'storecode',
  School_Id: 'schoolid',
};

export const defaultNavCourseHistory: Record<string, string> = {
  Course_Status: 'course_status',
  Course_Id: 'course_number',
  Credits_Earned: 'earnedcrhrs',
  Grade_Level_Taken: 'grade_level',
  Credits_Attempted: 'potentialcrhrs',
  Student_Id: 'student_number',
  Term: 'storecode',
  Course_Name: 'course_name',
  Credit_Type: 'credit_type',
  Letter_Grade: 'grade',
  Teacher: 'teacher_name',
  Section: 'sectionid',
  Score: 'percent',
  School_Year: 'termid',
};

export const defaultESPStudentMapping: Record<string, string> = {
  birthDate: 'birthdate',
  city: 'mailing_city',
  classYear: 'derivedClassYear',
  email: 'email',
  enrollmentStatus: 'enrollmentStatus',
  ethnicCode: 'federalRaceCode',
  firstName: 'firstName',
  gender: 'gender',
  homePhone: 'homePhone',
  hsStudentId: 'studentId',
  lastName: 'lastName',
  middleName: 'middleName',
  School_ID: 'buildingId',
  state: 'mailing_state',
  stateStudentId: 'stateStudentId',
  streetAddress: 'mailing_streetAddress',
  zip: 'mailing_zip',
};

export const defaultCleverStudentMapping: Record<string, string> = {
  cleverId: 'id',
  hsStudentId: 'student_number',
  stateStudentId: 'state_id',
  classYear: 'derivedClassYear',
  School_ID: 'school',
  firstName: 'first',
  lastName: 'last',
  middleName: 'middle',
  streetAddress: 'address',
  city: 'city',
  state: 'state',
  zip: 'zip',
  gender: 'gender',
  birthDate: 'dob',
  email: 'email',
  gpa: 'unweighted_gpa',
  weightedGpa: 'weighted_gpa',
  ethnicCode: 'race',
  username: 'district_username',
};
export const defaultCleverStaffMapping: Record<string, string> = {
  email: 'email',
  firstName: 'first',
  lastName: 'last',
  role: 'title',
  cleverId: 'id',
  employeeIdExternal: 'id_number',
  middleinitial: 'middle',
  title: 'title',
};

export const defaultNavCourseCatalogueMapping: Record<string, string> = {
  Academic_Year: 'academic_year',
  Course_ID: 'course_number',
  Course_Name: 'course_name',
  Credits: 'credit_hours',
  Credit_Type: 'credittype',
  dcid: 'dcid',
  Grade_High: 'grade_high',
  Grade_Low: 'grade_low',
  Subject_Area: 'sched_department',
  CTE: 'vocational',
  Instructional_Level: 'il_digits_part_of_course_number',
  State_ID: 'alt_course_number',
  Status: 'status',
};

export const defaultNavAlumniMapping: Record<string, string> = {
  birthDate: 'dob',
  diplomaType: 'exitcode',
  firstName: 'first_name',
  gradDate: 'exitdate',
};

export const defaultNavESPAlumniMapping: Record<string, string> = {
  birthDate: 'birthdate',
  diplomaType: 'diplomaType',
  firstName: 'firstName',
  ferpa: 'ferpaName',
  gradDate: 'graduationDate',
};

export const defaultNavCourseMapping: Record<string, string> = {
  Course_ID: 'course_number',
  Naviance_School_ID: 'navschoolid',
  SIS_School_ID: 'schoolid',
};

export const defaultNavCourseCurrent: Record<string, string> = {
  Course_Status: 'course_status',
  Course_Id: 'course_number',
  Grade_Level_Taken: 'grade_level',
  Student_Id: 'student_number',
  Term: 'abbreviation',
  Course_Name: 'course_name',
  Credits_Attempted: 'credit_hours',
  Credit_Type: 'credittype',
  Teacher: 'lastfirst',
  Section: 'sectionid',
  School_Year: 'termid',
};

export const defaultNavStaffMapping: Record<string, string> = {
  email: 'email_addr',
  firstName: 'first_name',
  lastName: 'last_name',
  role: 'roledefid',
  personUuid: 'dcid',
  isActive: 'status',
  employeeIdExternal: 'teachernumber',
};

export const defaultNavParentMapping: Record<string, string> = {
  hsStudentId: 'student_number',
  hsParentId: 'person_dcid',
  firstName: 'first_name',
  lastName: 'last_name',
  federationId: 'person_dcid',
  street: 'street',
  lineTwo: 'line_two',
  city: 'city',
  state: 'state',
  postalCode: 'postal_code',
  country: 'country',
  email: 'email_address',
  homePhone: 'home',
  mobilePhone: 'mobile',
  workPhone: 'work',
};

export interface NavianceField {
  name: string;
  val: string;
  required: boolean;
  sisFields: { label: string; field: string; notShared?: boolean }[];
  customFieldOption?: boolean;
  gpaMethodOptions?: 'gpa' | 'rank';
}

export const navianceStudentPSFields = (
  latestPluginInstalled = false,
  isStudentCounselorCompatible = false,
): NavianceField[] => {
  let sisCounselorFields = [];
  if (isStudentCounselorCompatible) {
    sisCounselorFields = [{ label: '[StudentCounselor]UserDCID', field: 'primary_counselor_id' }];
  }
  return [
    {
      name: 'High School Student ID',
      val: 'hsStudentId',
      required: true,
      sisFields: [
        { label: 'Student_Number', field: 'student_number' },
        { label: 'Students ID', field: 'id' },
      ],
    },
    {
      name: 'Class Year (4 digit)',
      val: 'classYear',
      required: true,
      sisFields: [
        { label: 'ClassOf', field: 'classof' },
        { label: 'Sched_YearOfGraduation', field: 'sched_yearofgraduation' },
        { label: '[StudentCoreFields]Graduation_Year', field: 'graduation_year' },
      ],
      customFieldOption: true,
    },
    {
      name: 'Last Name',
      val: 'lastName',
      required: true,
      sisFields: [
        { label: 'Last_Name', field: 'last_name' },
        { label: '[StudentCoreFields]PSCore_Legal_Last_Name', field: 'legal_last_name' },
      ],
    },
    {
      name: 'School ID',
      val: 'School_ID',
      required: true,
      sisFields: [{ label: 'SchoolID', field: 'schoolid' }],
    },
    {
      name: 'Enrollment Status',
      val: 'enrollmentStatus',
      required: true,
      sisFields: [{ label: 'Enroll_Status', field: 'enroll_status' }],
    },
    {
      name: 'First Name',
      val: 'firstName',
      required: false,
      sisFields: [
        { label: 'First_Name', field: 'first_name' },
        { label: '[StudentCoreFields]PSCore_Legal_First_Name', field: 'legal_first_name' },
      ],
    },
    {
      name: 'Middle Name',
      val: 'middleName',
      required: false,
      sisFields: [
        { label: 'Middle_Name', field: 'middle_name' },
        { label: '[StudentCoreFields]PSCore_Legal_Middle_Name', field: 'legal_middle_name' },
      ],
    },
    {
      name: 'Email',
      val: 'email',
      required: false,
      sisFields: [
        { label: '[PSM_StudentContact]Email', field: 'contact_email' },
        { label: '[EmailAddress]EmailAddress', field: 'email' },
      ],
      customFieldOption: true,
    },
    {
      name: 'Date Of Birth',
      val: 'birthDate',
      required: false,
      sisFields: [{ label: 'DOB', field: 'dob' }],
    },
    {
      name: 'Gender',
      val: 'gender',
      required: false,
      sisFields: [
        { label: 'Gender', field: 'gender' },
        { label: '[StudentCoreFields]PSCore_Legal_Gender', field: 'legal_gender' },
      ],
    },
    {
      name: 'Ethnicity',
      val: 'ethnicCode',
      required: false,
      sisFields: [
        { label: 'Ethnicity', field: 'ethnicity' },
        { label: '[Students]FedEthnicity and [StudentRace]RaceCD', field: 'ethnicity_code_mapping' },
      ],
    },
    {
      name: 'Street Address',
      val: 'streetAddress',
      required: false,
      sisFields: latestPluginInstalled
        ? [{ label: 'Street', field: 'personaddress_street' }]
        : [
            { label: 'Street', field: 'street' },
            { label: 'Mailing_Street', field: 'mailing_street' },
            { label: 'Mailing_Street (if available) or Street', field: 'mailing_street_or_street' },
          ],
    },
    {
      name: 'City',
      val: 'city',
      required: false,
      sisFields: latestPluginInstalled
        ? [{ label: 'City', field: 'personaddress_city' }]
        : [
            { label: 'City', field: 'city' },
            { label: 'Mailing_City', field: 'mailing_city' },
            { label: 'Mailing_City (if available) or City', field: 'mailing_city_or_city' },
          ],
    },
    {
      name: 'State',
      val: 'state',
      required: false,
      sisFields: latestPluginInstalled
        ? [{ label: 'State', field: 'personaddress_state' }]
        : [
            { label: 'State', field: 'state' },
            { label: 'Mailing_State', field: 'mailing_state' },
            { label: 'Mailing_State (if available) or State', field: 'mailing_state_or_state' },
          ],
    },
    {
      name: 'Zip Code',
      val: 'zip',
      required: false,
      sisFields: latestPluginInstalled
        ? [{ label: 'Zip', field: 'personaddress_zip' }]
        : [
            { label: 'Zip', field: 'zip' },
            { label: 'Mailing_Zip', field: 'mailing_zip' },
            { label: 'Mailing_Zip (if available) or Zip', field: 'mailing_zip_or_zip' },
          ],
    },
    {
      name: 'Home Room',
      val: 'homeRoom',
      required: false,
      sisFields: [{ label: 'Home_Room', field: 'home_room' }],
      customFieldOption: true,
    },
    {
      name: 'Home Phone',
      val: 'homePhone',
      required: false,
      sisFields: [{ label: 'Home_Phone', field: 'home_phone' }],
      customFieldOption: true,
    },
    {
      name: 'Mobile Phone',
      val: 'mobilePhone',
      required: false,
      sisFields: [],
      customFieldOption: true,
    },
    {
      name: 'Original Class Year',
      val: 'originalClassYear',
      required: false,
      sisFields: [
        { label: 'ClassOf', field: 'classof' },
        { label: 'Sched_YearOfGraduation', field: 'sched_yearofgraduation' },
        { label: '[StudentCoreFields]Graduation_Year', field: 'graduation_year' },
      ],
      customFieldOption: true,
    },
    {
      name: 'Unweighted GPA',
      val: 'gpa',
      required: false,
      sisFields: [],
      customFieldOption: true,
      gpaMethodOptions: 'gpa',
    },
    {
      name: 'Weighted GPA',
      val: 'weightedGpa',
      required: false,
      sisFields: [],
      customFieldOption: true,
      gpaMethodOptions: 'gpa',
    },
    {
      name: 'Rank',
      val: 'rank',
      required: false,
      sisFields: [],
      customFieldOption: true,
      gpaMethodOptions: 'rank',
    },
    {
      name: 'Decile',
      val: 'decile',
      required: false,
      sisFields: [],
      customFieldOption: true,
    },
    {
      name: 'Federation ID',
      val: 'federationId',
      required: false,
      sisFields: [
        { label: 'Student_Number', field: 'student_number' },
        { label: 'Student.DCID', field: 'dcid' },
      ],
      customFieldOption: true,
    },
    {
      name: 'State Student Id',
      val: 'stateStudentId',
      required: false,
      sisFields: [{ label: 'State_StudentNumber', field: 'state_studentnumber' }],
      customFieldOption: true,
    },
    {
      name: 'Counselor',
      val: 'counselorId',
      required: false,
      sisFields: sisCounselorFields,
      customFieldOption: true,
    },
  ];
};

export const navianceStudentESPFields: NavianceField[] = [
  {
    name: 'High School Student ID',
    val: 'hsStudentId',
    required: true,
    sisFields: [{ label: 'Student ID', field: 'studentId' }],
  },
  {
    name: 'Class Year (4 digit)',
    val: 'classYear',
    required: true,
    sisFields: [{ label: 'Class Year based on Current Grade', field: 'derivedClassYear' }],
  },
  {
    name: 'Last Name',
    val: 'lastName',
    required: true,
    sisFields: [{ label: 'Last Name', field: 'lastName' }],
  },
  {
    name: 'School ID',
    val: 'School_ID',
    required: true,
    sisFields: [{ label: 'Building', field: 'buildingId' }],
  },
  {
    name: 'Enrollment Status',
    val: 'enrollmentStatus',
    required: true,
    sisFields: [{ label: 'Status', field: 'enrollmentStatus' }],
  },
  {
    name: 'First Name',
    val: 'firstName',
    required: false,
    sisFields: [{ label: 'First Name', field: 'firstName' }],
  },
  {
    name: 'Middle Name',
    val: 'middleName',
    required: false,
    sisFields: [{ label: 'Middle Name', field: 'middleName' }],
  },
  {
    name: 'Email',
    val: 'email',
    required: false,
    sisFields: [{ label: 'Email Address', field: 'email' }],
  },
  {
    name: 'Date Of Birth',
    val: 'birthDate',
    required: false,
    sisFields: [{ label: 'Birthdate', field: 'birthdate' }],
  },
  {
    name: 'Gender',
    val: 'gender',
    required: false,
    sisFields: [{ label: 'Gender', field: 'gender' }],
  },
  {
    name: 'Ethnicity',
    val: 'ethnicCode',
    required: false,
    sisFields: [
      { label: 'Hispanic/Latino Ethnicity and Race', field: 'ethnicCode' },
      { label: 'Federal Race', field: 'federalRaceCode' },
    ],
  },
  {
    name: 'Street Address',
    val: 'streetAddress',
    required: false,
    sisFields: [
      { label: 'Mailing Address (Street)', field: 'mailing_streetAddress' },
      { label: 'Physical Address (Street)', field: 'physical_streetAddress' },
    ],
  },
  {
    name: 'City',
    val: 'city',
    required: false,
    sisFields: [
      { label: 'Mailing Address (City)', field: 'mailing_city' },
      { label: 'Physical Address (City)', field: 'physical_city' },
    ],
  },
  {
    name: 'State',
    val: 'state',
    required: false,
    sisFields: [
      { label: 'Mailing Address (State)', field: 'mailing_state' },
      { label: 'Physical Address (State)', field: 'physical_state' },
    ],
  },
  {
    name: 'Zip Code',
    val: 'zip',
    required: false,
    sisFields: [
      { label: 'Mailing Address (Zip)', field: 'mailing_zip' },
      { label: 'Physical Address (Zip)', field: 'physical_zip' },
    ],
  },
  {
    name: 'Home Phone',
    val: 'homePhone',
    required: false,
    sisFields: [{ label: 'Home Phone', field: 'homePhone' }],
  },
  {
    name: 'Mobile Phone',
    val: 'mobilePhone',
    required: false,
    sisFields: [{ label: 'Mobile Phone', field: 'mobilePhone' }],
  },
  {
    name: 'Original Class Year',
    val: 'originalClassYear',
    required: false,
    sisFields: [{ label: 'Graduation Year', field: 'originalClassYear' }],
  },
  {
    name: 'Unweighted GPA',
    val: 'gpa',
    required: false,
    sisFields: [],
    gpaMethodOptions: 'gpa',
  },
  {
    name: 'Weighted GPA',
    val: 'weightedGpa',
    required: false,
    sisFields: [],
    gpaMethodOptions: 'gpa',
  },
  {
    name: 'Rank',
    val: 'rank',
    required: false,
    sisFields: [],
    gpaMethodOptions: 'rank',
  },
  {
    name: 'Decile',
    val: 'decile',
    required: false,
    sisFields: [],
    gpaMethodOptions: 'gpa',
  },
  {
    name: 'Federation ID',
    val: 'federationId',
    required: false,
    sisFields: [{ label: 'Global ID', field: 'ssoUserid' }],
  },
  {
    name: 'State Student Id',
    val: 'stateStudentId',
    required: false,
    sisFields: [{ label: 'State Reporting ID', field: 'stateStudentId' }],
  },
  {
    name: 'Counselor',
    val: 'counselorId',
    required: false,
    sisFields: [{ label: 'Counselor', field: 'counselor' }],
  },
];

export const navianceStudentCleverFields: NavianceField[] = [
  {
    name: 'Class Year (4 digit)',
    val: 'classYear',
    required: true,
    sisFields: [
      { label: 'Class year based on grade', field: 'derivedClassYear' },
      { label: 'graduation_year', field: 'graduation_year' },
    ],
  },
  {
    name: 'Clever ID',
    val: 'cleverId',
    required: true,
    sisFields: [{ label: 'id', field: 'id' }],
  },
  {
    name: 'High School Student ID',
    val: 'hsStudentId',
    required: true,
    sisFields: [
      { label: 'student_number', field: 'student_number' },
      { label: 'sis_id', field: 'sis_id' },
    ],
  },
  {
    name: 'Last Name',
    val: 'lastName',
    required: true,
    sisFields: [{ label: 'name.last', field: 'last' }],
  },
  {
    name: 'City',
    val: 'city',
    required: false,
    sisFields: [{ label: 'location.city', field: 'city' }],
  },
  {
    name: 'Date Of Birth',
    val: 'birthDate',
    required: false,
    sisFields: [{ label: 'dob', field: 'dob' }],
  },
  {
    name: 'Email',
    val: 'email',
    required: false,
    sisFields: [{ label: 'email', field: 'email' }],
  },
  {
    name: 'Ethnicity',
    val: 'ethnicCode',
    required: false,
    sisFields: [{ label: 'race and hispanic_ethnicity', field: 'race' }],
  },
  {
    name: 'First Name',
    val: 'firstName',
    required: false,
    sisFields: [{ label: 'name.first', field: 'first' }],
  },
  {
    name: 'Gender',
    val: 'gender',
    required: false,
    sisFields: [{ label: 'gender', field: 'gender' }],
  },
  {
    name: 'Middle Name',
    val: 'middleName',
    required: false,
    sisFields: [{ label: 'name.middle', field: 'middle' }],
  },
  {
    name: 'State',
    val: 'state',
    required: false,
    sisFields: [{ label: 'location.state', field: 'state' }],
  },
  {
    name: 'State Student ID',
    val: 'stateStudentId',
    required: false,
    sisFields: [{ label: 'state_ID', field: 'state_id' }],
  },
  {
    name: 'Street Address',
    val: 'streetAddress',
    required: false,
    sisFields: [{ label: 'location.address', field: 'address' }],
  },
  {
    name: 'Unweighted GPA',
    val: 'gpa',
    required: false,
    sisFields: [{ label: 'unweighted_gpa', field: 'unweighted_gpa' }],
  },
  {
    name: 'Weighted GPA',
    val: 'weightedGpa',
    required: false,
    sisFields: [{ label: 'weighted_gpa', field: 'weighted_gpa' }],
  },
  {
    name: 'Zip',
    val: 'zip',
    required: false,
    sisFields: [{ label: 'location.zip', field: 'zip' }],
  },
];

export const courseCatalogRequiredFields: NavianceField[] = [
  {
    name: 'Academic Year',
    val: 'Academic_Year',
    required: true,
    sisFields: [{ label: '[School_Course]YearID', field: 'academic_year' }],
  },
  {
    name: 'Course ID',
    val: 'Course_ID',
    required: true,
    sisFields: [{ label: '[Courses]Course_Number', field: 'course_number' }],
  },
  {
    name: 'Course Name',
    val: 'Course_Name',
    required: true,
    sisFields: [{ label: '[Courses]Course_Name', field: 'course_name' }],
  },
  {
    name: 'Credits',
    val: 'Credits',
    required: true,
    sisFields: [{ label: '[Courses]Credit_Hours', field: 'credit_hours' }],
  },
  {
    name: 'Grade High',
    val: 'Grade_High',
    required: true,
    sisFields: [{ label: 'Highest grade of previously enrolled student', field: 'grade_high' }],
  },
  {
    name: 'Grade Low',
    val: 'Grade_Low',
    required: true,
    sisFields: [{ label: 'Lowest grade of previously enrolled student', field: 'grade_low' }],
  },
  {
    name: 'Subject Area',
    val: 'Subject_Area',
    required: true,
    sisFields: [
      { label: '[Courses]Sched_Department', field: 'sched_department' },
      { label: '[Courses]Sched_CourseSubjectAreaCode', field: 'sched_coursesubjectareacode' },
      { label: 'Create based on Sced_SubjectArea', field: 'create_based_on_sced_subjectArea' },
    ],
    customFieldOption: true,
  },
];

export const navianceCleverStaffFields: NavianceField[] = [
  {
    name: 'Email',
    val: 'email',
    required: true,
    sisFields: [{ label: 'Email', field: 'email' }],
  },
  {
    name: 'First name',
    val: 'firstName',
    required: true,
    sisFields: [{ label: 'First Name', field: 'first' }],
    customFieldOption: false,
  },
  {
    name: 'Last name',
    val: 'lastName',
    required: true,
    sisFields: [{ label: 'Last Name', field: 'last' }],
    customFieldOption: false,
  },
  {
    name: 'Unique Staff ID',
    val: 'cleverId',
    required: true,
    sisFields: [{ label: 'User ID', field: 'id' }],
  },
  {
    name: 'Role',
    val: 'role',
    required: true,
    sisFields: [{ label: 'Titles', field: 'title' }],
    customFieldOption: false,
  },
  {
    name: 'Employee ID',
    val: 'employeeIdExternal',
    required: false,
    sisFields: [{ label: 'ID Number', field: 'id_number' }],
    customFieldOption: false,
  },
  {
    name: 'Federation ID',
    val: 'federationId',
    required: false,
    sisFields: [{ label: 'User ID', field: 'id' }],
    customFieldOption: false,
  },
  {
    name: 'Middle name',
    val: 'middleinitial',
    required: false,
    sisFields: [{ label: 'Middle Name', field: 'middle' }],
    customFieldOption: false,
  },
  {
    name: 'Job title',
    val: 'title',
    required: true,
    sisFields: [{ label: 'Titles', field: 'title' }],
    customFieldOption: false,
  },
];

export const courseCatalogRecommendedFields: NavianceField[] = [
  {
    name: 'CTE',
    val: 'CTE',
    required: false,
    sisFields: [
      { label: '[Courses]Vocational', field: 'vocational' },
      { label: '[Courses]IsCareerTech', field: 'iscareertech' },
    ],
    customFieldOption: true,
  },
  {
    name: 'Description',
    val: 'Description',
    required: false,
    sisFields: [{ label: '[Courses]Sched_FullCatalogDescription', field: 'sched_fullcatalogdescription' }],
    customFieldOption: true,
  },
  {
    name: 'Instructional Level',
    val: 'Instructional_Level',
    required: false,
    sisFields: [{ label: 'Digits part of Course_Number', field: 'il_digits_part_of_course_number' }],
    customFieldOption: true,
  },
  {
    name: 'SCED Course Identifier',
    val: 'SCED_Course_Identifier',
    required: false,
    sisFields: [],
    customFieldOption: true,
  },
  {
    name: 'SCED Subject_Area',
    val: 'SCED_Subject_Area',
    required: false,
    sisFields: [],
    customFieldOption: true,
  },
  {
    name: 'State ID',
    val: 'State_ID',
    required: false,
    sisFields: [
      { label: '[CoursesCoreFields]Alt_Course_Number', field: 'alt_course_number' },
      { label: 'Digits part of Course_Number', field: 'sid_digits_part_of_course_number' },
    ],
    customFieldOption: true,
  },
  {
    name: 'Status',
    val: 'Status',
    required: false,
    sisFields: [{ label: 'Active if [School_Course]YearID is current academic year', field: 'status' }],
  },
];

export const courseMappingFields: NavianceField[] = [
  {
    name: 'Course ID',
    val: 'Course_ID',
    required: true,
    sisFields: [{ label: '[ScheduleCourseCatalogs]Course_Number', field: 'course_number' }],
    customFieldOption: false,
  },
  {
    name: 'School ID',
    val: 'Naviance_School_ID',
    required: true,
    sisFields: [{ label: '[ScheduleCourseCatalogs]SchoolID', field: 'navschoolid' }],
    customFieldOption: false,
  },
];

export const courseCatalogAdditionalFields: NavianceField[] = [
  {
    name: 'Coreq ID',
    val: 'Coreq_ID',
    required: false,
    sisFields: [{ label: '[CoursePreReqRule]RuleExpression', field: 'coreq_id' }],
    customFieldOption: true,
  },
  {
    name: 'Credit Type',
    val: 'Credit_Type',
    required: false,
    sisFields: [{ label: '[Courses]Credit_Type', field: 'credittype' }],
    customFieldOption: false,
  },
  {
    name: 'Equivalent',
    val: 'Equivalent',
    required: false,
    sisFields: [{ label: '[CourseEquivalency]Course_Number_Equivalent', field: 'course_number_equivalent' }],
    customFieldOption: true,
  },
  {
    name: 'Prereq ID',
    val: 'Prereq_ID',
    required: false,
    sisFields: [{ label: '[CoursePreReqRule]RuleExpression', field: 'prereq_id' }],
    customFieldOption: true,
  },
  {
    name: 'Prereq Text',
    val: 'Prereq_Text',
    required: false,
    sisFields: [{ label: '[CoursePreReqRule]Note', field: 'prereq_text' }],
    customFieldOption: true,
  },
  {
    name: 'Recommendation Required',
    val: 'Recommendation_Required',
    required: false,
    sisFields: [{ label: '[CoursePreReqRule]RuleExpression', field: 'recommendation_required' }],
    customFieldOption: true,
  },
  {
    name: 'Repeatable',
    val: 'Repeatable',
    required: false,
    sisFields: [
      { label: '[School_Course_Sched_Parm]Allow_Std_Rpt_Same_Term', field: 'allow_std_rpt_same_term' },
      { label: '[School_Course_Sched_Parm]Allow_Std_Rpt_Diff_Term', field: 'allow_std_rpt_diff_term' },
    ],
    customFieldOption: true,
  },
];

export const currentCoursesRequiredFields: NavianceField[] = [
  {
    name: 'Course Status',
    val: 'Course_Status',
    required: true,
    sisFields: [{ label: 'Course_Status', field: 'course_status' }],
  },
  {
    name: 'Course ID',
    val: 'Course_Id',
    required: true,
    sisFields: [{ label: '[CC]Course_Number', field: 'course_number' }],
  },
  {
    name: 'Grade Level Taken',
    val: 'Grade_Level_Taken',
    required: true,
    sisFields: [{ label: '[Students]Grade_Level', field: 'grade_level' }],
  },
  {
    name: 'Student ID',
    val: 'Student_Id',
    required: true,
    sisFields: [{ label: '[Students]Student_Number', field: 'student_number' }],
  },
];

export const currentCoursesRecommendedFields: NavianceField[] = [
  {
    name: 'Term',
    val: 'Term',
    required: false,
    customFieldOption: true,
    sisFields: [{ label: '[Terms]Abbreviation', field: 'abbreviation' }],
  },
];

export const currentCoursesAdditionalFields: NavianceField[] = [
  {
    name: 'Course Name',
    val: 'Course_Name',
    required: false,
    customFieldOption: true,
    sisFields: [{ label: '[Courses]Course_Name', field: 'course_name' }],
  },
  {
    name: 'Teacher',
    val: 'Teacher',
    required: false,
    customFieldOption: true,
    sisFields: [{ label: '[Teachers]LastFirst', field: 'lastfirst' }],
  },
  {
    name: 'Section',
    val: 'Section',
    required: false,
    customFieldOption: true,
    sisFields: [{ label: '[CC]SectionID', field: 'sectionid' }],
  },
  {
    name: 'School Year',
    val: 'School_Year',
    required: false,
    customFieldOption: true,
    sisFields: [{ label: '[CC]TermID', field: 'termid' }],
  },
  {
    name: 'Credits Attempted',
    val: 'Credits_Attempted',
    required: false,
    customFieldOption: true,
    sisFields: [{ label: '[Courses]Credit_Hours', field: 'credit_hours' }],
  },
  {
    name: 'Credit Type',
    val: 'Credit_Type',
    required: false,
    sisFields: [{ label: '[Courses]Credit_Type', field: 'credittype' }],
  },
];

export const courseHistoryRequiredFields: NavianceField[] = [
  {
    name: 'Course Status',
    val: 'Course_Status',
    required: true,
    sisFields: [{ label: 'Course_Status', field: 'course_status' }],
  },
  {
    name: 'Course ID',
    val: 'Course_Id',
    required: true,
    sisFields: [{ label: '[StoredGrades]Course_Number', field: 'course_number' }],
  },
  {
    name: 'Credits Earned',
    val: 'Credits_Earned',
    required: true,
    sisFields: [{ label: '[StoredGrades]EarnedCrHrs', field: 'earnedcrhrs' }],
  },
  {
    name: 'Grade Level Taken',
    val: 'Grade_Level_Taken',
    required: true,
    sisFields: [{ label: '[StoredGrades]Grade_Level', field: 'grade_level' }],
  },
  {
    name: 'Credits Attempted',
    val: 'Credits_Attempted',
    required: true,
    sisFields: [{ label: '[StoredGrades]PotentialCrHrs', field: 'potentialcrhrs' }],
  },
  {
    name: 'Student ID',
    val: 'Student_Id',
    required: true,
    sisFields: [{ label: '[Students]Student_Number', field: 'student_number' }],
  },
  {
    name: 'Term',
    val: 'Term',
    required: true,
    sisFields: [{ label: '[StoredGrades]StoreCode', field: 'storecode' }],
  },
];

export const courseHistoryRecommendedFields: NavianceField[] = [];

export const courseHistoryAdditionalFields: NavianceField[] = [
  {
    name: 'Course Name',
    val: 'Course_Name',
    required: false,
    sisFields: [{ label: '[StoredGrades]Course_Name', field: 'course_name' }],
  },
  {
    name: 'Teacher',
    val: 'Teacher',
    required: false,
    sisFields: [{ label: '[StoredGrades]Teacher_Name', field: 'teacher_name' }],
  },
  {
    name: 'Section',
    val: 'Section',
    required: false,
    sisFields: [{ label: '[StoredGrades]SectionID', field: 'sectionid' }],
  },
  {
    name: 'Score',
    val: 'Score',
    required: false,
    sisFields: [{ label: '[StoredGrades]Percent', field: 'percent' }],
  },
  {
    name: 'School_Year',
    val: 'School_Year',
    required: false,
    sisFields: [{ label: '[StoredGrades]TermID', field: 'termid' }],
  },
  {
    name: 'Credit Type',
    val: 'Credit_Type',
    required: false,
    sisFields: [{ label: '[Courses]Credit_Type', field: 'credit_type' }],
  },
  {
    name: 'Letter Grade',
    val: 'Letter_Grade',
    required: false,
    sisFields: [{ label: '[StoredGrades]Grade', field: 'grade' }],
  },
];
export const userNameFields: NavianceField[] = [
  {
    name: 'Naviance Student Username',
    val: 'username',
    required: false,
    sisFields: [
      { label: 'student_web_id', field: 'student_web_id' },
      { label: 'email', field: 'email' },
      { label: 'student_number', field: 'student_number' },
    ],
    customFieldOption: true,
  },
];

export const eSPUserNameFields: NavianceField[] = [
  {
    name: 'Naviance Student Username',
    val: 'username',
    required: false,
    sisFields: [{ label: 'Login ID', field: 'localUsername' }],
  },
];

export const cleverUserNameFields: NavianceField[] = [
  {
    name: 'Username',
    val: 'username',
    required: false,
    sisFields: [
      { label: 'credentials.district_username', field: 'district_username' },
      { label: 'id', field: 'id' },
      { label: 'sis_id', field: 'sis_id' },
      { label: 'student_number', field: 'student_number' },
    ],
  },
];

export const navianceAlumniFields: NavianceField[] = [
  {
    name: 'Date Of Birth',
    val: 'birthDate',
    required: true,
    sisFields: [{ label: '[Students]DOB', field: 'dob' }],
  },
  {
    name: 'Diploma Type',
    val: 'diplomaType',
    required: true,
    sisFields: [{ label: '[Students]ExitCode', field: 'exitcode' }],
    customFieldOption: true,
  },
  {
    name: 'First Name',
    val: 'firstName',
    required: true,
    sisFields: [
      { label: '[Students]First_Name', field: 'first_name' },
      { label: '[StudentCoreFields]PSCore_Legal_First_Name', field: 'legal_first_name' },
    ],
  },
  {
    name: 'Graduation Date',
    val: 'gradDate',
    required: true,
    sisFields: [{ label: '[Students]ExitDate', field: 'exitdate' }],
    customFieldOption: true,
  },
  {
    name: 'Ferpa Block Requested',
    val: 'ferpa',
    required: false,
    sisFields: [],
    customFieldOption: true,
  },
];

export const navianceESPAlumniFields: NavianceField[] = [
  {
    name: 'Date Of Birth',
    val: 'birthDate',
    required: true,
    sisFields: [{ label: 'Birth Date', field: 'birthdate' }],
  },
  {
    name: 'Diploma Type',
    val: 'diplomaType',
    required: true,
    sisFields: [{ label: 'Diploma Type', field: 'diplomaType' }],
  },
  {
    name: 'First Name',
    val: 'firstName',
    required: true,
    sisFields: [{ label: 'First_Name', field: 'firstName' }],
  },
  {
    name: 'Graduation Date',
    val: 'gradDate',
    required: true,
    sisFields: [{ label: 'Graduation Date', field: 'graduationDate' }],
  },
  {
    name: 'Ferpa Block Requested',
    val: 'ferpa',
    required: false,
    sisFields: [{ label: 'Ferpa Name', field: 'ferpaName' }],
  },
];

export const navianceStaffPSFields: NavianceField[] = [
  {
    name: 'Email',
    val: 'email',
    required: true,
    sisFields: [{ label: 'USERS.Email_Addr', field: 'email_addr' }],
  },
  {
    name: 'First Name',
    val: 'firstName',
    required: true,
    sisFields: [{ label: 'USERS.First_Name', field: 'first_name' }],
    customFieldOption: false,
  },
  {
    name: 'Last Name',
    val: 'lastName',
    required: true,
    sisFields: [{ label: 'USERS.Last_Name', field: 'last_name' }],
    customFieldOption: false,
  },
  {
    name: 'Role',
    val: 'role',
    required: true,
    sisFields: [{ label: 'ROLEDEF.Name', field: 'roledefid' }],
    customFieldOption: false,
  },
  {
    name: 'Unique Staff ID',
    val: 'personUuid',
    required: true,
    sisFields: [{ label: 'USERS.DCID', field: 'dcid' }],
  },
  {
    name: 'Status',
    val: 'isActive',
    required: true,
    sisFields: [{ label: 'SchoolStaff.Status', field: 'status' }],
    customFieldOption: false,
  },
  {
    name: 'Employee ID',
    val: 'employeeIdExternal',
    required: true,
    sisFields: [{ label: 'USERS.TeacherNumber', field: 'teachernumber' }],
    customFieldOption: false,
  },
  {
    name: 'Federation ID [Note: Federation ID is required for SSO]',
    val: 'federationId',
    required: false,
    sisFields: [
      { label: 'USERS.DCID', field: 'dcid' },
      { label: 'USERS.LoginID', field: 'loginid' },
      { label: 'USERS.TeacherNumber', field: 'teachernumber' },
      { label: 'USERS.TeacherLoginID', field: 'teacherloginid' },
    ],
    customFieldOption: true,
  },
  {
    name: 'Middle Name',
    val: 'middleInitial',
    required: false,
    sisFields: [{ label: 'USERS.Middle_Name', field: 'middle_name' }],
    customFieldOption: false,
  },
  {
    name: 'Job Title',
    val: 'title',
    required: false,
    sisFields: [{ label: 'USERS.Title', field: 'title' }],
    customFieldOption: true,
  },
  {
    name: 'Prefix',
    val: 'prefix',
    required: false,
    sisFields: [],
    customFieldOption: true,
  },
];

export const navianceParentPSFields: NavianceField[] = [
  {
    name: 'High School Student ID',
    val: 'hsStudentId', // student table
    required: true,
    sisFields: [{ label: 'Students.Student_Number', field: 'student_number' }],
  },
  {
    name: 'Unique Parent ID',
    val: 'hsParentId', // parent table
    required: true,
    sisFields: [{ label: 'Person.DCID', field: 'person_dcid' }],
    customFieldOption: false,
  },
  {
    name: 'Last Name',
    val: 'lastName', // parent table
    required: true,
    sisFields: [{ label: 'Person.LastName', field: 'last_name' }],
    customFieldOption: false,
  },
  {
    name: 'First Name',
    val: 'firstName', // parent table
    required: true,
    sisFields: [{ label: 'Person.FirstName', field: 'first_name' }],
    customFieldOption: false,
  },
  {
    name: 'Parent Email',
    val: 'email', // parent table
    required: false,
    sisFields: [{ label: 'EmailAddress', field: 'email_address' }],
    customFieldOption: false,
  },
  {
    name: 'Federation ID',
    val: 'federationId', // parent table
    required: false,
    sisFields: [
      { label: 'Person.DCID', field: 'person_dcid' },
      { label: 'Guardian.GuardianID', field: 'guardian_id' },
    ],
    customFieldOption: false,
  },
  {
    name: 'Contact Address',
    val: 'street', // parent table
    required: false,
    sisFields: [{ label: 'PersonAddress.Street', field: 'street' }],
    customFieldOption: false,
  },
  {
    name: 'Address1',
    val: 'lineTwo', // parent table
    required: false,
    sisFields: [{ label: 'PersonAddress.LineTwo', field: 'line_two' }],
    customFieldOption: false,
  },
  {
    name: 'City',
    val: 'city', // parent table
    required: false,
    sisFields: [{ label: 'PersonAddress.City', field: 'city' }],
    customFieldOption: false,
  },
  {
    name: 'State',
    val: 'state', // parent table
    required: false,
    sisFields: [{ label: 'PersonAddress.STATECODESETID', field: 'state' }],
    customFieldOption: false,
  },
  {
    name: 'Zip',
    val: 'postalCode', // parent table
    required: false,
    sisFields: [{ label: 'PersonAddress.PostalCode', field: 'postal_code' }],
    customFieldOption: false,
  },
  {
    name: 'Country',
    val: 'country', // parent table
    required: false,
    sisFields: [{ label: 'PersonAddress.COUNTRYCODESETID', field: 'country' }],
    customFieldOption: false,
  },
  {
    name: 'Home Phone',
    val: 'homePhone', // parent table
    required: false,
    sisFields: [{ label: 'Home Phone', field: 'home' }],
    customFieldOption: false,
  },
  {
    name: 'Mobile Phone',
    val: 'mobilePhone', // parent table
    required: false,
    sisFields: [{ label: 'Mobile Phone', field: 'mobile' }],
    customFieldOption: false,
  },
  {
    name: 'Work Phone',
    val: 'workPhone', // parent table
    required: false,
    sisFields: [{ label: 'Work Phone', field: 'work' }],
    customFieldOption: false,
  },
];

export const parentUserNameFields: NavianceField[] = [
  {
    name: 'Naviance Parent Username',
    val: 'userName', // parent_user table
    required: false,
    sisFields: [
      { label: 'Guardian.AccountIdentifier', field: 'account_identifier' },
      { label: 'EmailAddress', field: 'email_address' },
      { label: 'Person.DCID', field: 'person_dcid' },
    ],
  },
];

export const alphabeticalFields = (fields: NavianceField[]): NavianceField[] => {
  const reqArr = fields.filter((field) => {
    return field.required;
  });

  const nonReqArr = fields.filter((field) => {
    return !field.required;
  });

  return reqArr
    .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    .concat(nonReqArr.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())));
};

export const swapMapping = (
  mapping: Record<string, string[] | string>,
): { username: string; headerMappings: Record<string, string | string[]> } => {
  const username = Array.isArray(mapping.username) ? mapping.username[0] : mapping.username;
  const output = { username };

  if (username === 'email') {
    delete mapping.username;
  } else if (!username) {
    delete mapping.username;
    delete output.username;
  }

  const swap = (obj: Record<string, string | string[]>, key: string, value: string) => {
    const currObjValue = obj[value];
    if (currObjValue) {
      const newValues = [key];
      if (Array.isArray(currObjValue)) {
        newValues.push(...currObjValue);
      } else {
        newValues.push(currObjValue);
      }
      return { ...obj, [value]: newValues };
    }
    return { ...obj, [value]: key };
  };

  return {
    ...output,
    headerMappings: Object.keys(mapping).reduce((p, key) => {
      const value = mapping[key];
      if (Array.isArray(value)) {
        const newMaps = value.reduce((acc, val) => swap(acc, key, val), { ...p });
        return { ...newMaps };
      } else {
        return { ...swap(p, key, value) };
      }
    }, {} as Record<string, string | string[]>),
  };
};

export const hiddenFields = ['dcid', 'grade'];
