import type {
    SisStudentReconciled,
} from '../../../../../../libs/common-interfaces';
import { Col, List, Row } from 'antd';
import * as React from 'react';
import { PaginationConfig } from 'antd/lib/pagination';
import { IntegrationContext } from '../../utils/context';
import SisStudentReconcileEntity from '../../components-v2/SisReconcileJob/SisStudentReconcileEntity';
import { fetchToReconcile } from '../../utils/reconcileUtils';
import StatusIcon from '../../../assets/Icon/Status';

const VerticalDivider = () => {
    return <div style={{ width: 1, height: 15, backgroundColor: "#e0e0e0", margin: "3px 10px" }}></div>;
};

const ReconcileHeader = ({ inProgress, yetToStart }: { inProgress: number; yetToStart: number }) => {
    return (
        <span style={{ display: 'flex', paddingLeft: 10 }}>Status &nbsp;<StatusIcon inProgress={true} /> {inProgress} in progress <VerticalDivider /> <StatusIcon /> {yetToStart} not started</span>
    );
};

const PSDataImportStudentJobReconcile = ({
    jobGuid,
    update,
    post,
    isTestImport,
    reconcileDisabled,
    setReconcileDisabled,
}: {
    jobGuid: string;
    update: (updatedReconciled: SisStudentReconciled) => void;
    post: () => void;
    isTestImport: boolean;
    reconcileDisabled: boolean;
    setReconcileDisabled: (updated: boolean) => void;
}): React.ReactElement => {
    const [toReconcile, setToReconcile] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>();
    const [reconciled, setReconciled] = React.useState<SisStudentReconciled>(
        {},
    );
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [matchedRows, setMatchedRows] = React.useState<
        Record<
            string,
            { student: string; create: boolean }
        >
    >({});
    const [inProgress, setInProgress] = React.useState<number>(0);
    const [yetToStart, setYetToStart] = React.useState<number>(0);

    const { isLegacyCleverEnabled } = React.useContext(IntegrationContext);

    const pagination = {
        total: totalRecords,
        showSizeChanger: true,
        defaultPageSize: 10,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: ['10', '50', '100'],
        onShowSizeChange: async (currentPage, size) => {
            await fetchToReconcile(
                setLoading,
                setToReconcile,
                setTotalRecords,
                jobGuid,
                currentPage,
                size)
        },
        onChange: async (page, size) => {
            await fetchToReconcile(
                setLoading,
                setToReconcile,
                setTotalRecords,
                jobGuid,
                page, size
            )
        },
    } as PaginationConfig;

    const reconcile = async () => {
        setReconcileDisabled(true);
        post();
    };

    React.useEffect(() => {
        fetchToReconcile(setLoading, setToReconcile, setTotalRecords, jobGuid);
    }, []);

    React.useEffect(() => {
        setInProgress(Object.keys(matchedRows).length);
        setYetToStart(totalRecords - Object.keys(matchedRows).length);
    }, [totalRecords, matchedRows]);

    React.useEffect(() => {
        update(reconciled);
    }, [reconciled]);

    const updateReconciled = (update: SisStudentReconciled) => {
        const updatedReconciled = { ...reconciled };
        Object.keys(update).forEach((row) => {
            if (update[row].student || update[row].create) {
                updatedReconciled[row] = update[row];
            } else {
                delete updatedReconciled[row];
            }
        });
        setReconciled(updatedReconciled);
    };

    return (
        <>
            <br />
            <Row justify="space-between" align="middle">
                <Col span={20}>
                    {!loading && (
                        <ReconcileHeader inProgress={inProgress} yetToStart={yetToStart} />
                    )}
                </Col>
                <Col span={4}>
                    {Object.keys(toReconcile).length > 0 && !reconcileDisabled && !isLegacyCleverEnabled && (
                        <button
                            className="button button-blue"
                            style={{ float: 'right' }}
                            disabled={
                                Object.keys(reconciled).length === 0 ||
                                Object.keys(toReconcile).length === 0 ||
                                reconcileDisabled ||
                                isTestImport ||
                                isLegacyCleverEnabled
                            }
                            onClick={() => {
                                reconcile();
                            }}
                            data-test-id="process_btn"
                        >
                            Process all updates
                        </button>
                    )}
                </Col>
            </Row>
            <Row justify="space-between" align="middle">
                <Col span={24}>
                    <List
                        itemLayout="horizontal"
                        dataSource={toReconcile}
                        renderItem={(row: any) => (
                            <SisStudentReconcileEntity
                                student={row.student}
                                navStudents={row.navStudents}
                                key={row.student.dcid}
                                updateReconciled={updateReconciled}
                                matchedRows={matchedRows}
                                setMatchedRows={setMatchedRows}
                                isDisabled={Object.keys(toReconcile).length === 0 || reconcileDisabled || isTestImport || isLegacyCleverEnabled}
                            />
                        )}
                        loading={loading}
                        pagination={pagination}
                        size="large"
                    />
                </Col>
            </Row>
        </>
    );
};

export default PSDataImportStudentJobReconcile;
