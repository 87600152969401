import { Col, Row } from 'antd';
import * as React from 'react';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { API_URL } from '../../utils/constants';
import { getJobStatusMsg } from '../../utils/jobStatus';

const courseCatalogImportData = {
  courseCatalogDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
    jobGuid,
  ) => {
    let warningCount = 0;
    for (const category in warningRowsPerCategory) {
      warningCount += warningRowsPerCategory[category];
    }

    let recordsSkipped = 0;
    for (const category in errorRowsPerCategory) {
      recordsSkipped += errorRowsPerCategory[category];
    }
    const noChanges =
      job?.importResults?.activeCourses - (job?.importResults?.createdImports + job?.importResults?.updatedImports);

    return [
      {
        name: 'Total Records',
        key: 'Total Records',
        value: job?.validation?.totalDataCount,
      },
      {
        name: 'Active(no errors)',
        key: 'Active(no errors)',
        value: job?.importResults?.activeCourses,
      },
      {
        name: 'Added',
        key: 'Added',
        value: job?.importResults?.createdImports,
      },
      {
        name: 'Updated',
        key: 'Updated',
        value: job?.importResults?.updatedImports,
      },
      {
        name: 'Deactivated',
        key: 'Deactivated',
        value: job?.importResults?.inactivated,
      },
      {
        name: 'Reactivated',
        key: 'Reactivated',
        value: job?.importResults?.reactivated,
      },
      {
        name: 'No Changes',
        key: 'No Changes',
        value: noChanges,
      },
      {
        name: 'Alerts',
        key: 'Alerts',
        value: (
          <Row>
            {hasIssues && (
              <Col span={2}>
                <AlertYellowIcon />
              </Col>
            )}
            <Col span={22}>
              <Row>
                <Col>{job?.noOfAlerts}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${recordsSkipped} Errors:`}</span> Records Skipped
                  {recordsSkipped && (
                    <ul className="custom-bullet">
                      {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                        return (
                          <li key={category}>
                            <span style={{}}>{`${errorRowsPerCategory[category]} ${category}`}: </span>
                            {errorColumnsPerCategory[category].join(', ')}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${warningCount} Warnings:`}</span> Records Not Skipped
                  {Number(job?.validation?.warningCount) > 0 && (
                    <ul className="custom-bullet">
                      {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                        return (
                          <li key={category}>
                            <span style={{}}>{` ${warningRowsPerCategory[category]} ${category}`}: </span>
                            {warningColumnsPerCategory[category].join(', ')}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="export-link">
                    <a
                      onClick={() =>
                        (window.location.href = `${API_URL}/data-ingest/sis/jobs/${jobGuid}/download/logs`)
                      }
                      data-test-id="export_link"
                    >
                      Export Log for additional details
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
    ];
  },

  courseCatalogSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: 'PowerSchool SIS',
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },

  courseCatalogUpdatedResolvedData: (job, history, context) => {
    const { dataImportPath } = context;
    return [
      {
        name: 'Last Completed by',
        key: 'Last Completed by',
        value: job?.updatesResolved?.author,
      },
      {
        name: 'Last Completed on',
        key: 'Last Completed on',
        value: job?.updatesResolved?.end ? new Date(job?.updatesResolved?.end).toLocaleString() : '',
      },
      {
        name: 'Potential Updates Resolved',
        key: 'Potential Updates Resolved',
        value: (
          <Row>
            <Col span={22}>
              <Row>
                <Col>{job?.updatesResolved.recordsMatched + job?.updatesResolved.newRecordsAdded || 0}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.recordsMatched || 0
                  } records matched`}</span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.newRecordsAdded || 0
                  } records added`}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
      {
        name: 'Student Updates Log',
        key: 'Student Updates Log',
        value: (
          <a className="backbtn" onClick={() => history.push(dataImportPath + '/studentUpdates')}>
            View Student Updates Log
          </a>
        ),
      },
    ];
  },

  subjectAreaDetailsData: (job) => {
    const subjectAreaWiseData = job.importResults.importResultSubjectAreaWise ? job.importResults.importResultSubjectAreaWise : [];
    
    if (subjectAreaWiseData?.length === 0 ) {
      return [];
    }
    for(let i=0;i<subjectAreaWiseData.length;i++){
      subjectAreaWiseData[i].no_changes=subjectAreaWiseData[i].active-(subjectAreaWiseData[i].added+subjectAreaWiseData[i].updated)
    }
    
    const subjectAreaDetails = subjectAreaWiseData.sort(function (a: any, b: any) {
      return a.subject_area - b.subject_area;
    });
    if (subjectAreaDetails[0].subject_area === '')
      subjectAreaDetails[0].subject_area ="No Subject Area";
    return subjectAreaDetails;
  },
};

export default courseCatalogImportData;
