// ----------------------------------------------------------------------------
// IMPORTS

/* NPM */
import React, { FormEvent, useState } from 'react';

// hooks
import { useHistory } from 'react-router-dom';

// components
import { Modal } from 'antd';
import Title from 'antd/lib/typography/Title';
import { LabeledValue, SelectValue } from 'antd/es/select';

import Row from '../../components-v2/Row';
import Col from '../../components-v2/Col';
import MainTemplate from '../Shell/MainTemplate';
import Spinner from '../../components-v2/Spinner';
import ConfigureButton from '../../components-v2/Button/ConfigureButton';
import SyncCourseRequestForm, { FieldName, PLAN_STATUS, schoolIds, CourseRequestParameterGroup } from './form';
import { DEFAULT_ROLL_OVER_DAY, DEFAULT_ROLL_OVER_MONTH } from '../../utils/constants';

// icons
import { CheckCircleFilled } from '@ant-design/icons';

// utils
import apiClient from '../../utils/apiClient';
import { IntegrationContext } from './../../utils/context';
import { showNotification, NotificationTypes } from '../../components/Notifications';

// const
type ClassYearRange = [number, number];
import { NotificationStyle } from '../SisStartImport';
import type { NavSchoolsType } from '../../../../../../libs/common-interfaces';
export interface PlanOfStudyType {
  value: string;
  label: string;
}

// request endpoints
const startCourseRequestJobUrl = 'data-ingest/sis/start-course-request';
const naviancePlanOfStudyUrl = 'data-ingest/sis/ps-nav-plan-of-study';
const getSisSchoolsUrl = 'data-ingest/sis/parameter-group/schoolMapping';
const getNavSchoolsUrl = 'highschools/v2?byType=district&includeInactive=true';
const courseRequestParameterGroupUrl = 'data-ingest/sis/parameter-group/course-request';

const getCurrentYear = () => {
  const today = new Date(Date.now());
  const year = today.getFullYear();
  const semesterLimit = new Date(year, DEFAULT_ROLL_OVER_MONTH, DEFAULT_ROLL_OVER_DAY);
  return today < semesterLimit ? year : year + 1;
};

const getClassYearRange = (currentYear: number, numYears: number): ClassYearRange[] => {
  const ranges = [];
  for (let i = 0; i < numYears; i++) {
    ranges.push([i + currentYear - 1, i + currentYear]);
  }
  return ranges;
};

const generateAcademicYearOptions = (currentYear, numberOfYears = 6): LabeledValue[] => {
  const classYearRanges = getClassYearRange(currentYear, numberOfYears);

  return classYearRanges.map(([fromYear, toYear]) => ({
    label: `${fromYear} - ${toYear} School Year`,
    value: fromYear,
  }));
};

const getClassYearLabel = (year: number, currentYear: number): string => {
  const maxGrade = 12;
  if (year >= currentYear) {
    const gradeCalculated = maxGrade - (year - currentYear);
    if (gradeCalculated < 0) {
      return `Class of ${year} (Grade k)`;
    }

    return `Class of ${year} (Grade ${gradeCalculated})`;
  }

  return `Class of ${year}`;
};

const generateClassYearOptions = (currentYear: number): LabeledValue[] => {
  const classYears = Array(14)
    .fill(currentYear - 6)
    .map((x: number, index: number) => x + index);

  return classYears.map((year) => ({
    label: getClassYearLabel(year, currentYear),
    value: year,
  }));
};

const SyncCourseRequest = (): React.ReactElement => {
  const history = useHistory();
  const isMountedRef = React.useRef(null);
  const currentYear = getCurrentYear() as number;
  const [loading, setLoading] = React.useState(false);
  const [pageLoading, setPageLoading] = React.useState(true);
  const [disableStart, setDisableStart] = React.useState(false);
  const { dataImportPath, userFullName } = React.useContext(IntegrationContext);
  const [plansOfStudy, setPlansOfStudy] = React.useState<PlanOfStudyType[]>([]);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const [availableMappedSchools, setAvailableMappedSchools] = React.useState<NavSchoolsType[]>([]);
  const initialSelectedFieldsValues: CourseRequestParameterGroup = {
    academicYear: currentYear,
    classYearFrom: currentYear,
    classYearTo: currentYear,
    planOfStudy: null,
    planStatus: null,
    schools: null,
  };
  const [courseRequestSettings, setCourseRequestSettings] =
    useState<CourseRequestParameterGroup>(initialSelectedFieldsValues);

  const academicYears: LabeledValue[] = generateAcademicYearOptions(currentYear);

  const classYearOptions: LabeledValue[] = generateClassYearOptions(currentYear);

  const handleOnChange = (fieldName: FieldName, value: SelectValue | schoolIds | PLAN_STATUS) => {
    const newCourseRequestSettings: CourseRequestParameterGroup = JSON.parse(JSON.stringify(courseRequestSettings));
    if (fieldName === 'classYearFrom') {
      // if classYearTo is gearter than classYearFrom reset it
      const classYearTo = courseRequestSettings.classYearTo < value ? value : courseRequestSettings.classYearTo;
      newCourseRequestSettings.classYearTo = classYearTo;
    }
    setCourseRequestSettings({
      ...newCourseRequestSettings,
      [fieldName]: value,
    });
  };

  const handleFormSubmit = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setDisableStart(true);
      await apiClient.post(startCourseRequestJobUrl, {
        type: 'course-request',
        requestConfig: courseRequestSettings,
        author: userFullName,
      });
      setShowConfirmationModal(true);
      onOk();
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Starting Job', 'Server Error');
    }
  };

  const onOk = () => {
    history.push(dataImportPath);
  };

  const loadPage = async () => {
    if (isMountedRef.current) {
      // start loading
      setLoading(true);
    }

    // get schools
    const fetchSisSchoolsPromise = apiClient.get(getSisSchoolsUrl);
    const fetchNavSchoolsPromise = apiClient.get(getNavSchoolsUrl);

    // get plan of study
    const fetchPlanOfStudyPromise = apiClient.get<PlanOfStudyType[]>(naviancePlanOfStudyUrl);

    // get saved parametergroup data
    const fetchCourseRequestParameterGroupPromise =
      apiClient.get<CourseRequestParameterGroup>(courseRequestParameterGroupUrl);

    try {
      // resolve all calls
      const [sisSchoolMapping, navSchoolMapping, planOfStudy, courseRequestParameterGroupSettings] = await Promise.all([
        fetchSisSchoolsPromise,
        fetchNavSchoolsPromise,
        fetchPlanOfStudyPromise,
        fetchCourseRequestParameterGroupPromise,
      ]).finally(() => {
        if (isMountedRef.current) setPageLoading(false);
      });

      const sisMapping = sisSchoolMapping.data?.schoolMapping.reduce((acc, value) => {
        const { sisId, nid, disabled } = value;
        acc[nid] = { nid, sisId, enabled: !disabled };
        return acc;
      }, {});

      const mappedSchools = Object.values(sisMapping)
        .filter((mappedSchool: { sisId: string }) => !!mappedSchool.sisId)
        .map((mappedSchool: { nid: string }) => mappedSchool.nid);

      const navSchools: NavSchoolsType[] = navSchoolMapping.data
        .filter((navSchool) => mappedSchools.includes(navSchool.nid))
        .sort((a, b) => (a.isAccount > b.isAccount ? 1 : a.isAccount < b.isAccount ? -1 : 0));

      // update states
      setAvailableMappedSchools(navSchools);
      setPlansOfStudy(planOfStudy.data || []);

      setCourseRequestSettings({
        academicYear:
          courseRequestParameterGroupSettings.data?.academicYear || initialSelectedFieldsValues.academicYear,
        classYearFrom:
          courseRequestParameterGroupSettings.data?.classYearFrom || initialSelectedFieldsValues.classYearFrom,
        classYearTo: courseRequestParameterGroupSettings.data?.classYearTo || initialSelectedFieldsValues.classYearTo,
        planOfStudy: courseRequestParameterGroupSettings.data?.planOfStudy || initialSelectedFieldsValues.planOfStudy,
        planStatus: courseRequestParameterGroupSettings.data?.planStatus || initialSelectedFieldsValues.planStatus,
        schools: courseRequestParameterGroupSettings.data?.schools || initialSelectedFieldsValues.schools,
      });
    } catch (e) {
      console.log('Error in loadPage call: Course Request', e.message);
    } finally {
      if (isMountedRef.current) {
        // hide loading
        setLoading(false);
      }
    }
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  return (
    <MainTemplate
      title="Sync Course Requests"
      titleTestId="sync-course-request"
      subheaderRSContent={<ConfigureButton />}
    >
      <React.Fragment>
        <Row className="sync-course-request">
          <Col span={24}>
            <Row>
              <Col xs={24} md={20} lg={16} xl={14} xxl={12}>
                <p>
                  Use filters to customize your student course requests sync. A large number of course requests may take
                  up to 4 hours to complete
                </p>
              </Col>
            </Row>
            <Row>
              <Col xs={24} md={18} lg={14} xl={10} xxl={8}>
                {pageLoading ? (
                  <Spinner />
                ) : (
                  <SyncCourseRequestForm
                    loading={loading}
                    disableStart={disableStart}
                    plansOfStudy={plansOfStudy}
                    academicYears={academicYears}
                    classYearToList={classYearOptions}
                    schoolList={availableMappedSchools}
                    classYearFromList={classYearOptions}
                    courseRequestSettings={courseRequestSettings}
                    onChange={handleOnChange}
                    onSubmit={handleFormSubmit}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Modal
          visible={showConfirmationModal}
          onOk={onOk}
          cancelButtonProps={{ hidden: true }}
          wrapClassName="start_import_modal"
          okText="Done"
        >
          <Title level={5}>Your import has started!</Title>
          <ul>
            <li>
              Click <b>Done</b> to view your import status on the PowerSchool Data Integration page.
            </li>
            <li>The import may take a few minutes to complete.</li>
          </ul>
        </Modal>
      </React.Fragment>
    </MainTemplate>
  );
};

export default SyncCourseRequest;
