import * as React from 'react';
import { Select } from 'antd';
import {
  CourseCatalogDefineCodesParameterGroup,
  StateIdParameterGroup,
} from '../../containers/SisDefineCodes/SisCourseCatalogDefineCodes';

export interface StateIdDefineCodeProps {
  defineCodesSettings: CourseCatalogDefineCodesParameterGroup;
  triggerSetState: () => void;
}

const StateIdDefineCode = ({ defineCodesSettings, triggerSetState }: StateIdDefineCodeProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);
  const [stateIdSettings, setStateIdSettings] = React.useState<StateIdParameterGroup>({
    fromDigit: 1,
    toDigit: 1,
    fromTranslateConfig: '',
  });

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setStateIdSettings(defineCodesSettings.stateId);
  }, [stateIdSettings]);

  const onChange = (fromDigit: number, toDigit: number) => {
    setStateIdSettings({ ...stateIdSettings, fromDigit, toDigit });
    defineCodesSettings.stateId = { ...stateIdSettings, fromDigit, toDigit };
    triggerSetState();
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    if (isMountedRef.current) setLoading(false);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  return (
    <div className="subSection mainMatchFieldsSection">
      <h2 className="infoHeading uploadHeading">State ID</h2>

      <div className="pagepara">
        <Select
          className="select select-responsive"
          style={{ width: 100 }}
          value={stateIdSettings.fromDigit}
          onChange={(val) => {
            onChange(val, stateIdSettings.toDigit);
          }}
          data-test-id={''}
        >
          {indexRange.map(([value, display]) => (
            <Option
              className="selectOptions"
              key={`sis-state-id-1-${value}`}
              value={value}
              data-test-id={`state_id_option_${value}`}
              disabled={false}
            >
              {display}
            </Option>
          ))}
        </Select>{' '}
        to{' '}
        <Select
          className="select select-responsive"
          style={{ width: 100 }}
          value={stateIdSettings.toDigit}
          onChange={(val) => {
            onChange(stateIdSettings.fromDigit, val);
          }}
          data-test-id={''}
        >
          {indexRange.map(([value, display]) => (
            <Option
              className="selectOptions"
              key={`sis-state-id-2-${value}`}
              value={value}
              data-test-id={`state_id_option_${value}`}
              disabled={false}
            >
              {display}
            </Option>
          ))}
        </Select>{' '}
        digit of Course_Number{' '}
      </div>
    </div>
  );
};
const indexRange = [
  [1, '1st'],
  [2, '2nd'],
  [3, '3rd'],
  [4, '4th'],
  [5, '5th'],
  [6, '6th'],
  [7, '7th'],
  [8, '8th'],
  [9, '9th'],
  [10, '10th'],
  [11, '11th'],
  [12, '12th'],
  [13, '13th'],
  [14, '14th'],
];

export default StateIdDefineCode;
