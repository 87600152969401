import * as React from 'react';
import Col from '../../components-v2/Col';
import Row from '../../components-v2/Row';
import SisConnection from '../../components-v2/SisConnection';
import MainTemplate from '../Shell/MainTemplate';
import { IntegrationContext } from './../../utils/context';
import ConfigButton from './ConfigButton';
import { featureFlags } from '../../utils/featureFlags';
import apiClient from '../../utils/apiClient';

const PSConfigure = () : React.ReactElement => {
  const { isCleverIntegration } = React.useContext(IntegrationContext);

  const [sisIntegration, setSisIntegration] = React.useState(false);
  const getIntegrationSettings = async () => {
    try {
      const { data } = await apiClient.get(`/data-ingest/sis/tenant-meta`);
      if (data.sisIntegration === 'psSis') {
        setSisIntegration(true);
      } else {
        setSisIntegration(false);
      }
    } catch (e) {
      console.error("Couldn't get the meta from tenant!");
    }
  };

  React.useEffect(() => {
    getIntegrationSettings();
  }, []);

  return (
    <MainTemplate title="Configure" titleClassName="configureTitle" titleTestId="configureTitle">
      <div>
        <p>
          Complete <b>{isCleverIntegration ? 'Clever data' : 'SIS'} integration setup</b> before any data integration
          can begin.
        </p>
        <Row>
          <Col span={20}>
            <ConfigButton id="sis_integration_setup" to={'/configure/integration'}>
              {isCleverIntegration ? 'Clever data' : 'SIS'} integration setup
            </ConfigButton>
            <b>Status:</b> <SisConnection />
            <p className="configText" data-test-id="sis_connect_school_match_description">
              {isCleverIntegration
                ? 'Match schools between Naviance and Clever.'
                : 'Setup a connection between Naviance and PowerSchool products and School Matching.'}
            </p>
          </Col>
        </Row>
        <Row>
          <Col span={20}>
            <ConfigButton id="sis_connect_settings" to="/configure/import-settings">
              {isCleverIntegration ? 'Clever data integration' : 'SIS Import'} settings
            </ConfigButton>
            <p className="configText" data-test-id="sis_connect_settings_description">
              Update import settings for connection between Naviance and{' '}
              {isCleverIntegration ? 'Clever' : 'PowerSchool Products'}.
            </p>
          </Col>
        </Row>
        {sisIntegration && featureFlags['feature.dataIngest.cplan.syncCourseRequest'] && !isCleverIntegration && (
          <Row>
            <Col span={20}>
              <ConfigButton id="sync_course_request" to="/sync-course-request">
                Sync Course Request
              </ConfigButton>
              <p className="configText" data-test-id="sync_course_request_description">
                Sync course request data with PowerSchool.
              </p>
            </Col>
          </Row>
        )}
        <Row>
          <Col span={20}>
            <ConfigButton id="sis_email" to="/configure/email">
              Manage email notifications
            </ConfigButton>
            <p className="configText" data-test-id="sis_email_description">
              Indicate one or more recipients of data integration notifications for each data type.
            </p>
          </Col>
        </Row>
      </div>
    </MainTemplate>
  );
};

export default PSConfigure;
