import Title from 'antd/lib/typography/Title';
import * as React from 'react';

interface SisWizardHeaderProps {
    text: string;
} 

const SisWizardHeader = (props: SisWizardHeaderProps) : React.ReactElement => {
    return (
        <Title className="titleField" level={5}>
          {props.text}
        </Title>
      );
};

export default SisWizardHeader;