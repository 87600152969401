import { Menu } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { IntegrationContext } from '../../utils/context';
import { StatusMessages } from '../../utils/jobStatus';

interface UserMenuProps {
  row: {
    done: boolean;
    importDataGuid: string;
    undoEnabled: boolean;
    canContinueImport: boolean;
    navianceStatus: string;
    dataType: string;
  };
  reStartImport: (jobGuid: string, dataType: string) => void;
  setUndoModal: (jobGuid: string) => void;
  setUndoType: (dataType: string) => void;
}

const UserMenu = ( {row, reStartImport, setUndoModal, setUndoType} : UserMenuProps ) : React.ReactElement => {
  const history = useHistory();
  const undoEnabled = row.dataType.includes('Course') ? false : row.undoEnabled; //disable undo for now for all the course related imports
  const { isLegacyCleverEnabled } = React.useContext(IntegrationContext);

  const setModalProperties = (row: any) : void => {
    setUndoType(row.dataType);
    setUndoModal(row.importDataGuid);
  }
  
  return (
    <Menu>
      {row.canContinueImport && (row.navianceStatus === 'IMPORT_COMPLETED' || row.navianceStatus === 'IMPORT_COMPLETED_WITH_ALERTS') && (
        <Menu.Item
          key={`${row.importDataGuid}-continue_import`}
          data-test-id="menu_continue"
          onClick={() => reStartImport(row.importDataGuid, row.dataType)}
          disabled={isLegacyCleverEnabled}
          title={isLegacyCleverEnabled ? StatusMessages.LEGACY_CLEVER_ENABLED : ''}
        >
          Start Import
        </Menu.Item>
      )}
      <Menu.Item
        key={`${row.importDataGuid}-log`}
        data-test-id="menu_log"
        disabled={!row.done}
        onClick={() => history.push(`/view-log/${row.importDataGuid}`)}
      >
        View log
      </Menu.Item>
      {undoEnabled ? (
        <Menu.Item key={`${row.importDataGuid}-undo`} data-test-id="menu_undo" data-cy-id={`${row.importDataGuid}-menu_undo`} onClick={() => setModalProperties(row)}>
          Undo
        </Menu.Item>
      ) : (
        <></>
      )}
    </Menu>
  );
};

export default UserMenu;