import { Upload, Space, Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect } from 'react';
import { get } from 'lodash';
import Checkbox from '../../../components-v2/Checkbox';
import Col from '../../../components-v2/Col';
import Divider from '../../../components-v2/Divider';
import Radio from '../../../components-v2/Radio/index';
import Row from '../../../components-v2/Row';
import Select from '../../../components-v2/Select';
import AlertErrorIcon from '../../../../assets/Icon/AlertErrorIcon';

import { NotificationTypes, showNotification } from '../../../components/Notifications';
import { ImportTypeParam } from '../../../utils/constants';
import { readFileHeaders, uploadFile } from '../../../utils/utils';
interface Props {
  importType: ImportTypeParam;
  renderNextComponent: (componentToRender: string) => void;
  onCancel: () => void;
}

const importTypes = {
  [ImportTypeParam.CourseCatalog]: 'Course Catalog',
  [ImportTypeParam.CourseMapping]: 'Course Mapping',
  [ImportTypeParam.StudentCourseData]: 'Student Course Data',
  [ImportTypeParam.Scholarship]: 'Scholarship Data',
};
const MAX_FILE_SIZE_BYTES = 1e7; // 10MB

const TEMPLATE_URL = 'https://help.powerschool.com/t5/Naviance-Documents/QuickDoc-Scholarship-Record-Import-Template/ta-p/444520';

const FileUpload: React.FC<Props> = ({ importType, renderNextComponent, onCancel }) => {
  const [fileSelectedName, setFileSelectedName] = React.useState('no file chosen');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [fileHeader, setFileHeaders] = React.useState<string[]>(null);
  const [files, setFiles] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [containsHeading, setContainsHeading] = React.useState('yes');
  const [importSettings, setImportSettings] = React.useState({
    updateExistingRecords: false,
    addNewRecords: false,
  });
  const { Option } = Select;
  const selectedFile = async (file) => {
    if (file.file.size >= MAX_FILE_SIZE_BYTES) {
      setErrorMessage('File size should be less than 10MB.');
      return;
    }
    // Will need to re-investigate file name restrictions as we redo
    // additional file imports and allow for SFTP for all

    /* const fileName = (file.file as File).name;
    const expectedFileName = 'Scholarships';
    if (!fileName.startsWith(expectedFileName)) {
      const extension = fileName.includes('.') ? fileName.split('.').slice(-1)[0] : '.csv';
      setErrorMessage(`File must be named ${expectedFileName}.${extension}`);
      return;
    } */

    setErrorMessage('');

    setFileSelectedName(file.file.name);
    setFiles(file.file);
    try {
      setFileHeaders(await readFileHeaders(file.file));
    } catch (err) {
      setErrorMessage('Invalid file, could not find Scholarships Data');
    }
  };
  const continueClick = async () => {
    if (files === '') {
      showNotification(NotificationTypes.error, 'Upload File', 'Please upload file to continue import.');
      return;
    }
    sessionStorage.setItem('importSettings', JSON.stringify(importSettings));
    setLoading(true);
    const saveSettings = true;
    try {
      await uploadFile(files, importType, 'scholarship', fileHeader, containsHeading, saveSettings);
      showNotification(
        NotificationTypes.success,
        'File Uploaded Successfully',
        'File imported successfully for data import.',
      );
      renderNextComponent('MatchFields');
    } catch (error) {
      setLoading(false);
      let errorMessage = 'Failure in sending data the server.';
      if (get(error, 'response.status') === 401) {
        errorMessage = 'You are not authorized to make this change or perform this action on behalf of the client.';
      }
      showNotification(NotificationTypes.error, 'Error Importing File', errorMessage);
    }
  };
  const onChangeContainsHeading = (e) => {
    setContainsHeading(e.target.value);
  };

  const importRulesChangeHandler = (key: string, e) => {
    setImportSettings({
      ...importSettings,
      [key]: e.target.checked,
    });
  };
  useEffect(() => {
    if (importSettings.updateExistingRecords || importSettings.addNewRecords) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [importSettings]);
  return (
    <div className="new-data-import mainMatchFieldsSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleField" data-cy="data-import-heading" level={1}>
              Scholarships Data Import - Upload File
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              <button className="button" onClick={() => onCancel()}>
                Cancel
              </button>
              <button className="continue-btn" onClick={continueClick} disabled={loading}>
                Continue
              </button>
            </Space>
          </Col>
        </Row>
        <Divider className="divider-data" />
      </div>
      <div className="mainWhitebgWrapper">
        <Row className="mainPerformTimeWrapper">
          <Col span={17}>
            <div className="section">
              <Row>
                Import type<span style={{ color: 'red' }}>&nbsp;*</span>
              </Row>
              <Row>
                <Col span={10}>
                  <Select placeholder="Select Import Type" className="select" value={importType} disabled>
                    {Object.keys(importTypes).map((key) => {
                      return (
                        <Option key={key} value={key}>
                          {importTypes[key]}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            </div>
            <div className="section mainUploadFileSection">
              <Row>
                <Col span="20">
                  <p className="uploadPara">
                    Upload your file by clicking the "Add File" button and selecting the text file containing your data.{' '}
                  </p>
                </Col>
                <Col span="18">
                  <strong>
                    <a href={TEMPLATE_URL} target="_blank" rel="noreferrer">
                      Click Here
                    </a>{' '}
                    if you want more detailed instructions.
                  </strong>
                </Col>
              </Row>

              <Row className="uploadSection">
                <Col span="18">
                  <h2 className="uploadHeading">Upload File</h2>
                </Col>

                <Col span="18">
                  <span className="uploadcont">
                    File must be named Scholarships. Only file types .csv, .txt, and .zip accepted.
                  </span>
                </Col>

                <Col span="18">
                  <span className="uploadcont">10 MB Max file size</span>
                </Col>

                <Col span="18">
                  <span className="uploadcont">Average processing time is currently less than 1 minute</span>
                </Col>

                <Col span="18">
                  <div className="fileUploadbtn">
                    <Col style={{ display: 'inline-block' }} span="4">
                      <Upload showUploadList={false} accept=".csv, .txt, .zip" customRequest={selectedFile}>
                        <Button>Add File</Button>
                      </Upload>
                    </Col>
                    <Col style={{ display: 'inline-block' }} span="18">
                      <div>
                        <span style={errorMessage === '' ? { marginLeft: '7px' } : { display: 'none' }}>
                          {fileSelectedName}
                        </span>
                        <span
                          className="validation-error-message"
                          style={errorMessage !== '' ? {} : { display: 'none' }}
                        >
                          <AlertErrorIcon></AlertErrorIcon>
                          <span>{errorMessage}</span>
                        </span>
                      </div>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section">
              <Row justify="start">
                <Col span={18}>
                  <span>Does the first row of your data contain column headings?</span>
                </Col>
                <Col span={18}>
                  <div className="radioValue">
                    <Radio.Group onChange={onChangeContainsHeading} value={containsHeading}>
                      <Col span="auto">
                        <Radio className="radio-item vertical" value={'yes'}>
                          Yes
                        </Radio>
                        <Radio className="radio-item vertical" value={'no'}>
                          No
                        </Radio>
                      </Col>
                    </Radio.Group>
                  </div>
                </Col>
                <Col span={24}>
                  <Row>
                    <h2 className="infoHeading uploadHeading">Import Rules</h2>
                    <span style={{ color: 'red', paddingTop: '5px' }}>&nbsp;*</span>
                    <span style={{ paddingTop: '10px' }} className="uploadcont">
                      (select at least one of the below options)
                    </span>
                  </Row>
                  <Col span="24">
                    <Checkbox
                      className="checkbox"
                      onChange={(e) => {
                        importRulesChangeHandler('addNewRecords', e);
                      }}
                    >
                      Add new records if in your file and not in Naviance
                    </Checkbox>
                  </Col>
                  <Col span="24">
                    <Checkbox
                      className="checkbox"
                      onChange={(e) => {
                        importRulesChangeHandler('updateExistingRecords', e);
                      }}
                    >
                      Update existing records if in your file
                    </Checkbox>
                  </Col>
                </Col>
              </Row>
            </div>
            <div className="btnsSection">
              <Space>
                <button className="button" onClick={() => onCancel()}>
                  Cancel
                </button>
                <button className="continue-btn" onClick={continueClick} disabled={loading}>
                  Continue
                </button>
              </Space>
            </div>
          </Col>
          <Col span={7}>
            <div className="" style={{ textAlign: 'right' }}>
              <a href={TEMPLATE_URL} target="_blank" rel="noreferrer">
                <button className="button template-available-btn">Template Available</button>
              </a>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default FileUpload;
