import React, { useState } from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisSyncSettings from '.';
import { courseImportFrequencies } from './constants';

const SisCourseMappingSyncSetting = (props: WizardProps): React.ReactElement => {
  const defaultFrequency = 'ONE_TIME_IMPORT';
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [shouldInactivateRecords, setShouldInactivateRecords] = useState(false);

  return (
    <SisSyncSettings
      frequencies={courseImportFrequencies}
      defaultFrequency={defaultFrequency}
      shouldInactivateRecords={shouldInactivateRecords}
      overwriteNavData={overwriteNavData}
      flagNames={['shouldInactivateRecords', 'overwriteNavData']} //need to be in same order with flag setter
      setFlagCallees={[setShouldInactivateRecords, setOverwriteNavData]} //need to be in same order with flag setter
      type="course-mapping"
      showRadio={false}
      includeSchools={false}
      includeInactivateRule={false}
      includeOverwriteRule={false}
      includeOverwriteEmailRule={false}
      {...props}
    />
  );
};

export default SisCourseMappingSyncSetting;
