import React from 'react';
import Icon from '@ant-design/icons';

export const ChipUnselected = (): React.ReactElement => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.00065 0.333008C3.31398 0.333008 0.333984 3.31301 0.333984 6.99967C0.333984 10.6863 3.31398 13.6663 7.00065 13.6663C10.6873 13.6663 13.6673 10.6863 13.6673 6.99967C13.6673 3.31301 10.6873 0.333008 7.00065 0.333008Z" fill="#DFE2E2" />
  </svg>
);

export const ChipSelected = (): React.ReactElement => (
  <svg width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.33389 9.33369C4.05949 9.33369 3.79675 9.22043 3.60795 9.02123L0.608019 5.85456C0.228152 5.45383 0.245419 4.82103 0.646085 4.44116C1.04715 4.06029 1.67995 4.07889 2.05982 4.47923L4.33489 6.88056L9.94195 0.978359C10.3222 0.577626 10.9556 0.561692 11.356 0.942226C11.7564 1.32243 11.7727 1.95556 11.3925 2.35596L5.05889 9.02249C4.87009 9.22103 4.60795 9.33369 4.33389 9.33369Z" fill="#00427C" />
  </svg>

);

const ChipIcon = ({ checked }: { checked?: boolean }): React.ReactElement => {
  if (checked) {
    return (
      <Icon style={{ marginRight: 5 }} component={ChipSelected} />
    );
  }
  return (
    <Icon style={{ marginRight: 5 }} component={ChipUnselected} />
  );
};

export default ChipIcon;