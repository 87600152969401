import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import { Alert, Col, Row } from 'antd';
import {
  defaultNavAlumniMapping,
  defaultNavESPAlumniMapping,
  navianceAlumniFields,
  navianceESPAlumniFields,
} from '../../utils/fieldMapUtils';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import apiClient from '../../utils/apiClient';
import { NotificationTypes, showNotification } from '../../components/Notifications';

const SisAlumniFieldMatching = (props: WizardProps): React.ReactElement => {
  const [sisIntegration, setSisIntegration] = React.useState<null | 'psSis' | 'eSP'>(null);

  const getTenantMeta = async () => {
    try {
      const { data } = await apiClient.get(`data-ingest/sis/tenant-meta`);
      setSisIntegration(data.sisIntegration || 'psSis');
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Config', 'Server Error');
    }
  };

  React.useEffect(() => {
    void getTenantMeta();
  }, []);

  const Header = () => {
    return (
      <>
        <h3>Define the import by matching the Naviance field to corresponding SIS data field.</h3>
        <br />
        <MessageHeader />
        <br />
      </>
    );
  };

  const AlumniHeader = () => {
    return (
      <Title className="titleField" level={5}>
        Student Records (Alumni)
      </Title>
    );
  };

  const MessageHeader = () => {
    return (
      <Row justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
        <Col span={24}>
          <Alert
            style={{ border: '0px', borderLeft: '2px solid #6BD7DB', backgroundColor: '#DEF6F7' }}
            message={<Text>When importing alumni records</Text>}
            description={
              <ul style={{ paddingInlineStart: '25px' }}>
                <li>
                  Changes to field matching for Birthdate and First Name will be applied to the Student Records import.
                </li>
                <li>Data fields matched in Student Records import will also be included in the alumni import.</li>
              </ul>
            }
            type="success"
          />
        </Col>
      </Row>
    );
  };

  const sections = [
    {
      tableHeader: <AlumniHeader />,
      defaultFields: sisIntegration === 'eSP' ? navianceESPAlumniFields : navianceAlumniFields,
      defaultBlankValue: '(Do not import)',
    },
  ];

  return sisIntegration ? (
    <SisFieldMatching
      defaultMapping={sisIntegration === 'eSP' ? defaultNavESPAlumniMapping : defaultNavAlumniMapping}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/alumnitranslateConfig'}
      sections={sections}
      header={<Header />}
      {...props}
    />
  ) : (
    <></>
  );
};

export default SisAlumniFieldMatching;
