import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface RowAlertsProps {
  row : {
    importDataGuid: string;
    noOfAlerts: number;
    done: boolean;
  }
}

const RowAlerts = ({row}:RowAlertsProps) : React.ReactElement=> {
  const history = useHistory();
  return (
    <span data-test-id="alerts">
    {row.done ? (
      <Button type="link" onClick={() => history.push(`/view-log/${row.importDataGuid}`)}>
        {row.noOfAlerts === -1 ? '-' : row.noOfAlerts}
      </Button>
    ) : (
      <span style={{ padding: '4px 15px' }}>{row.noOfAlerts === -1 ? '-' : row.noOfAlerts}</span>
    )}
  </span>
  )
}

export default RowAlerts;