import { NotificationTypes, showNotification } from '../components/Notifications';
import apiClient from './apiClient';
import { PSError } from './constants';
import type { PluginDetails } from '../../../../../libs/common-interfaces';

export enum PluginStatus {
  UpdateAvailable = 'updateAvailable',
  NotSupported = 'notSupported',
}

const fetchPluginVersion = async (notify: boolean): Promise<PluginDetails> => {
  try {
    const { data } = await apiClient.get(`/data-ingest/sis/plugin-status`);
    if ((data as PluginDetails)?.type === PluginStatus.NotSupported) {
      notify && showNotification(NotificationTypes.error, 'Update SIS plugin', data.message, undefined, undefined, 15);
    }
    if ((data as PluginDetails)?.type === PluginStatus.UpdateAvailable) {
      notify &&
        showNotification(NotificationTypes.warning, 'Update SIS plugin', data.message, undefined, undefined, 15);
    }
    return data as PluginDetails;
  } catch (error) {
    if (error.message !== PSError) {
      showNotification(NotificationTypes.error, 'Error Getting plugin status', 'Failure in getting data from server.');
    }
  }
};

export default fetchPluginVersion;
