import React from 'react';
import { Select as AntSelect, SelectProps } from 'antd';
import { SelectValue } from 'antd/es/select';

const Select = (props: SelectProps<SelectValue>): React.ReactElement => {
  const { id, children, ...rest } = props;
  return (
    <AntSelect style={{ width: '100%' }} data-test-id={`select-${id}`} className="select select-responsive" {...rest}>
      {children}
    </AntSelect>
  );
};

export const Option = AntSelect.Option;

export default Select;
