import * as React from 'react';
import { featureFlags } from '../../utils/featureFlags';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import PencilIcon from '../../../assets/Icon/PencilIcon';

const EmailRecipients = ({
  notifications,
  editRecipients,
  sisIntegration,
}: {
  notifications: any;
  editRecipients: (toEdit: string[], type: string) => void;
  sisIntegration: string;
}): React.ReactElement => {
    const RecipientTableColumns: ColumnsType<{ dataType: string; recipients: string }> = [
    {
      title: 'Data Type',
      dataIndex: 'dataType',
      key: 'dataType',
      render: (name, _, index) => (
        <span title={name} data-test-id={`recipient_datatype_${index}`}>
          {name}
        </span>
      ),
    },
    {
      title: 'Recipient(s)',
      dataIndex: 'recipients',
      key: 'recipients',
      render: (recipients, _, index) => (
        <span title={recipients} data-test-id={`recipient_datatype_${index}`}>
          {recipients.join(', ')}
        </span>
      ),
    },
    {
      title: 'Actions',
      render: (row) => (
        <>
          <a
            className="edit-action"
            title={'edit'}
            data-test-id={`${row.type}_edit-action`}
            onClick={(e) => {
              editRecipients(row.recipients.length ? row.recipients : [], row.type);
            }}
          >
            <PencilIcon />
          </a>
        </>
      ),
    },
  ];

  return (
    <div>
      <h1>Email Recipient(s)</h1>
      <Table
        rowKey="dataType"
        columns={RecipientTableColumns}
        dataSource={notifications.filter(
          (notification) =>
            notification.supportedInt.includes(sisIntegration) &&
            (!notification.featureFlags ||
              featureFlags[notification.featureFlags?.[sisIntegration]] ||
              featureFlags[notification.featureFlags?.[sisIntegration]] === undefined),
        )}
        data-test-id="email_recipients_table"
        pagination={false}
      ></Table>
    </div>
  );
};

export default EmailRecipients;
