import React from 'react';
import { Space } from 'antd';
import { CheckSquareFilled } from '@ant-design/icons';
import { LabeledValue } from 'antd/es/select';
import { schoolIds } from './form';

interface SchoolOptionProps {
  option: LabeledValue;
  schools: schoolIds;
}

const SchoolOption = (props: SchoolOptionProps): React.ReactElement => {
  const { option, schools } = props;
  return (
    <Space>
      {schools?.indexOf(option.value.toString()) >= 0 ? (
        <CheckSquareFilled style={{ fontSize: '1rem' }} />
      ) : (
        <span className="empty-checkbox" />
      )}
      {option.label}
    </Space>
  );
};

export default SchoolOption;
