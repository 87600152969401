import { showNotification, NotificationTypes } from '../components/Notifications';
import apiClient from './apiClient';
import { getRolesMap } from './cleverStaffUtils';

export const getRoles = async (setRolesMap: (data: any) => void): Promise<void> => {
    let schoolRoles: any, districtRoles: any;
    try {
      [schoolRoles, districtRoles] = await Promise.all([
        apiClient.get(`/roles/userroles?userType=school`),
        apiClient.get(`/roles/userroles?userType=district`)
      ]);
  
      const rolesMap = getRolesMap(schoolRoles.data, districtRoles.data);
      setRolesMap(rolesMap);
    } catch (error) {
      console.error('Error:', error);
      showNotification(NotificationTypes.error, 'Error Getting Roles', 'Failure in getting data from server.');
    }
}

export const fetchToReconcile = async (
  setLoading: (value: boolean) => void,
  setToReconcile: (value: any) => void,
  setTotalRecords: (value: number) => void,
  jobGuid: string,
  page = 1,
  size = 10,
): Promise<void> => {
  try {
    setLoading(true);
    const { data } = await apiClient.get(`/data-ingest/sis/jobs/${jobGuid}/reconcile?page=${page}&size=${size}`);
    setToReconcile(data.records ?? []);
    setTotalRecords(data.total ?? 0);
    setLoading(false);
  } catch (error) {
    console.log(error);
    showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
  }
};
