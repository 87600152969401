import { Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useRef, useState } from 'react';
import CloseIconNoBg from '../../../../assets/Icon/CloseIconNoBg';
import PencilIcon from '../../../../assets/Icon/PencilIcon';
import Col from '../../../components-v2/Col';
import Input from '../../../components-v2/Input';
import Row from '../../../components-v2/Row';
import Select from '../../../components-v2/Select';
import apiClient from '../../../utils/apiClient';
import { IMPORT_NAMES_ENDPOINT, ADD_EMAIL_ENDPOINT, REMOVE_EMAIL_ENDPOINT } from '../../../utils/constants';

const EmailDrawerContent = (): React.ReactElement => {
  const { Option } = Select;
  const { TextArea } = Input;
  const [importNames, setImportNames] = useState([]);
  const [buttonText, setButtonText] = useState<'Add' | 'Edit'>('Add');
  const [loading, setLoading] = useState(true);
  const [emails, setEmails] = useState('');
  const [selectedImportNameId, setSelectedImportNameId] = useState<null | string>(null);
  const inputref = useRef(null);

  const ImportNamesTableColumns = [
    {
      title: 'Import Type',
      dataIndex: 'importType',
      key: 'importType',
    },
    {
      title: 'Recipient(s)',
      dataIndex: 'email',
      key: 'recipient',
      render: (email) => {
        const mail = email?.emailConfig?.to ?? '-';
        return <span title={mail}>{mail}</span>;
      },
    },
    {
      title: '',
      render: (row) => (
        <div className="drawerTableIcons">
          <a
            title={'edit'}
            onClick={(e) => {
              e.preventDefault();
              void editEmail(row.guid);
            }}
          >
            <PencilIcon />
          </a>
          <a
            title={'remove'}
            onClick={(e) => {
              e.preventDefault();
              void removeEmail(row.guid);
            }}
          >
            <CloseIconNoBg />
          </a>
        </div>
      ),
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(IMPORT_NAMES_ENDPOINT);
      setImportNames(data);
    } catch (error) {
      alert('');
    } finally {
      setLoading(false);
    }
  };

  const addEmail = async () => {
    setLoading(true);
    try {
      const { data } = await apiClient.put(ADD_EMAIL_ENDPOINT, {
        emails,
        guid: selectedImportNameId,
      });
      setEmails('');
      setSelectedImportNameId(null);
      setImportNames([]);
      setButtonText('Add');
      void fetchData();
    } catch (error) {
      setLoading(false);
    }
  };

  const removeEmail = async (guid) => {
    setLoading(true);
    try {
      const { data } = await apiClient.put(REMOVE_EMAIL_ENDPOINT, {
        guid,
      });
      setImportNames([]);
      void fetchData();
    } catch (error) {
      setLoading(false);
    }
  };

  const editEmail = async (guid) => {
    inputref.current.focus();
    setSelectedImportNameId(guid);
    setButtonText('Edit');
  };

  useEffect(() => {
    void fetchData();
  }, []);

  return (
    <div className="mainDrawer mainMatchFieldsSection">
      <Row>
        <Col span={22}>
          <Title className="drawerTitle" data-cy="data-import-heading" level={1}>
            Manage Import Email Notifications
          </Title>
        </Col>
        <Col span={24}>
          <p className="drawerDescription">You can control who receives notifications by data import name. </p>
        </Col>
        <Col span={24}>
          <p className="drawerLableText">Select Import Name</p>
          <Select
            onSelect={(e) => setSelectedImportNameId(e.toString())}
            placeholder="Select Import Name"
            className="select"
            value={selectedImportNameId}
            disabled={loading}
          >
            {(testType, key) => {
              return (
                <Option key={key} value={testType.value}>
                  {testType.text}
                </Option>
              );
            }}
            {importNames.map((each) => (
              <Option key={each.guid} value={each.guid}>
                {each.importName}
              </Option>
            ))}
          </Select>
        </Col>
        <Col className="drawerTopGutter" span={24}>
          <Row justify="space-between">
            <Col span={22}>
              <p className="drawerLableText">Add email recipient(s)</p>
              <TextArea
                ref={inputref}
                disabled={loading}
                value={emails}
                onChange={(e) => setEmails(e.target.value)}
                placeholder="Add email addresses separated by a comma"
                autoSize={{ minRows: 3, maxRows: 6 }}
              />
            </Col>
            <Col span="auto">
              <button
                className="button addBtn"
                disabled={emails === '' || selectedImportNameId === null || loading}
                onClick={addEmail}
              >
                {buttonText}
              </button>
            </Col>
          </Row>
        </Col>
        <Col className="drawerTopGutter" span={24}>
          <div className="mainDataImportTable">
            <Table rowKey="guid" loading={loading} columns={ImportNamesTableColumns} dataSource={importNames}></Table>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmailDrawerContent;
