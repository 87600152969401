import { frequency } from '../../../../../../libs/common-interfaces/src/lib/Sis';
import * as React from 'react';
import SisStartImport from '.';
import { WizardProps } from '../../components-v2/Wizard';

const SisCourseHistoryStartImport = (props: WizardProps): React.ReactElement => {
  return (
    <SisStartImport
      type="course-history"
      defaultFrequency={frequency.TWENTY_FOUR_HOURS}
      typeLabel="Student Course Data (Course History)"
      {...props}
    />
  );
};

export default SisCourseHistoryStartImport;
