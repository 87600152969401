import * as React from 'react';
import { Table } from 'antd';
export interface summaryBasedOnSchoolId {
  totalRecords: number;
  added: number;
  updated: number;
  no_changes: number;
}
export interface summaryBasedOnSchoolIdObj {
  dataSource: summaryBasedOnSchoolId[];
}
const SummaryPerDetails = (props: summaryBasedOnSchoolIdObj): React.ReactElement => {
  const columns = [
    {
      title: 'School Id',
      dataIndex: 'schoolId',
      key: 'schoolId',
    },
    {
      title: 'Total Records',
      dataIndex: 'totalCount',
      key: 'totalCount',
    },
    {
      title: 'Added',
      dataIndex: 'created',
      key: 'created',
    },
    {
      title: 'Updated',
      dataIndex: 'updated',
      key: 'updated',
    },
    {
      title: 'No Changes',
      dataIndex: 'no_changes',
      key: 'No Changes',
    },
    {
      title: 'Alert',
      dataIndex: 'alert',
      key: 'alert',
    },
  ];

  return (
    <div>
      <h3>Records Per School</h3>
      <Table dataSource={props.dataSource} columns={columns} pagination={false} />
    </div>
  );
};

export const importDetailsPerSChool = {
  summaryPerSchoolData: (job) => {
    console.log('job per school', job);

    const summaryPerSchool = job.importResults.summaryPerSchool ? job.importResults.summaryPerSchool : [];

    if (summaryPerSchool?.length === 0) {
      return [];
    }
    for (let i = 0; i < summaryPerSchool.length; i++) {
      summaryPerSchool[i].created = summaryPerSchool[i].created ?? 0;
      summaryPerSchool[i].updated = summaryPerSchool[i].updated ?? 0;
      summaryPerSchool[i].alert = summaryPerSchool[i].alert ?? 0;
      summaryPerSchool[i].no_changes =
        summaryPerSchool[i].totalCount - (summaryPerSchool[i].created + summaryPerSchool[i].updated+ summaryPerSchool[i].alert);
    }

    const schoolWiseDetails = summaryPerSchool.sort(function (a: any, b: any) {
      return a.schoolId - b.schoolId;
    });

    console.log('schoolWiseDetails', schoolWiseDetails);
    if (schoolWiseDetails[0].schoolId === '') schoolWiseDetails[0].schoolId = 'No Subject Area';
    return schoolWiseDetails;
  },
};

export default SummaryPerDetails;
