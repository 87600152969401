import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import { OutgoingHttpHeaders } from 'http';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { ImportTypeParam, CoursePlannerImportData, SignedURLData } from '../../utils/constants';
import { readFileHeaders } from '../../utils/utils';
import { API_URL } from '../../utils/constants';
import MatchField from './MatchField';
import UploadPage from './UploadPage';
import axios from 'axios';
import { featureFlags } from '../../utils/featureFlags';

type Props = {
  importType: ImportTypeParam;
};

type Pages = 'UploadPage' | 'MatchFields';

const COURSE_PLANNER_URL = '/data-import';
const SIGNED_URL_COURSE_PLANNER = `${COURSE_PLANNER_URL}/signedurl`;
const COURSE_PLANNER_JOB_STATUS_UPDATE_URL = `${COURSE_PLANNER_URL}/update-import-status`;

const CoursePlannerImport: FC<Props> = ({ importType }) => {
  const initialState = {
    importType,
    file: null,
    saveSettings: true,
    importSettings: { overrideData: false },
    fileHeaders: [],
    mapping: '{}',
    loading: false,
  };

  useEffect(() => {
    let prefix = API_URL ?? '';
    if (!prefix.endsWith('/')) {
      prefix += '/';
    }

    apiClient.get(`/user?product=7`).then((resp) => setUserData(resp.data));
  }, []);

  const [importData, setImportData] = useState<CoursePlannerImportData>(initialState);
  const [currentPage, setCurrentPage] = useState<Pages>('UploadPage');
  const [userData, setUserData] = useState<any>();

  const handleChange = async (key, value) => {
    if (key === 'file') {
      const fileHeaders = await readFileHeaders(value.file);
      setImportData({ ...importData, [key]: value.file, fileHeaders });
    } else {
      setImportData({ ...importData, [key]: value });
    }
  };

  const handleCancel = () => {
    window.location.href = `${window.location.origin}${!isSchool() ? '/district' : ''}/setupmain/dataimport/import.php`;
  };

  const handleUploadContinue = async () => {
    if (importData.file === null) {
      showNotification(NotificationTypes.error, 'Upload File', 'Please upload file to continue import.');
      return;
    }
    setCurrentPage('MatchFields');
  };

  const isSchool = (): boolean => {
    let school = false;
    try {
      if (userData.products['7'] !== 'DISTRICT') {
        school = true;
      }
    } catch (err) {
      // pass
    }
    return school;
  };

  const getSignedUrlAndUploadFile = async () => {
    const payload = {
      fileName: importData.file.name,
      importType: importData.importType,
      saveSettings: importData.saveSettings,
    };
    const ext = payload.fileName.split('.').pop();
    switch (importType) {
      case ImportTypeParam.CourseCatalog:
        payload.fileName = `CourseCatalog.${ext}`;
        break;
      case ImportTypeParam.CourseMapping:
        payload.fileName = `CourseMapping.${ext}`;
        break;
      case ImportTypeParam.StudentCourseData:
        payload.fileName = `StudentCourseData.${ext}`;
        break;
    }
    try {
      // get signed url
      const { data } = await apiClient.post<SignedURLData>(SIGNED_URL_COURSE_PLANNER, payload, {
        headers: {
          'content-type': 'application/json',
        },
      });
      let headers: OutgoingHttpHeaders | undefined;
      switch (ext) {
        case 'txt':
        case 'csv':
          headers = { 'Content-Type': 'text/csv' };
          break;
      }
      try {
        // upload file to s3
        await axios.put(data.url, importData.file, {
          headers: {
            ...(headers || {}),
            Accept: '*/*',
          },
        });
      } catch (e) {
        console.log(`Error while uploading the file to s3: ${e}`);
        // update the status of the job to failed.
        await apiClient.post<SignedURLData>(
          COURSE_PLANNER_JOB_STATUS_UPDATE_URL,
          { importId: data.importId },
          {
            headers: {
              'content-type': 'application/json',
            },
          },
        );
        throw new Error('Error Importing File');
      }
      return { bucketName: data.bucketName, keyName: data.keyName };
    } catch (error) {
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Importing File', error);
    }
  };

  const runJob = async (mapping) => {
    const formData = new FormData();
    const fileUploadFromBrowserAllowed = featureFlags['feature.coursePlanner.fileUploadFromBrowser'];
    let uploadFileData = { bucketName: '', keyName: '' };
    if (fileUploadFromBrowserAllowed) {
      setImportData({ ...importData, loading: true }); // start loading
      const { bucketName, keyName } = await getSignedUrlAndUploadFile();
      uploadFileData = { bucketName, keyName };
    } else {
      // send file to server only when fileUploadFromBrowserAllowed toggle is off
      formData.append('file', importData.file);
    }
    setImportData({ ...importData, loading: true });
    formData.append('importType', importData.importType);
    formData.append('bucketName', uploadFileData.bucketName);
    formData.append('keyName', uploadFileData.keyName);
    formData.append('saveSettings', JSON.stringify(importData.saveSettings));
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('importSettings', JSON.stringify(importData.importSettings));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    try {
      await apiClient.post(COURSE_PLANNER_URL, formData, config);
      showNotification(
        NotificationTypes.success,
        'File Uploaded Successfully',
        'File imported successfully for data import.',
      );
      window.location.href = `${window.location.origin}/${
        !isSchool() ? 'district/' : ''
      }setupmain/dataimport/show_history.php`;
    } catch (error) {
      let errorMessage = 'Failure in sending data the server.';
      if (get(error, 'response.status') === 401) {
        errorMessage = 'You are not authorized to make this change or perform this action on behalf of the client.';
      }
      showNotification(NotificationTypes.error, 'Error Importing File', errorMessage);
      setImportData({ ...importData, loading: false });
    }
  };

  return (
    <div>
      {currentPage === 'UploadPage' && (
        <UploadPage
          importData={importData}
          handleContinue={handleUploadContinue}
          handleChange={handleChange}
          handleCancel={handleCancel}
        />
      )}
      {currentPage === 'MatchFields' && (
        <MatchField importData={importData} runJob={runJob} handleCancel={handleCancel} handleChange={handleChange} />
      )}
    </div>
  );
};

export default CoursePlannerImport;
