import { Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import DisabledIcon from '../../../assets/Icon/DisabledIcon';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import PencilIcon from '../../../assets/Icon/PencilIcon';
import { featureFlags } from '../../utils/featureFlags';
import Col from '../../components-v2/Col';
import Row from '../../components-v2/Row';
import { showNotification, NotificationTypes } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import MainTemplate from '../Shell/MainTemplate';
import { IntegrationContext } from './../../utils/context';
import ConfigureButton from '../../components-v2/Button/ConfigureButton';
import { parsePSSisServerVersion } from './../../utils/utils';
import { lt as isVersionLessThan } from 'semver';


const PSDataImportConnectSettings = (): React.ReactElement => {
  const history = useHistory();
  const [sisImports, setSisImports] = React.useState([]);
  const [tableLoading, setTableLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(null);
  const [sisIntegration, setSisIntegration] = React.useState(null);
  const { isCleverIntegration, pluginDetails, sisServerDetails, sisError } = React.useContext(IntegrationContext);

  const onDisabledClick = (item) => {
    setShowModal(item);
  };

  const disableImport = async () => {
    try {
      await apiClient.patch(`/data-ingest/ingress/${showModal.type}/disable`, {
        isDisabled: true,
      });
      const studentImports = [...sisImports];
      const studentImportInd = studentImports.findIndex((imp) => showModal.type === imp.type);
      if (studentImportInd !== -1) {
        studentImports[studentImportInd].status = 'Disabled';
      }

      setSisImports(studentImports);
      setShowModal(null);

      showNotification(NotificationTypes.success, `Successfully Disabled ${showModal?.dataType}`, '');
      return;
    } catch (error) {
      showNotification(NotificationTypes.error, `Error Disabling ${showModal?.dataType}`, 'Server Error');
    }
  };

  const fetchIngress = async () => {
    try {
      const { data: ingressData } = await apiClient.get('/data-ingest/ingress');
      const { data: meta } = await apiClient.get(`/data-ingest/sis/tenant-meta`);
      const studentDependentIngress = ['alumni', 'parent-records'];
      //set connection state only when the page in sync with meta sisIntegration
      if (
        (!isCleverIntegration && meta.sisIntegration === 'clever') ||
        (isCleverIntegration && meta.sisIntegration !== 'clever')
      ) {
        setSisImports([]);
      } else {
        setSisIntegration(meta.sisIntegration ?? 'psSis');
        const imports = ingressData.map((ingress) => {
        
          const sourceEnum = {
            'PowerSchool SIS': 'psSis',
            'clever': 'clever',
            'eSchoolPlus SIS': 'eSP'
          };
          const lastJobStartDate = ingress?.lastJob?.start ? new Date(ingress.lastJob.start).toLocaleString() : '-';
          const displayDate = sourceEnum[ingress?.lastJob?.source] === meta.sisIntegration ? lastJobStartDate : null;
          return {
            ...ingress,
            actions: { edit: `/import-wizard/${ingress.type}` },
            lastEdit: ingress.lastEdited ? new Date(ingress.lastEdited).toLocaleString() : '-',
            lastImport: displayDate,
            status: ingress.isDisabled ? 'Disabled' : 'Enabled',
            editDisabled: studentDependentIngress.includes(ingress.type)
              ? !ingressData.find((ingress) => ingress.type === 'students').firstJobCompleted
              : false,
          };
        });
        setSisImports(imports);
      }
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        `Error Getting ${isCleverIntegration ? 'Clever' : 'SIS'} Import Settings`,
        'Failure in getting data from server.',
      );
    } finally {
      setTableLoading(false);
    }
  };

  const validateVersions = (integrationPath: string) => {
    let stopNavigating = false;
    // throw error if it does not satisfy the minumum need of SIS server and Plugin version for staff import only
    if ((!isCleverIntegration && integrationPath.includes('staff')) || integrationPath.includes('parent')) {
      try {
        if (!sisError) {
          if (isVersionLessThan(parsePSSisServerVersion((sisServerDetails as any).powerschool_version), '22.7.0')) {
            showNotification(
              NotificationTypes.error,
              'SIS version not supported',
              'Please upgrade SIS to the latest version',
            );
            stopNavigating = true;
          } else if (isVersionLessThan((pluginDetails as any)?.version, '1.2.4')) {
            showNotification(
              NotificationTypes.error,
              'Naviance plugin not supported',
              'Please upgrade the Naviance plugin to the latest version',
            );
            stopNavigating = true;
          }
        } else {
          showNotification(NotificationTypes.error, 'Error Getting Server Status', 'SIS Server Error');
          stopNavigating = true;
        }
      } catch (err) {
        showNotification(NotificationTypes.error, 'Error Getting Server Status', 'SIS Server Error');
        stopNavigating = true;
      }
    }

    if (!stopNavigating) {
      history.push(integrationPath);
    }
  };

  React.useEffect(() => {
    void fetchIngress();
  }, []);

  const DataImportTableColumns = [
    {
      title: 'Data type',
      dataIndex: 'dataType',
      key: 'dataType',
      render: (dataType) => <span title={dataType}>{dataType}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => <span title={status}>{status}</span>,
    },
    {
      title: 'Last import',
      dataIndex: 'lastImport',
      key: 'lastImport',
      render: (lastImport) => <span title={lastImport}>{lastImport}</span>,
    },
    {
      title: 'Last edited',
      dataIndex: 'lastEdit',
      key: 'lastEdit',
      render: (lastEdit) => <span title={lastEdit}>{lastEdit}</span>,
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (actions, data) => (
        <>
          {actions.edit && (
            <PencilIcon
              inactive={data.editDisabled}
              data-test-id={`${data.type}-edit-action`}
              onClick={() => validateVersions(actions.edit)}
            />
          )}
          <DisabledIcon
            inactive={data.status === 'Disabled'}
            data-test-id={`${data.type}-disable-action`}
            onClick={() => onDisabledClick(data)}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <MainTemplate
        title={`${isCleverIntegration ? 'Clever data integration' : 'SIS Import'} settings`}
        titleTestId="data_import_title"
        subheaderRSContent={<ConfigureButton />}
      >
        <Row>
          <Col span={24}>
            <Row>
              <Col span={24}>
                <span className="labelName">
                  Update {isCleverIntegration ? 'integration' : 'import'} settings for connection between Naviance and{' '}
                  {isCleverIntegration ? 'Clever' : 'PowerSchool Products'}.
                </span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <Table
                  rowKey="dataType"
                  columns={DataImportTableColumns}
                  dataSource={sisImports.filter(
                    (sisImport) =>
                      sisImport.supportedInt.includes(sisIntegration) &&
                      (!sisImport.featureFlags ||
                        featureFlags[sisImport.featureFlags?.[sisIntegration]] ||
                        featureFlags[sisImport.featureFlags?.[sisIntegration]] === undefined),
                  )}
                  loading={tableLoading}
                  locale={{
                    emptyText: (
                      <div style={{ margin: '20px' }}>
                        <h1>Data Not Connected</h1>
                        <p>You have not integrated your {isCleverIntegration ? 'Clever' : 'SIS'}.</p>
                        <p>
                          Click <b>Configure</b> to complete your {isCleverIntegration ? 'Clever' : 'SIS'} Integration
                          Setup.
                        </p>
                        <button
                          data-test-id="no_data_configure_btn"
                          className="button button-blue"
                          onClick={() => history.push('/configure')}
                        >
                          Configure
                        </button>
                      </div>
                    ),
                  }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </MainTemplate>
      <Modal
        visible={!!showModal}
        onOk={() => disableImport()}
        onCancel={() => setShowModal(null)}
        okText="Disable Import"
      >
        <Title level={5}>Are you sure you want to disable this import?</Title>
        <ul>
          <li>
            {showModal?.dataType} will no longer be imported from the {isCleverIntegration ? 'Clever' : 'SIS'}
          </li>
          <li>You can resume imports by editing this import and selecting "Start Import" again</li>
        </ul>
      </Modal>
    </>
  );
};

export default PSDataImportConnectSettings;
