import React from 'react';
import { render as DOMRender } from 'react-dom';
import App from './app/app';

const render = (Component: React.ReactElement) => {
  DOMRender(Component, document.getElementById('root'));
};

document.addEventListener('DOMContentLoaded', () => {
  render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  );
});
