import { Table } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import Col from '../../../components-v2/Col';
import Radio from '../../../components-v2/Radio/index';
import Row from '../../../components-v2/Row';
export const DataSources = (props): React.ReactElement => {
  return props.fieldsPossibleValues.map((fpv, index) => {
    return (
      <Row key={index}>
        <Col span={24} key={index}>
          <Title className="titleMatchField" data-cy="data-import-heading" level={3}>
            {fpv.field}
          </Title>
          <Table
            dataSource={fpv.possibleValues}
            pagination={false}
            columns={props.columns}
            loading={props.loading}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => props.setEditingId(record.key),
                onMouseEnter: (event) => props.setEditingTable(fpv.field),
                onMouseLeave: (event) => props.setEditingId(null),
                onFocus: (event) => props.setEditingId(record.key),
              };
            }}
          >
            <Table.Column title="code" key="code" render={(text, record) => <span>text</span>} />
          </Table>
        </Col>
      </Row>
    );
  });
};
export const DateDataSources = (props): React.ReactElement => {
  const [selectedDobFormat, setDobFormat] = React.useState({});
  const dateColumns = [
    {
      key: 'dateOfBirth',
      title: 'My Date Of Birth has the following format for date of birth',
      dataIndex: 'dateOfBirth',
      render: (text: string, record) => (
        <Radio
          onChange={onChangeDobFormat}
          className="radio-item vertical"
          name={record.name}
          value={record.date}
          checked={selectedDobFormat[record.name] ? true : false}
        >
          {record.date}
        </Radio>
      ),
    },
  ];
  const DateDataSource = [
    {
      key: 1,
      date: 'MM/DD/YYYY(10/15/2020)',
      name: 'MM/DD/YYYY(10/15/2020)',
    },
    {
      key: 2,
      date: 'MM-DD-YYYY(10-15-2020',
      name: 'MM-DD-YYYY(10-15-2020',
    },
    {
      key: 3,
      date: 'DD/MM/YYYY(15/10/2020',
      name: 'DD/MM/YYYY(15/10/2020',
    },
    {
      key: 4,
      date: 'YYYYMMDD(20201015',
      name: 'YYYYMMDD(20201015',
    },
  ];
  const onChangeDobFormat = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const selectedValue = {};
    selectedValue[name] = value;
    setDobFormat(selectedValue);
  };
  return (
    <Col span="auto" className="codes-table">
      <Title className="titleMatchField" data-cy="data-import-heading" level={3}>
        {props.fieldName}
      </Title>
      <Table
        dataSource={DateDataSource}
        pagination={false}
        columns={dateColumns}
        loading={props.loading}
        onRow={(record, rowIndex) => {
          return {};
        }}
        className="codes-table"
      >
        <Table.Column title="code" key="code" render={(text, record) => <span>text</span>} />
      </Table>
    </Col>
  );
};
