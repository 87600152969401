import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import {
  courseCatalogAdditionalFields,
  courseCatalogRecommendedFields,
  courseCatalogRequiredFields,
  defaultNavCourseCatalogueMapping,
} from '../../utils/fieldMapUtils';

const SisCourseCatalogFieldMatching = (props: WizardProps): React.ReactElement => {
  const Header = () => {
    return (
      <>
        <h3>Define the import by matching the Naviance field to the corresponding SIS data field.</h3>
        <h3>For fields that are not syncing, you can manually import data using the Naviance SchoolSync</h3>
      </>
    );
  };

  const sections = [
    {
      tableHeader: <h2>Required Fields</h2>,
      defaultFields: courseCatalogRequiredFields,
      defaultBlankValue: '(Do not import)',
    },
    {
      tableHeader: <h2>Recommended Fields</h2>,
      defaultFields: courseCatalogRecommendedFields,
      defaultBlankValue: '(Do not import)',
    },
    {
      tableHeader: <h2>Additional Fields</h2>,
      defaultFields: courseCatalogAdditionalFields,
      defaultBlankValue: '(Do not import)',
    },
  ];

  return (
    <SisFieldMatching
      defaultMapping={defaultNavCourseCatalogueMapping}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/course-catalog'}
      extensionsEndpoint={'data-ingest/sis/course-extension-tables'}
      header={<Header />}
      sections={sections}
      {...props}
    />
  );
};

export default SisCourseCatalogFieldMatching;
