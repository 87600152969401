import Space from 'antd/lib/space';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import Col from '../Col';
import Row from '../Row';
import Select from '../Select';
import Banner from '../../components/Drawer/banner';
import Input from '../Input';
import { ScedOptionType, scedSubjectAreaScedCode } from './subjectArea';
import { csscMapping } from '../../../../../../libs/common-interfaces/constants';

export interface CustomFieldDrawerProps {
  onSave: (subjectAreaName: string, scedCode: number) => void;
  onChange?: (subjectAreaName: string, scedCode: number) => void;
  editSubjectArea?: boolean;
  scedCode?: string;
  csscCode?: string;
  navianceCode?: string;
}

const CustomSubjectAreaDrawer = (props: CustomFieldDrawerProps): React.ReactElement => {
  const { Option } = Select;

  const [message, setMessage] = React.useState<string | null>(null);
  const [isErrorMsg, setIsErrorMsg] = React.useState(false);

  const [scedOptions, setScedOptions] = React.useState<ScedOptionType[]>([]);
  const [subjectAreaName, setSubjectAreaName] = React.useState<string>();
  const [scedCode, setScedCode] = React.useState<number>();

  const canSave = () => {
    if (props.editSubjectArea) {
      return !subjectAreaName && !scedCode;
    }
    return !subjectAreaName || !scedCode;
  };

  const onSave = async () => {
    props.onSave(subjectAreaName, scedCode);
  };

  const loadPage = async () => {
    setScedOptions(scedSubjectAreaScedCode);
  };

  React.useEffect(() => {
    void loadPage();
  }, []);

  const onKeyPress = (e: React.KeyboardEvent) => {
    const allowedCharacters = new RegExp("[a-zA-Z0-9@.' ,\\|\\(\\)\\-\\\\/]");
    const pressedKey = e.key;
    if (!allowedCharacters.test(pressedKey)) {
      e.preventDefault();
      return false;
    }
  };

  return (
    <div className="mainDrawer">
      {message && <Banner message={message} handleClose={() => setMessage(null)} isErrorMsg={isErrorMsg} />}
      <Row>
        <Col span={24}>
          {!props.editSubjectArea && (
            <Title className="drawerTitle" level={1} data-test-id="drawer_title">
              Add Subject Area
            </Title>
          )}

          {props.editSubjectArea && (
            <Title className="drawerTitle" level={1} data-test-id="drawer_title">
              Edit Subject Area:
            </Title>
          )}
        </Col>

        <Col span={24}>
          {props.editSubjectArea && (
            <Title className="drawerTitle" level={1} data-test-id="drawer_title" style={{ marginTop: '-4%' }}>
              {props.navianceCode}
            </Title>
          )}
        </Col>
      </Row>

      <Space direction="vertical" size="large" style={{ width: '100%' }}>
        <Row align="middle" gutter={[24, 16]}>
          <Col className="gutter-row" span={24}>
            {!props.editSubjectArea && (
              <p className="drawerLableText " data-test-id="table_selection_label">
                Name
              </p>
            )}

            {props.editSubjectArea && (
              <p className="drawerLableText " data-test-id="table_selection_label">
                Naviance code
              </p>
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            <Input
              size="middle"
              allowClear={false}
              placeholder="Enter a name"
              value={subjectAreaName}
              style={{ maxWidth: '80%' }}
              onChange={(evt) => {
                setSubjectAreaName(evt.target.value);
              }}
              onBlur={(change) => {
                /**/
              }}
              onKeyPress={onKeyPress}
              defaultValue={props.navianceCode}
            />
          </Col>
        </Row>
        <Row align="middle" gutter={[24, 16]}>
          <Col className="gutter-row" span={24}>
            <p className="drawerLableText " data-test-id="fields_selection_label">
              SCED Subject Code
            </p>
          </Col>
          <Col className="gutter-row" span={24}>
            <Select
              className="select"
              placeholder="Select a code"
              value={scedCode}
              onChange={(value) => setScedCode(value)}
              data-test-id="field_select"
              style={{ width: '80%' }}
              disabled={false}
              defaultValue={props.editSubjectArea && parseInt(props.scedCode)}
              showSearch
              filterOption={(input, option) => {
                // console.log(`${JSON.stringify(option)}`);
                return option.children.toLowerCase().indexOf(input.toLowerCase()) !== -1;
              }}
            >
              {scedOptions.map((oneOption: ScedOptionType) => (
                <Option
                  className="selectOptions"
                  key={`field-${oneOption.code}`}
                  value={oneOption.code}
                  data-test-id={`${oneOption.code}_option`}
                >{`${oneOption.code} - ${oneOption.description}`}</Option>
              ))}
            </Select>
          </Col>
        </Row>

        {props.editSubjectArea && parseInt(props.csscCode) !== 0 && props.csscCode !== undefined && (
          <Row align="middle" gutter={[24, 16]}>
            <Col className="gutter-row" span={24}>
              <p className="drawerLableText " data-test-id="table_cssc_label">
                CSSC Course code
              </p>
            </Col>
            <Col className="gutter-row" span={24} style={{ marginTop: '-2%' }}>
              <p className="drawerLableText boldDrawerLabelText" data-test-id="table_cssc_code_text">
                {`${props.csscCode} ${csscMapping[props.csscCode]}`}
              </p>
            </Col>
          </Row>
        )}
      </Space>
      <Row>
        <Col className="drawerTopGutter" span={24}>
          <Space align="center">
            <button className="buttonSolid" onClick={() => onSave()} data-test-id="save_btn" disabled={canSave()}>
              Save
            </button>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default CustomSubjectAreaDrawer;
