import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Table, Select, Tag } from 'antd';

import { translateHeaders } from '../../containers/SisDefineCodes/SisCourseCatalogDefineCodes';

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

const CteCustomMapping = ({ triggerSetState, valueMappings, defineCodesSettings }): React.ReactElement => {
  const isMountedRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const changeCTECustomMapping = (key: string, val: string[]) => {
    defineCodesSettings.cteCustom = val;
    valueMappings[translateHeaders.CTE.saved] = [];
    val.map((ele) => {
      valueMappings[translateHeaders.CTE.saved].push({ fromValue: ele, toValue: ele });
    });
    triggerSetState();
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);

    if (isMountedRef.current) setLoading(false);
  };

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const cteCustomColumn = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (data) => <>CTE</>,
    },
    {
      title: 'Enter SIS Codes',
      width: '60%',
      render: (data) => {
        return (
          <Select
            mode="tags"
            size="large"
            tagRender={tagRender}
            defaultValue={defineCodesSettings.cteCustom ?? []}
            style={{ width: '100%' }}
            options={[]}
            tokenSeparators={[',']}
            dropdownStyle={{ display: 'none' }}
            placeholder="Add custom codes"
            onChange={(change) => {
              changeCTECustomMapping(data.rowId, change);
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="subSection mainMatchFieldsSection">
      <h2 className="infoHeading uploadHeading">CTE</h2>
      <Row className="mainSelectRow">
        <Col span={18}>
          <span>If matching multiple SIS codes to Naviance codes, then separate with commas</span>
        </Col>
        <Col span={16}>
          <Table
            rowKey="code"
            columns={cteCustomColumn}
            dataSource={[{ rowId: 1, description: 'CTE' }]}
            pagination={false}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default CteCustomMapping;
