import * as React from 'react';
import SisStaffRecordsMatching from '../SisFieldMatching/SisStaffRecordsMatching';
import SisStaffRecordsDefineCodes from '../SisDefineCodes/SisStaffRecordsDefineCodes';
import SisStaffSyncSettings from '../SisSyncSettings/SisStaffSyncSettings';
import SisImport from '.';
import SisStaffStartImport from '../SisStartImport/SisStaffStartImport';

export const StaffRecordsSteps = [
  { title: 'Match fields', content: SisStaffRecordsMatching, path: '/import-wizard/staff/match-fields' },
  { title: 'Define codes', content: SisStaffRecordsDefineCodes, path: '/import-wizard/staff/define-code' },
  { title: 'Sync settings', content: SisStaffSyncSettings, path: '/import-wizard/staff/sync-settings' },
  { title: 'Test or start import', content: SisStaffStartImport, path: '/import-wizard/staff/test-start-import' },
];

const SisStaffRecords = (): React.ReactElement => {
  return <SisImport steps={StaffRecordsSteps} />;
};

export default SisStaffRecords;
