import { Col, Row } from 'antd';
import * as React from 'react';
import { getJobStatusMsg } from '../../utils/jobStatus';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { API_URL } from '../../utils/constants';
import { SchoolDetailsData } from './SchoolDetails/SchoolDetails';
import { featureFlags } from '../../utils/featureFlags';

const staffImportData = {
  staffDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
    history,
  ) => {
    const dataToUse = job.isTestImport
      ? job?.importResults?.readLegacyMetrics.summary
      : job?.importResults?.legacyImportMetrics.summary;
    const details = [
      {
        name: 'Records Added',
        key: 'Records Added',
        value: dataToUse.create || 0,
      },
      {
        name: 'Records Updated',
        key: 'Records Updated',
        value: dataToUse.update || 0,
      },
      {
        name: 'Records Made Inactive',
        key: 'Records Made Inactive',
        value: dataToUse.deactivated || 0,
      },
      {
        name: 'Records with No Changes',
        key: 'Records with No Changes',
        value: dataToUse.noChanges || 0,
      },
    ];

    const unMappedCodes = {
      name: 'Unmapped Codes',
      key: 'Unmapped Codes',
      value: (
        <>
          <Row>
            <Col span={2}>
              <AlertYellowIcon />
            </Col>
            <Col span={22}>
              <span>
                {(job?.unMappedCodes?.counselors.length || 0) + (job?.unMappedCodes?.diplomaTypes.length || 0)}
              </span>
            </Col>
          </Row>
          {job?.unMappedCodes?.diplomaTypes?.length > 0 ? (
            <Row>
              <Col span={2}></Col>
              <Col span={22}>
                <span style={{ fontWeight: 'bold' }}>
                  {job?.unMappedCodes?.diplomaTypes.length || 0} Unmapped Codes:
                </span>{' '}
                DiplomaType
                <ul className="custom-bullet">
                  {job?.unMappedCodes?.diplomaTypes.map((diplomaType) => {
                    return (
                      <li key={diplomaType}>
                        <span>{diplomaType}</span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {job?.unMappedCodes?.counselors?.length > 0 ? (
            <Row>
              <Col span={2}></Col>
              <Col span={22}>
                <span style={{ fontWeight: 'bold' }}>{job?.unMappedCodes?.counselors.length || 0} Unmapped Codes:</span>{' '}
                Counselors
                <ul className="custom-bullet">
                  {job?.unMappedCodes?.counselors.map((counselor) => {
                    return (
                      <li key={counselor}>
                        <span>{counselor}</span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </>
      ),
    };

    const alerts = {
      name: 'Alerts',
      key: 'Alerts',
      value: (
        <Row>
          {hasIssues && (
            <Col span={1}>
              <AlertYellowIcon />
            </Col>
          )}
          <Col span={22}>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>{(job?.recordsSkipped || 0) + (job?.recordsNotSkipped || 0)}</span>
              </Col>
            </Row>
            {Number(job?.toReconcile) > 0 && (
              <Row>
                <Col>
                  <span style={{ fontWeight: 'bold' }}>{job?.toReconcile} To Reconcile</span>
                </Col>
              </Row>
            )}
            {Number(job?.validation?.invalidCount) > 0 && (
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.invalidCount} Errors:`}</span> Records
                  Skipped
                  <ul className="custom-bullet">
                    {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${errorRowsPerCategory[category]} ${category}${
                              errorColumnsPerCategory[category]?.length ? ': ' : ''
                            }`}
                          </span>
                          {errorColumnsPerCategory[category]?.join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            )}
            {Number(job?.recordsNotSkipped) > 0 && (
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${job?.recordsNotSkipped} Warnings:`}</span> Records Not
                  Skipped
                  <ul className="custom-bullet">
                    {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${warningRowsPerCategory[category]} ${category}${
                              warningColumnsPerCategory[category]?.length ? ': ' : ''
                            }`}
                          </span>
                          {warningColumnsPerCategory[category]?.join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                </Col>
              </Row>
            )}
            <Row>
              <a href={`${API_URL}/data-ingest/sis/jobs/${job?.id}/download/logs`} data-test-id={`match-export-link`}>
                Export Log for additional details
              </a>
            </Row>
          </Col>
        </Row>
      ),
    };

    const staffUpdateLog = {
      name: 'Last Potential Update Resolved',
      key: 'PotentialUpdatesResolved',
      value: (
        <Row>
          <Col span={22}>
            <Row>
              <Col>
                <a className="backbtn" onClick={() => history.push('/staffUpdates')}>
                  View Staff Updates Log
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    };

    if (job?.unMappedCodes) {
      details.push(unMappedCodes);
    }
    details.push(alerts);
    if (featureFlags['feature.dataIngest.staffUpdatesLog']) {
      details.push(staffUpdateLog);
    }
    return details;
  },
  staffSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: job.sis_source === 'clever' ? 'Clever' : job.sis_source || 'PowerSchool SIS',
      },
      {
        name: 'Number of Records',
        key: 'Number of Records',
        value: job?.validation?.totalDataCount ? job?.validation?.totalDataCount + job?.notTransfered : 0,
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },
  schoolDetailsData: (job, context) => {
    const readLegacyMetrics = job.importResults.readLegacyMetrics || {};
    const legacyImportMetrics = job.importResults.legacyImportMetrics || {};

    let dataToProcess;

    if (job.isTestImport) {
      dataToProcess = readLegacyMetrics;
    } else {
      dataToProcess = legacyImportMetrics;
    }

    const result = [];
    Object.keys(dataToProcess).forEach((school: string, index: number) => {
      if (school !== 'summary') {
        const schoolData: SchoolDetailsData = dataToProcess[school];
        schoolData['updateTransfer'] = `${schoolData.update}(${schoolData.transfer})`;
        if (schoolData.schoolId === context.districtId) {
          schoolData.schoolName = schoolData.schoolName + '(District)';
          result.unshift(schoolData);
        } else {
          result.push(schoolData);
        }
      }
    });
    return result;
  },
  undoData: (job) => {
    return [
      {
        name: 'Staff Undone',
        key: 'Staff Undone',
        value: job?.undo.undone,
      },
      {
        name: 'Staff Skipped',
        key: 'Staff Skipped',
        value: job?.undo.skipped,
      },
      {
        name: 'Staff Reactivated',
        key: 'Staff Reactivated',
        value: job?.undo.reactivated,
      },
    ];
  },

  staffUpdatedResolvedData: (job, history, context) => {
    return [
      {
        name: 'Last Completed by',
        key: 'Last Completed by',
        value: job?.updatesResolved?.author,
      },
      {
        name: 'Last Completed on',
        key: 'Last Completed on',
        value: job?.updatesResolved?.end ? new Date(job?.updatesResolved?.end).toLocaleString() : '',
      },
      {
        name: 'Potential Updates Resolved',
        key: 'Potential Updates Resolved',
        value: (
          <Row>
            <Col span={22}>
              <Row>
                <Col>{job?.updatesResolved.recordsMatched + job?.updatesResolved.newRecordsAdded || 0}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.recordsMatched || 0
                  } records matched`}</span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.newRecordsAdded || 0
                  } records added`}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
      {
        name: 'Staff Updates Log',
        key: 'Staff Updates Log',
        value: (
          <a className="backbtn" onClick={() => history.push('/staffupdates')}>
            View staff Updates Log
          </a>
        ),
      },
    ];
  },
};

export default staffImportData;
