import React, { useState } from 'react';

import { WizardProps } from '../../components-v2/Wizard';
import SisSyncSettings from '.';
import { IntegrationContext } from '../../utils/context';
import { FrequencyOptions } from '../../components-v2/PSDataImportStudentRecords/dataUpdateFrequency';

const SisStudentSyncSettings = (props: WizardProps): React.ReactElement => {
  const { isCleverIntegration } = React.useContext(IntegrationContext);

  const frequencies: FrequencyOptions = [
    {
      value: 'FOUR_HOURS',
      label: '4 hours',
    },
    {
      value: 'TWELVE_HOURS',
      label: '12 hours',
    },
    {
      value: 'TWENTY_FOUR_HOURS',
      label: '24 hours',
    },
  ];

  const defaultFrequency = 'TWENTY_FOUR_HOURS';
  const [shouldInactivateRecords, setShouldInactivateRecords] = useState(false);
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [doNotOverwriteEmail, setDoNotOverwriteEmail] = useState(false);
  const [doNotMarkCleverStudentInactive, setdoNotMarkCleverStudentInactive] = useState(false);

  return (
    <SisSyncSettings
      frequencies={frequencies}
      defaultFrequency={defaultFrequency}
      shouldInactivateRecords={shouldInactivateRecords}
      overwriteNavData={overwriteNavData}
      doNotOverwriteEmail={doNotOverwriteEmail}
      doNotMarkCleverStudentInactive={doNotMarkCleverStudentInactive}
      flagNames={[
        'shouldInactivateRecords',
        'overwriteNavData',
        'doNotOverwriteEmail',
        'doNotMarkCleverStudentInactive',
      ]} //need to be in same order with flag setter
      setFlagCallees={[
        setShouldInactivateRecords,
        setOverwriteNavData,
        setDoNotOverwriteEmail,
        setdoNotMarkCleverStudentInactive,
      ]} //need to be in same order with flag setter
      showRadio={true}
      type="students"
      includeInactivateRule={true}
      includeOverwriteRule={true}
      includeOverwriteEmailRule={true}
      includeSchools={true}
      fetchAvailableSchoolMappings={true}
      includeMarkCleverStudentInactiveRule={isCleverIntegration}
      {...props}
    />
  );
};

export default SisStudentSyncSettings;
