import * as React from 'react';
import { Row, Col, Table, Select, Tag } from 'antd';
import { showNotification, NotificationTypes } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { CloseOutlined } from '@ant-design/icons';

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

const SisGroupMatching = ({ groupMapping, setGroupMapping }): React.ReactElement => {
  const [loading, setLoading] = React.useState(true);
  const [groupNameFields, setGroupNameFields] = React.useState([]);

  const changeGroupSisCodeMapping = (key: string, val: (string | number)[]) => {
    const updatedMapping = { ...groupMapping };
    if (val) {
      updatedMapping[key] = val;
    } else {
      delete updatedMapping[key];
    }
    setGroupMapping(updatedMapping);
  };

  const getDistrictGroupName = async () => {
    try {
      const { data } = await apiClient.get(`/school-student-groups`);
      const groupFieldData = data.map((group) => ({
        name: group.name,
        id: group.id as string,
      }));
      const updatedMapping = { ...groupMapping };
      groupFieldData.forEach((element) => {
        if (!(element.id in updatedMapping)) {
          changeGroupSisCodeMapping(element.id, []);
        }
      });
      setLoading(false);
      setGroupNameFields(groupFieldData);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Config', 'Server Error');
    }
  };

  React.useEffect(() => {
    void getDistrictGroupName();
  }, []);

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  const studentGroupTableColumns = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (groupNameFields) => <>{groupNameFields.name}</>,
    },
    {
      title: 'SIS Codes',
      width: '60%',
      render: (data) => {
        return (
          <Select
            mode="tags"
            size="large"
            tagRender={tagRender}
            defaultValue={groupMapping[data.id]}
            style={{ width: '100%' }}
            options={[]}
            tokenSeparators={[',']}
            dropdownStyle={{ display: 'none' }}
            placeholder="Add custom codes"
            onChange={(change) => {
              changeGroupSisCodeMapping(data.id as string, change);
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Row className="mainSelectRow">
        <Col span={18}>
          <h2 className="infoHeading uploadHeading" data-test-id='defineCodesGroupHeader'>Group</h2>
        </Col>
        <Col span={16}>
          <Table
            rowKey="id"
            columns={studentGroupTableColumns}
            dataSource={groupNameFields}
            pagination={false}
            loading={loading}
          />
        </Col>
      </Row>
    </div>
  );
};

export default SisGroupMatching;
