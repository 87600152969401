import { Col, Row } from 'antd';
import * as React from 'react';
import { SisCourseRequestJobSummary } from '../../../../../../libs/common-interfaces';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { getJobStatusMsg } from '../../utils/jobStatus';

const courseRequestExportData = {
  courseRequestDetailsData: (job: SisCourseRequestJobSummary) => {
    return [
      {
        name: 'Course Requests Created',
        key: 'Course Requests Created',
        value: job?.importResults?.created ?? 0,
      },
      {
        name: 'Course Requests Errored',
        key: 'Course Requests Errored',
        value: job?.noOfAlerts ?? 0,
      },
      {
        name: 'Alerts',
        key: 'Alerts',
        value: (
          <Row>
            {job?.importResults?.alerts?.length > 0 && (
              <Col span={2}>
                <AlertYellowIcon />
              </Col>
            )}
            {job?.importResults?.alerts?.length === 0 && (
              <React.Fragment>
                <Col span={22}>
                  <span style={{ fontWeight: 'bold' }}>{`0 Errors:`}</span> Records Skipped
                </Col>
                <Col span={22}>
                  <span style={{ fontWeight: 'bold' }}>{`0 Warnings:`}</span> Records Not Skipped
                </Col>
              </React.Fragment>
            )}
            <Col span={22}>
              {job?.importResults?.alerts?.length > 0 && (
                <React.Fragment>
                  <span style={{ fontWeight: 'bold' }}>Errors:</span>
                  {job?.importResults?.alerts?.map((result: any) => {
                    return (
                      <Col>
                        <span>{`${result.count}  ${result.error}`}</span>
                      </Col>
                    );
                  })}
                </React.Fragment>
              )}
            </Col>
            <Col span={22}>
              {job?.['crNotEnabledError'] && (
                <Col span={22}>
                  <span style={{ fontWeight: 'bold' }}>Errors:</span>
                  {` ${job?.['crNotEnabledError']}`}
                </Col>
              )}
            </Col>
          </Row>
        ),
      },
    ];
  },

  courseRequestSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: 'Naviance',
      },
      {
        name: 'Number of Records',
        key: 'Number of Records',
        value: 0,
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },
};

export default courseRequestExportData;
