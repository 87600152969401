import { Col, Row } from 'antd';
import * as React from 'react';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { getJobStatusMsg } from '../../utils/jobStatus';
import { IntegrationContext } from './../../utils/context';
import { API_URL } from '../../utils/constants';

const currentCourseImportData = {
  courseCurrentDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
    jobGuid,
  ) => {
    let recordsSkipped = 0;
    for (const category in errorRowsPerCategory) {
      recordsSkipped += errorRowsPerCategory[category];
    }

    recordsSkipped += job?.validation?.invalidCount;
    const noChanges: number =
      job?.validation?.totalDataCount - (job?.importResults?.createdImports + job?.importResults?.updatedImports);
    const totalRecord: number =
      job?.importResults?.createdImports + job?.importResults?.updatedImports + (noChanges ?? 0);
    //const recordsSkipped = job?.validation?.totalDataCount - (job?.importResults?.createdImports + job?.importResults?.updatedImports + job?.validation?.warningCount );
    return [
      {
        name: 'Total Records',
        key: 'Total Records',
        value: job?.validation?.totalDataCount ? job?.validation?.totalDataCount : 0,
      },
      {
        name: 'Added',
        key: 'Added',
        value: job?.importResults?.createdImports,
      },
      {
        name: 'Updated',
        key: 'Updated',
        value: job?.importResults?.updatedImports,
      },
      {
        name: 'No Changes',
        key: 'No Changes',
        value: job?.importResults?.noChanges,
      },
      {
        name: 'Alerts',
        key: 'Alerts',
        value: (
          <Row>
            {
              <Col span={1}>
                <AlertYellowIcon />
              </Col>
            }
            <Col span={22}>
              <Row>
                <Col>
                  <span style={{ fontWeight: 'bold' }}>
                    {(recordsSkipped || 0) + (job?.validation?.warningCount || 0)}
                  </span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <ul className="course-bullet">
                    <li key={'error'}>
                      <span style={{ fontWeight: 'bold' }}>{`${recordsSkipped} Rows with Errors:`}</span> Records
                      Skipped
                      {Number(recordsSkipped) > 0 && (
                        <ul className="remove-bullet">
                          {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                            return (
                              <li key={category}>
                                <span style={{ fontWeight: 'bold' }}>
                                  {`${errorRowsPerCategory[category]} ${category}`}:{' '}
                                </span>
                                {errorColumnsPerCategory[category].join(', ')}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                    <li key={'warning'}>
                      <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.warningCount} Warnings:`}</span> Records
                      Not Skipped
                      {Number(job?.validation?.warningCount) > 0 && (
                        <ul className="remove-bullet">
                          {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                            return (
                              <li key={category}>
                                <span style={{ fontWeight: 'bold' }}>
                                  {`${warningRowsPerCategory[category]} ${category}`}:{' '}
                                </span>
                                {warningColumnsPerCategory[category].join(', ')}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </li>
                  </ul>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="export-link">
                    <a
                      onClick={() =>
                        (window.location.href = `${API_URL}/data-ingest/sis/jobs/${jobGuid}/download/logs`)
                      }
                      data-test-id="export_link"
                    >
                      Export Log for additional details
                    </a>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
    ];
  },

  courseCurrentSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: job.sis_source || 'PowerSchool SIS',
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },

  courseCurrentUpdatedResolvedData: (job, history, context) => {
    const { dataImportPath } = context;
    return [
      {
        name: 'Last Completed by',
        key: 'Last Completed by',
        value: job?.updatesResolved?.author,
      },
      {
        name: 'Last Completed on',
        key: 'Last Completed on',
        value: job?.updatesResolved?.end ? new Date(job?.updatesResolved?.end).toLocaleString() : '',
      },
      {
        name: 'Potential Updates Resolved',
        key: 'Potential Updates Resolved',
        value: (
          <Row>
            <Col span={22}>
              <Row>
                <Col>{job?.updatesResolved.recordsMatched + job?.updatesResolved.newRecordsAdded || 0}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.recordsMatched || 0
                  } records matched`}</span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.newRecordsAdded || 0
                  } records added`}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
      {
        name: 'Student Updates Log',
        key: 'Student Updates Log',
        value: (
          <a className="backbtn" onClick={() => history.push(dataImportPath + '/studentUpdates')}>
            View Student Updates Log
          </a>
        ),
      },
    ];
  },
};

export default currentCourseImportData;
