import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import { defaultNavParentMapping, navianceParentPSFields, parentUserNameFields } from '../../utils/fieldMapUtils';
import SisMatchDescriptionHeader from '../../components-v2/SisMatchDescriptionHeader';
import SisWizardHeader from '../../components-v2/SisWizardHeader';
import Title from 'antd/lib/typography/Title';
import CustomAlert from '../../components-v2/CustomAlert';

export const ParentUserNameHeader = () => {
  return (
    <>
      <Title className="titleField" data-cy="data-import-heading" level={5}>
        Username
      </Title>
      <CustomAlert
        description='Do not map this field if parents do not need to log in to Naviance Student. If mapped, parents will be registered and receive an introductory email containing a temporary password.'
      />
    </>
  );
};

const SisParentRecordsMatching = (props: WizardProps): React.ReactElement => {
  const sections = [
    {
      tableHeader: <SisWizardHeader text={'Parent Records'} />,
      defaultFields: navianceParentPSFields,
      isParent: true,
      defaultBlankValue: '(Do not import)',
    },
    {
      tableHeader: <ParentUserNameHeader />,
      defaultFields: parentUserNameFields,
      defaultBlankValue: 'Choose an option',
    },
  ];

   // add custom headers as needed in future during implementation of processors
  const customHeaders = {
    custodial: 'is_custodial', // student parent link field
    receivesCommunication: 'receives_email_flag',
    fullName: 'full_name', // student parent link field
    student_dcid: 'student_dcid',
    SIS_SchoolID: 'schoolid',
    student_number: 'student_number',
    accountIdentifier: 'account_identifier',
    is_active: 'is_active',
    middleName: 'middle_name',
  };

  return (
    <SisFieldMatching
      defaultMapping={defaultNavParentMapping}
      specificMapping={{ ...customHeaders }}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/parent-recordstranslateConfig'}
      extensionsEndpoint={'data-ingest/sis/parent-extension-tables'}
      removeUnmappedHeaders={true}
      removeEmptyHeaders={false}
      sections={sections}
      header={<SisMatchDescriptionHeader />}
      {...props}
    />
  );
};

export default SisParentRecordsMatching;
