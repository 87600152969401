import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisStartImport from '.';
import { featureFlags } from '../../utils/featureFlags';

const SisStudentStartImport = (props: WizardProps): React.ReactElement => {
  return (
    <SisStartImport
      type="students"
      defaultFrequency="TWENTY_FOUR_HOURS"
      typeLabel="Student Records"
      includeSchools={true}
      enableAskImportPopUp={true}
      includeResetJobs={featureFlags['feature.dataIngest.reset.setTTL']}
      {...props}
    />
  );
};

export default SisStudentStartImport;
