import { Frequency } from '../../../../../../libs/common-interfaces/constants';
import * as React from 'react';
import SisStartImport from '.';
import { WizardProps } from '../../components-v2/Wizard';

const SisCourseMappingStartImport = (props: WizardProps): React.ReactElement => {
  return (
    <SisStartImport
      type="course-mapping"
      defaultFrequency={Frequency.TWENTY_FOUR_HOURS}
      typeLabel="Course Mapping"
      {...props}
    />
  );
};

export default SisCourseMappingStartImport;
