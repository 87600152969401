import { CaretDownOutlined } from '@ant-design/icons';
import { Col, Dropdown, Menu, Space } from 'antd';
import * as React from 'react';

type Placement = 'topRight' | 'topLeft' | 'topCenter' | 'bottomLeft' | 'bottomCenter' | 'bottomRight';

interface CSCProps {
  cancelFn: () => any;
  continueFn: () => any;
  saveFn: () => any;
  saveAndCloseFn?: () => any;
  continueText?: string;
  disableContinue?: boolean;
  hideContinue?: boolean;
  saveAndCloseBtn?: boolean;
  placement?: Placement;
}
export const CancelSaveContinue = (props: CSCProps) => {
  const onMenuClick = ({ key }) => {
    if (key === 'save') {
      props.saveFn();
    } else {
      props.saveAndCloseFn();
    }
  };

  const saveMenu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="save">Save</Menu.Item>
      <Menu.Item key="save_and_close">Save and Close</Menu.Item>
    </Menu>
  );

  return (
    <Col span="auto" className="cancel-save-continue-btn-group">
      <Space>
        <button className="button cancel-button" data-test-id="cancel_btn" onClick={props.cancelFn}>
          Cancel
        </button>
        {props.saveAndCloseBtn ? (
          <Dropdown.Button
            data-test-id="save_dropdown_btn"
            onClick={props.saveFn}
            disabled={props.disableContinue}
            overlay={saveMenu}
            placement={props.placement ?? undefined}
            size="large"
            icon={<CaretDownOutlined />}
            className="dropdown_button"
          >
            Save
          </Dropdown.Button>
        ) : (
          <button className="button" data-test-id="save_btn" onClick={props.saveFn} disabled={props.disableContinue}>
            Save
          </button>
        )}
        {!props.hideContinue && (
          <button
            className="continue-btn button-blue"
            data-test-id="continue_btn"
            disabled={props.disableContinue}
            onClick={props.continueFn}
          >
            {props.continueText || 'Continue'}
          </button>
        )}
      </Space>
    </Col>
  );
};
