import * as React from 'react';
import { Row, Col, Table, Input, Select } from 'antd';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { IntegrationContext } from './../../utils/context';
import { availableCodes, changeMapping, getCodeMappings, parsePSSisServerVersion } from './../../utils/utils';
import { CodeSet } from '../../../../../../libs/common-interfaces';
import { gte as isGreaterThanOrEqualTo } from 'semver';
interface CounselorCodeMappingProps {
  counselorMapping: Record<string, string | number>;
  counselorMappingV2: Record<string, string | number>;
  setCounselorMapping: (counselorMapping: Record<string, string | number | (string | number)[]>) => void;
  setCounselorMappingV2: (counselorMappingV2: Record<string, string | number | (string | number)[]>) => void;
  setNavCounselorGroupMap: (counselorMappingV2: Record<string, (string | number)[]>) => void;
  isStudentCounselorMapped: boolean;
}

interface CodeSetDisplay extends CodeSet {
  used?: boolean;
}

const CounselorCodeMapping = ({
  counselorMapping,
  setCounselorMapping,
  counselorMappingV2,
  setCounselorMappingV2,
  setNavCounselorGroupMap,
  isStudentCounselorMapped,
}: CounselorCodeMappingProps): React.ReactElement => {
  const isMountedRef = React.useRef(null);

  const { Option } = Select;

  const [loading, setLoading] = React.useState(false);
  const [navCounselorData, setNavCounselorData] = React.useState([]);
  const [defaultCounselorCodes, setDefaultCounselorCodes] = React.useState<CodeSetDisplay[]>([]);
  const [counselorCodes, setCounselorCodes] = React.useState([]);
  const [isStudentCounselorCompatible, setIsStudentCounselorCompatible] = React.useState(false);

  const { pluginDetails, sisServerDetails, sisError } = React.useContext(IntegrationContext);

  const availableCounselorCodes = (): CodeSetDisplay[] =>
    availableCodes<CodeSetDisplay>(counselorMappingV2, defaultCounselorCodes);

  React.useEffect(() => {
    setCounselorCodes(availableCounselorCodes());
  }, [counselorMappingV2, defaultCounselorCodes]);

  const validate = (key: string, val: string | number) => {
    const updatedMapping = { ...counselorMappingV2 };
    const values = Object.values(counselorMappingV2);
    if (values.filter((value) => value === val).length > 1) {
      showNotification(NotificationTypes.info, 'Please add unique SIS codes for Counselors', '');
      delete updatedMapping[key];
    }
    setCounselorMappingV2(updatedMapping);
  };

  const mapCounselors = (data) => {
    return data.map((row) => {
      return {
        description: row.fullName,
        code: row.email,
      };
    });
  };
  const getIdEmailMap = (counselors) => {
    const keymap = {};
    for (const obj of counselors) {
      keymap[obj.id] = obj.email;
    }
    return keymap;
  };

  const getCounselors = async () => {
    try {
      const { data } = await apiClient.get('/contacts/districtCounselors');
      const navCounselorMap = {};
      const uniqueNavCounselors = [];
      data.forEach((counselor) => {
        const mapKey = counselor['email'];
        if (navCounselorMap[mapKey]) {
          navCounselorMap[mapKey].push(counselor);
        } else {
          uniqueNavCounselors.push(counselor);
          navCounselorMap[mapKey] = [counselor];
        }
      });
      const idEmailMap = getIdEmailMap(data);
      const newCounselorMapping = {};
      if (counselorMapping && Object.keys(counselorMapping).length > 0) {
        for (const id in counselorMapping) {
          newCounselorMapping[idEmailMap[id]] = counselorMapping[id];
        }
        setCounselorMappingV2(newCounselorMapping);
      }

      if (isMountedRef.current) {
        setNavCounselorData(mapCounselors(uniqueNavCounselors));
        setNavCounselorGroupMap(navCounselorMap);
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Counselors', 'Failure in getting data from server.');
    }
  };

  const getSisCounselors = async () => {
    try {
      if (
        !sisError &&
        isGreaterThanOrEqualTo(parsePSSisServerVersion((sisServerDetails as any).powerschool_version), '22.7.0') &&
        isGreaterThanOrEqualTo((pluginDetails as any)?.version, '1.2.4')
      ) {
        const { data: pluginTargetVersion } = await apiClient.get('data-ingest/sis/plugin-target-version');
        if (pluginTargetVersion !== 'default' && isGreaterThanOrEqualTo(pluginTargetVersion, '22.7.0')) {
          const { data } = await apiClient.get('data-ingest/sis/sis-counselors');
          const codeMappings = getCodeMappings<CodeSetDisplay>(data);
          setDefaultCounselorCodes(Object.values(codeMappings));
          setIsStudentCounselorCompatible(true);
        }
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting SIS Counselors', 'Failure in getting data from server.');
    }
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    await getCounselors();
    if (
      ((pluginDetails as any)?.version !== undefined && (sisServerDetails as any)?.powerschool_version !== undefined) ||
      sisError
    ) {
      await getSisCounselors();
    }
    if (isMountedRef.current) setLoading(false);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, [pluginDetails, sisServerDetails]);

  const isDisabled = (data, counselor) => {
    return counselor.used && !(counselorMappingV2[data.code] as string)?.includes(counselor.code);
  };

  const selectOptions = (data, counselor, ind) => (
    <Option
      className="selectOptions"
      key={`sis-${counselor.code}-${ind}`}
      value={counselor.code}
      data-test-id={`counselor_option_${counselor.code}`}
      disabled={isDisabled(data, counselor)}
    >
      {counselor.code} - {counselor.displayValue}
    </Option>
  );

  const counselorTableColumns = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (data) => data.description,
    },
    {
      title: 'Enter SIS Codes',
      width: '60%',
      render: (data) => {
        if (sisError || !isStudentCounselorCompatible || !isStudentCounselorMapped) {
          return (
            <Input
              size="large"
              allowClear={true}
              placeholder="Add SIS Code here"
              value={counselorMappingV2[data.code]}
              style={{ width: '100%' }}
              onChange={(change) =>
                changeMapping(data.code, change.target.value?.toString(), counselorMappingV2, setCounselorMappingV2)
              }
              onBlur={(change) => validate(data.code, change.target.value?.toString())}
            />
          );
        }
        return (
          <Select
            className="select select-responsive"
            mode="multiple"
            value={counselorMappingV2[data.code]}
            allowClear
            onChange={(val) => changeMapping(data.code, val, counselorMappingV2, setCounselorMappingV2)}
            data-test-id={`counselor_select_${data.code}`}
          >
            {counselorCodes.map((counselor, ind) => selectOptions(data, counselor, ind))}
          </Select>
        );
      },
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 style={{ marginBottom: '0px' }} data-test-id="defineCodesCounselorHeader">
          Counselor
        </h2>
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          className="counselorTable"
          columns={counselorTableColumns}
          dataSource={navCounselorData}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default CounselorCodeMapping;
