import type { SisCourse } from '../../../../../../libs/common-interfaces';
import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Table, Select, Tag } from 'antd';
import Drawer from '../../components/Drawer';

import { NotificationTypes, showNotification } from '../../components/Notifications';
import {
  CourseCatalogDefineCodesParameterGroup,
  CourseCatalogValueMappings,
  navianceSubjectAreaCodesUrl,
  SubjectAreaSelectedOption,
  SubjectAreaSelectionParameterGroup,
  translateHeaders,
  translateValues,
  ValueMappingItem,
} from '../../containers/SisDefineCodes/SisCourseCatalogDefineCodes';
import apiClient from '../../utils/apiClient';
import { PSError } from '../../utils/constants';
import CustomSubjectAreaDrawer from './customSubjectAreaDrawer';
import { scedMapping } from '../../../../../../libs/common-interfaces/constants';
import PencilIcon from '../../../assets/Icon/PencilIcon';
import DeleteIcon from '../../../assets/Icon/DeleteIcon';
import Modal from 'antd/lib/modal/Modal';
import Title from 'antd/lib/typography/Title';
import { Tooltip } from 'antd';
import { featureFlags } from '../../utils/featureFlags';

export interface NavianceDefinedSubjectAreaItem {
  rowId: number;
  scedCode: number;
  description: string;
  csscCode?: number;
  oldDescription?: string;
  oldScedCode?: number;
}

export type ScedBasedSubjectAreaSelectionParameterGroup = {
  rowId: number;
  scedCode: number;
  description: string;
};

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';
export interface SubjectAreaType {
  scedCode: string;
  description: string;
  csscCode?: string;
  oldDescription?: string;
  oldScedCode?: string;
  delete?: boolean;
}

export interface SubjectAreaWithLockedStatus {
  CSSCCourse: number;
  SCEDSubject: number;
  locked: boolean;
  name: string;
}
export const navianceDefinedSubjectArea: NavianceDefinedSubjectAreaItem[] = [
  // now loads from naviance /academic planner annotation type SUBJECT_AREA instead of from here
  // {rowId: 0, scedCode: 5, description: "Arts"},
  // {rowId: 1, scedCode: 23, description: "CTE"},
  // {rowId: 2, scedCode: 23, description: "ECS"},
  // {rowId: 3, scedCode: 1, description: "English"},
  // {rowId: 4, scedCode: 8, description: "Health & PE"},
  // {rowId: 5, scedCode: 2, description: "Math"},
  // {rowId: 6, scedCode: 23, description: "Military"},
  // {rowId: 7, scedCode: 22, description: "Miscellaneous"},
  // {rowId: 8, scedCode: 23, description: "Non Classm"},
  // {rowId: 9, scedCode: 23, description: "Occupational Course Study"},
  // {rowId: 10, scedCode: 3, description: "Science"},
  // {rowId: 11, scedCode: 4, description: "Social Studies"},
  // {rowId: 12, scedCode: 24, description: "World Language"},
];

export interface ScedOptionType {
  description: string;
  code: number;
}

export const scedSubjectAreaScedCode: ScedOptionType[] = [
  { code: 1, description: 'English Language and Literature' },
  { code: 2, description: 'Mathematics' },
  { code: 3, description: 'Life and Physical Sciences' },
  { code: 4, description: 'Social Sciences and History' },
  { code: 5, description: 'Visual and Performing Arts' },
  { code: 6, description: 'Foreign Language and Literature' },
  { code: 7, description: 'Religious Education and Theology' },
  { code: 8, description: 'Physical, Health, and Safety Education' },
  { code: 9, description: 'Military Science' },
  { code: 10, description: 'Information Technology' },
  { code: 11, description: 'Communication and Audio\\/Visual Technology' },
  { code: 12, description: 'Business and Marketing' },
  { code: 13, description: 'Manufacturing' },
  { code: 14, description: 'Health Care Sciences' },
  { code: 15, description: 'Public, Protective, and Government Services' },
  { code: 16, description: 'Hospitality and Tourism' },
  { code: 17, description: 'Architecture and Construction' },
  { code: 18, description: 'Agriculture, Food, and Natural Resources' },
  { code: 19, description: 'Human Services' },
  { code: 20, description: 'Transportation, Distribution and Logistics' },
  { code: 21, description: 'Engineering and Technology' },
  { code: 22, description: 'Miscellaneous' },
  { code: 23, description: 'Non-Subject-Specific, such as CTE, ECS, Military, Non Classm' },
  { code: 24, description: 'World Languages' },
];

export interface ValuesInAColumn {
  index: number | string;
  value: string;
}

export interface SubjectAreaDefineCodeProps {
  defineCodesSettings: CourseCatalogDefineCodesParameterGroup;
  triggerSetState: () => void;
  valueMappings: CourseCatalogValueMappings;
  subjectAreaTypes: SubjectAreaType[];
  setSubjectAreaTypes: (subjectAreaTypes: SubjectAreaType[]) => void;
  subjectAreaSource: string;
}

const SubjectAreaDefineCode = ({
  defineCodesSettings,
  triggerSetState,
  valueMappings,
  subjectAreaTypes,
  setSubjectAreaTypes,
  subjectAreaSource,
}: SubjectAreaDefineCodeProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [courseSubjectAreaSelectOptions, setCourseSubjectAreaSelectOptions] = React.useState<ValuesInAColumn[]>([]);
  const [subjectAreaWithLockedStatus, setsubjectAreaWithLockedStatus] = React.useState<SubjectAreaWithLockedStatus[]>(
    [],
  );
  let customSubjectArea = [];
  const [sideDrawer, setSideDrawer] = React.useState<boolean>(false);
  const [isCreateBasedOnSced, setIsCreateBasedOnSced] = React.useState<boolean>(false);
  const [scedCode, setScedCode] = React.useState<string>('');
  const [csscCode, setCsscCode] = React.useState<string>('');
  const [navianceCode, setNavianceCode] = React.useState<string>('');
  const [rowId, setRowId] = React.useState<number>(null);
  const [oldDescription, setOldDescription] = React.useState<string>(null);
  const [oldScedCode, setOldScedCode] = React.useState<string>(null);
  const [editSubjectArea, setEditSubjectArea] = React.useState<boolean>(false);
  const [tableData, setTableData] = React.useState<NavianceDefinedSubjectAreaItem[]>([]);
  const [scedTableData, setScedTableData] = React.useState<ScedBasedSubjectAreaSelectionParameterGroup[]>([]);
  const [selectMode, setSelectMode] = React.useState<string>('');
  const [showModal, setShowModal] = React.useState(null);

  const deleteTooltipText =
    'You must move all the associated courses to a different subject area before you can delete this subject area';
  const editTooltipText =
    'You must move all the associated courses to a different subject area before you can edit this subject area';

  const loadSelectMode = () => {
    if (
      subjectAreaSource === translateValues.schedDepartment.internal ||
      subjectAreaSource === translateValues.courseSubjectAreadCode.internal
    ) {
      setSelectMode('multiple');
    }
  };

  const renderSideDrawer = () => {
    if (sideDrawer) {
      return (
        <Drawer
          visible={true}
          onClose={() => {
            setSideDrawer(false);
          }}
          destroyOnClose={true}
          content={
            <CustomSubjectAreaDrawer
              onSave={(subjectAreaName, scedCode) => {
                onSaveSubjectArea(subjectAreaName, scedCode);
              }}
              editSubjectArea={editSubjectArea}
              csscCode={csscCode}
              scedCode={scedCode}
              navianceCode={navianceCode}
            />
          }
        />
      );
    }
  };

  const onSaveSubjectArea = (newSubjectAreaName, newScedCode) => {
    if (editSubjectArea) {
      if (isCreateBasedOnSced) {
        editNavianceCodeAndScedCode(scedTableData, newSubjectAreaName, newScedCode, navianceCode, scedCode, '');
      } else {
        editNavianceCodeAndScedCode(tableData, newSubjectAreaName, newScedCode, navianceCode, scedCode, csscCode);
      }
    } else if (isCreateBasedOnSced) {
      addSubjectAreaBasedForScedTable(newSubjectAreaName, newScedCode);
    } else {
      addSubjectArea(newSubjectAreaName, newScedCode);
    }
  };

  const editNavianceCodeAndScedCode = (
    tableData: NavianceDefinedSubjectAreaItem[],
    newSubjectAreaName: string | null,
    newScedCode: number | null,
    navianceCode: string,
    scedCode: string,
    csscCode: string,
  ) => {
    if (newSubjectAreaName != null && newScedCode != null) {
      setNavianceCode(newSubjectAreaName.trim());
      SubjectAreaData.set(tableData, rowId, {
        rowId,
        scedCode: newScedCode,
        description: newSubjectAreaName.trim(),
        csscCode: parseInt(csscCode),
      });
    } else {
      if (newSubjectAreaName != null) {
        setNavianceCode(newSubjectAreaName.trim());
        SubjectAreaData.set(tableData, rowId, {
          rowId,
          scedCode: parseInt(scedCode),
          description: newSubjectAreaName.trim(),
          csscCode: parseInt(csscCode),
        });
      }

      if (newScedCode != null) {
        setScedCode(newScedCode.toString());
        setSubjectAreaTypes(subjectAreaTypes);
        SubjectAreaData.set(tableData, rowId, {
          rowId,
          scedCode: newScedCode,
          description: `${navianceCode}`,
          csscCode: parseInt(csscCode),
        });
      }
    }
    if (isCreateBasedOnSced) {
      setScedTableData([...tableData]);
      saveScedBasedTableInDefineSetting();
    } else {
      SavedCodes.updateSaved(rowId, defineCodesSettings, newSubjectAreaName, newScedCode);
      setTableData([...tableData]);
    }

    makeValueMappings(defineCodesSettings);

    setSubjectAreaTypes(SubjectAreaTypes.generate(tableData));
    setSideDrawer(false);
    triggerSetState();
  };

  const deleteSubjectArea = (tableData) => {
    tableData.splice(showModal, 1);
    subjectAreaTypes.splice(showModal, 1);
    subjectAreaWithLockedStatus.splice(showModal, 1);

    for (let index = showModal; index < tableData.length; index += 1) {
      tableData[index] = {
        rowId: index,
        scedCode: tableData[index].scedCode,
        description: `${tableData[index].description}`,
        csscCode: 0,
      };
    }
    if (isCreateBasedOnSced) {
      setScedTableData([...tableData]);
      saveScedBasedTableInDefineSetting();
    } else {
      deletedSavedSubjectArea(showModal, defineCodesSettings);
      setTableData([...tableData]);
    }

    setSubjectAreaTypes([...subjectAreaTypes]);
    setsubjectAreaWithLockedStatus([...subjectAreaWithLockedStatus]);
    setShowModal(null);
  };

  const addSubjectArea = (subjectAreaName: string, scedCode: number) => {
    const latestRowId = SubjectAreaData.getLastRowId(tableData);
    const newSubjectAreaItem = { scedCode, description: subjectAreaName.trim(), rowId: latestRowId + 1 };
    SubjectAreaData.append(tableData, newSubjectAreaItem);
    setSubjectAreaTypes(SubjectAreaTypes.generate(tableData));
    setTableData([...tableData]);
    setSideDrawer(false);
    triggerSetState();
  };

  const saveScedBasedTableInDefineSetting = () => {
    defineCodesSettings.subjectArea.basedOnScedSubjectArea = scedTableData;
  };

  const addSubjectAreaBasedForScedTable = (subjectAreaName: string, scedCode: number) => {
    const latestRowId = scedTableData.length !== 0 ? SubjectAreaData.getLastRowId(scedTableData) : -1;
    const newSubjectAreaItem = { scedCode, description: subjectAreaName.trim(), rowId: latestRowId + 1 };
    SubjectAreaData.append(scedTableData, newSubjectAreaItem);
    setSubjectAreaTypes(SubjectAreaTypes.generate(scedTableData));
    setScedTableData([...scedTableData]);
    setSideDrawer(false);
    saveScedBasedTableInDefineSetting();
    triggerSetState();
  };

  const makeValueMappings = (defineCodeSettings: CourseCatalogDefineCodesParameterGroup) => {
    const newValueMappingItems: ValueMappingItem[] = [];
    let codeMappings = defineCodeSettings.subjectArea.schedDepartment || {};

    switch (defineCodesSettings.subjectArea.fromTranslateConfig) {
      case translateValues.schedDepartment.internal: {
        codeMappings = defineCodeSettings.subjectArea.schedDepartment || {};
        break;
      }
      case translateValues.courseSubjectAreadCode.internal: {
        codeMappings = defineCodeSettings.subjectArea.courseSubjectAreaCode || {};
        break;
      }
      case translateValues.subjectArea.internal: {
        codeMappings = defineCodeSettings.subjectArea.customFieldSelection || {};
        const tempCodeMappings = {};
        let i = 0;
        for (const codeMapping of Object.values(codeMappings)) {
          (codeMapping.sisCode as string[]).map((ele) => {
            tempCodeMappings[i++] = {
              sisCode: ele,
              navCode: codeMapping.navCode,
            };
          });
        }
        codeMappings = tempCodeMappings;
        break;
      }
      default: {
        break;
      }
    }

    for (const codeMapping of Object.values(codeMappings)) {
      // map from sisCode to NavCode
      // ToDo: how about scedCode?
      if (codeMapping.sisCode == null) {
        continue;
      }
      if (Array.isArray(codeMapping.sisCode)) {
        for (const code of codeMapping.sisCode) {
          newValueMappingItems.push({ fromValue: code, toValue: codeMapping.navCode });
        }
      } else {
        newValueMappingItems.push({ fromValue: codeMapping.sisCode, toValue: codeMapping.navCode });
      }
    }
    valueMappings[translateHeaders.subjectArea.saved] = newValueMappingItems;
  };

  /**
   * Change single item format to array format
   * @param mapping
   */
  const migrateOldMapping = (mapping: SubjectAreaSelectionParameterGroup) => {
    const optMap = new Map(courseSubjectAreaSelectOptions.map((obj) => [obj.index, obj.value]));
    for (const [key, value] of Object.entries(mapping)) {
      if (value.sisCode === '') {
        if (Array.isArray(value.rowValue)) {
          const result = [];
          for (const val of value.rowValue) {
            if (optMap.has(val)) {
              result.push(optMap.get(val));
            }
          }
          value.sisCode = result;
        } else {
          if (optMap.has(value.rowValue)) {
            value.sisCode = optMap.get(value.rowValue);
          }
        }
      }
    }
  };

  /**
   * Handle user changing mapping
   * @param navItem
   * @param value
   */
  const changeSubjectAreaMapping = (
    navItem: NavianceDefinedSubjectAreaItem,
    value: string | number | number[] | string[],
  ) => {
    const updatedMapping: CourseCatalogDefineCodesParameterGroup = { ...defineCodesSettings };
    const foundSisCodes = [];
    for (const sDept of courseSubjectAreaSelectOptions) {
      if (Array.isArray(value)) {
        if ((value as string[]).includes(sDept.value)) {
          foundSisCodes.push(sDept.value);
        }
      } else if (sDept.index === value) {
        foundSisCodes.push(sDept.value);
      }
    }
    const selected: SubjectAreaSelectedOption = {
      rowValue: navItem.rowId,
      scedCode: navItem.scedCode,
      sisCode: foundSisCodes,
      navCode: navItem.description,
    };
    switch (defineCodesSettings.subjectArea.fromTranslateConfig) {
      case translateValues.schedDepartment.internal: {
        if (!updatedMapping.subjectArea.schedDepartment) {
          updatedMapping.subjectArea.schedDepartment = {};
        }
        updatedMapping.subjectArea.schedDepartment[navItem.rowId] = selected;
        migrateOldMapping(updatedMapping.subjectArea.schedDepartment);
        break;
      }
      case translateValues.courseSubjectAreadCode.internal: {
        if (!updatedMapping.subjectArea.courseSubjectAreaCode) {
          updatedMapping.subjectArea.courseSubjectAreaCode = {};
        }
        updatedMapping.subjectArea.courseSubjectAreaCode[navItem.rowId] = selected;
        migrateOldMapping(updatedMapping.subjectArea.courseSubjectAreaCode);
        break;
      }
      case translateValues.subjectArea.internal: {
        if (!updatedMapping.subjectArea.customFieldSelection) {
          updatedMapping.subjectArea.customFieldSelection = {};
        }
        updatedMapping.subjectArea.customFieldSelection[navItem.rowId] = selected;
        break;
      }
      default: {
        break;
      }
    }
    makeValueMappings(updatedMapping);
    triggerSetState();
  };

  // deleted the selected subject area and delete the mapping if we have
  const deletedSavedSubjectArea = (rowId: number, defineCodesSettings: CourseCatalogDefineCodesParameterGroup) => {
    const type = SavedCodes.getType(defineCodesSettings);
    if (defineCodesSettings.subjectArea[type] == null) {
      return null;
    }

    const defineCodeSettingLength = Object.keys(defineCodesSettings.subjectArea[type] || {}).length;

    if (defineCodeSettingLength !== 0) {
      delete defineCodesSettings.subjectArea[type][`${rowId}`]; // delete the selected row id.
      makeValueMappings(defineCodesSettings);

      // need to update rows for the remaining subject area
      for (const subjetAreaRow in defineCodesSettings.subjectArea[type]) {
        const otherCode = defineCodesSettings.subjectArea[type][`${subjetAreaRow}`];
        if (parseInt(subjetAreaRow) > rowId) {
          otherCode.rowId = parseInt(subjetAreaRow) - 1;
          otherCode.rowValue = parseInt(subjetAreaRow) - 1;
          defineCodesSettings.subjectArea[type][parseInt(subjetAreaRow) - 1] = otherCode;
          delete defineCodesSettings.subjectArea[type][`${subjetAreaRow}`];
        }
      }
    }
  };

  // delete old subject area code and value mapping
  // make row value sync when deleted
  const updateDefineCodeSettings = (
    defineCodesSettings: CourseCatalogDefineCodesParameterGroup,
    subjectArea: NavianceDefinedSubjectAreaItem[],
  ) => {
    const type = SavedCodes.getType(defineCodesSettings);
    if (defineCodesSettings.subjectArea[type] == null) {
      return null;
    }

    const defineSetting = defineCodesSettings.subjectArea[type];
    for (const subjetAreaFromDefineRow in defineSetting) {
      const defineSettingRow = defineCodesSettings.subjectArea[type][`${subjetAreaFromDefineRow}`];
      let oldSubjectArea = true;
      for (const tableData in subjectArea) {
        if (
          subjectArea[tableData].description === defineSettingRow.navCode &&
          subjectArea[tableData].scedCode === defineSettingRow.scedCode
        ) {
          oldSubjectArea = false;
          delete defineCodesSettings.subjectArea[type][`${defineSettingRow.rowId}`];
          defineSettingRow.rowId = subjectArea[tableData].rowId;
          defineSettingRow.rowValue = subjectArea[tableData].rowId;
          defineCodesSettings.subjectArea[type][subjectArea[tableData].rowId] = defineSettingRow;
        }
      }

      if (oldSubjectArea) {
        delete defineCodesSettings.subjectArea[type][`${subjetAreaFromDefineRow}`];
        makeValueMappings(defineCodesSettings);
      }
    }
  };

  const changeSubjectAreaMappingCustom = (navItem: NavianceDefinedSubjectAreaItem, value) => {
    const updatedMapping: CourseCatalogDefineCodesParameterGroup = { ...defineCodesSettings };
    let foundSisCode = '';
    for (const sDept of courseSubjectAreaSelectOptions) {
      if (sDept.index === value) {
        foundSisCode = sDept.value;
      }
    }
    const selected: SubjectAreaSelectedOption = {
      rowValue: value,
      scedCode: navItem.scedCode,
      sisCode: foundSisCode == '' ? value : foundSisCode,
      navCode: navItem.description,
    };
    switch (defineCodesSettings.subjectArea.fromTranslateConfig) {
      case translateValues.schedDepartment.internal: {
        if (!updatedMapping.subjectArea.schedDepartment) {
          updatedMapping.subjectArea.schedDepartment = {};
        }
        updatedMapping.subjectArea.schedDepartment[navItem.rowId] = selected;
        break;
      }
      case translateValues.courseSubjectAreadCode.internal: {
        if (!updatedMapping.subjectArea.courseSubjectAreaCode) {
          updatedMapping.subjectArea.courseSubjectAreaCode = {};
        }
        updatedMapping.subjectArea.courseSubjectAreaCode[navItem.rowId] = selected;
        break;
      }
      case translateValues.subjectArea.internal: {
        if (!updatedMapping.subjectArea.customFieldSelection) {
          updatedMapping.subjectArea.customFieldSelection = {};
        }
        updatedMapping.subjectArea.customFieldSelection[navItem.rowId] = selected;
        break;
      }
      default: {
        break;
      }
    }
    makeValueMappings(updatedMapping);
    triggerSetState();
  };

  const getAlreadyDefinedNavianceSubjectAreas = async (): Promise<number> => {
    const { data } = await apiClient.get<SubjectAreaType[]>(navianceSubjectAreaCodesUrl);
    SubjectAreaData.reset(tableData);
    for (const [index, { scedCode, description, csscCode }] of data.entries()) {
      SubjectAreaData.append(tableData, {
        rowId: index,
        scedCode: parseInt(scedCode),
        description,
        csscCode: parseInt(csscCode),
      });
    }
    subjectAreaTypes = SubjectAreaTypes.generate(tableData);
    setSubjectAreaTypes(subjectAreaTypes);
    return subjectAreaTypes.length;
  };

  const getSubjectAreaOptions = async () => {
    try {
      let allValues: string[] = [];
      if (defineCodesSettings.subjectArea.fromTranslateConfig === translateValues.subjectArea.internal) {
        const tableName = defineCodesSettings.subjectArea.customField.table;
        const fieldName = defineCodesSettings.subjectArea.customField.field;
        const { data: allCourses } = await apiClient.get<SisCourse[]>(
          `/data-ingest/sis/ps-custom-table/${tableName}/${fieldName}`,
          {},
        );
        allValues = allCourses
          .map((course) => course[fieldName.toLowerCase()])
          .filter((item) => item && item.length > 0);
      } else if (defineCodesSettings.subjectArea.fromTranslateConfig === translateValues.schedDepartment.internal) {
        const { data: departments } = await apiClient.get<string[]>(
          '/data-ingest/sis/ps-distinct-course-departments',
          {},
        );
        allValues = departments;
      } else if (
        defineCodesSettings.subjectArea.fromTranslateConfig === translateValues.courseSubjectAreadCode.internal
      ) {
        const { data: subjectAreaCodes } = await apiClient.get<string[]>(
          '/data-ingest/sis/ps-distinct-course-subject-area-codes',
          {},
        );
        allValues = subjectAreaCodes;
      }

      const allValueSet = new Set(allValues);
      const sortedValues: ValuesInAColumn[] = [];
      for (const [index, value] of Array.from(allValueSet).sort().entries()) {
        sortedValues.push({ index, value });
      }

      if (isMountedRef.current) {
        switch (defineCodesSettings.subjectArea.fromTranslateConfig) {
          case 'subjectArea':
          case 'Sched_Department':
          case 'Sched_CourseSubjectAreaCode': {
            setCourseSubjectAreaSelectOptions(sortedValues);
            break;
          }
          default: {
            break;
          }
        }
      }
    } catch (error) {
      console.error(error.message);
      if (error.message !== PSError) {
        showNotification(
          NotificationTypes.error,
          'Error getting SIS Codes',
          `Failure in getting data from server. ${error.message}`,
        );
      }
    }
  };

  const getSubjectAreaWithLockedStatus = async () => {
    const { data } = await apiClient.get<SubjectAreaWithLockedStatus[]>('data-ingest/sis/ps-nav-subject-areas-locked');
    setsubjectAreaWithLockedStatus(data);
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);

    if (defineCodesSettings.subjectArea.fromTranslateConfig === 'create_based_on_sced_subjectArea') {
      setIsCreateBasedOnSced(true);
    }

    makeValueMappings(defineCodesSettings);

    loadSelectMode();
    // load subject area from naviance
    const subjectAreaCount = await getAlreadyDefinedNavianceSubjectAreas();
    setTableData([...tableData]);
    setScedTableData([...tableData]);

    if (subjectAreaCount !== 0) await getSubjectAreaWithLockedStatus();

    if (subjectAreaCount == 0) {
      SavedCodes.reset(defineCodesSettings);
    } else {
      if (featureFlags['feature.dataIngest.cplan.courseCatlogDeleteSubjectArea']) {
        updateDefineCodeSettings(defineCodesSettings, tableData);
      }
    }

    await getSubjectAreaOptions();

    if (isMountedRef.current) setLoading(false);
  };
  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };
  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const subjectAreaSourceDisplay = () => {
    if (defineCodesSettings.subjectArea.fromTranslateConfig === 'subjectArea') {
      return `(custom) ${defineCodesSettings.subjectArea.customField.table} - ${defineCodesSettings.subjectArea.customField.field}`;
    }
    return defineCodesSettings.subjectArea.fromTranslateConfig;
  };

  const getMenuValue = (menuItem: NavianceDefinedSubjectAreaItem) => {
    if (menuItem == null) {
      return '';
    }
    if (defineCodesSettings == null || defineCodesSettings.subjectArea == null) {
      return '';
    }

    if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_Department') {
      if (defineCodesSettings.subjectArea.schedDepartment == null) {
        return '';
      }
      return defineCodesSettings.subjectArea.schedDepartment[menuItem.rowId]?.rowValue ?? '';
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_CourseSubjectAreaCode') {
      if (defineCodesSettings.subjectArea.courseSubjectAreaCode == null) {
        return '';
      }
      return defineCodesSettings.subjectArea.courseSubjectAreaCode[menuItem.rowId]?.rowValue ?? '';
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'subjectArea') {
      if (defineCodesSettings.subjectArea.customFieldSelection == null) {
        return '';
      }
      return defineCodesSettings.subjectArea.customFieldSelection[menuItem.rowId]?.rowValue ?? '';
    }

    return '';
  };
  const getMenuValueArray = (menuItem: NavianceDefinedSubjectAreaItem): string[] => {
    if (menuItem == null) {
      return [];
    }
    if (defineCodesSettings == null || defineCodesSettings.subjectArea == null) {
      return [];
    }
    const cleanUpStrings = (vals: string | string[]): string[] => {
      if (Array.isArray(vals)) {
        return vals;
      }
      return [vals];
    };

    if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_Department') {
      if (defineCodesSettings.subjectArea.schedDepartment == null) {
        return [];
      }
      const savedSetting = SavedCodes.getFromSaved(menuItem, 'schedDepartment', defineCodesSettings);
      if (savedSetting == null) {
        return [];
      }
      const savedNavCode = savedSetting.navCode;
      const savedSced = savedSetting.scedCode;
      if (menuItem.description !== savedNavCode || menuItem.scedCode !== savedSced) {
        // double check since it may have been deleted from naviance
        return [];
      }
      return cleanUpStrings(savedSetting.sisCode ?? []);
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_CourseSubjectAreaCode') {
      if (defineCodesSettings.subjectArea.courseSubjectAreaCode == null) {
        return [];
      }
      const savedSetting = SavedCodes.getFromSaved(menuItem, 'courseSubjectAreaCode', defineCodesSettings);
      if (savedSetting == null) {
        return [];
      }

      const savedNavCode = savedSetting.navCode;
      const savedSced = savedSetting.scedCode;
      if (menuItem.description !== savedNavCode || menuItem.scedCode !== savedSced) {
        // double check since it may have been deleted from naviance
        return [];
      }
      return cleanUpStrings(savedSetting.sisCode ?? []);
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'subjectArea') {
      if (defineCodesSettings.subjectArea.customFieldSelection == null) {
        return [];
      }
      const savedSetting = SavedCodes.getFromSaved(menuItem, 'customFieldSelection', defineCodesSettings);
      if (savedSetting == null) {
        return [];
      }
      const savedNavCode = savedSetting.navCode;
      const savedSced = savedSetting.scedCode;
      if (menuItem.description !== savedNavCode || menuItem.scedCode !== savedSced) {
        // double check since it may have been deleted from naviance
        return [];
      }
      return cleanUpStrings(savedSetting.sisCode ?? []);
    }

    return [];
  };

  const subjectAreaTableColumns = [
    {
      title: 'Naviance Code',
      width: '30%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: 'SCED Subject Code',
      width: '30%',
      render: (data) => (
        <div data-test-id={`subject_area_sced_code_${data.rowId}`}>
          {data.scedCode < 10 && data.scedCode !== 0 && '0' + data.scedCode + ' ' + scedMapping[data.scedCode]}
          {data.scedCode > 9 && data.scedCode + ' ' + scedMapping[data.scedCode]}
        </div>
      ),
    },
    {
      title: 'Select SIS Code',
      width: '35%',
      render: (navItem: NavianceDefinedSubjectAreaItem) => {
        if (selectMode === 'multiple') {
          return (
            <div>
              <Select
                className="select select-responsive"
                value={getMenuValueArray(navItem)}
                onChange={(vals) => {
                  changeSubjectAreaMapping(navItem, vals);
                }}
                data-test-id={`subject_area_select_${navItem.rowId}`}
                mode={'multiple'}
                filterOption={(input, option) =>
                  option?.display != null && option.display.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {courseSubjectAreaSelectOptions &&
                  courseSubjectAreaSelectOptions.length > 1 &&
                  courseSubjectAreaSelectOptions.map((areaCodes) => (
                    <Option
                      className="selectOptions"
                      key={`sis-csac-${areaCodes.index}`}
                      value={areaCodes.value}
                      data-test-id={`subject_area_code_option_${areaCodes.index}`}
                      display={areaCodes.value}
                    >
                      {areaCodes.value}
                    </Option>
                  ))}
              </Select>
            </div>
          );
        }
        return (
          <div>
            <Select
              className="select select-responsive"
              value={getMenuValue(navItem)}
              onChange={(val) => {
                changeSubjectAreaMapping(navItem, val);
              }}
              data-test-id={`subject_area_select_${navItem.rowId}`}
            >
              {courseSubjectAreaSelectOptions &&
                courseSubjectAreaSelectOptions.length > 1 &&
                courseSubjectAreaSelectOptions.map((areaCodes) => (
                  <Option
                    className="selectOptions"
                    key={`sis-csac-${areaCodes.index}`}
                    value={areaCodes.index}
                    data-test-id={`subject_area_code_option_${areaCodes.index}`}
                  >
                    {areaCodes.value}
                  </Option>
                ))}
            </Select>
          </div>
        );
      },
    },
    {
      title: '',
      width: '10%',
      render: (data) => {
        return (
          <div>
            {featureFlags['feature.dataIngest.cplan.courseCatlogEditSubjectArea'] ? (
              <div style={{ width: '50%', display: 'inline-block', padding: '0', margin: '0' }}>
                {subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <Tooltip placement="topLeft" title={editTooltipText}>
                    <PencilIcon
                      editSubjectArea={true}
                      data-test-id={`subject_area_edit_disable_${data.rowId}`}
                      inactive={true}
                    />
                  </Tooltip>
                )}
                {!subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <PencilIcon
                    editSubjectArea={true}
                    data-test-id={`subject_area_edit_${data.rowId}`}
                    onClick={() => {
                      onClickEditSubjectArea(data.scedCode, data.csscCode, data.description, data.rowId);
                    }}
                  />
                )}
              </div>
            ) : (
              <React.Fragment />
            )}

            {featureFlags['feature.dataIngest.cplan.courseCatlogDeleteSubjectArea'] ? (
              <div style={{ width: '50%', display: 'inline-block' }}>
                {subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <Tooltip placement="topLeft" title={deleteTooltipText}>
                    <DeleteIcon
                      editSubjectArea={true}
                      data-test-id={`${data.rowId}-disable-action`}
                      inactive={true}
                    ></DeleteIcon>
                  </Tooltip>
                )}
                {!subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <DeleteIcon
                    editSubjectArea={true}
                    data-test-id={`${data.rowId}-delete-action`}
                    inactive={false}
                    onClick={() => {
                      onDeleteSubjectArea(data.scedCode, data.csscCode, data.description, data.rowId);
                    }}
                  ></DeleteIcon>
                )}
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        );
      },
    },
  ];

  const createSubjectAreaTableColumns = [
    {
      title: 'Naviance Code',
      width: '30%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: 'SCED Subject Code',
      width: '30%',
      render: (data) => (
        <div data-test-id={`subject_area_sced_code_${data.rowId}`}>
          {data.scedCode < 10 && data.scedCode !== 0 && '0' + data.scedCode + ' ' + scedMapping[data.scedCode]}
          {data.scedCode > 9 && data.scedCode + ' ' + scedMapping[data.scedCode]}
        </div>
      ),
    },
    {
      title: '',
      width: '5%',
      render: (data) => {
        return (
          <div>
            {featureFlags['feature.dataIngest.cplan.courseCatlogEditSubjectArea'] ? (
              <div style={{ width: '50%', display: 'inline-block', padding: '0', margin: '0' }}>
                {subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <Tooltip placement="topLeft" title={editTooltipText}>
                    <PencilIcon
                      editSubjectArea={true}
                      data-test-id={`subject_area_edit_disable_${data.rowId}`}
                      inactive={true}
                    />
                  </Tooltip>
                )}
                {!subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <PencilIcon
                    editSubjectArea={true}
                    data-test-id={`subject_area_edit_${data.rowId}`}
                    onClick={() => {
                      onClickEditSubjectArea(data.scedCode, data.csscCode, data.description, data.rowId);
                    }}
                  />
                )}
              </div>
            ) : (
              <React.Fragment />
            )}

            {featureFlags['feature.dataIngest.cplan.courseCatlogDeleteSubjectArea'] ? (
              <div style={{ width: '50%', display: 'inline-block' }}>
                {subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <Tooltip placement="topLeft" title={deleteTooltipText}>
                    <DeleteIcon
                      editSubjectArea={true}
                      data-test-id={`${data.rowId}-disable-action`}
                      inactive={true}
                    ></DeleteIcon>
                  </Tooltip>
                )}
                {!subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <DeleteIcon
                    editSubjectArea={true}
                    data-test-id={`${data.rowId}-delete-action`}
                    inactive={false}
                    onClick={() => {
                      onDeleteSubjectArea(data.scedCode, data.csscCode, data.description, data.rowId);
                    }}
                  ></DeleteIcon>
                )}
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        );
      },
    },
  ];

  const getSubjectAreaColumns = () => {
    switch (defineCodesSettings.subjectArea.fromTranslateConfig) {
      case 'subjectArea':
        return subjectAreaTableColumnsCustom;
      case 'create_based_on_sced_subjectArea':
        return createSubjectAreaTableColumns;
      case 'Sched_CourseSubjectAreaCode':
      case 'Sched_Department':
        return subjectAreaTableColumns;
    }

    if (!featureFlags['feature.dataIngest.cplan.courseCatalogCustomSubjectArea']) {
      return subjectAreaTableColumns;
    }
  };

  const onClickEditSubjectArea = (scedCode, csscCode, navianceCode, rowId) => {
    setOldDescription(navianceCode);
    setOldScedCode(scedCode);
    setRowId(rowId);
    setScedCode(scedCode);
    setCsscCode(csscCode);
    setNavianceCode(navianceCode);
    setEditSubjectArea(true);
    setSideDrawer(true);
  };

  const onDeleteSubjectArea = async (scedCode, csscCode, navianceCode, rowId) => {
    setShowModal(rowId);
  };

  const onClickAddSubjectArea = () => {
    // when not editing subject area
    setOldDescription('');
    setNavianceCode('');
    setCsscCode('');

    setRowId(null);
    setEditSubjectArea(false);
    setSideDrawer(true);
  };
  const subjectAreaTableColumnsCustom = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: 'SCED Subject Code',
      width: '30%',
      render: (data) => (
        <div data-test-id={`subject_area_sced_code_${data.rowId}`}>
          {data.scedCode < 10 && data.scedCode !== 0 && '0' + data.scedCode + ' ' + scedMapping[data.scedCode]}
          {data.scedCode > 9 && data.scedCode + ' ' + scedMapping[data.scedCode]}
        </div>
      ),
    },
    {
      title: 'Enter SIS Code',
      width: '25%',
      render: (navItem: NavianceDefinedSubjectAreaItem) => {
        if (defineCodesSettings.subjectArea.hasOwnProperty('customFieldSelection')) {
          customSubjectArea = [];
          for (const [key, val] of Object.entries(defineCodesSettings.subjectArea.customFieldSelection)) {
            customSubjectArea.push({ navCode: val.navCode, sisCode: val.sisCode });
          }
        }
        return (
          <div>
            <Select
              mode="tags"
              size="large"
              tagRender={tagRender}
              value={
                customSubjectArea.filter((ele) => {
                  return ele.navCode == navItem.description;
                })[0]?.sisCode
              }
              style={{ width: '100%' }}
              options={[]}
              tokenSeparators={[',']}
              data-test-id={`subject_area_select_${navItem.rowId}`}
              dropdownStyle={{ display: 'none' }}
              placeholder={<div style={{ color: 'grey' }}>Add custom codes</div>}
              onChange={(change) => {
                changeSubjectAreaMappingCustom(navItem, change);
              }}
            />
          </div>
        );
      },
    },
    {
      title: '',
      width: '5%',
      render: (data) => {
        return (
          <div>
            {featureFlags['feature.dataIngest.cplan.courseCatlogEditSubjectArea'] ? (
              <div style={{ width: '50%', display: 'inline-block', padding: '0', margin: '0' }}>
                {subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <Tooltip placement="topLeft" title={editTooltipText}>
                    <PencilIcon
                      editSubjectArea={true}
                      data-test-id={`subject_area_edit_disable_${data.rowId}`}
                      inactive={true}
                    />
                  </Tooltip>
                )}
                {!subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <PencilIcon
                    editSubjectArea={true}
                    data-test-id={`subject_area_edit_${data.rowId}`}
                    onClick={() => {
                      onClickEditSubjectArea(data.scedCode, data.csscCode, data.description, data.rowId);
                    }}
                  />
                )}
              </div>
            ) : (
              <React.Fragment />
            )}

            {featureFlags['feature.dataIngest.cplan.courseCatlogDeleteSubjectArea'] ? (
              <div style={{ width: '50%', display: 'inline-block' }}>
                {subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <Tooltip placement="topLeft" title={deleteTooltipText}>
                    <DeleteIcon
                      editSubjectArea={true}
                      data-test-id={`${data.rowId}-disable-action`}
                      inactive={true}
                    ></DeleteIcon>
                  </Tooltip>
                )}
                {!subjectAreaWithLockedStatus[data.rowId]?.locked && (
                  <DeleteIcon
                    editSubjectArea={true}
                    data-test-id={`${data.rowId}-delete-action`}
                    inactive={false}
                    onClick={() => {
                      onDeleteSubjectArea(data.scedCode, data.csscCode, data.description, data.rowId);
                    }}
                  ></DeleteIcon>
                )}
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="subSection mainMatchFieldsSection">
      <Row className="">
        <Col span={24}>
          <h2>Subject Area</h2>
          <div className="pagepara">
            Match the Subject Area in Naviance to the SIS codes from <b>{subjectAreaSourceDisplay()}</b>
          </div>
          <br />
        </Col>
        <Col span={24} className="mainMatchFieldsSection">
          <Table
            rowKey="rowId"
            columns={getSubjectAreaColumns()}
            dataSource={isCreateBasedOnSced ? scedTableData : tableData}
            pagination={false}
            loading={loading}
          />
        </Col>
        <Col span="auto">
          <button
            className="button"
            style={{ marginTop: '23px' }}
            onClick={() => {
              onClickAddSubjectArea();
            }}
            data-test-id="add_subject_area_btn"
          >
            Add Subject Area <span style={{ marginLeft: '10px', fontSize: 'large' }}>+</span>
          </button>
        </Col>
      </Row>
      {renderSideDrawer()}
      <Modal
        visible={!!showModal || showModal === 0}
        onOk={() => (isCreateBasedOnSced ? deleteSubjectArea(scedTableData) : deleteSubjectArea(tableData))}
        onCancel={() => setShowModal(null)}
        okText="Delete"
      >
        <Title level={5}>Are you sure you want to delete the subject area</Title>
      </Modal>
    </div>
  );
};

/**
 * Extracted to a static class to reduce confusion and avoid bugs
 */
export class SubjectAreaData {
  static reset(definedSubjectAreas: NavianceDefinedSubjectAreaItem[]): void {
    if (definedSubjectAreas != null) {
      definedSubjectAreas.length = 0;
    }
  }
  static getLastRowId(definedSubjectAreas: NavianceDefinedSubjectAreaItem[]): number {
    const rowIds = definedSubjectAreas
      .map((item) => {
        return item.rowId;
      })
      .sort((n1, n2) => n1 - n2)
      .reverse();
    return rowIds.length !== 0 ? rowIds[0] : -1;
  }
  static append(definedSubjectAreas: NavianceDefinedSubjectAreaItem[], item: NavianceDefinedSubjectAreaItem): void {
    definedSubjectAreas.push(item);
  }
  static set(
    definedSubjectAreas: NavianceDefinedSubjectAreaItem[],
    rowId: number,
    item: NavianceDefinedSubjectAreaItem,
  ): void {
    definedSubjectAreas[rowId] = item;
  }
}

/**
 * To be sent over to naviance to save in subject areas
 */
export class SubjectAreaTypes {
  static generate(tableData: NavianceDefinedSubjectAreaItem[]): SubjectAreaType[] {
    const subTypes: SubjectAreaType[] = [];
    for (const item of tableData) {
      subTypes.push({ scedCode: `${item.scedCode}`, description: `${item.description}`, csscCode: `${item.csscCode}` });
    }
    return subTypes;
  }
}

export class SavedCodes {
  static getFromSaved(
    menuItem: NavianceDefinedSubjectAreaItem,
    type: string,
    defineCodesSettings: CourseCatalogDefineCodesParameterGroup,
  ): SubjectAreaSelectedOption | null {
    if (defineCodesSettings.subjectArea[type] == null) {
      return null;
    }

    const setting = defineCodesSettings.subjectArea[type][menuItem.rowId];
    if (setting == null) {
      // find the first one that matches both navCode and sced
      for (const value of Object.values(defineCodesSettings.subjectArea[type])) {
        if (value['navCode'] === menuItem.description && value['scedCode'] === menuItem.scedCode) {
          return value as SubjectAreaSelectedOption;
        }
      }
    } else {
      return setting;
    }
  }

  static getType(defineCodesSettings: CourseCatalogDefineCodesParameterGroup): string {
    if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_Department') {
      return 'schedDepartment';
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_CourseSubjectAreaCode') {
      return 'courseSubjectAreaCode';
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'subjectArea') {
      return 'customFieldSelection';
    }
    return 'schedDepartment';
  }

  static updateSaved(
    rowId: number,
    defineCodesSettings: CourseCatalogDefineCodesParameterGroup,
    newDescription: string | null,
    newScedCode: number | null,
  ): void {
    const type = SavedCodes.getType(defineCodesSettings);
    if (defineCodesSettings.subjectArea[type] == null) {
      return null;
    }

    const setting = defineCodesSettings.subjectArea[type][`${rowId}`];
    if (setting == null) {
      return null;
    }

    if (newDescription != null) {
      setting.navCode = newDescription;
    }
    if (newScedCode != null) {
      setting.scedCode = newScedCode;
    }
  }

  /**
   * When naviance has no subject areas, we need to cleanup parametergroup settings
   * @param defineCodesSettings
   */
  static reset(defineCodesSettings: CourseCatalogDefineCodesParameterGroup): void {
    if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_Department') {
      if (defineCodesSettings.subjectArea.schedDepartment == null) {
        return;
      }
      defineCodesSettings.subjectArea.schedDepartment = {};
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'Sched_CourseSubjectAreaCode') {
      if (defineCodesSettings.subjectArea.courseSubjectAreaCode == null) {
        return;
      }
      defineCodesSettings.subjectArea.courseSubjectAreaCode = {};
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'subjectArea') {
      if (defineCodesSettings.subjectArea.customFieldSelection == null) {
        return;
      }
      defineCodesSettings.subjectArea.customFieldSelection = {};
    } else if (defineCodesSettings.subjectArea.fromTranslateConfig === 'create_based_on_sced_subjectArea') {
      if (defineCodesSettings.subjectArea.basedOnScedSubjectArea == null) {
        return;
      }
      defineCodesSettings.subjectArea.basedOnScedSubjectArea = [];
    }
  }
}

export default SubjectAreaDefineCode;

/**
 * Documenting how Naviance subject areas are set up:
 * 1. Eventually, subject areas settings (for course planner import) are saved in Academic Planner AnnotationType SUBJECT_AREA
 *    in the district namespace. e.g.
 *    aplan -e dev annotationtype.findone naviance.9000001DUS SUBJECT_AREA
 * 2. The content has an array of strings. Each string has underscore separated two or three parts.
 *    First part is name. Second part is CSSC code. Third part is SCED code.
 *    That means, subject area cannot have underscores.
 * 3. dchan naviance processor "createSubjects" step creates those subject areas.
 * 4. In createSubjects step, for file import, the Subject_Area column and SCED_Subject_Area columns are from the
 *    "translate" step. For example,
 *    A course can have "Physical Education-PE" in Subject_Area and "" in SCED_Subject_Area and
 *    the district have " "Physical Education-PE_0_8" in one of the SUBJECT_AREA AnnotationType string array.
 *    So 8 is the SCED code that should have been created there by createSubjects step
 *    From all of those values, the createSubjects will set up the SUBJECT_AREA AnnotationType according.
 * 5. In createSubjects step, for SIS import, the data for setting up the SUBJECT_AREA AnnotationType is
 *    from the district parameter groups that the data-ingest UI has saved. For example,
 *    (a) the UI has set up Naviance Code "Arts" with "Phys Ed".
 *    (b) "Arts" has scedCode 5
 *    (c) the parameter group will have:
 *    subjectArea: {
 *        schedDepartment: {
 *          "0": {
 *            navCode: "Arts",
 *            sisCode: "Phys Ed",
 *            rowValue: 2,
 *            scedCode: 5
 *          }
 *        }
 *    }
 *    (d) So that means SUBJECT_AREA AnnotationType needs to have Arts_0_5 as a string in the array.
 *    (e) Check "headerMappings" to see which ones should be in effect, from schedule department, subject area code or
 *        custom field.
 */
