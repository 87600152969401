import JSZip from 'jszip';
import apiClient from './apiClient';
import { ImportTypeParam, UPLOAD_FILE_ENDPOINT, DEFAULT_ROLL_OVER_DAY, DEFAULT_ROLL_OVER_MONTH } from './constants';
import moment from 'moment';

export const getTriggerJobData = () => {
  const headerlessFile = sessionStorage.getItem('headerlessFile') === 'yes' ? false : true;
  const importRules = sessionStorage.getItem('importSettings');
  return {
    importType: sessionStorage.getItem('importType'),
    mappings: sessionStorage.getItem('mapping').split(','),
    bucketName: sessionStorage.getItem('bucketName'),
    keyName: sessionStorage.getItem('keyName'),
    headerlessFile,
    importSettings: JSON.parse(importRules),
  };
};

export const getImportName = (importType: ImportTypeParam): string => {
  switch (importType) {
    case ImportTypeParam.CourseCatalog:
      return 'Course Catalog  Data Import - Upload File';
    case ImportTypeParam.CourseMapping:
      return 'Course Mapping  Data Import - Upload File';
    case ImportTypeParam.StudentCourseData:
      return 'Student Course Data Import - Upload File';
    case ImportTypeParam.Scholarship:
      return 'Scholarship  Data Import - Upload File';
    default:
      return `${importType} Data Import - Upload File`;
  }
};
export const getMatchFieldsTitle = (importType: ImportTypeParam): string => {
  switch (importType) {
    case ImportTypeParam.CourseCatalog:
      return 'Course Catalog  Data Import - Match Fields';
    case ImportTypeParam.CourseMapping:
      return 'Course Mapping  Data Import - Match Fields';
    case ImportTypeParam.StudentCourseData:
      return 'Student Course Data Import - Match Fields';
    case ImportTypeParam.Scholarship:
      return 'Scholarship  Data Import - Match Fields';
    default:
      return `${importType} Data Import - Match Fields`;
  }
};

export enum FILE_TYPE {
  TXT = 'txt',
  CSV = 'csv',
  ZIP = 'zip',
}

export const readFileHeaders = async (file: File): Promise<string[]> => {
  // eslint-disable-next-line no-useless-escape
  const ext = file.name.match(/\.([^\.]+)$/)?.[1];
  switch (ext) {
    case FILE_TYPE.TXT:
      return parseText(file, '\t');
    case FILE_TYPE.CSV:
      return parseText(file, ',');
    case FILE_TYPE.ZIP:
      return parseZip(file);
    default:
      throw new Error(`Can not get field data from .${ext} files.`);
  }
};

const parseToArray = (str: string, delimiter: string) => {
  if (!str) {
    throw new Error('Incorrect field mapping data.');
  }
  return str
    .split(delimiter)
    .filter((i) => i !== '' && i !== '\r')
    .map((i) => i.replace(/[^\x20-\x7E]/g, ''));
};

const parseText = async (file: File, delimiter: string) => {
  const reader = new FileReader();
  const offset = 200;
  let bytesRead = 0;
  const stringData = await new Promise<string>((resolve, reject) => {
    let fields = '';
    reader.onloadend = (e) => {
      if (bytesRead > file.size) {
        resolve(fields);
        return;
      }

      fields += e.target.result;
      bytesRead += offset;
      const newLineIndex = fields.indexOf('\n');
      if (newLineIndex === -1) {
        reader.readAsBinaryString(file.slice(bytesRead, bytesRead + offset));
      } else {
        resolve(fields.substr(0, newLineIndex));
      }
    };
    reader.onerror = reject;
    reader.onabort = reject;
    reader.readAsBinaryString(file.slice(bytesRead, bytesRead + offset));
  });

  return parseToArray(stringData, delimiter);
};

const parseZip = async (file: File) => {
  const z = new JSZip();
  await z.loadAsync(file.slice(0));

  const foundFile = Object.values(z.files)[0];

  const delimiter = foundFile.name.endsWith('csv') ? ',' : '\t';
  const stream = (foundFile as any).internalStream('binarystring');
  const stringData = await new Promise<string>((resolve, reject) => {
    let fields = '';
    stream
      .on('data', (chunk: string) => {
        fields += chunk;
        const newLineIndex = fields.indexOf('\n');
        if (newLineIndex !== -1) {
          resolve(fields.substr(0, newLineIndex));
          stream.pause();
        }
      })
      .on('error', reject)
      .on('end', resolve)
      .resume();
  });
  stream._worker.end();

  return parseToArray(stringData, delimiter);
};

export const uploadFile = async (
  files: string,
  importType: ImportTypeParam,
  importName: string,
  fileHeader: string[],
  containsHeading: string,
  saveSettings: boolean,
): Promise<void> => {
  if (files === '') {
    return;
  }
  const formData = new FormData();
  formData.append('file', files);
  formData.append('importType', importType);
  formData.append('importName', importName);
  formData.append('headerlessFile', containsHeading === 'yes' ? 'false' : 'true');
  formData.append('saveSettings', saveSettings.toString());
  formData.append('mapping', fileHeader.toString());
  sessionStorage.setItem('importType', importType);
  sessionStorage.setItem('importName', importName);
  sessionStorage.setItem('mapping', fileHeader.toString());
  sessionStorage.setItem('headerlessFile', containsHeading);
  const config = {
    headers: {
      'content-type': 'multipart/form-data',
    },
  };
  const data = await apiClient.post(UPLOAD_FILE_ENDPOINT, formData, config);
  sessionStorage.setItem('keyName', data.data.keyName);
  sessionStorage.setItem('bucketName', data.data.bucketName);
  sessionStorage.setItem('firstImport', data.data.firstImport);
};

export const getIngressType = (dataType: string): string => {
  switch (dataType) {
    case 'Student Records':
      dataType = 'students';
      break;
    case 'Student Records (Alumni)':
      dataType = 'alumni';
      break;
    case 'Staff Records':
      dataType = 'staff-records';
      break;
    case 'Parent Records':
      dataType = 'parent-records';
      break;
    case 'Course Catalog':
      dataType = 'course-catalog';
      break;
    case 'Course Mapping':
      dataType = 'course-mapping';
      break;
    case 'Student Course Data':
      dataType = 'student-course-data';
      break;
    case 'Student Course Data (Course History)':
      dataType = 'course-history';
      break;
    case 'Course Current':
    case 'Student Course Data (Current Courses)':
      dataType = 'course-current';
      break;
    default:
      dataType = 'Unknown';
      break;
  }
  return dataType;
};

export const getCurrentAcademicYear = (): number | string => {
  const currentDate = new Date();
  const currentYear = new Date().getFullYear();
  const ROLL_OVER_DATE = new Date(`${DEFAULT_ROLL_OVER_MONTH}/${DEFAULT_ROLL_OVER_DAY}/${currentYear}`);
  const startYear = currentDate > ROLL_OVER_DATE ? currentYear : currentYear - 1;
  return startYear;
};

export const parsePSSisServerVersion = (powerschool_version: string): string => {
  const elements = powerschool_version.split('.');
  elements.splice(3, elements.length - 1);
  return elements.join('.');
};

export const availableCodes = <T>(mapping: unknown, defaultCodes: T[]): T[] => {
  const usedCodes = Object.values(mapping).reduce((acc: any, val: any) => acc.concat(val), []);
  return defaultCodes.reduce((acc, race) => {
    (race as any).used = usedCodes.includes((race as any).code);
    return acc.concat(race);
  }, []);
};

export const availableSisCodes = <T>(mapping: unknown, defaultCodes: T[]): T[] => {
  const usedCodes = Object.values(mapping).reduce((acc: any, val: any) => acc.concat(val), []);
  const availableCodes = defaultCodes.reduce((acc, defaultCode) => {
    (defaultCode as any).used = usedCodes.includes((defaultCode as any).value);
    return acc.concat(defaultCode);
  }, []);
  return availableCodes;
};

export const availableDiplamoTypes = <T>(mapping: unknown, defaultCodes: T[]): T[] => {
  const usedCodes = Object.values(mapping).reduce((acc: any, val: any) => acc.concat(val), []);
  const availableCodes = defaultCodes.reduce((acc, defaultCode) => {
    (defaultCode as any).used = usedCodes.includes((defaultCode as any).name);
    return acc.concat(defaultCode);
  }, []);
  return availableCodes;
};
export const changeMapping = (
  key: string,
  val: (string | number)[] | (string | number),
  mapping: any,
  setMapping: any,
): void => {
  const updatedMapping = { ...mapping };
  if (val) {
    updatedMapping[key] = val;
  } else {
    delete updatedMapping[key];
  }
  setMapping(updatedMapping);
};

export const changeGradeMapping = (
  key: number,
  val: string[],
  mapping: Record<number, string[]>,
  setMapping: any,
): void => {
  const updatedMapping = { ...mapping };
  if (val.length) {
    updatedMapping[key] = val;
  } else {
    delete updatedMapping[key];
  }
  setMapping(updatedMapping);
};

export const getCodeMappings = <T>(data): T[] => {
  return data.reduce((acc, codeset) => {
    acc[codeset.code] = { code: codeset.code, displayValue: codeset.displayValue };
    return acc;
  }, {});
};

export const checkLegacyClever = (isCleverIntegration: boolean, featureFlags: Record<string, unknown>): boolean => {
  if (isCleverIntegration) {
    if (featureFlags['feature.dataIngest.cleverLegacy.student.systemEnabled']) {
      return !featureFlags['feature.dataIngest.cleverLegacy.student.tenantDisabled'];
    } else {
      return !!featureFlags['feature.dataIngest.cleverLegacy.student.tenantEnabled'];
    }
  }
  return false;
};

export const getRequiredFields = (channel: any) =>
  (channel.data.validateValues.details.body.validateConfig?.columns ?? channel.data.validateValues.details.body) || {};

export const discardMappedCleverRoles = (roles: { name: string; id: number }[] = [], mappingCodes: Record<string, any> = {}): { label: string; value: number }[] => {
  const mappedRoleNames = Object.keys(mappingCodes || {});
  const visitedRoles = new Set();
  return roles
    .filter((role) => {
      const isReturnRole = !visitedRoles.has(role.name) && !mappedRoleNames.includes(role.name);
      visitedRoles.add(role.name);
      return isReturnRole;
    })
    .map((role) => ({ label: role.name, value: role.id }));
}

export const buildRolesFromConfig = (rolesMapping: Record<string, { id: number; institutionId: string }[]>): { data: { name: string }[] } => {
  const roles = { data: [] };
  Object.keys(rolesMapping).forEach((role) => {
    roles.data.push({ name: role });
  });
  return roles;
};

// This function unixTimeToDateTime converts a Unix timestamp (in seconds) to a human-readable date and time format using the Moment.js library, returning the result in the format "MMM DD, YYYY hh:mm A".
export function unixTimeToDateTime(unixTime: number): string {
  const milliseconds = unixTime * 1000; // Unix time is in seconds, so convert to milliseconds
  const date = moment(milliseconds);
  return date.format('MMM DD, YYYY hh:mm A');
}