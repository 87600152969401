import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Table, Select, Tag } from 'antd';

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

const InstructionLevelTypeMapping = ({
  defineCodesSettings,
  setInstructionalLevelSettings,
  dataSource,
}): React.ReactElement => {
  const isMountedRef = React.useRef(null);

  const [loading, setLoading] = React.useState(false);

  const changeInstructionLevelMapping = (key: string, val: (string | number)[]) => {
    const updatedInstructionLevel = defineCodesSettings.instructionalLevel;
    let existing = false,
      index;
    if (updatedInstructionLevel.length > 0) {
      updatedInstructionLevel.map((instruct, ind) => {
        if (instruct['navDisplay'] == key) {
          existing = true;
          instruct['sisCode'] = val;
          index = ind;
          return instruct;
        }
      });
    }
    if (val.length == 0 && index) updatedInstructionLevel.splice(index, 1);

    if (existing == false || updatedInstructionLevel.length == 0)
      updatedInstructionLevel.push({ navDisplay: key, sisCode: val });

    setInstructionalLevelSettings(updatedInstructionLevel);
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);

    if (isMountedRef.current) setLoading(false);
  };

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const instructionLevelTableColumns = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: 'Enter SIS Codes',
      width: '60%',
      render: (data) => {
        return (
          <Select
            mode="tags"
            size="large"
            tagRender={tagRender}
            defaultValue={
              defineCodesSettings.instructionalLevel.filter((ele) => {
                return ele.navDisplay == data.description;
              })[0]?.sisCode
            }
            style={{ width: '100%' }}
            options={[]}
            tokenSeparators={[',']}
            dropdownStyle={{ display: 'none' }}
            placeholder="Add custom codes"
            onChange={(change) => {
              changeInstructionLevelMapping(data.description, change);
            }}
          />
        );
      },
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <span>If matching multiple SIS codes to Naviance codes, then separate with commas</span>
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          columns={instructionLevelTableColumns}
          dataSource={dataSource}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default InstructionLevelTypeMapping;
