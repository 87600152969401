import Icon from '@ant-design/icons';
import React from 'react';

const DisabledActiveSvg = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Disabled-Active</title>
    <g id="Disabled-Active" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="100%" transform="translate(2.000000, 2.000000)" fill="#141497" fillRule="nonzero">
        <path
          d="M10.2124444,0 C10.9173333,0 11.5937778,0.28 12.0915556,0.77866667 L12.0915556,0.77866667 L15.2213333,3.90755556
              C15.7191111,4.40622222 16,5.08266667 16,5.78755556 L16,5.78755556 L16,10.2124444 C16,10.9173333 15.7191111,11.5937778
              15.2213333,12.0924444 L15.2213333,12.0924444 L12.0915556,15.2213333 C11.5937778,15.72 10.9173333,16 10.2124444,16
              L10.2124444,16 L5.78666667,16 C5.08266667,16 4.40622222,15.72 3.90755556,15.2213333 L3.90755556,15.2213333 L0.77866667,12.0924444
              C0.28,11.5937778 0,10.9173333 0,10.2124444 L0,10.2124444 L0,5.78755556 C0,5.08266667 0.28,4.40622222 0.77866667,3.90755556
              L0.77866667,3.90755556 L3.90755556,0.77866667 C4.40622222,0.28 5.08266667,0 5.78666667,0 L5.78666667,0 L10.2124444,0 Z M7.55574468,2.44563788
              C6.90685579,2.40674899 6.86796691,3.33119344 6.86796691,3.33119344 C6.86796691,3.33119344 7.28130024,7.30341566 7.08463357,7.24563788
              C6.83130024,7.16897121 6.00241135,4.15674899 6.00241135,4.15674899 L6.00241135,4.15674899 L5.99062666,4.11608002 C5.9390632,3.94944035
              5.71507802,3.33474899 5.25574468,3.4878601 C4.72463357,3.66563788 4.88130024,4.62897121 4.88130024,4.62897121 C4.88130024,4.62897121
              5.80574468,7.75674899 5.58907802,7.8978601 C5.37352246,8.0378601 4.64574468,6.12341566 4.64574468,6.12341566 C4.64574468,6.12341566
              4.38907802,5.67119344 3.89796691,5.89563788 C3.40685579,6.12008232 3.58130024,6.77341566 3.60241135,6.95008232 C3.62167061,7.1014815
              3.93714996,8.0328411 4.27674668,9.0325268 L4.43420528,9.4961805 C4.74858419,10.4228258 5.04784345,11.3144033 5.11796691,11.6111934
              C5.27463357,12.2800823 6.63130024,13.3834157 7.75352246,13.3834157 C9.3857447,13.3834157 9.5224114,12.9689712 9.919078,12.7523045
              C10.3146336,12.5356379 10.8013002,11.6111934 10.8013002,11.6111934 C10.8013002,11.6111934 12.6301891,8.3856379 12.9057447,7.61897121
              C13.1813002,6.85230455 12.3546336,6.36008232 11.9624114,6.65452677 C11.5679669,6.95008232 11.0568558,7.8978601 11.0568558,7.8978601
              C10.6246336,8.7856379 10.2901891,8.4534157 10.2113002,8.2678601 C10.1324114,8.0823045 10.4668558,4.33341566 10.4868558,4.03897121
              C10.5068558,3.74452677 10.4868558,3.29119344 9.919078,3.23230455 C9.3513002,3.17341566 9.2479669,3.82341566 9.2479669,3.82341566
              C9.2479669,3.82341566 8.8735225,7.14674899 8.6668558,7.08563788 C8.4601891,7.02452677 8.2735225,3.09452677 8.2735225,3.09452677
              C8.2735225,3.09452677 8.2046336,2.48563788 7.55574468,2.44563788 Z"
          id="path-2"
        ></path>
      </g>
      <g id="50%" opacity="0.5" fill="#141497" fillRule="nonzero">
        <path
          d="M12.7655556,0 C13.6466667,0 14.4922222,0.35 15.1144444,0.973333333 L15.1144444,0.973333333 L19.0266667,4.88444444 C19.6488889,5.50777778
              20,6.35333333 20,7.23444444 L20,7.23444444 L20,12.7655556 C20,13.6466667 19.6488889,14.4922222 19.0266667,15.1155556 L19.0266667,15.1155556
              L15.1144444,19.0266667 C14.4922222,19.65 13.6466667,20 12.7655556,20 L12.7655556,20 L7.23333333,20 C6.35333333,20 5.50777778,19.65 4.88444444,19.0266667
              L4.88444444,19.0266667 L0.973333333,15.1155556 C0.35,14.4922222 0,13.6466667 0,12.7655556 L0,12.7655556 L0,7.23444444 C0,6.35333333 0.35,5.50777778
              0.973333333,4.88444444 L0.973333333,4.88444444 L4.88444444,0.973333333 C5.50777778,0.35 6.35333333,0 7.23333333,0 L7.23333333,0 L12.7655556,0 Z M12.489,1
              L7.51,1 C6.718,1 5.957,1.315 5.396,1.876 L1.876,5.396 C1.315,5.957 1,6.718 1,7.511 L1,12.489 C1,13.282 1.315,14.043 1.876,14.604 L5.396,18.124
              C5.957,18.685 6.718,19 7.51,19 L12.489,19 C13.282,19 14.043,18.685 14.603,18.124 L18.124,14.604 C18.684,14.043 19,13.282 19,12.489 L19,7.511 C19,6.718
              18.684,5.957 18.124,5.396 L14.603,1.876 C14.043,1.315 13.282,1 12.489,1 Z"
          id="path-4"
        ></path>
      </g>
    </g>
  </svg>
);

const DisabledInactiveSvg = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Disabled-Inactive</title>
    <g id="Disabled-Inactive" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="100%" transform="translate(2.000000, 2.000000)" fill="#97A6C5" fillRule="nonzero">
        <path
          d="M10.2124444,0 C10.9173333,0 11.5937778,0.28 12.0915556,0.77866667 L12.0915556,0.77866667 L15.2213333,3.90755556
              C15.7191111,4.40622222 16,5.08266667 16,5.78755556 L16,5.78755556 L16,10.2124444 C16,10.9173333 15.7191111,11.5937778
              15.2213333,12.0924444 L15.2213333,12.0924444 L12.0915556,15.2213333 C11.5937778,15.72 10.9173333,16 10.2124444,16
              L10.2124444,16 L5.78666667,16 C5.08266667,16 4.40622222,15.72 3.90755556,15.2213333 L3.90755556,15.2213333 L0.77866667,12.0924444
              C0.28,11.5937778 0,10.9173333 0,10.2124444 L0,10.2124444 L0,5.78755556 C0,5.08266667 0.28,4.40622222 0.77866667,3.90755556
              L0.77866667,3.90755556 L3.90755556,0.77866667 C4.40622222,0.28 5.08266667,0 5.78666667,0 L5.78666667,0 L10.2124444,0 Z M7.55574468,2.44563788
              C6.90685579,2.40674899 6.86796691,3.33119344 6.86796691,3.33119344 C6.86796691,3.33119344 7.28130024,7.30341566 7.08463357,7.24563788
              C6.83130024,7.16897121 6.00241135,4.15674899 6.00241135,4.15674899 L6.00241135,4.15674899 L5.99062666,4.11608002 C5.9390632,3.94944035
              5.71507802,3.33474899 5.25574468,3.4878601 C4.72463357,3.66563788 4.88130024,4.62897121 4.88130024,4.62897121 C4.88130024,4.62897121
              5.80574468,7.75674899 5.58907802,7.8978601 C5.37352246,8.0378601 4.64574468,6.12341566 4.64574468,6.12341566 C4.64574468,6.12341566
              4.38907802,5.67119344 3.89796691,5.89563788 C3.40685579,6.12008232 3.58130024,6.77341566 3.60241135,6.95008232 C3.62167061,7.1014815
              3.93714996,8.0328411 4.27674668,9.0325268 L4.43420528,9.4961805 C4.74858419,10.4228258 5.04784345,11.3144033 5.11796691,11.6111934
              C5.27463357,12.2800823 6.63130024,13.3834157 7.75352246,13.3834157 C9.3857447,13.3834157 9.5224114,12.9689712 9.919078,12.7523045
              C10.3146336,12.5356379 10.8013002,11.6111934 10.8013002,11.6111934 C10.8013002,11.6111934 12.6301891,8.3856379 12.9057447,7.61897121
              C13.1813002,6.85230455 12.3546336,6.36008232 11.9624114,6.65452677 C11.5679669,6.95008232 11.0568558,7.8978601 11.0568558,7.8978601
              C10.6246336,8.7856379 10.2901891,8.4534157 10.2113002,8.2678601 C10.1324114,8.0823045 10.4668558,4.33341566 10.4868558,4.03897121
              C10.5068558,3.74452677 10.4868558,3.29119344 9.919078,3.23230455 C9.3513002,3.17341566 9.2479669,3.82341566 9.2479669,3.82341566
              C9.2479669,3.82341566 8.8735225,7.14674899 8.6668558,7.08563788 C8.4601891,7.02452677 8.2735225,3.09452677 8.2735225,3.09452677
              C8.2735225,3.09452677 8.2046336,2.48563788 7.55574468,2.44563788 Z"
          id="path-2"
        ></path>
      </g>
      <g id="50%" opacity="0.5" fill="#97A6C5" fillRule="nonzero">
        <path
          d="M12.7655556,0 C13.6466667,0 14.4922222,0.35 15.1144444,0.973333333 L15.1144444,0.973333333 L19.0266667,4.88444444 C19.6488889,5.50777778
              20,6.35333333 20,7.23444444 L20,7.23444444 L20,12.7655556 C20,13.6466667 19.6488889,14.4922222 19.0266667,15.1155556 L19.0266667,15.1155556
              L15.1144444,19.0266667 C14.4922222,19.65 13.6466667,20 12.7655556,20 L12.7655556,20 L7.23333333,20 C6.35333333,20 5.50777778,19.65 4.88444444,19.0266667
              L4.88444444,19.0266667 L0.973333333,15.1155556 C0.35,14.4922222 0,13.6466667 0,12.7655556 L0,12.7655556 L0,7.23444444 C0,6.35333333 0.35,5.50777778
              0.973333333,4.88444444 L0.973333333,4.88444444 L4.88444444,0.973333333 C5.50777778,0.35 6.35333333,0 7.23333333,0 L7.23333333,0 L12.7655556,0 Z M12.489,1
              L7.51,1 C6.718,1 5.957,1.315 5.396,1.876 L1.876,5.396 C1.315,5.957 1,6.718 1,7.511 L1,12.489 C1,13.282 1.315,14.043 1.876,14.604 L5.396,18.124
              C5.957,18.685 6.718,19 7.51,19 L12.489,19 C13.282,19 14.043,18.685 14.603,18.124 L18.124,14.604 C18.684,14.043 19,13.282 19,12.489 L19,7.511 C19,6.718
              18.684,5.957 18.124,5.396 L14.603,1.876 C14.043,1.315 13.282,1 12.489,1 Z"
          id="path-4"
        ></path>
      </g>
    </g>
  </svg>
);

const DisabledIcon = ({ inactive = false, ...props }) => {
  const className = 'disable-action';
  const title = 'disable';

  if (inactive) return <Icon component={DisabledInactiveSvg} title={title} className={className} disabled {...props} />;
  return <Icon component={DisabledActiveSvg} title={title} className={className} {...props} />;
};

export default DisabledIcon;
