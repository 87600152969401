import React from 'react';
import Icon from '@ant-design/icons';

export const PencilActiveSvg = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Edit-Active</title>
    <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="savedImportTable_addStudents1" transform="translate(-1272.000000, -355.000000)">
        <rect x="0" y="0" width="1445" height="781"></rect>
        <rect id="Rectangle" x="50" y="205" width="1360" height="528"></rect>
        <g id="Table" transform="translate(75.000000, 262.000000)">
          <g id="Table/cell/short/basic" transform="translate(1205.000000, 83.000000)">
            <rect id="Cell-Background" x="0" y="0.975609756" width="94" height="39.0243902"></rect>
          </g>
          <g id="actions-col" transform="translate(1177.000000, 1.000000)">
            <g id="Table/cell/short/basic" transform="translate(0.000000, 80.000000)"></g>
            <g id="action-icons" transform="translate(19.000000, 52.000000)" fill="#141497">
              <g id="Spectrum/16/Draw_18_N" transform="translate(1.000000, 40.000000)">
                <path
                  d="M9.09066667,3.55555554 L12.4444445,6.90844444 L5.61155555,13.7404445 C5.56029525,13.7922748 5.49689203,13.8304695 5.4271111,13.8515556 C4.78533332,14.0444445 1.41155553,15.0577778 1.28888886,15.0862223 C1.27981574,15.0880289 1.27058448,15.0888898 1.26133331,15.0888898 C1.1173333,15.0888898 0.862666637,14.8782223 0.913333304,14.7111111 L0.913333304,14.7111111 L2.14799998,10.5746667 C2.16894868,10.503947 2.20730464,10.4396129 2.25955553,10.3875556 L2.25955553,10.3875556 L9.09066667,3.55555554 Z M2.96888887,10.9368889 L2.07599998,13.928 C2.90577776,13.6804445 4.10622221,13.3177778 5.06666665,13.0293334 L5.06666665,13.0293334 L2.96888887,10.9368889 Z M11.9706667,0.923982763 C12.1147565,0.922850444 12.2533296,0.97933579 12.3555556,1.08088886 L12.3555556,1.08088886 L14.9186667,3.64444443 C15.0283767,3.75579746 15.0848833,3.90896434 15.0737778,4.06488887 C15.0642453,4.21297777 15.0010378,4.35250838 14.896,4.45733332 L14.896,4.45733332 L13.0737778,6.27955555 L9.72133334,2.92711109 L11.5435556,1.10488886 C11.6528071,0.994274776 11.8001839,0.92956886 11.9555556,0.923982763 L11.9555556,0.923982763 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const PencilInactiveSvg = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Edit-Inactive</title>
    <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="savedImportTable_addStudents1" transform="translate(-1272.000000, -355.000000)">
        <rect x="0" y="0" width="1445" height="781"></rect>
        <rect id="Rectangle" x="50" y="205" width="1360" height="528"></rect>
        <g id="Table" transform="translate(75.000000, 262.000000)">
          <g id="Table/cell/short/basic" transform="translate(1205.000000, 83.000000)">
            <rect id="Cell-Background" x="0" y="0.975609756" width="94" height="39.0243902"></rect>
          </g>
          <g id="actions-col" transform="translate(1177.000000, 1.000000)">
            <g id="Table/cell/short/basic" transform="translate(0.000000, 80.000000)"></g>
            <g id="action-icons" transform="translate(19.000000, 52.000000)" fill="#97A6C5">
              <g id="Spectrum/16/Draw_18_N" transform="translate(1.000000, 40.000000)">
                <path
                  d="M9.09066667,3.55555554 L12.4444445,6.90844444 L5.61155555,13.7404445 C5.56029525,13.7922748 5.49689203,13.8304695 5.4271111,13.8515556 C4.78533332,14.0444445 1.41155553,15.0577778 1.28888886,15.0862223 C1.27981574,15.0880289 1.27058448,15.0888898 1.26133331,15.0888898 C1.1173333,15.0888898 0.862666637,14.8782223 0.913333304,14.7111111 L0.913333304,14.7111111 L2.14799998,10.5746667 C2.16894868,10.503947 2.20730464,10.4396129 2.25955553,10.3875556 L2.25955553,10.3875556 L9.09066667,3.55555554 Z M2.96888887,10.9368889 L2.07599998,13.928 C2.90577776,13.6804445 4.10622221,13.3177778 5.06666665,13.0293334 L5.06666665,13.0293334 L2.96888887,10.9368889 Z M11.9706667,0.923982763 C12.1147565,0.922850444 12.2533296,0.97933579 12.3555556,1.08088886 L12.3555556,1.08088886 L14.9186667,3.64444443 C15.0283767,3.75579746 15.0848833,3.90896434 15.0737778,4.06488887 C15.0642453,4.21297777 15.0010378,4.35250838 14.896,4.45733332 L14.896,4.45733332 L13.0737778,6.27955555 L9.72133334,2.92711109 L11.5435556,1.10488886 C11.6528071,0.994274776 11.8001839,0.92956886 11.9555556,0.923982763 L11.9555556,0.923982763 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const PencilInactiveEditSvg = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Edit-Inactive</title>
    <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="editSubjectArea_inactive" transform="translate(-1272.000000, -355.000000)">
        <g id="Table" transform="translate(75.000000, 262.000000)">
          <g id="actions-col" transform="translate(1177.000000, 1.000000)">
            <g id="action-icons" transform="translate(19.000000, 52.000000)" fill="#BEBEBE">
              <g id="Spectrum/16/Draw_18_N" transform="translate(1.000000, 40.000000)">
                <path
                  d="M9.09066667,3.55555554 L12.4444445,6.90844444 L5.61155555,13.7404445 C5.56029525,13.7922748 5.49689203,13.8304695 5.4271111,13.8515556 C4.78533332,14.0444445 1.41155553,15.0577778 1.28888886,15.0862223 C1.27981574,15.0880289 1.27058448,15.0888898 1.26133331,15.0888898 C1.1173333,15.0888898 0.862666637,14.8782223 0.913333304,14.7111111 L0.913333304,14.7111111 L2.14799998,10.5746667 C2.16894868,10.503947 2.20730464,10.4396129 2.25955553,10.3875556 L2.25955553,10.3875556 L9.09066667,3.55555554 Z M2.96888887,10.9368889 L2.07599998,13.928 C2.90577776,13.6804445 4.10622221,13.3177778 5.06666665,13.0293334 L5.06666665,13.0293334 L2.96888887,10.9368889 Z M11.9706667,0.923982763 C12.1147565,0.922850444 12.2533296,0.97933579 12.3555556,1.08088886 L12.3555556,1.08088886 L14.9186667,3.64444443 C15.0283767,3.75579746 15.0848833,3.90896434 15.0737778,4.06488887 C15.0642453,4.21297777 15.0010378,4.35250838 14.896,4.45733332 L14.896,4.45733332 L13.0737778,6.27955555 L9.72133334,2.92711109 L11.5435556,1.10488886 C11.6528071,0.994274776 11.8001839,0.92956886 11.9555556,0.923982763 L11.9555556,0.923982763 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const PencilEditSvg = () => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>Edit-Active</title>
    <g id="-" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="editSubjectArea_active" transform="translate(-1272.000000, -355.000000)">
        <rect x="0" y="0" width="1445" height="781"></rect>
        <rect id="Rectangle" x="50" y="205" width="1360" height="528"></rect>
        <g id="Table" transform="translate(75.000000, 262.000000)">
          <g id="Table/cell/short/basic" transform="translate(1205.000000, 83.000000)">
            <rect id="Cell-Background" x="0" y="0.975609756" width="94" height="39.0243902"></rect>
          </g>
          <g id="actions-col" transform="translate(1177.000000, 1.000000)">
            <g id="Table/cell/short/basic" transform="translate(0.000000, 80.000000)"></g>
            <g id="action-icons" transform="translate(19.000000, 52.000000)" fill="black">
              <g id="Spectrum/16/Draw_18_N" transform="translate(1.000000, 40.000000)">
                <path
                  d="M9.09066667,3.55555554 L12.4444445,6.90844444 L5.61155555,13.7404445 C5.56029525,13.7922748 5.49689203,13.8304695 5.4271111,13.8515556 C4.78533332,14.0444445 1.41155553,15.0577778 1.28888886,15.0862223 C1.27981574,15.0880289 1.27058448,15.0888898 1.26133331,15.0888898 C1.1173333,15.0888898 0.862666637,14.8782223 0.913333304,14.7111111 L0.913333304,14.7111111 L2.14799998,10.5746667 C2.16894868,10.503947 2.20730464,10.4396129 2.25955553,10.3875556 L2.25955553,10.3875556 L9.09066667,3.55555554 Z M2.96888887,10.9368889 L2.07599998,13.928 C2.90577776,13.6804445 4.10622221,13.3177778 5.06666665,13.0293334 L5.06666665,13.0293334 L2.96888887,10.9368889 Z M11.9706667,0.923982763 C12.1147565,0.922850444 12.2533296,0.97933579 12.3555556,1.08088886 L12.3555556,1.08088886 L14.9186667,3.64444443 C15.0283767,3.75579746 15.0848833,3.90896434 15.0737778,4.06488887 C15.0642453,4.21297777 15.0010378,4.35250838 14.896,4.45733332 L14.896,4.45733332 L13.0737778,6.27955555 L9.72133334,2.92711109 L11.5435556,1.10488886 C11.6528071,0.994274776 11.8001839,0.92956886 11.9555556,0.923982763 L11.9555556,0.923982763 Z"
                  id="Combined-Shape"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const PencilIcon = ({ inactive = false, editSubjectArea = false, ...props }) => {
  const className = 'edit-action';
  const title = 'edit';

  if (editSubjectArea && inactive) return <Icon component={PencilInactiveEditSvg} {...props} />;
  if (editSubjectArea) return <Icon component={PencilEditSvg} {...props} />;
  if (inactive) return <Icon component={PencilInactiveSvg} title={title} className={className} disabled {...props} />;
  return <Icon component={PencilActiveSvg} title={title} className={className} {...props} />;
};

export default PencilIcon;
