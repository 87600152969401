import { Col, Dropdown, Row, Space, Table, TablePaginationConfig } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import GearIcon from '../../../assets/Icon/GearIcon';
import MoreVerticalIcon from '../../../assets/Icon/MoreVerticalICon';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import MainTemplate from '../Shell/MainTemplate';
import { getIngressType } from '../../utils/utils';
import { IntegrationContext } from './../../utils/context';
import { featureFlags } from '../../utils/featureFlags';
import JobIconAndStatus from '../../components-v2/JobIconAndStatus/index'
import UserMenu from '../../components-v2/UserMenu';
import UndoModal from '../../components-v2/UndoModal';
import RowAlerts from '../../components-v2/RowAlerts';

const DataImport: React.FC = () => {
  const history = useHistory();
  const [importRecords, setImportRecords] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [undoModal, setUndoModal] = React.useState('');
  const [undoType, setUndoType] = React.useState('');

  const [totalRecords, setTotalRecords] = React.useState(0);
  const integrationContext = React.useContext(IntegrationContext);

  const continueJob = async (jobGuid, dataType) => {
    try {
      const type = getIngressType(dataType);
      const { data } = await apiClient.post(`/data-ingest/sis/jobs/${jobGuid}/reconcile`, {
        reconciled: {},
        ingressType: type,
        isTestImport: true,
        author: integrationContext.userFullName,
      });
      const { started, error } = data;
      if (started) {
        showNotification(
          NotificationTypes.success,
          'Import in Progress',
          'Large imports may take a few minutes to complete.',
        );
        await fetchJobs();
      }
      if (error) {
        showNotification(NotificationTypes.error, 'Error Continuing Job', `${error}`);
      }
    } catch (error) {
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
    }
  };

  const reStartImport = async (jobGuid, dataType) => {
    try {
      const import_type = getIngressType(dataType);
      const { data } = await apiClient.get(`/data-ingest/ingress/${import_type}`);
      if (import_type.includes('course-')) {
        await apiClient.post('/data-ingest/sis/start', {
          type: import_type,
          isTestImport: false,
          cron: data?.cron,
          author: integrationContext.userFullName,
        });
        showNotification(
          NotificationTypes.success,
          'Import is in progress.',
          'Large import may take a few minutes to complete.',
        );
        await fetchJobs();
      } else {
        continueJob(jobGuid, dataType);
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Starting Job', 'Server Error');
    }
  };

  const fetchJobs = async (page = 1) => {
    setLoading(true);
    try {
      const isValidImportFeature = featureFlags['feature.dataIngest.cplan.removeBaseJobs'];
      const { data } = await apiClient.get(
        `/data-ingest/jobs?page=${page}&removeBaseJobs=${isValidImportFeature}&isCleverIntegration=${integrationContext.isCleverIntegration}`,
      );

      setImportRecords(data?.jobs || []);
      setTotalRecords(data?.meta.totalJobs || 0);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Jobs', 'Failure in getting data from server.');
    } finally {
      setLoading(false);
    }
  };

  const methodColumn = {
    title: 'Method',
    dataIndex: 'method',
    key: 'method',
    render: (method) => <span data-test-id="method">{method}</span>,
  };

  const sourceColumnHeader = integrationContext.isCleverIntegration ? 'User/Source' : 'Source';

  const DataImportTableColumns = [
    {
      title: 'Data type',
      dataIndex: 'dataType',
      key: 'dataType',
      width: '18%',
      render: (dataType) => (
        <span title={dataType} data-test-id="data_type">
          {dataType}
        </span>
      ),
    },
    {
      title: 'Start time',
      dataIndex: 'start',
      key: 'start',
      width: '14%',
      render: (date) => <span data-test-id="start_date">{new Date(date).toLocaleString()}</span>,
    },
    {
      title: 'End time',
      dataIndex: 'end',
      key: 'end',
      width: '14%',
      render: (date) => <span data-test-id="end_date">{date ? new Date(date).toLocaleString() : ''}</span>,
    },
    {
      title: sourceColumnHeader,
      dataIndex: 'sis_source',
      key: 'sis_source',
      render: (row, dataType) => {
        if (dataType.dataType === 'Course Request') {
          return <span data-test-id="data_type">{'Naviance'}</span>;
        } else {
          return (
            <span data-test-id="data_type">
              {row === undefined || row === null ? 'PowerSchool SIS' : row === 'clever' ? 'Clever' : row}
            </span>
          );
        }
      },
    },
    {
      title: '# of Rows',
      dataIndex: 'noOfRows',
      key: 'noOfRows',
      render: (rows) => <span data-test-id="num_of_rows">{rows === -1 ? '-' : rows}</span>,
    },
    {
      title: 'Status',
      width: '20%',
      render: (row) => (
        <span data-test-id="status" className="status">
          <JobIconAndStatus row={row} />
        </span>
      ),
    },
    {
      title: 'Alerts',
      width: '10%',
      render: (row) => <RowAlerts row={row} />,
    },
    {
      title: 'More',
      render: (row) => (
        <Dropdown
          overlay={<UserMenu row={row} reStartImport={reStartImport} setUndoModal={setUndoModal} setUndoType={setUndoType} />}
        >
          <a href="#" onClick={(e) => e.preventDefault()} data-test-id={`${row.importDataGuid}-more_btn`}>
            <MoreVerticalIcon />
          </a>
        </Dropdown>
      ),
    },
  ];

  const pagination = {
    total: totalRecords,
    showSizeChanger: false,
    onChange: async (page) => await fetchJobs(page),
  } as TablePaginationConfig;

  React.useEffect(() => {
    setLoading(true);
    if (Object.keys(featureFlags).length > 0) void fetchJobs();
  }, [featureFlags]);

  const configureBtn = (
    <Space>
      <button className="button" onClick={() => history.push('/configure')} data-test-id="configuration_btn">
        Configure <GearIcon />
      </button>
    </Space>
  );

  if (integrationContext.isCleverIntegration) {
    DataImportTableColumns.splice(4, 0, methodColumn);
  }

  return (
    <>
      <MainTemplate
        title={`${integrationContext.isCleverIntegration ? 'Clever data' : 'PowerSchool Data'} integration`}
        titleTestId="data_import_title"
        subheaderRSContent={configureBtn}
      >
        <>
          {integrationContext.isCleverIntegration && (
            <Row style={{ marginBottom: '1rem', fontSize: '1rem' }}>
              <Col span={24}>
                <span>Data integration between Naviance and Clever. The Clever Integration page shows the Sync Information for 30 days.</span>
              </Col>
            </Row>
          )}
          <Row>
            <Col span={24}>
              <div className="mainDataImportTable sisImportTable">
                <Table
                  rowKey="id"
                  loading={loading}
                  columns={DataImportTableColumns}
                  dataSource={importRecords}
                  pagination={pagination}
                  locale={{
                    emptyText: (
                      <div style={{ margin: '100px 20px' }}>
                        <h1>Data Not Connected</h1>
                        {!integrationContext.isCleverIntegration
                          ? <>
                            <p>You have not integrated your SIS.</p>
                            <p>
                              Click <b>Configure</b> to complete your SIS Integration Setup.
                            </p>
                          </>
                          : <>
                            <p>In Clever, you will need to Request App for Naviance.</p>
                          </>
                        }
                        <button
                          data-test-id="no_data_configure_btn"
                          className="button button-blue"
                          onClick={() => history.push('/configure')}
                        >
                          Configure
                        </button>
                      </div>
                    ),
                  }}
                ></Table>
              </div>
            </Col>
          </Row>
        </>
      </MainTemplate>
      <UndoModal undoModal={undoModal} setUndoModal={setUndoModal} undoType={undoType} setUndoType={setUndoType} />
    </>
  );
};

export default DataImport;
