/* eslint-disable react-hooks/exhaustive-deps */
import { Space, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import CloseIcon from '../../../assets/Icon/CloseIcon';
import Col from '../../components-v2/Col';
import Divider from '../../components-v2/Divider';
import Row from '../../components-v2/Row';
import Checkbox from '../../components-v2/Checkbox';
import Select from '../../components-v2/Select';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { GET_COURSEPLANNER_CHANNEL_ENDPOINT, CoursePlannerImportData } from '../../utils/constants';
import { getMatchFieldsTitle } from '../../utils/utils';
import { featureFlags } from '../../utils/featureFlags';

export interface IMeta {
  latestHeaders?: string[];
  importType?: string;
  importName?: string;
}
export interface IFieldMapping {
  field?: string;
  mapping?: string;
}

export interface IProps {
  importData: CoursePlannerImportData;
  handleCancel: () => void;
  handleChange: (key, value) => void;

  runJob: (mapping) => void;
}
export interface INavField {
  name: string;

  key: string;
}

const MatchFieldsComponent = (props: IProps): React.ReactElement => {
  const { Option } = Select;
  const [meta, setMeta] = React.useState<IMeta>({ latestHeaders: [] });
  const [requiredFieldsData, setRequiredFieldsData] = React.useState<string[]>([]);
  const [fieldMapping, setFieldMapping] = React.useState<IFieldMapping[]>([]);
  const [navianceMatchingFields, setNavianceMatchingFields] = React.useState<INavField[]>(null);
  const [requiredFieldsMissing, setRequiredFieldsMissing] = React.useState(0);
  const [unmatchedFields, setUnmatchedFields] = React.useState(0);
  const [allSelects, setAllSelects] = React.useState(null);
  const [indexMappings, setIndexMappings] = React.useState({});
  const latestHeaders = props.importData.fileHeaders;
  React.useEffect(() => {
    localStorage.removeItem('latestHeaders');
    void fetchChannelConfigs();
  }, []);
  React.useEffect(() => {
    const enteredData = [];
    fieldMapping.some((mapping) => {
      if (requiredFieldsData.includes(mapping.mapping)) {
        enteredData.push(mapping.field);
      }
      return null;
    });
    setRequiredFieldsMissing(requiredFieldsData.length - enteredData.length);
    setUnmatchedFields(meta?.latestHeaders?.length - fieldMapping.length);
  }, [fieldMapping, allSelects]);

  React.useEffect(() => {
    console.log('All selects obj updated', allSelects);
  }, [allSelects]);

  React.useEffect(() => {
    if (navianceMatchingFields && navianceMatchingFields.length) {
      columnMapping();
    }
  }, [meta, navianceMatchingFields, indexMappings]);

  const columnMapping = () => {
    if (indexMappings) {
      const selectValue = {};
      const mappingObjectArray = [];
      latestHeaders.forEach((header, index) => {
        if (indexMappings[index + 1]) {
          selectValue[header] = indexMappings[index + 1];
          mappingObjectArray.push({ field: header, mapping: indexMappings[index + 1] });
        }
      });
      setAllSelects({ ...allSelects, ...selectValue });
      setFieldMapping(mappingObjectArray);
    }
  };

  const fetchChannelConfigs = async () => {
    try {
      const importType = props.importData.importType;
      const channel = await apiClient.get(`${GET_COURSEPLANNER_CHANNEL_ENDPOINT}${importType}`);
      const columns = channel.data.columns;
      const keys = [];
      const allKeys = [];

      /* istanbul ignore next */
      if (!featureFlags['feature.dataIngest.cplan.courseCatalogEquivalent'] && importType === "course-catalog") {
        const equivalenceExist = columns.findIndex(a => a.name === "Equivalent");
        if (equivalenceExist > -1) {
          columns.splice(equivalenceExist , 1)
        }
      }

      columns.forEach((column) => {
        // filter out credits earned, this needs to be revised at a future date
        if (column.required === true && column.key !== 'Credits_Earned') {
          keys.push(column.key).toString();
        }
        if (column.key === 'Credits_Earned') {
          column.name = column.name.replace(' (required)', '');
        }
        allKeys.push({ name: column.name, key: column.key });
      });
      const latestHeadersObj = {};
      latestHeaders.map((e) => {
        latestHeadersObj[`${e}`] = '';
      });
      setAllSelects(latestHeadersObj);
      setMeta({ latestHeaders });
      setIndexMappings(channel.data.mapping);
      setRequiredFieldsData(keys);
      setNavianceMatchingFields(
        allKeys.sort((opt1, opt2) =>
          opt1.name.localeCompare(opt2.name, undefined, { numeric: true, sensitivity: 'base' }),
        ),
      );
      setRequiredFieldsMissing(keys.length);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Failed to get mapping data', 'Service Failure: Failed to get data');
    }
  };

  const clearFieldsHandler = () => {
    setFieldMapping([]);
    const placeholderObj = {};
    for (const prop in allSelects) {
      placeholderObj[prop] = '';
    }
    setAllSelects(placeholderObj);
  };

  const continueBtn = async () => {
    const enteredData = [];
    if (requiredFieldsData.length === 0 && fieldMapping.length === 0) {
      showNotification(
        NotificationTypes.warning,
        'Provide Required Fields',
        'Please wait while all required fields are fetched from server',
      );
      return;
    }
    fieldMapping.some((mapping) => {
      if (requiredFieldsData.includes(mapping.mapping)) {
        enteredData.push(mapping.field);
      }
      return null;
    });
    if (enteredData.length !== requiredFieldsData.length) {
      showNotification(
        NotificationTypes.warning,
        'Provide Required Fields',
        'Please provide mapping for all required fields',
      );
      return;
    } else {
      const placeholder = {};
      let counter = 1;
      for (const [key] of Object.entries(allSelects)) {
        fieldMapping.forEach((field) => {
          if (key === field.field) {
            placeholder[counter] = field.mapping;
          }
        });
        counter++;
      }
      props.runJob(placeholder);
    }
  };

  const renderTable = () => {
    if (meta.latestHeaders.length === 0) {
      return (
        <Col span={24}>
          <div className="spinner">
            <Spin size="large" />
          </div>
        </Col>
      );
    } else {
      const mappings: string[] = meta.latestHeaders;
      return (
        <Col span={24}>
          <table style={{ paddingBottom: '20px' }}>
            <thead>
              <tr>
                <th style={{ textAlign: 'left' }}>Field</th>
                <th style={{ textAlign: 'left' }}>Your Data Field</th>
                <th style={{ textAlign: 'left' }}>Matching Naviance Field</th>
                <th className="clearField">
                  <span onClick={clearFieldsHandler}>Clear Fields</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {mappings.map((fieldType, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{fieldType}</td>
                    <td colSpan={2}>
                      <Select
                        placeholder="Select Mapping"
                        className="select"
                        showSearch={true}
                        value={allSelects[fieldType]}
                        defaultValue={''}
                        onChange={(value) => {
                          fieldMapping.map((e) => {
                            if (e.mapping === value) {
                              value = '';
                              return;
                            }
                          });
                          setAllSelects({ ...allSelects, [fieldType]: value });
                          if (value === '') {
                            const mappingsArr = fieldMapping.filter((mapping) => mapping.field !== fieldType);
                            setFieldMapping([...mappingsArr]);
                            return;
                          }
                          const mappingsArr = fieldMapping.filter((mapping) => mapping.field !== fieldType);
                          setFieldMapping([...mappingsArr, { field: fieldType, mapping: value }]);
                        }}
                      >
                        {navianceMatchingFields &&
                          navianceMatchingFields.map((matchingField, keyForOptions) => {
                            if (requiredFieldsData.includes(matchingField.key)) {
                              return (
                                <Option
                                  data-ingest-uion
                                  key={'options' + keyForOptions}
                                  value={matchingField.key}
                                  className="selectOptions"
                                >
                                  {matchingField.name}
                                </Option>
                              );
                            } else {
                              return (
                                <Option
                                  data-ingest-uion
                                  key={'options' + keyForOptions}
                                  value={matchingField.key}
                                  className="selectOptions"
                                >
                                  {matchingField.name}
                                </Option>
                              );
                            }
                          })}
                      </Select>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </Col>
      );
    }
  };

  return (
    <div className="new-data-import mainMatchFieldsSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleStudent" data-cy="data-import-heading" level={1}>
              {getMatchFieldsTitle(props.importData.importType)}
            </Title>
            <Title className="titleField" data-cy="data-import-heading" level={4}>
              Match Fields
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              <button className="button" onClick={() => props.handleCancel()}>
                Cancel
              </button>
              <button className="continue-btn" disabled={props.importData.loading} onClick={continueBtn}>
                Continue
              </button>
            </Space>
          </Col>
        </Row>
        <Divider />
      </div>
      <div className="mainWhitebgWrapper">
        <div className="imprNameSection">
          <Row>
            <Col span={24}>
              <Title className="titleMatchField" data-cy="data-import-heading" level={3}>
                Matching Fields
              </Title>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <ul className="listFields">
                <li>Naviance data fields must align with fields in your file.</li>
                <li>Examples of data used in each field in your file is provided.</li>
              </ul>
            </Col>
          </Row>
          <Row>
            <Col span={17}>
              <Row justify="space-between">
                <Col span="auto">
                  <span className="reqField">
                    <Checkbox
                      checked={props.importData.saveSettings}
                      className="checkbox"
                      onChange={(e) => {
                        props.handleChange('saveSettings', e.target.checked);
                      }}
                    >
                      Save settings for future imports
                    </Checkbox>
                  </span>
                </Col>
                <Row justify="end" gutter={30}>
                  <Col span="auto">
                    <span className="reqField">
                      <strong>{requiredFieldsMissing}</strong> Required Fields Missing
                    </span>
                  </Col>
                  <Col span="auto">
                    <span className="matField">
                      <strong>{fieldMapping.length}</strong> Fields Matched
                    </span>
                  </Col>
                  <Col span="auto">
                    <span className="ummField">
                      <CloseIcon />
                      <strong>{unmatchedFields}</strong> Fields Unmatched
                    </span>
                  </Col>
                </Row>
              </Row>
              <Row>{renderTable()}</Row>
            </Col>
          </Row>
          <Row>
            <Col span={17}>
              <span className="reviewNappingText">Please review your field mapping before pressing “Continue”.</span>
            </Col>
          </Row>
          <Row className="btnsSection">
            <Col span={24}>
              <Space>
                <button className="button" onClick={() => props.handleCancel()}>
                  Cancel
                </button>
                <button className="continue-btn" disabled={props.importData.loading} onClick={continueBtn}>
                  Continue
                </button>
              </Space>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default MatchFieldsComponent;
