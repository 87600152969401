import { Space, Input, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import GearIcon from '../../../../assets/Icon/GearIcon';
import Col from '../../../components-v2/Col';
import Divider from '../../../components-v2/Divider';
import Row from '../../../components-v2/Row';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';
import { GET_PARAMETERGROUP_ENDPOINT, GET_CHANNEL_ENDPOINT, ImportTypeParam } from '../../../utils/constants';
import { getTriggerJobData } from '../../../utils/utils';
import { DataSources, DateDataSources } from './GenerateDataSources';
export interface IDefineCodesProps {
  importType: string;
  isDistrict: boolean;
  onCancel: () => void;
}
interface ValueMap {
  toValue: string;
  fromValue: string;
}
export interface ITranslateStepParameters {
  valueMappings: Record<string, ValueMap[]>;
}
interface IRecord {
  key: number;
  code: string;
  possibleValue: string;
  table: string;
}

const DefineCodes: React.FC<IDefineCodesProps> = ({ importType, isDistrict, onCancel }): React.ReactElement => {
  const history = useHistory();
  const [translateStepParameters, setTranslateStepParameters] = React.useState<ITranslateStepParameters | null>(null);
  const [editingRowId, setEditingId] = React.useState(1);
  const [editingTable, setEditingTable] = React.useState<string | null>(null);
  const [recordCodeValues, setRecordCodeValues] = React.useState([]);
  const [dobFormat, setDobFormat] = React.useState('MM/DD/YYYY(10/15/20');
  const [Fields, setFields] = React.useState([]);
  const [dateFields, setDateFields] = React.useState([]);
  const [fieldsPossibleValues, setFieldsPossibleValues] = React.useState([]);
  const [validateStepParameters, setvalidateStepParameters] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const [childChannelGuid, setChannelGuid] = React.useState<string | null>(null);
  importType = importType ? importType : sessionStorage.getItem('importType');

  const getChannelData = async () => {
    try {
      const { data } = await apiClient.get(GET_CHANNEL_ENDPOINT, {
        params: {
          importType,
        },
      });
      await onParentChannelFetch(data);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Failed to get parent channel', 'Service Failure');
    }
  };

  React.useEffect(() => {
    void getChannelData();
  }, []);
  const columns = [
    {
      key: 'possibleValue',
      title: 'Possible value',
      dataIndex: 'possibleValue',
    },
    {
      title: 'Enter Your Code',
      dataIndex: 'code',
      render: (text: string, record: IRecord) => (
        <Input
          className="text-input"
          placeholder={getCodeValueFromRecordKey(record)}
          type="text"
          onChange={(e) => handleCodeChange(e, record)}
        />
      ),
      key: 'code',
    },
  ];
  const onParentChannelFetch = async (pchannel) => {
    if (pchannel && pchannel.length === 0) {
      showNotification(NotificationTypes.error, 'can not find parent channel', 'Service error');
    }
    if (pchannel[0]) {
      await loadValidateConfigFromParameterGroup(pchannel[0].config.steps.validate.parameterGroupNames[0]);
      await loadTranslateStepParametersFromChildConfig(importType);
    }
  };
  const loadTranslateStepParametersFromChildConfig = async (importTypeVal: string) => {
    try {
      const childChannel = await apiClient.get(GET_CHANNEL_ENDPOINT, {
        params: {
          name: importTypeVal,
        },
      });
      setChannelGuid(childChannel.data[0].config.guid);
      setTranslateStepParameters(childChannel.data[0].config.steps.translate.parameters.translateConfig ?? null);
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting data from server',
        'translate parameters from child config not received',
      );
    }
  };
  const loadValidateConfigFromParameterGroup = async (parameterGroupName: string) => {
    try {
      const { data } = await apiClient.get(GET_PARAMETERGROUP_ENDPOINT, {
        params: {
          parameterGroupName,
        },
      });
      const validateConfig = (data.details.body.validateConfig?.columns ?? data.details.body) || {}
      setvalidateStepParameters(validateConfig);
      setFields(extractFieldsFromParameterGroup(validateConfig));
      const fieldspossibleValues = generateFieldsPossibleValuesList(validateConfig);

      const shouldSkip = isValidValues(validateConfig);

      if (shouldSkip === null) {
        await triggerJob();
      }
      setFieldsPossibleValues(fieldspossibleValues);
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting data from server',
        'Parameter group from validate not received',
      );
    } finally {
      setLoading(false);
    }
  };

  function isValidValues(data) {
    let doesNotExists = true;
    for (const key in data) {
      const obj = data[key];
      if (obj.validValues) {
        doesNotExists = false;
        continue;
      }
    }
    return doesNotExists;
  }

  function extractFieldsFromParameterGroup(validateConfig: any) {
    for (const prop in validateConfig) {
      if (validateConfig[prop].validValues != null) {
        Fields.push(prop);
      }
      if (validateConfig[prop].type === 'date') {
        dateFields.push(validateConfig[prop].name);
      }
    }
    return Fields;
  }
  function generateFieldsPossibleValuesList(validateConfig: any) {
    const possibleValues = Fields.map((field) => {
      return {
        field,
        possibleValues: getFieldPossibleValues(field, validateConfig),
      };
    });
    return possibleValues;
  }
  function getFieldPossibleValues(field, validateConfig) {
    if (validateConfig[field].validValuesDescription) {
      const sortedValidValuesDescription = Object.keys(validateConfig[field].validValuesDescription)
        .sort()
        .reduce((acc, value) => {
          acc[value] = validateConfig[field].validValuesDescription[value];
          return acc;
        }, {});
      const possibleValues = [];
      let index = 0;
      for (const [key, value] of Object.entries(sortedValidValuesDescription)) {
        if (!Array.isArray(value)) {
          possibleValues.push({
            key: index,
            possibleValue: `${value} (${key})`,
            code: '',
            table: field,
          });
          index++;
        } else {
          for (let i = 0; i < value.length; i++) {
            possibleValues.push({
              key: index,
              possibleValue: `${value[i]} (${key})`,
              code: '',
              table: field,
            });
            index++;
          }
        }
      }
      return possibleValues;
    } else {
      return validateConfig[field].validValues.map((pv, index) => {
        return {
          key: index,
          possibleValue: pv,
          code: '',
          table: field,
        };
      });
    }
  }
  const generateValueMappingsFromRecordedCodeValues = () => {
    const tableNames = new Set(recordCodeValues.map((record) => record.table));
    const columnsArray = Array.from(tableNames).map((table) => {
      return {
        [table]: recordCodeValues
          .filter((rcv) => rcv.table === table)
          .map((r) => {
            return { fromValue: r.possibleValue, toValue: r.codeValue };
          }),
      };
    });

    const valueMappings = translateStepParameters.valueMappings ?? {};

    columnsArray.forEach((col) => {
      for (const [key, value] of Object.entries(col)) {
        valueMappings[key] = value;
      }
    });
    return valueMappings;
  };
  const updateTranslateStepParameters = async () => {
    setLoading(true);
    recordCodeValues.forEach((recordedCodeValue) => {
      if (translateStepParameters.valueMappings) {
        if (translateStepParameters.valueMappings[recordedCodeValue.table]) {
          const existingValueMapIndex = translateStepParameters.valueMappings[recordedCodeValue.table].findIndex(
            (rcv) => rcv.fromValue === recordedCodeValue.possibleValue,
          );
          if (existingValueMapIndex !== -1) {
            // update existingValueMap to recordedCodeValue
            translateStepParameters.valueMappings[recordedCodeValue.table][existingValueMapIndex] = {
              fromValue: recordedCodeValue.possibleValue,
              toValue: recordedCodeValue.codeValue,
            };
          } else {
            //update translateStepParameter valueMapping
            translateStepParameters.valueMappings[recordedCodeValue.table].push({
              fromValue: recordedCodeValue.possibleValue,
              toValue: recordedCodeValue.codeValue,
            });
          }
        } else {
          const valueMappings = generateValueMappingsFromRecordedCodeValues();
          translateStepParameters.valueMappings = valueMappings;
        }
      } else {
        const valueMappings = generateValueMappingsFromRecordedCodeValues();
        translateStepParameters.valueMappings = valueMappings;
      }
    });

    try {
      const { data } = await apiClient.patch(`${GET_CHANNEL_ENDPOINT}/${childChannelGuid}?importType=${importType}`, {
        body: {
          step: 'translate',
          configName: 'translateConfig',
          updates: [
            {
              propertyName: 'valueMappings',
              value: translateStepParameters.valueMappings,
            },
          ],
        },
      });
      if (data === true) {
        showNotification(NotificationTypes.success, 'Success', 'codes updated successfully');
        await triggerJob();
      }
    } catch (error) {
      showNotification(NotificationTypes.error, 'Failure', 'codes updated failed');
      setLoading(false);
    }
  };

  const triggerJob = async () => {
    const jobRequestBody = getTriggerJobData();
    await apiClient.post('/data-ingest/jobs/create', jobRequestBody);
    showNotification(
      NotificationTypes.info,
      'Validation In Progress',
      'Job has been triggered and validation is in progress',
    );
    if (importType === ImportTypeParam.Scholarship) {
      window.location.href = `${window.location.origin}/${
        isDistrict ? 'district/' : ''
      }setupmain/dataimport/show_history.php`;
    } else {
      history.push('/scholarships');
    }
  };

  const getCodeValueFromRecordKey = (record: IRecord) => {
    const codeIndex = recordCodeValues.findIndex((obj) => obj.recordId === record.key && record.table === obj.table);
    let codeValue = recordCodeValues[codeIndex]?.codeValue;
    if (codeValue) {
      return codeValue;
    } else if (
      !codeValue &&
      translateStepParameters &&
      translateStepParameters.valueMappings &&
      importType !== ImportTypeParam.Scholarship
    ) {
      // check code value in translate step parameters
      if (translateStepParameters && translateStepParameters.valueMappings[record.table]) {
        translateStepParameters?.valueMappings[record.table].find((obj) => {
          if (obj.fromValue === record.possibleValue) {
            codeValue = obj.toValue;
          } else {
            const code = record.possibleValue.split('(')[1];
            if (obj.fromValue === code.slice(0, code.indexOf(')'))) {
              codeValue = obj.toValue;
            }
          }
        });
      }

      return codeValue;
    } else if (
      !codeValue &&
      translateStepParameters &&
      translateStepParameters.valueMappings &&
      importType === ImportTypeParam.Scholarship
    ) {
      codeValue = translateStepParameters?.valueMappings[record.table]?.find((obj) => {
        const code = record.possibleValue.split('(')[1];
        if (obj.fromValue === code.slice(0, code.indexOf(')'))) {
          return obj.toValue;
        }
      });
      return (
        codeValue?.toValue ??
        validateStepParameters[record.table].validValues.find((validValue) => {
          const splitValue = record.possibleValue.split('(');

          return (
            validValue === splitValue[splitValue.length - 1].slice(0, splitValue[splitValue.length - 1].indexOf(')`'))
          );
        })
      );
    }
  };
  const renderFieldsPossibleValues = () => {
    if (fieldsPossibleValues.length > 0) {
      return (
        <Col span={8}>
          <div className="mainDataTableSection">
            <DataSources
              fieldsPossibleValues={fieldsPossibleValues}
              setEditingId={setEditingId}
              setEditingTable={setEditingTable}
              columns={columns}
              loading={false}
            />
          </div>
        </Col>
      );
    } else {
      return (
        <Col span={8}>
          <div className="spinner">
            <Spin size="large" />
          </div>
        </Col>
      );
    }
  };

  const renderDateTables = () => {
    if (dateFields.length > 0) {
      return (
        <Col span={8}>
          <div className="mainDataTableSection">
            {dateFields.map((df, index) => (
              <DateDataSources key={index} onChangeDobFormat={onChangeDobFormat} dobFomrat={dobFormat} fieldName={df} />
            ))}
          </div>
        </Col>
      );
    }
  };

  const onChangeDobFormat = (value: string) => {
    setDobFormat(value);
  };
  const handleCodeChange = (e, record: IRecord) => {
    if (recordCodeValues.length === 0) {
      recordCodeValues.push({
        possibleValue:
          importType === ImportTypeParam.Scholarship
            ? record.possibleValue.split('(')[1].slice(0, record.possibleValue.split(')').indexOf(')'))
            : record.possibleValue,
        recordId: editingRowId,
        codeValue: e.target.value,
        table: editingTable,
      });
    } else {
      // check if the recordCodeValues already has the record
      const index = recordCodeValues.findIndex((rcv) => rcv.table === editingTable && rcv.recordId === editingRowId);
      if (index !== -1) {
        recordCodeValues[index] = { ...recordCodeValues[index], codeValue: e.target.value };
      } else {
        recordCodeValues.push({
          possibleValue:
            importType === ImportTypeParam.Scholarship
              ? record.possibleValue.split('(')[1].slice(0, record.possibleValue.split(')').indexOf(')'))
              : record.possibleValue,
          recordId: editingRowId,
          codeValue: e.target.value,
          table: editingTable,
        });
      }
    }
  };
  const renderImportHeading = () => {
    if (importType !== ImportTypeParam.Scholarship) {
      return (
        <React.Fragment>
          <Col span={24}>
            <Title className="titleImprName" data-cy="data-import-heading" level={3}>
              Import Name
            </Title>
          </Col>
          <Col span={24}>
            <Title className="titleImpralumni" data-cy="data-import-heading" level={3}>
              Alumni Import
            </Title>
          </Col>
        </React.Fragment>
      );
    }
  };

  const renderImportantNote = () => {
    if (importType !== ImportTypeParam.Scholarship) {
      return (
        <Col span={20}>
          <div className="imprtSection">
            <p className="mtchpara">
              <strong>IMPORTANT:</strong>
              &nbsp;if you are entering this information for the first time, it will be saved for the next time you need
              to load this type of data Please note that if you change your code mapping settings, this will affect your
              settings for any automated import that you have scheduled
            </p>
          </div>
        </Col>
      );
    }
  };
  return (
    <div className="new-data-import mainMatchFieldsSection defineCodeSec">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleStudent" data-cy="data-import-heading" level={1}>
              Add Students
            </Title>
            <Title className="titleField" data-cy="data-import-heading" level={2}>
              Scholarships Data Import - Define Codes
            </Title>
          </Col>
          <Col span="auto">
            <Space>
              {importType !== ImportTypeParam.Scholarship && (
                <button className="button" onClick={() => history.push('/scholarships/data-import-configuration')}>
                  Configure <GearIcon />
                </button>
              )}
              <button className="button" onClick={() => onCancel()}>
                Cancel
              </button>
              <button className="continue-btn" onClick={updateTranslateStepParameters} disabled={loading}>
                Continue
              </button>
            </Space>
          </Col>
        </Row>
        <Divider className="divider-data" />
      </div>
      <div className="mainWhitebgWrapper">
        <div className="mainPerformTimeWrapper">
          <Row>
            {renderImportHeading()}
            <Col span={24}>
              <Title className="titleMatchField" data-cy="data-import-heading" level={3}>
                Match Field Codes
              </Title>
            </Col>
            <Col span={20}>
              <p className="mtchpara">
                You have choose to update one or more data fields that use codes to indicate different values or that
                required data to be formatted in a specific manner. Please review the list of possible values for each
                field and indicate how these values are represented in your data file so that they will be properly
                updated
              </p>
            </Col>
            {renderImportantNote()}
          </Row>
          <Row>{renderFieldsPossibleValues()}</Row>
          <Row>{renderDateTables()}</Row>
          <Row>
            <Col span={24}>
              <div className="btnsSection">
                <Space>
                  <button className="button" onClick={() => onCancel()}>
                    Cancel
                  </button>
                  <button className="continue-btn" onClick={updateTranslateStepParameters} disabled={loading}>
                    Continue
                  </button>
                </Space>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default DefineCodes;
