import { Col, Row } from 'antd';
import * as React from 'react';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { IntegrationContext } from '../../utils/context';
import { getJobStatusMsg } from '../../utils/jobStatus';

const courseMappingImportData = {
  courseMappingDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
  ) => {
    return [
      {
        name: 'Courses Updated',
        key: 'Courses Updated',
        value: job?.importResults?.updatedImports ?? 0,
      },
      {
        name: 'Alerts',
        key: 'Alerts',
        value: (
          <Row>
            {hasIssues && (
              <Col span={2}>
                <AlertYellowIcon />
              </Col>
            )}
            <Col span={22}>
              <Row>
                <Col>{job?.noOfAlerts + ' Errors (Records Skipped)'}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.invalidCount} Errors:`}</span> Records
                  Skipped
                  {job?.validation?.invalidCount && (
                    <ul className="custom-bullet">
                      {Object.keys(errorColumnsPerCategory ?? {}).map((category) => {
                        return (
                          <li key={category}>
                            <span style={{}}>{`${errorRowsPerCategory[category]} ${category}`}: </span>
                            {errorColumnsPerCategory[category].join(', ')}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.warningCount} Warnings:`}</span> Records Not
                  Skipped
                  {Number(job?.validation?.warningCount) && (
                    <ul className="custom-bullet">
                      {Object.keys(warningColumnsPerCategory ?? {}).map((category) => {
                        return (
                          <li key={category}>
                            <span style={{}}>{`${warningRowsPerCategory[category]} ${category}`}: </span>
                            {warningColumnsPerCategory[category].join(', ')}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
    ];
  },

  courseMappingSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: 'PowerSchool SIS',
      },
      {
        name: 'Number of Records',
        key: 'Number of Records',
        value: job?.validation?.totalDataCount ?? 0,
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },

  courseMappingUpdatedResolvedData: (job, history, context) => {
    const { dataImportPath } = context;
    return [
      {
        name: 'Last Completed by',
        key: 'Last Completed by',
        value: job?.updatesResolved?.author,
      },
      {
        name: 'Last Completed on',
        key: 'Last Completed on',
        value: job?.updatesResolved?.end ? new Date(job?.updatesResolved?.end).toLocaleString() : '',
      },
      {
        name: 'Potential Updates Resolved',
        key: 'Potential Updates Resolved',
        value: (
          <Row>
            <Col span={22}>
              <Row>
                <Col>{job?.updatesResolved.recordsMatched + job?.updatesResolved.newRecordsAdded || 0}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.recordsMatched || 0
                  } records matched`}</span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.newRecordsAdded || 0
                  } records added`}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
      {
        name: 'Student Updates Log',
        key: 'Student Updates Log',
        value: (
          <a className="backbtn" onClick={() => history.push(dataImportPath + '/studentUpdates')}>
            View Student Updates Log
          </a>
        ),
      },
    ];
  },
};

export default courseMappingImportData;
