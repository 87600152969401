import { FrequencyOptions } from '../../components-v2/PSDataImportStudentRecords/dataUpdateFrequency';

export const courseImportFrequencies: FrequencyOptions = [
  {
    value: 'ONE_TIME_IMPORT',
    label: 'One-Time Import',
    compatibleWith: [],
  },
  {
    value: 'DAILY',
    label: 'Daily',
    info: 'Import will be initiated next day at 12am EST',
    compatibleWith: ['TWENTY_FOUR_HOURS'],
  },
  {
    value: 'WEEKLY',
    label: 'Weekly',
    info: 'Import will be initiated at 2am EST on the selected day of the week',
    compatibleWith: ['ONE_WEEK'],
  },
];
