import { Row, Col, Space } from 'antd';
import React from 'react';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import CheckmarkCircleIcon from '../../../assets/Icon/CheckmarkCircleIcon';
import CloseIconNoBg from '../../../assets/Icon/CloseIconNoBg';

interface BannerProps {
  isErrorMsg?: boolean;
  message: string;
  handleClose: (message?: string) => void;
}

const Banner = (props: BannerProps): React.ReactElement => {
  const className = props.isErrorMsg ? 'errorBanner' : 'successBanner';
  const icon = props.isErrorMsg ? <AlertErrorIcon /> : <CheckmarkCircleIcon />;
  return (
    <Row justify="space-between" align="top">
      <Col span={24}>
        <Space className={className} align="center" size="middle">
          <strong>
            <Space align="center">{icon}</Space>
          </strong>

          <span className="drawerLableText" data-test-id="drawer_banner_message">
            {props.message}
          </span>
          <a
            onClick={(e) => {
              e.preventDefault();
              props.handleClose();
            }}
            data-test-id="drawer_banner_close"
          >
            <CloseIconNoBg />
          </a>
        </Space>
      </Col>
    </Row>
  );
};

export default Banner;
