import React from 'react';
import Icon from '@ant-design/icons';

export const StatusNotStarted = (): React.ReactElement => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.66602 10.0003C1.66602 5.39199 5.39102 1.66699 9.99935 1.66699C14.6077 1.66699 18.3327 5.39199 18.3327 10.0003C18.3327 14.6087 14.6077 18.3337 9.99935 18.3337C5.39102 18.3337 1.66602 14.6087 1.66602 10.0003ZM3.33268 10.0003C3.33268 6.31366 6.31268 3.33366 9.99935 3.33366C13.686 3.33366 16.666 6.31366 16.666 10.0003C16.666 13.687 13.686 16.667 9.99935 16.667C6.31268 16.667 3.33268 13.687 3.33268 10.0003Z" fill="#1162EE" />
    </svg>
);

export const StatusInProgress = (): React.ReactElement => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.3327 10.0003C18.3327 12.8035 16.9646 15.2815 14.86 16.8009L14.0106 15.3257C15.6235 14.109 16.6661 12.1764 16.6661 10.0003C16.6661 6.59424 14.1118 3.78474 10.8142 3.38291V1.66699C15.0406 2.08608 18.3327 5.65191 18.3327 10.0003ZM7.40535 3.85716C7.95135 3.62633 8.53452 3.46616 9.14335 3.38816V1.66708C8.22485 1.75816 7.35035 1.99791 6.54427 2.36199L7.40535 3.85716ZM3.19518 5.10691L4.68777 5.97083C5.05243 5.49091 5.48052 5.06199 5.9596 4.69649L5.0976 3.19974C4.36543 3.72833 3.72252 4.37299 3.19518 5.10691ZM3.85035 7.42016L2.35935 6.55724C1.9961 7.36533 1.75693 8.24191 1.66602 9.16274H3.38477C3.46127 8.55241 3.62035 7.96774 3.85035 7.42016ZM3.38477 10.8377H1.66602C1.75685 11.7585 1.99593 12.6351 2.35918 13.4432L3.85018 12.5802C3.62018 12.0327 3.46118 11.448 3.38477 10.8377ZM4.6876 14.0296L3.19493 14.8935C3.72227 15.6276 4.36535 16.2722 5.09752 16.8009L5.9596 15.3041C5.48043 14.9386 5.05227 14.5094 4.6876 14.0296ZM6.5441 17.6387C7.35018 18.0028 8.22468 18.2427 9.14335 18.3337V16.6127C8.53443 16.5346 7.95127 16.3744 7.40518 16.1435L6.5441 17.6387ZM10.8141 16.6178V18.3337C11.7328 18.2427 12.6074 18.0029 13.4134 17.6387L12.5603 16.1575C12.0116 16.386 11.4256 16.5433 10.8141 16.6178ZM10.835 9.58674V6.66783C10.835 6.20908 10.4595 5.83374 10.0005 5.83374C9.54152 5.83374 9.16602 6.20908 9.16602 6.66783V9.58674C9.16602 10.0288 9.26618 10.5292 9.58327 10.8377L12.1703 13.3398C12.4958 13.6651 13.0131 13.6651 13.3386 13.3398C13.664 13.0146 13.664 12.4974 13.3386 12.1722L10.835 9.58674Z" fill="#1162EE" />
    </svg>
);

const StatusIcon = ({ inProgress }: { inProgress?: boolean }): React.ReactElement => {
    if (inProgress) {
        return (
            <Icon style={{ marginRight: 5 }} component={StatusInProgress} />
        );
    }
    return (
        <Icon style={{ marginRight: 5 }} component={StatusNotStarted} />
    );
};

export default StatusIcon;