import { NotificationTypes, showNotification } from '../components/Notifications';
import apiClient from './apiClient';
import { PSError } from './constants';

const fetchSisServerStatus = async (): Promise<Record<string, string>> => {
  try {
    const { data } = await apiClient.get(`data-ingest/sis/sis-server-status`);
    return data as Record<string, string>;
  } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting sis server status',
        'Failure in getting data from server.',
      );
  }
};

export default fetchSisServerStatus;
