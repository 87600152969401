import type {
    SisStudentReconciled,
} from '../../../../../../libs/common-interfaces';
import { Col, Input, List, Row } from 'antd';
import * as React from 'react';
import { PaginationConfig } from 'antd/lib/pagination';
import { IntegrationContext } from '../../utils/context';
import SisStudentReconcileEntity from '../../components-v2/SisReconcileJob/SisStudentReconcileEntity';
import { debounceSearch, fetchToReconcileWithSearch } from '../../utils/reconcileUtils';
import StatusIcon from '../../../assets/Icon/Status';
import { SearchOutlined } from '@ant-design/icons';

const VerticalDivider = () => {
    return <div style={{ width: 1, height: 15, backgroundColor: "#e0e0e0", margin: "3px 10px" }}></div>;
};

const ReconcileHeader = ({ inProgress, yetToStart }: { inProgress: number; yetToStart: number }) => {
    return (
        <span style={{ display: 'flex', paddingLeft: 10 }}>Status &nbsp;<StatusIcon inProgress={true} /> {inProgress} in progress <VerticalDivider /> <StatusIcon /> {yetToStart} not started</span>
    );
};

const PSDataImportStudentJobReconcile = ({
    jobGuid,
    update,
    post,
    isTestImport,
    reconcileDisabled,
    setReconcileDisabled,
    totalToReconcile,
}: {
    jobGuid: string;
    update: (updatedReconciled: SisStudentReconciled) => void;
    post: () => void;
    isTestImport: boolean;
    reconcileDisabled: boolean;
    setReconcileDisabled: (updated: boolean) => void;
    totalToReconcile: number;
}): React.ReactElement => {
    const [toReconcile, setToReconcile] = React.useState<any>([]);
    const [loading, setLoading] = React.useState<boolean>();
    const [reconciled, setReconciled] = React.useState<SisStudentReconciled>(
        {},
    );
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [matchedRows, setMatchedRows] = React.useState<
        Record<
            string,
            { student: string; create: boolean }
        >
    >({});
    const [inProgress, setInProgress] = React.useState<number>(0);
    const [yetToStart, setYetToStart] = React.useState<number>(0);
    const [searchInput, setSearchInput] = React.useState<string>('');
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);

    const { isLegacyCleverEnabled } = React.useContext(IntegrationContext);

    const pagination = {
        total: totalRecords,
        showSizeChanger: true,
        showQuickJumper: true,
        current: currentPageNum,
        defaultPageSize: 10,
        pageSize: pageSize,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        pageSizeOptions: [10, 50, 100],
        onShowSizeChange: async (currentPage, size) => {
            setPageSize(size);
            setCurrentPageNum(currentPage);
            await fetchToReconcileWithSearch(
                setLoading,
                setToReconcile,
                setTotalRecords,
                searchInput,
                jobGuid,
                currentPage,
                size)
        },
        onChange: async (page, size) => {
            setPageSize(size);
            setCurrentPageNum(page);
            await fetchToReconcileWithSearch(
                setLoading,
                setToReconcile,
                setTotalRecords,
                searchInput,
                jobGuid,
                page,
                size
            )
        },
    } as PaginationConfig;

    const reconcile = async () => {
        setReconcileDisabled(true);
        post();
    };

    React.useEffect(() => {
        fetchToReconcileWithSearch(setLoading, setToReconcile, setTotalRecords, searchInput, jobGuid);
    }, []);

    React.useEffect(() => {
        setInProgress(Object.keys(matchedRows).length);
        setYetToStart(totalToReconcile - Object.keys(matchedRows).length);
    }, [matchedRows, totalToReconcile]);

    React.useEffect(() => {
        update(reconciled);
    }, [reconciled]);

    const updateReconciled = (update: SisStudentReconciled) => {
        const updatedReconciled = { ...reconciled };
        Object.keys(update).forEach((row) => {
            if (update[row].student || update[row].create) {
                updatedReconciled[row] = update[row];
            } else {
                delete updatedReconciled[row];
            }
        });
        setReconciled(updatedReconciled);
    };

    const handleChange = (event) => {
        const newSearchInput = event.target.value;
        setLoading(true);
        setCurrentPageNum(1);
        setSearchInput(newSearchInput);
        debounceSearch(setLoading, setToReconcile, setTotalRecords, newSearchInput, jobGuid);
    };

    return (
        <div className="searchInput">
            <br />
            <Row justify="space-between" align="middle">
                <Col span={5}>
                    <Input
                        prefix={<SearchOutlined />}
                        allowClear={true}
                        placeholder="Search"
                        type="text"
                        value={searchInput}
                        onChange={handleChange}
                        style={{ width: 300 }}
                    />
                </Col>
                <Col span={15}>
                    {!loading && (
                        <ReconcileHeader inProgress={inProgress} yetToStart={yetToStart} />
                    )}
                </Col>
                <Col span={4}>
                    {Object.keys(toReconcile).length > 0 && !reconcileDisabled && !isLegacyCleverEnabled && (
                        <button
                            className="button button-blue"
                            style={{ float: 'right' }}
                            disabled={
                                Object.keys(reconciled).length === 0 ||
                                Object.keys(toReconcile).length === 0 ||
                                reconcileDisabled ||
                                isTestImport ||
                                isLegacyCleverEnabled
                            }
                            onClick={() => {
                                reconcile();
                            }}
                            data-test-id="process_btn"
                        >
                            Process all updates
                        </button>
                    )}
                </Col>
            </Row>
            <Row justify="space-between" align="middle">
                <Col span={24}>
                    <List
                        itemLayout="horizontal"
                        dataSource={toReconcile}
                        renderItem={(row: any) => (
                            <SisStudentReconcileEntity
                                student={row.student}
                                navStudents={row.navStudents}
                                key={row.student.dcid}
                                updateReconciled={updateReconciled}
                                matchedRows={matchedRows}
                                setMatchedRows={setMatchedRows}
                                isDisabled={Object.keys(toReconcile).length === 0 || reconcileDisabled || isTestImport || isLegacyCleverEnabled}
                            />
                        )}
                        loading={loading}
                        pagination={pagination}
                        size="large"
                    />
                </Col>
            </Row>
        </div>
    );
};

export default PSDataImportStudentJobReconcile;
