// eslint-disable-next-line import/no-unresolved
import type { IJobLogs } from '../../../../../../../libs/common-interfaces';
import { Col, Divider, Row, Spin } from 'antd';
import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import LeftAngleIcon from '../../../../assets/Icon/LeftAngleIcon';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';
import { JOB_LOGS_ENDPOINT } from '../../../utils/constants';

const JobLogs: React.FC = () => {
  const [logsData, setLogsData] = useState<null | IJobLogs>(null);
  const [loading, setLoading] = useState<boolean>(true);

  const getLogs = async (guid: string) => {
    setLoading(true);
    try {
      const { data } = await apiClient.get(`${JOB_LOGS_ENDPOINT}/${guid}`);
      setLogsData(data);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Failed to get logs', 'Service Failure');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const guid = 'any-guid'; // hardcode for now
    void getLogs(guid);
  }, []);

  return (
    <div className="new-data-import mainLogsSection">
      <div className="main-header-sec">
        <Row justify="space-between" align="middle">
          <Col className="titleCol" span="auto">
            <Title className="titleField" data-cy="data-import-heading" level={1}>
              View Log
            </Title>
          </Col>
        </Row>
        <Divider className="divider-data" />
      </div>
      <div className="mainWhitebgWrapper">
        {loading && (
          <Row justify="center">
            <Col span={24}>
              <div className="spinner">
                <Spin size="large" />
              </div>
            </Col>
          </Row>
        )}
        {logsData && (
          <>
            <Row justify="space-between" align="middle">
              <Col span="auto">
                <span className="bckBtn">
                  <LeftAngleIcon /> <a className="backbtn">Back</a>
                </span>
              </Col>
              <Col span="auto">
                <button className="button">Export Log</button>
              </Col>
            </Row>
            <Row className="impDetails" justify="start">
              <Col span={7}>
                <Title className="titleField" data-cy="data-import-heading" level={5}>
                  Import Details
                </Title>
                <ul>
                  <li className="darkText">Import Type:</li>
                  <li className="lightText">{logsData.ImportType}</li>
                  <li className="darkText">Import Name:</li>
                  <li className="lightText">{logsData.ImportName}</li>
                  <li className="darkText">Current Status:</li>
                  <li className="lightText">{logsData.ImportCurrentStatus}</li>
                  <li className="darkText">Data loaded on:</li>
                  <li className="lightText">{logsData.Date}</li>
                  <li className="darkText">Data loaded by:</li>
                  <li className="lightText">{logsData.Author}</li>
                  <li className="darkText">Number of records:</li>
                  <li className="lightText">{logsData.Records}</li>
                </ul>
              </Col>
            </Row>
            <Row className="impSummary">
              <Col span={7}>
                <Title className="titleField" data-cy="data-import-heading" level={5}>
                  Import Summary
                </Title>
                <ul>
                  <li className="lightText">Number of students added: 8000</li>
                  <li className="lightText">Number of student transfers: 45</li>
                  <li className="lightText">Number of student class year updates: 0</li>
                  <li className="lightText">Number of students deactivated: 5</li>
                  <li className="lightText">Number of alerts (record skipped): 15</li>
                  <li className="lightText">- [DUPLICATE: STUDENT EXISTS IN NAVIANCE]:</li>
                  <li className="lightText">- [REQUIRED FIELD MISSING: LAST NAME]: 5</li>
                  <li className="lightText">- [INVALID DATE: CLASS YEAR - 4 DIGITS EXPECTED]: 5</li>
                </ul>
              </Col>
            </Row>
            <Row className="nedAttention">
              <Col span={10}>
                <Title className="titleField" data-cy="data-import-heading" level={5}>
                  Needs Attention!
                </Title>
                <span className="darkText">(from first 1,000 rows only, download to see all)</span>
              </Col>
            </Row>
          </>
        )}
      </div>
    </div>
  );
};

export default JobLogs;
