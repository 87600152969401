import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Table, Select, Tag } from 'antd';
import type { DiplomaTypeCode } from '../../../../../../libs/common-interfaces';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { availableSisCodes, changeMapping, availableDiplamoTypes } from '../../utils/utils';
import { AvailableCodes, navDiplomaTypeData } from '../../utils/constants';
import { getSisExtenstionDataAndLabel } from '../../utils/getSisExtensionsData';

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

interface DiplomaTypeDisplay extends DiplomaTypeCode {
  used?: boolean;
}

interface DiplomaTypeCodeMappingProps {
  diplomaTypeMapping: Record<string, (string | number)[]>;
  setDiplomaTypeMapping: (diplomaTypeMapping: Record<string, (string | number)[]>) => void;
  headerMappings: { [key: string]: string };
  sisIntegration: string;
  diplomaTypeExtensionConfig: any;
  dataTestId?: string;
}

const DiplomaTypeCodeMapping = ({
  diplomaTypeMapping,
  setDiplomaTypeMapping,
  headerMappings,
  sisIntegration,
  diplomaTypeExtensionConfig,
}: DiplomaTypeCodeMappingProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);
  const [isCustomField] = React.useState(headerMappings?.['exitcode'] || sisIntegration === 'eSP' ? false : true);
  const [loading, setLoading] = React.useState(false);
  const [defaultSisDiplomaTypeCodes, setDefaultSisDiplomaTypeCodes] = React.useState([]);
  const [diplomaTypeCodes, setDiplomaTypeCodes] = React.useState([]);
  const [diplomaTypeLabel, setDiplomaTypeLabel] = React.useState('');
  const [diplomaTypeCurrentValue, setDiplomaTypeCurrentValue] = React.useState([]);

  const availableSisDiplomaTypeCodes = (): AvailableCodes[] | DiplomaTypeDisplay[] =>
    isCustomField
      ? availableSisCodes<AvailableCodes>(diplomaTypeMapping, defaultSisDiplomaTypeCodes)
      : availableDiplamoTypes<DiplomaTypeDisplay>(diplomaTypeMapping, defaultSisDiplomaTypeCodes);

  const getDiplomaTypeCodes = async () => {
    try {
      const { data }: { data: any } = await apiClient.get('/data-ingest/sis/diploma-type-codes');
      if (isMountedRef.current) setDefaultSisDiplomaTypeCodes(data.diplomaTypeCodes);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Race Codes', 'Failure in getting data from server.');
    }
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    await getDiplomaTypeCodes();
    setDiplomaTypeCurrentValue(getAllDiplomaValues(diplomaTypeMapping));
    if (isMountedRef.current) setLoading(false);
  };

  const getAllDiplomaValues = (data) => {
    let diplomaValues = [];
    for (let key in data) {
      if (data.hasOwnProperty(key)) {
        diplomaValues = diplomaValues.concat(data[key]);
      }
    }
    return diplomaValues;
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();

    return () => (isMountedRef.current = false);
  }, []);

  React.useEffect(() => {
    setDiplomaTypeCodes(availableSisDiplomaTypeCodes());
  }, [diplomaTypeMapping, defaultSisDiplomaTypeCodes]);

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  const changeCustomMapping = (key: string, val: (string | number)[], mapping: any, setMapping: any): void => {
    const updatedMapping = { ...mapping };
    const updatedValue = [...diplomaTypeCurrentValue];
    if (updatedValue.includes(val[val.length - 1]) && updatedMapping[key].length < val.length) {
      val.pop();
    }

    if (val) {
      updatedMapping[key] = val;
    } else {
      delete updatedMapping[key];
    }
    setMapping(updatedMapping);
    setDiplomaTypeCurrentValue([...getAllDiplomaValues(updatedMapping)]);
  };

  const diplomaTypeTableColumns = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: !isCustomField ? 'Select SIS Codes' : `SIS Code(s): ${diplomaTypeLabel}`,
      width: '60%',
      render: (data) => {
        return !isCustomField ? (
          <Select
            className="select select-responsive"
            mode="multiple"
            tagRender={tagRender}
            allowClear
            value={diplomaTypeMapping[data.code]}
            onChange={(val) => changeMapping(data.code, val, diplomaTypeMapping, setDiplomaTypeMapping)}
            data-test-id={`diploma_type_select_${data.code}`}
          >
            {diplomaTypeCodes.map((diplomaType, ind) => (
              <Option
                className="selectOptions"
                key={`sis-${diplomaType.id}-${ind}`}
                value={diplomaType.name}
                data-test-id={`diploma_type_option_${diplomaType.id}`}
                disabled={diplomaType.used && !diplomaTypeMapping[data.code]?.includes(diplomaType.name)}
              >
                {`(${diplomaType.name}) ${diplomaType.valuet}`}
              </Option>
            ))}
          </Select>
        ) : (
          <Select
            className="select select-responsive"
            mode="tags"
            tagRender={tagRender}
            allowClear
            value={diplomaTypeMapping[data.code]}
            onChange={(val) => changeCustomMapping(data.code, val, diplomaTypeMapping, setDiplomaTypeMapping)}
            data-test-id={`diploma_type_select_${data.code}`}
            dropdownStyle={{ display: 'none' }}
            options={[]}
            size="large"
            style={{ width: '100%' }}
          />
        );
      },
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 style={{ marginBottom: '0px' }}>Diploma Type</h2>
        <span>
          If matching multiple SIS codes to Naviance codes, then separate with commas. Unmapped SIS codes will be
          visible on the View Log page after importing.
        </span>
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          columns={diplomaTypeTableColumns}
          dataSource={navDiplomaTypeData}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default DiplomaTypeCodeMapping;
