import * as React from 'react';
import { Table } from 'antd';
export interface SchoolDetailsData {
  schoolId: string;
  schoolName: string
  totalRecords: number;
  invalid: number;
  active: number;
  create: number;
  missing: number;
  update: number;
  transfer: number;
  noChanges: number;
  deactivated: number;
  discarded: number;
}
export interface SchoolDetailsDataObj {
  dataSource: SchoolDetailsData[];
}
const SchoolDetails = (props: SchoolDetailsDataObj): React.ReactElement => {
  const cellsStyle = { borderBottom: "1px Solid #d3d3d39e" };
  const separatorCellsStyle = {  borderRight: "1px Solid #d3d3d39e", ...cellsStyle };
  const columns = [
    {
      title: 'District/School Name',
      dataIndex: 'schoolName',
      key: 'District/School Name',
      render(text, record) {
        return {
          props: {
            style: separatorCellsStyle
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Total Records',
      dataIndex: 'totalRecords',
      key: 'Total Records',
      render(text, record) {
        return {
          props: {
            style: cellsStyle
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Invalid',
      dataIndex: 'invalid',
      key: 'Invalid',
      render(text, record) {
        return {
          props: {
            style: cellsStyle
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Active Records',
      dataIndex: 'active',
      key: 'Active Records',
      render(text, record) {
        return {
          props: {
            style: separatorCellsStyle
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Create',
      dataIndex: 'create',
      key: 'Create',
      render(text, record) {
        return {
          props: {
            style: cellsStyle
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Update(Transfer)',
      dataIndex: 'updateTransfer',
      key: 'Update(Transfer)',
      render(text, record) {
        return {
          props: {
            style: cellsStyle
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'No Changes',
      dataIndex: 'noChanges',
      key: 'No Changes',
      render(text, record) {
        return {
          props: {
            style: separatorCellsStyle
          },
          children: <div>{text}</div>
        };
      }
    },
    {
      title: 'Deactivate In Naviance',
      dataIndex: 'deactivated',
      key: 'Deactivate In Naviance',
      render(text, record) {
        return {
          props: {
            style: cellsStyle
          },
          children: <div>{text}</div>
        };
      }
    }
  ];

  return (
    <div style={{marginTop: "1rem"}}>
      <h3>Records Per School</h3>
      <Table
        dataSource={props.dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default SchoolDetails;
