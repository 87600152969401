/* eslint-disable @typescript-eslint/no-explicit-any */
import Table, { ColumnsType } from 'antd/lib/table';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { IntegrationContext } from '../../utils/context';
import moment from 'moment';

interface StudentReconcileRow {
  source: string;
  lastName: string;
  firstName: string;
  schoolId: string;
  navStudentId: string;
  classYear: string;
  birthDate: string;
  status: string;
  numVisits: string;
}
interface StaffReconcileRow {
  source: string;
  lastName: string;
  firstName: string;
  schoolId: string;
  employeeId: string;
  staffEmail: string;
  role: string;
  userName: string;
  status: string;
  numVisits: string;
  lastVisit: string;
  teacherRecommendations: string;
}
interface ParentReconcileRow {
  source: string;
  studentId: string;
  parentId: string;
  lastName: string;
  firstName: string;
  email: string;
}
interface SisFailedReconcileProps {
  student?: Record<string, any>;
  navStudents?: Record<string, any>[];
  staff?: Record<string, any>;
  navStaffs?: Record<string, any>[];
  parent?: Record<string, any>;
  navParents?: Record<string, any>[];
  dataType: string;
}
const SisFailedReconcile = ({
  student,
  navStudents,
  staff,
  navStaffs,
  parent,
  navParents,
  dataType,
}: SisFailedReconcileProps): React.ReactElement => {
  const [toReconcile, setToReconcile] = React.useState([]);
  const { isCleverIntegration } = React.useContext(IntegrationContext);
  React.useEffect(() => {
    const data = [];
    if (dataType === 'students' || dataType === 'alumni') {
      data.push(
        {
          source: 'SIS',
          lastName: student?.lastName,
          firstName: student?.firstName,
          schoolId: student?.Naviance_School_ID,
          hsStudentId: student?.hsStudentId,
          classYear: student?.classYear ? `${student?.classYear}` : '',
          birthDate: student?.birthDate ? `${student?.birthDate}` : '',
          status: (student?.student as any)?.isActive ? 'Active' : 'Inactive',
          numVisits: '',
          key: `${student?.dcid}-sis`,
        },
        ...navStudents.map((navStudent) => ({
          id: navStudent?.id,
          source: 'Naviance',
          lastName: navStudent?.lastName,
          firstName: navStudent?.firstName,
          schoolId: navStudent?.schoolId,
          hsStudentId: navStudent?.hsStudentId,
          classYear: navStudent?.classYear ? `${navStudent?.classYear}` : '',
          birthDate: navStudent?.birthDate ? `${navStudent?.birthDate}` : '',
          status: navStudent?.isActive ? 'Active' : 'Inactive',
          numVisits: (navStudent?.studentUser as any)?.numVisits || 0,
          key: `${student?.dcid}-${navStudent?.id}`,
        })),
      );
    } else if (dataType === 'parent-records') {
      data.push(
        {
          source: 'SIS',
          studentId: parent.student_links.map((student_link) => student_link.student_dcid).toString(),
          parentId: parent?.hsParentId,
          lastName: parent?.lastName,
          firstName: parent?.firstName,
          email: parent?.email,
          key: `${parent?.personDcid}-sis`,
        },
        ...navParents.map((navParent) => ({
          source: 'Naviance',
          studentId: navParent?.studentParentLinks
            .map((studentParentLink) => studentParentLink.fk_student_id)
            .toString(),
          parentId: navParent?.id,
          lastName: navParent?.lastName,
          firstName: navParent?.firstName,
          email: navParent?.email,
          key: `${parent?.personDcid}-${navParent?.id}`,
        })),
      );
    } else {
      data.push(
        {
          source: 'SIS',
          lastName: staff?.lastName,
          firstName: staff?.firstName,
          schoolId: staff?.Naviance_School_ID,
          employeeId: staff?.employeeIdExternal,
          email: staff?.email,
          role: staff?.role,
          userName: staff.userName,
          status: (staff?.staff as any)?.isActive ? 'Active' : 'Inactive',
          numVisits: '-',
          teacherRecommendations: '-',
          lastVisit: '-',
          key: `${staff?.dcid}-${staff?.entityId}-${staff?.role}-sis`,
        },
        ...navStaffs.map((navStaff) => ({
          id: navStaff?.id,
          source: 'Naviance',
          lastName: navStaff?.lastName,
          firstName: navStaff?.firstName,
          schoolId: navStaff?.entityId,
          employeeId: navStaff?.employeeIdExternal,
          email: navStaff?.email,
          role: navStaff?.highSchoolUser?.role,
          userName: navStaff?.highSchoolUser?.userName,
          status: navStaff?.isActive ? 'Active' : 'Inactive',
          numVisits: (navStaff?.highSchoolUser as any)?.numVisits || 0,
          teacherRecommendations: navStaff?.teacherRecommendations || 0,
          lastVisit: navStaff?.lastVisit ? moment.unix(navStaff?.lastVisit).format('DD-MM-YYYY') : '',
          key: `${isCleverIntegration ? staff?.cleverId : staff?.dcid}-${staff?.entityId}-${staff?.role}-${navStaff?.id}`,
        })),
      );
    }
    setToReconcile(data);
  }, []);

  const DataImportTableColumnsStudents: ColumnsType<StudentReconcileRow> = [
    {
      title: <Title level={5}>Source</Title>,
      dataIndex: 'source',
      key: 'source',
      width: '10%',
    },
    {
      title: <Title level={5}>Last Name</Title>,
      dataIndex: 'lastName',
      key: 'lastName',
      width: '10%',
    },
    {
      title: <Title level={5}>First Name</Title>,
      dataIndex: 'firstName',
      key: 'firstName',
      width: '10%',
    },
    {
      title: <Title level={5}>School ID</Title>,
      dataIndex: 'schoolId',
      key: 'schoolId',
      width: '10%',
    },
    {
      title: <Title level={5}>Student ID</Title>,
      dataIndex: 'hsStudentId',
      key: 'hsStudentId',
      width: '10%',
    },
    {
      title: <Title level={5}>Class Year</Title>,
      dataIndex: 'classYear',
      key: 'classYear',
      width: '10%',
    },
    {
      title: <Title level={5}>Birth Date</Title>,
      dataIndex: 'birthDate',
      key: 'birthDate',
      width: '10%',
    },
    {
      title: <Title level={5}>Status</Title>,
      dataIndex: 'status',
      key: 'status',
      width: '8%',
    },
    {
      title: <Title level={5}>Visits</Title>,
      dataIndex: 'numVisits',
      key: 'numVisits',
      width: '8%',
    },
  ];
  const DataImportTableColumnsStaffs: ColumnsType<StaffReconcileRow> = [
    {
      title: <Title level={5}>Source</Title>,
      dataIndex: 'source',
      key: 'source',
      width: '10%',
    },
    {
      title: <Title level={5}>Last Name</Title>,
      dataIndex: 'lastName',
      key: 'lastName',
      width: '10%',
    },
    {
      title: <Title level={5}>First Name</Title>,
      dataIndex: 'firstName',
      key: 'firstName',
      width: '10%',
    },
    {
      title: <Title level={5}>School ID</Title>,
      dataIndex: 'schoolId',
      key: 'schoolId',
      width: '10%',
    },
    {
      title: <Title level={5}>Employee ID</Title>,
      dataIndex: 'employeeId',
      key: 'employeeId',
      width: '10%',
    },
    ...(isCleverIntegration
      ? [
          {
            title: <Title level={5}>Last Visit</Title>,
            dataIndex: 'lastVisit',
            key: 'lastVisit',
            width: '8%',
          },
          {
            title: <Title level={5}>Teacher Recommendations</Title>,
            dataIndex: 'teacherRecommendations',
            key: 'teacherRecommendations',
            width: '8%',
          },
        ]
      : [
          {
            title: <Title level={5}>Staff Email</Title>,
            dataIndex: 'email',
            key: 'email',
            width: '10%',
          },
          {
            title: <Title level={5}>Role</Title>,
            dataIndex: 'role',
            key: 'role',
            width: '8%',
          },
          {
            title: <Title level={5}>UserName</Title>,
            dataIndex: 'userName',
            key: 'userName',
            width: '10%',
          },
        ]),
        {
          title: <Title level={5}>Status</Title>,
          dataIndex: 'status',
          key: 'status',
          width: '8%',
        },
        {
          title: <Title level={5}>Visits</Title>,
          dataIndex: 'numVisits',
          key: 'numVisits',
          width: '8%',
        },
  ];
  const DataImportTableColumnsParents: ColumnsType<ParentReconcileRow> = [
    {
      title: <Title level={5}>Source</Title>,
      dataIndex: 'source',
      key: 'source',
      width: '10%',
    },
    {
      title: <Title level={5}>Student ID</Title>,
      dataIndex: 'studentId',
      key: 'studentId',
      width: '10%',
    },
    {
      title: <Title level={5}>Parent ID</Title>,
      dataIndex: 'parentId',
      key: 'parentId',
      width: '10%',
    },
    {
      title: <Title level={5}>Last Name</Title>,
      dataIndex: 'lastName',
      key: 'lastName',
      width: '10%',
    },
    {
      title: <Title level={5}>First Name</Title>,
      dataIndex: 'firstName',
      key: 'firstName',
      width: '10%',
    },
    {
      title: <Title level={5}>Email</Title>,
      dataIndex: 'email',
      key: 'email',
      width: '10%',
    },
  ];
  return (
    <div>
      <h1></h1>
      <Table
        rowKey="key"
        columns={
          dataType === 'students' || dataType === 'alumni'
            ? DataImportTableColumnsStudents
            : dataType === 'parent-records'
            ? DataImportTableColumnsParents
            : DataImportTableColumnsStaffs
        }
        dataSource={toReconcile}
        data-test-id={
          dataType === 'students' || dataType === 'alumni'
            ? 'student_to_reconcile'
            : dataType === 'parent-records'
            ? 'parent_to_reconcile'
            : 'staff_to_reconcile'
        }
        pagination={false}
        rowClassName={(_, ind) => (ind === 0 ? 'sisRow' : '')}
      ></Table>
      <br />
    </div>
  );
};

export default SisFailedReconcile;
