/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  SisStaffReconciled,
  SisStudentReconciled,
  SisParentReconciled,
} from '../../../../../../libs/common-interfaces';
import { Col, List, Row } from 'antd';
import * as React from 'react';
import { PaginationConfig } from 'antd/lib/pagination';
import { IntegrationContext } from '../../utils/context';
import SisReconcileEntity from '../../components-v2/SisReconcileJob';
import { fetchToReconcile, getRoles } from '../../utils/reconcileUtils';

const PSDataImportJobReconcile = ({
  jobGuid,
  update,
  post,
  isTestImport,
  reconcileDisabled,
  setReconcileDisabled,
  dataType,
}: {
  jobGuid: string;
  update: (updatedReconciled: SisStudentReconciled | SisStaffReconciled | SisParentReconciled) => void;
  post: () => void;
  isTestImport: boolean;
  reconcileDisabled: boolean;
  setReconcileDisabled: (updated: boolean) => void;
  dataType?: string;
}): React.ReactElement => {
  const [toReconcile, setToReconcile] = React.useState<any>([]);
  const [rolesMap, setRolesMap] = React.useState<any>({});
  const [loading, setLoading] = React.useState<boolean>();
  const [reconciled, setReconciled] = React.useState<SisStudentReconciled | SisStaffReconciled | SisParentReconciled>(
    {},
  );
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [matchedRows, setMatchedRows] = React.useState<
    Record<
      string,
      { student: string; create: boolean } | { staff: string; create: boolean } | { parent: string; create: boolean }
    >
  >({});
  const { isLegacyCleverEnabled } = React.useContext(IntegrationContext);

  const pagination = {
    total: totalRecords,
    showSizeChanger: true,
    defaultPageSize: 10,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSizeOptions: ['10', '50', '100'],
    onShowSizeChange: async (currentPage, size) => {
      await fetchToReconcile(
        setLoading,
        setToReconcile,
        setTotalRecords,
        jobGuid,
        currentPage,
        size)
    },
    onChange: async (page, size) => {
      await fetchToReconcile(
        setLoading,
        setToReconcile,
        setTotalRecords,
        jobGuid,
        page,
        size
      )
    },
  } as PaginationConfig;

  const reconcile = async () => {
    setReconcileDisabled(true);
    post();
  };

  React.useEffect(() => {
    if (dataType === 'staff-records') {
      getRoles(setRolesMap).then(() => fetchToReconcile(setLoading, setToReconcile, setTotalRecords, jobGuid));
    } else {
      fetchToReconcile(setLoading, setToReconcile, setTotalRecords, jobGuid);
    }
  }, [dataType]);

  React.useEffect(() => {
    update(reconciled);
  }, [reconciled]);

  const updateReconciled = (update: SisStudentReconciled | SisStaffReconciled | SisParentReconciled) => {
    const updatedReconciled = { ...reconciled };
    Object.keys(update).forEach((row) => {
      if (
        (update as SisStudentReconciled)[row].student ||
        (update as SisStaffReconciled)[row].staff ||
        (update as SisParentReconciled)[row].parent ||
        update[row].create
      ) {
        updatedReconciled[row] = update[row];
      } else {
        delete updatedReconciled[row];
      }
    });
    setReconciled(updatedReconciled);
  };

  return (
    <>
      <br />
      <Row justify="space-between" align="middle">
        <Col span={20}>
          <h2>Resolve {totalRecords} Potential Updates</h2>
          <p>
            {reconcileDisabled
              ? ''
              : Object.keys(reconciled).length === 0
                ? `Below are the records for manual reconciliation${isTestImport ? ', after actual import reconciliation option will be displayed' : ''
                }.`
                : 'After manually matching records below, select Process All Updates which starts a new import.'}
          </p>
        </Col>
        <Col span={4}>
          {Object.keys(toReconcile).length > 0 && !reconcileDisabled && !isLegacyCleverEnabled && (
            <button
              className="button button-blue"
              style={{ float: 'right' }}
              disabled={
                Object.keys(reconciled).length === 0 ||
                Object.keys(toReconcile).length === 0 ||
                reconcileDisabled ||
                isTestImport ||
                isLegacyCleverEnabled
              }
              onClick={() => {
                reconcile();
              }}
              data-test-id="process_btn"
            >
              Process All Updates
            </button>
          )}
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={toReconcile}
            renderItem={(row: any) => (
              <SisReconcileEntity
                {...(row.student && { student: row.student })}
                {...(row.navStudents && { navStudents: row.navStudents })}
                {...(row.staff && { staff: row.staff })}
                {...(row.navStaffs && { navStaffs: row.navStaffs })}
                {...(row.parent && { parent: row.parent })}
                {...(row.navParents && { navParents: row.navParents })}
                key={
                  dataType === 'students' || dataType === 'alumni'
                    ? row.student.dcid
                    : dataType === 'parent-records'
                      ? row.parent.personDcid
                      : `${row?.staff?.dcid}-${row?.staff?.entityId}-${row?.staff?.role}`
                }
                updateReconciled={updateReconciled}
                matchedRows={matchedRows}
                setMatchedRows={setMatchedRows}
                isDisabled={
                  Object.keys(toReconcile).length === 0 || reconcileDisabled || isTestImport || isLegacyCleverEnabled
                }
                dataType={dataType}
                rolesMap={rolesMap}
              />
            )}
            loading={loading}
            pagination={pagination}
            size="large"
          />
          ,
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col span={24}>
          {Object.keys(toReconcile).length > 0 && !reconcileDisabled && !isLegacyCleverEnabled && (
            <button
              className="button button-blue"
              style={{ float: 'right' }}
              disabled={
                Object.keys(reconciled).length === 0 ||
                Object.keys(toReconcile).length === 0 ||
                reconcileDisabled ||
                isTestImport ||
                isLegacyCleverEnabled
              }
              onClick={() => {
                reconcile();
              }}
              data-test-id="process_btn"
            >
              Process All Updates
            </button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PSDataImportJobReconcile;
