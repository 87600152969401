import { Button } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import CheckmarkCircleIcon from '../../../assets/Icon/CheckmarkCircleIcon';

interface JobIconAndStatusProps {
  row: {
    undo?: {
      status: string;
    };
    importDataGuid: string;
    navianceStatus: string;
    isTestImport: boolean;
  };
}

const JobIconAndStatus = ({ row }: JobIconAndStatusProps): React.ReactElement => {
  const history = useHistory();
  if (row.undo && row.undo.status) {
    switch (row.undo.status) {
      case 'COMPLETE':
        return (
          <Button data-test-id="jobStatus" type="link" onClick={() => history.push(`/view-log/${row.importDataGuid}`)}>
            <CheckmarkCircleIcon /> Undo Complete
          </Button>
        );
      case 'FAILED':
        return (
          <Button data-test-id="jobStatus" type="link" onClick={() => history.push(`/view-log/${row.importDataGuid}`)}>
            <AlertErrorIcon /> Undo Failed
          </Button>
        );
      default:
        return <>Undo in progress</>;
    }
  }
  switch (row.navianceStatus) {
    case 'VALIDATION_IN_PROGRESS':
      return <>{row.isTestImport ? 'Test ' : ''}Validation in progress</>;
    case 'SYNC_VALIDATION_IN_PROGRESS':
      return <>{row.isTestImport ? 'Test ' : ''}Sync Validation in Progress</>;
    case 'IMPORT_IN_PROGRESS':
      return <>{row.isTestImport ? 'Test ' : ''}Import in progress</>;
    case 'SYNC_IN_PROGRESS':
      return <>{row.isTestImport ? 'Test ' : ''}Sync in progress</>;
    case 'IMPORT_COMPLETED':
      return (
        <Button data-test-id="jobStatus" type="link" onClick={() => history.push(`/view-log/${row.importDataGuid}`)}>
          <CheckmarkCircleIcon /> {row.isTestImport ? 'Test ' : ''}Import Completed
        </Button>
      );
    case 'IMPORT_COMPLETED_WITH_ALERTS':
      return (
        <Button type="link" onClick={() => history.push(`/view-log/${row.importDataGuid}`)}>
          <AlertYellowIcon /> {row.isTestImport ? 'Test ' : ''}Import Completed with Alerts
        </Button>
      );
    case 'SYNC_COMPLETED_WITH_ALERTS':
      return (
        <Button type="link" onClick={() => history.push(`/view-log/${row.importDataGuid}`)}>
          <AlertYellowIcon /> {row.isTestImport ? 'Test ' : ''}Sync Completed with Alerts
        </Button>
      );
    case 'CRITICAL_ERROR':
      return (
        <Button type="link" onClick={() => history.push(`/view-log/${row.importDataGuid}`)}>
          <AlertErrorIcon /> Critical Error - Import Cancelled
        </Button>
      );
    default:
      return <>{row.navianceStatus}</>;
  }
};

export default JobIconAndStatus;
