import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import {
  cleverUserNameFields,
  defaultCleverStudentMapping,
  defaultESPStudentMapping,
  defaultPsStudentMapping,
  eSPUserNameFields,
  navianceStudentCleverFields,
  navianceStudentESPFields,
  navianceStudentPSFields,
  userNameFields,
  NavianceField,
} from '../../utils/fieldMapUtils';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { IntegrationContext } from './../../utils/context';
import { parsePSSisServerVersion } from './../../utils/utils';
import { gte as isGreaterThanOrEqualTo } from 'semver';
import { featureFlags } from '../../utils/featureFlags';
import { Modal } from 'antd';

interface Section {
  hasGroup?: boolean;
  tableHeader?: React.ReactElement;
  defaultFields: NavianceField[];
  defaultBlankValue?: string;
}

const SisStudentFieldMatching = (props: WizardProps): React.ReactElement => {
  const [sisIntegration, setSisIntegration] = React.useState<null | 'psSis' | 'eSP' | 'clever'>(null);
  const [groupNameFields, setGroupNameFields] = React.useState([]);
  const [navianceStudentCleverAlteredFields, setStudentCleverAlteredFields] =
    React.useState(navianceStudentCleverFields);
  const [cleverAlteredUserNameFields, setCleverAlteredUserNameFields] = React.useState(cleverUserNameFields);
  const [loading, setLoading] = React.useState(true);
  const [notShared, setNotShared] = React.useState<string[]>([]);
  const [showMissingRequiredFieldsPopup, setShowMissingRequiredFieldsPopup] = React.useState(false);
  const [requiredNotShared, setRequiredNotShared] = React.useState<string[]>([]);
  const [isStudentCounselorCompatible, setIsStudentCounselorCompatible] = React.useState<boolean>(false);

  const { pluginDetails, sisServerDetails, sisError } = React.useContext(IntegrationContext);

  const getTenantMeta = async () => {
    try {
      const { data } = await apiClient.get(`data-ingest/sis/tenant-meta`);
      setSisIntegration(data?.sisIntegration || 'psSis');
      if (data.sisIntegration !== 'clever' && data.sisIntegration !== 'psSis') {
        setLoading(false);
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Config', 'Server Error');
    }
  };

  const getDistrictGroupName = async () => {
    try {
      const { data } = await apiClient.get(`/school-student-groups`);
      const groupFieldData = data.map((group) => ({
        name: group.name,
        val: group.id as string,
        required: false,
        sisFields: [],
        customFieldOption: true,
      }));
      setGroupNameFields(groupFieldData);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Config', 'Server Error');
    }
  };

  const determineAndSetSharedFields = (fields, cleverSharedfields, notSharedFields) => {
    const hispanicNotShared = !cleverSharedfields.includes('hispanic_ethnicity');
    fields.forEach((field) => {
      const sharedFields = field.sisFields.map((sisField) => {
        const fieldNotShared = !cleverSharedfields.includes(sisField.field);
        if (sisField.field !== 'race' ? fieldNotShared : fieldNotShared || hispanicNotShared) {
          if (sisField.field === 'race') {
            fieldNotShared && notSharedFields.push(sisField.field); // push race to notSharedFields if not shared
            hispanicNotShared && notSharedFields.push('hispanic_ethnicity'); // push hispanic_ethnicity to notSharedFields if not shared
          } else {
            notSharedFields.push(sisField.field);
          }
          sisField.notShared = true;
        }
        return sisField;
      });
      field.sisFields = sharedFields;

      // show the pop-up if no fields are shared for any required field
      if (field.required) {
        let requiredFieldNotSharedlength = 0;
        field.sisFields.forEach((sisField) => {
          sisField.notShared && requiredFieldNotSharedlength++;
        });
        if (field.sisFields.length === requiredFieldNotSharedlength) {
          requiredNotShared.push(
            ...field.sisFields.map((sisField) => (sisField.field === 'derivedClassYear' ? 'grade' : sisField.field)),
          );
        }
      }
    });
  };

  const getCleverStudentandAlterMapping = async () => {
    try {
      if (featureFlags['feature.dataIngest.student.cleverFieldNotShared']) {
        const { data } = await apiClient.get(`data-ingest/sis/get-sis-student`);
        const cleverSharedfields = Object.keys(data);
        const notSharedfields = [];
        determineAndSetSharedFields(navianceStudentCleverAlteredFields, cleverSharedfields, notSharedfields);
        determineAndSetSharedFields(cleverAlteredUserNameFields, cleverSharedfields, notSharedfields);
        if (requiredNotShared.length) {
          setRequiredNotShared(requiredNotShared);
          setShowMissingRequiredFieldsPopup(true);
        }
        setNotShared(notSharedfields);
        setStudentCleverAlteredFields(navianceStudentCleverAlteredFields);
        setCleverAlteredUserNameFields(cleverAlteredUserNameFields);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      setLoading(false);
      showNotification(NotificationTypes.error, 'Error Getting Student', 'Server Error');
    }
  };

  const validateStudentCounselorCompatibility = async () => {
    try {
      if (
        !sisError &&
        isGreaterThanOrEqualTo(parsePSSisServerVersion((sisServerDetails as any).powerschool_version), '22.7.0') &&
        isGreaterThanOrEqualTo((pluginDetails as any)?.version, '1.2.4')
      ) {
        setIsStudentCounselorCompatible(true);
      }
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      setLoading(false);
      showNotification(NotificationTypes.error, 'Error Getting Server Status', 'Server Error');
    }
  };

  const onOk = async () => {
    props.cancelFn();
  };

  React.useEffect(() => {
    void getTenantMeta();
    void getDistrictGroupName();
  }, []);

  React.useEffect(() => {
    if (Object.keys(featureFlags).length > 0 && sisIntegration === 'clever') {
      void getCleverStudentandAlterMapping();
    }
    if (
      Object.keys(featureFlags).length > 0 &&
      sisIntegration === 'psSis' &&
      (((pluginDetails as any)?.version !== undefined &&
        (sisServerDetails as any)?.powerschool_version !== undefined) ||
        sisError)
    ) {
      void validateStudentCounselorCompatibility();
    }
  }, [sisIntegration, featureFlags, pluginDetails, sisServerDetails, sisError]);

  const StudentGroupHeader = () => {
    return (
      <>
        {sisIntegration === 'psSis' ? (
          <>
            <Title className="titleField" data-cy="data-import-heading" level={5}>
              Groups
            </Title>
          </>
        ) : null}
      </>
    );
  };

  const Header = () => {
    return (
      <>
        {sisIntegration === 'clever' ? (
          <>
            <Text>Define the import by matching the Naviance field to the corresponding Clever field.</Text>
            <br />
          </>
        ) : (
          <ul>
            <li className="darkText">Match the SIS data field with the corresponding field in Naviance.</li>
            <li className="darkText">
              For import fields not listed below, use Naviance SchoolSync (Go to Setup &gt; Data Import).
            </li>
            <li className="darkText">
              Fields matched below should not be imported into Naviance using the Data Import function.
            </li>
          </ul>
        )}
      </>
    );
  };

  const UserNameHeader = () => {
    return (
      <>
        {sisIntegration === 'clever' ? (
          <>
            <Title className="titleField" data-cy="data-import-heading" level={5}>
              Select Username (Required for Student SSO)
            </Title>
            <Text>Select a username if using student SSO or direct login to Naviance.</Text>
            <br />
          </>
        ) : (
          <>
            <Title className="titleField" data-cy="data-import-heading" level={5}>
              Select Username (Required for Student SSO and Alumni Login)
            </Title>
            <ul>
              <li className="darkText">Select a username if using student SSO or direct login to Naviance.</li>
              <li className="darkText">
                Select a username if alumni will log in to Naviance directly (not using SSO).
              </li>
              <li className="darkText">
                Do not select a username if students do not need to login to Naviance (Staff login only).
              </li>
            </ul>
          </>
        )}
      </>
    );
  };

  const sections = [
    {
      defaultFields:
        sisIntegration === 'clever'
          ? navianceStudentCleverAlteredFields
          : sisIntegration === 'eSP'
          ? navianceStudentESPFields
          : navianceStudentPSFields(
              (pluginDetails as any)?.version !== undefined &&
                isGreaterThanOrEqualTo((pluginDetails as any)?.version, '1.2.2'),
              isStudentCounselorCompatible,
            ),
      defaultBlankValue: '(Do not import)',
    },
    {
      hasGroup: true,
      tableHeader: <StudentGroupHeader />,
      defaultFields: sisIntegration === 'psSis' ? groupNameFields : [],
      defaultBlankValue: '(Do not import)',
    },
    {
      tableHeader: <UserNameHeader />,
      defaultFields:
        sisIntegration === 'clever'
          ? cleverAlteredUserNameFields
          : sisIntegration === 'eSP'
          ? eSPUserNameFields
          : userNameFields,
      defaultBlankValue: '(No student login created)',
    },
  ];

  return sisIntegration ? (
    <>
      <SisFieldMatching
        defaultMapping={
          sisIntegration === 'clever'
            ? defaultCleverStudentMapping
            : sisIntegration === 'eSP'
            ? defaultESPStudentMapping
            : defaultPsStudentMapping(
                (pluginDetails as any)?.version !== undefined &&
                  isGreaterThanOrEqualTo((pluginDetails as any)?.version, '1.2.2'),
              )
        }
        specificMapping={
          sisIntegration === 'clever'
            ? { grade: 'grade', enrollmentStatus: 'enrollmentStatus', 'School_ID': 'school' }
            : sisIntegration === 'eSP'
            ? { grade: 'grade' }
            : {}
        }
        allFieldsRequired={false}
        translateConfigEndPoint={'data-ingest/sis/parameter-group/translateConfig'}
        header={<Header />}
        sections={sections}
        gpaRequired={sisIntegration === 'clever' ? false : true}
        sisIntegration={sisIntegration}
        notShared={notShared}
        pageLoading={loading}
        {...props}
      />
      <Modal
        visible={showMissingRequiredFieldsPopup}
        onOk={onOk}
        onCancel={onOk}
        cancelButtonProps={{ hidden: true }}
        closable={false}
        wrapClassName="required_fields_notShared_modal"
        okText="Ok"
      >
        <Text>
          Below are required fields that must be shared with Naviance from Clever in order to proceed with the student
          import.
        </Text>
        <ul>
          {requiredNotShared.map((field) => (
            <li key={field}>{field}</li>
          ))}
        </ul>
      </Modal>
    </>
  ) : (
    <></>
  );
};
export default SisStudentFieldMatching;
