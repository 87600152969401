import * as React from 'react';
import { Row, Col } from 'antd';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import { IntegrationContext } from '../../utils/context';

interface SisDefineCodesProps extends WizardProps {
  className?: string;
  codesToDefine: React.ReactElement[];
  saveMapping: () => Promise<boolean>;
  getMappings: () => void;
}

const SisDefineCodes = (props: SisDefineCodesProps): React.ReactElement => {
  const isMountedRef = React.useRef(null);

  const [pageLoading, setPageLoading] = React.useState(true);
  const [disableFooter, setHideFooterComp] = React.useState(true);
  const { saveMapping, getMappings } = props;
  const context = React.useContext(IntegrationContext);

  const save = async (saveAndContinue?: boolean) => {
    try {
      const saveOk = await saveMapping();
      if (saveOk) {
        if (saveAndContinue) {
          props.continueFn();
        } else {
          showNotification(NotificationTypes.success, 'Save Successful', '');
          props.saveFn();
        }
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Code Matchings', 'Server Error');
    }
  };

  const saveAndClose = async () => {
    try {
      await saveMapping();
      showNotification(NotificationTypes.success, 'Save Successful', '');

      props.saveFn();
      props.cancelFn();
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Code Matchings', 'Server Error');
    }
  };

  React.useEffect(() => {
      window.scrollTo(0, 0);
      isMountedRef.current = true;
      void Promise.all([getMappings()]).finally(() => {
        if (isMountedRef.current) {
          setPageLoading(false);
          setHideFooterComp(false);
        }
      });
    return () => (isMountedRef.current = false);
  }, [props.pageLoading]);

  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => save(true)}
      previousFn={props.previousFn}
      saveFn={() => save()}
      cancelFn={props.cancelFn}
      saveAndCloseFn={saveAndClose}
      changeStepFn={props.changeStepFn}
      pageLoading={pageLoading}
      hideHeaderButtons={true}
      hideFooter={disableFooter}
    >
      <div className={props.className ?? 'defineCodes'}>
        <Row className="mainSelectRow">
          <Col span={24}>
            {props.codesToDefine.length ? (
              <span className="pagepara">
                {context.isCleverIntegration
                  ? 'Review the list of possible values for each Naviance field and indicate how these values are presented in Clever.'
                  : 'Review the list of possible values for each code and indicate how these values are presented in your data.'}
              </span>
            ) : 'className' in props && props.className.includes('noDefineCodes') ? (
              <span className="pagepara">There are no codes to define for this import</span>
            ) : (
              <span className="pagepara">No fields requiring data mapping have been configured.</span>
            )}
          </Col>
        </Row>
        {props.codesToDefine}
      </div>
    </Wizard>
  );
};

export default SisDefineCodes;
