import * as React from 'react';
import { Select } from 'antd';
import apiClient from '../../utils/apiClient';
import { SIX_YEAR_HISTORY } from '../../utils/constants';

export interface AcademicYearDefineCodeProps {
  fromAcademicYear: string | number;
  setFromAcademicYear: (val) => void;
}

const academicYearOffset = 1990;

const CourseHistoryAcademicYear = ({
  fromAcademicYear,
  setFromAcademicYear,
}: AcademicYearDefineCodeProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);
  const [yearOptions, setYearOptions] = React.useState<number[]>([]);

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    loadPage();
    setFromAcademicYear(fromAcademicYear);
  }, []);

  const onChange = (val: string) => {
    setFromAcademicYear(val);
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    const { data: allYearIdStrings } = await apiClient.get<string[]>(`/data-ingest/sis/ps-year-ids/`, {});

    if (allYearIdStrings.length < 4) {
      const length = allYearIdStrings.length;
      for (let count = 0; count < SIX_YEAR_HISTORY - length; count++) {
        allYearIdStrings.unshift(parseInt(allYearIdStrings[0]) - 1 + '');
      }
    }

    const allYearIds = allYearIdStrings
      .map((item) => parseInt(item))
      .filter((item) => item && !isNaN(item))
      .sort()
      .reverse();
    setYearOptions(allYearIds);

    if (isMountedRef.current) setLoading(false);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  return (
    <div className="subSection mainMatchFieldsSection mainSelectRow">
      <h2 className="infoHeading uploadHeading">Academic Year</h2>
      <span className="pagepara">
        This value is based on <b>[School_Course]YearID</b>
      </span>
      <br />
      <span className="pagepara">
        Import courses starting from{' '}
        <Select
          className="select select-responsive"
          style={{ width: 150 }}
          value={fromAcademicYear}
          onChange={(val) => {
            onChange(val);
          }}
          data-test-id={'academic-year'}
          loading={loading}
        >
          {yearOptions.map((yearId: number) => (
            <Option
              className="selectOptions"
              key={`sis-state-id-1-${yearId}`}
              value={yearId + academicYearOffset}
              data-test-id={`state_id_option_${yearId}`}
              disabled={false}
            >
              {`${yearId + academicYearOffset} - ${yearId + academicYearOffset + 1}`}
            </Option>
          ))}
        </Select>{' '}
        to current academic year
      </span>
    </div>
  );
};

export default CourseHistoryAcademicYear;
