import * as React from 'react';
import SisCourseHistoryDefineCodes from '../SisDefineCodes/SisCourseHistoryDefineCodes';
import SisCourseHistoryFieldMatching from '../SisFieldMatching/SisCourseHistoryFieldMatching';
import SisCourseHistoryStartImport from '../SisStartImport/SisCourseHistoryStartImport';
import SisImport from '.';
import SisCourseHistorySyncSettings from '../SisSyncSettings/SisCourseHistorySyncSettings';

export const CourseHistorySteps = [
  { title: 'Match Fields', content: SisCourseHistoryFieldMatching, path: '/import-wizard/course-history/match-fields' },
  { title: 'Define Codes', content: SisCourseHistoryDefineCodes, path: '/import-wizard/course-history/define-code' },
  {
    title: 'Sync Settings',
    content: SisCourseHistorySyncSettings,
    path: '/import-wizard/course-history/sync-settings',
  },
  {
    title: 'Test / Start Import',
    content: SisCourseHistoryStartImport,
    path: '/import-wizard/course-history/test-start-import',
  },
];

const SisCourseHistory = (): React.ReactElement => {
  return <SisImport steps={CourseHistorySteps} />;
};

export default SisCourseHistory;
