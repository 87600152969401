import Title from 'antd/lib/typography/Title';
import React from 'react';
import { IntegrationContext } from '../../utils/context';
import Table, { ColumnsType } from 'antd/lib/table';
import { Col, Collapse, Row } from 'antd';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import StatusIcon from '../../../assets/Icon/Status';

const PanelHeader = ({ student, matchedRows }) => {
    return (
        <Row>
            <Col span={24}>
                <span className='panelHeader'>
                    {matchedRows[student?.dcid] ? <StatusIcon inProgress={true} /> : <StatusIcon />} <Title level={5}>{`${student.firstName} ${student.lastName}`}</Title>
                </span>
            </Col>
        </Row>
    )
};

interface StudentReconcileRow {
    source: string;
    lastName: string;
    firstName: string;
    schoolId: string;
    navStudentId: string;
    classYear: string;
    birthDate: string;
    status: string;
    numVisits: string;
}

interface SisStudentReconcileProps {
    student: Record<string, any>;
    navStudents: Record<string, any>[];
    updateReconciled: (
        update: Record<
            string,
            { student: string; create: boolean }
        >,
    ) => void;
    matchedRows: Record<
        string,
        { student: string; create: boolean }
    >;
    setMatchedRows: (
        matchedRows: Record<
            string,
            { student: string; create: boolean }
        >,
    ) => void;
    isDisabled: boolean;
}

const SisStudentReconcileEntity = ({
    student,
    navStudents,
    updateReconciled,
    matchedRows,
    setMatchedRows,
    isDisabled,
}: SisStudentReconcileProps): React.ReactElement => {
    const [toReconcile, setToReconcile] = React.useState([]);
    const [matchedRow, setMatchedRow] = React.useState(matchedRows[student?.dcid] ?? { student: null, create: false });
    const { isCleverIntegration } = React.useContext(IntegrationContext);
    const data = [];

    React.useEffect(() => {
        data.push(
            {
                source: isCleverIntegration ? 'Clever' : 'SIS',
                lastName: student?.lastName,
                firstName: student?.firstName,
                schoolId: student?.Naviance_School_ID,
                hsStudentId: student?.hsStudentId,
                classYear: student?.classYear ? `${student?.classYear}` : '',
                birthDate: student?.birthDate ? `${student?.birthDate}` : '',
                status: (student?.student as any)?.isActive ? 'Active' : 'Inactive',
                numVisits: '',
                key: `${student?.dcid}-sis`,
            },
            ...navStudents.map((navStudent) => ({
                id: navStudent?.id,
                source: 'Naviance',
                lastName: navStudent?.lastName,
                firstName: navStudent?.firstName,
                schoolId: navStudent?.schoolId,
                hsStudentId: navStudent?.hsStudentId,
                classYear: navStudent?.classYear ? `${navStudent?.classYear}` : '',
                birthDate: navStudent?.birthDate ? `${navStudent?.birthDate}` : '',
                status: navStudent?.isActive ? 'Active' : 'Inactive',
                numVisits: (navStudent?.studentUser as any)?.numVisits || 0,
                key: `${student?.dcid}-${navStudent?.id}`,
            })),
        );
        setToReconcile(data);
    }, []);

    React.useEffect(() => {
        if (student && Object.keys(student).length) {
            updateReconciled({ [student.dcid]: matchedRow });
        }
    }, [matchedRow]);

    const DataImportTableColumnsStudents: ColumnsType<StudentReconcileRow> = [
        {
            title: <Title level={5}>Source</Title>,
            dataIndex: 'source',
            key: 'source',
            width: '10%',
        },
        {
            title: <Title level={5}>Last Name</Title>,
            dataIndex: 'lastName',
            key: 'lastName',
            width: '10%',
        },
        {
            title: <Title level={5}>First Name</Title>,
            dataIndex: 'firstName',
            key: 'firstName',
            width: '10%',
        },
        {
            title: <Title level={5}>School ID</Title>,
            dataIndex: 'schoolId',
            key: 'schoolId',
            width: '10%',
        },
        {
            title: <Title level={5}>Student ID</Title>,
            dataIndex: 'hsStudentId',
            key: 'hsStudentId',
            width: '10%',
        },
        {
            title: <Title level={5}>Class Year</Title>,
            dataIndex: 'classYear',
            key: 'classYear',
            width: '10%',
        },
        {
            title: <Title level={5}>Birth Date</Title>,
            dataIndex: 'birthDate',
            key: 'birthDate',
            width: '10%',
        },
        {
            title: <Title level={5}>Status</Title>,
            dataIndex: 'status',
            key: 'status',
            width: '8%',
        },
        {
            title: <Title level={5}>Visits</Title>,
            dataIndex: 'numVisits',
            key: 'numVisits',
            width: '8%',
        },
        {
            title: <Title level={5}>Match Records</Title>,
            width: '14%',
            hidden: isDisabled,
            render: (row: { source: string; key: any; id: any }) => {
                if (row.source === 'Naviance') {
                    if (!matchedRow.create) {
                        if (!(matchedRow as any).student) {
                            return (
                                <button
                                    className="button"
                                    data-test-id={`match_btn-${row.key}`}
                                    disabled={Object.values(matchedRows).some((item) => item.student === row.id)}
                                    onClick={() => {
                                        setMatchedRow({ student: row.id, create: false });
                                        const upDatedMatchedRows = { ...matchedRows };
                                        upDatedMatchedRows[student.dcid] = { student: row.id, create: false };
                                        setMatchedRows(upDatedMatchedRows);
                                    }}
                                >
                                    Match
                                </button>
                            );
                        }
                        if ((matchedRow as any).student && `${(matchedRow as any).student}` === `${row.id}`) {
                            return (
                                <button
                                    className="button"
                                    data-test-id={`cancel_match_btn-${row.key}`}
                                    onClick={() => {
                                        setMatchedRow({ student: null, create: false });
                                        const upDatedMatchedRows = { ...matchedRows };
                                        delete upDatedMatchedRows[student.dcid];
                                        setMatchedRows(upDatedMatchedRows);
                                    }}
                                >
                                    Cancel Match
                                </button>
                            );
                        }
                    }
                } else {
                    if (!(matchedRow as any).student) {
                        if (matchedRow.create) {
                            return (
                                <button
                                    className="button"
                                    data-test-id={`cancel_create_btn-${row.key}`}
                                    onClick={() => {
                                        setMatchedRow({ student: null, create: false });
                                        const upDatedMatchedRows = { ...matchedRows };
                                        delete upDatedMatchedRows[student.dcid];
                                        setMatchedRows(upDatedMatchedRows);
                                    }}
                                >
                                    Cancel Create
                                </button>
                            );
                        } else {
                            return (
                                <button
                                    className="button"
                                    data-test-id={`create_btn-${row.key}`}
                                    onClick={() => {
                                        setMatchedRow({ student: null, create: true });
                                        const upDatedMatchedRows = { ...matchedRows };
                                        upDatedMatchedRows[student.dcid] = { student: null, create: true };
                                        setMatchedRows(upDatedMatchedRows);
                                    }}
                                >
                                    Create
                                </button>
                            );
                        }
                    }
                }
                return '';
            },
        },
    ].filter((item) => !item.hidden);

    return (
        <div className="collapsible">
            <h1></h1>
            <Collapse
                collapsible="header"
                expandIconPosition="end"
                bordered={true}
            >
                <CollapsePanel
                    header={<PanelHeader student={student} matchedRows={matchedRows} />}
                    key={student.dcid}
                >
                    <Table
                        rowKey="key"
                        columns={DataImportTableColumnsStudents}
                        dataSource={toReconcile}
                        data-test-id={'student_to_reconcile'}
                        pagination={false}
                        rowClassName={(_: any, ind: number) => (ind === 0 ? 'sisRow' : '')}
                        scroll={{ x: 1300 }}
                    ></Table>
                </CollapsePanel>
            </Collapse>
            <br />
        </div>
    );
};

export default SisStudentReconcileEntity;