import { Upload, Space, Button } from 'antd';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import Checkbox from '../../../components-v2/Checkbox';
import Col from '../../../components-v2/Col';
import Divider from '../../../components-v2/Divider';
import Radio from '../../../components-v2/Radio/index';
import Row from '../../../components-v2/Row';
import Select from '../../../components-v2/Select';
import { NotificationTypes, showNotification } from '../../../components/Notifications';
import apiClient from '../../../utils/apiClient';
import { UPLOAD_FILE_ENDPOINT, TestImportTypes } from '../../../utils/constants';

export interface NewDataImportProps {
  newImport?: boolean;
}

const NewDataImport = (): React.ReactElement => {
  const history = useHistory();
  const [importType, setImportType] = React.useState('new');
  const [selectedImportType, setSelectedImportType] = React.useState('');
  const [fileInfo, setFileInfo] = React.useState('new');
  const [fileSelectedName, setFileSelectedName] = React.useState('no file chosen');
  const [fileHeader, setFileHeaders] = React.useState<string[]>(null);
  const [containsHeading, setContainsHeading] = React.useState('yes');
  const [files, setFiles] = React.useState('');
  const [saveSettings, setSaveSettings] = React.useState(false);
  const [importName, setImportName] = React.useState('');
  const [showSaveCheckbox, setShowSaveCheckbox] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const { Option } = Select;
  const onChangeImportType = (e) => {
    setImportType(e.target.value);
    setShowSaveCheckbox(!showSaveCheckbox);
  };
  const renderImportNameSection = () => {
    if (importType === 'new') {
      return (
        <div className="section">
          <Row justify="start">
            <Col span={3}>
              <span className="labelName">
                Import Name<span style={{ color: 'red' }}>&nbsp;*</span>
              </span>
            </Col>
            <Col span={7}>
              <div className="" style={{ textAlign: 'right' }}>
                <Checkbox className="checkbox" onChange={saveSettingsForFutureImport}>
                  Save settings for future imports
                </Checkbox>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <input
                type="text"
                className="text-input"
                placeholder="Required Inorder to reuse for future imports"
                value={importName}
                onChange={(e) => {
                  setImportName(e.target.value);
                }}
              ></input>
            </Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div className="section">
          <Row justify="start">
            <Col span={8}>
              Import Name<span style={{ color: 'red' }}>&nbsp;*</span>
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Select
                placeholder="Required in order to reuse settings"
                className="select"
                onChange={handleSelectChange}
              >
                <Option value="Sample1">Sample Setting 1</Option>
                <Option value="Sample2">Sample Setting 2</Option>
                <Option value="Sample3">Sample Setting 3</Option>
              </Select>
            </Col>
          </Row>
        </div>
      );
    }
  };
  const onChangeFileInfo = (e) => {
    setFileInfo(e.target.value);
  };
  const onChangeContainsHeading = (e) => {
    setContainsHeading(e.target.value);
  };

  const checkboxChange = (e) => {
    console.log('Checkbox', e.target.checked);
  };

  function handleImportTypeChange(value) {
    setSelectedImportType(value);
  }
  const saveSettingsForFutureImport = (e) => {
    setSaveSettings(e.target.checked);
  };

  function handleSelectChange(value) {
    console.log(`selected ${value}`);
  }

  const selectedFile = async (file) => {
    setFileSelectedName(file.file.name);
    setFiles(file.file);
    await readFileHeaders(file);
  };

  const readFileHeaders = async (e) => {
    const reader = new FileReader();
    // eslint-disable-next-line @typescript-eslint/no-shadow
    reader.onload = async (e) => {
      const rows = e.target.result.toString().split('\n');
      setFileHeaders(rows[0].split(','));
    };
    reader.readAsText(e.file);
    console.log(fileHeader);
  };

  const continueClick = async () => {
    if (files === '') {
      return;
    }
    const formData = new FormData();
    formData.append('file', files);
    formData.append('importType', selectedImportType);
    formData.append('importName', importName);
    formData.append('headerlessFile', containsHeading === 'yes' ? 'false' : 'true');
    formData.append('saveSettings', saveSettings.toString());
    formData.append('mapping', fileHeader.toString());
    sessionStorage.setItem('importType', selectedImportType);
    sessionStorage.setItem('importName', importName);
    sessionStorage.setItem('mapping', fileHeader.toString());
    sessionStorage.setItem('headerlessFile', containsHeading);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    try {
      setLoading(true);
      await apiClient.post(UPLOAD_FILE_ENDPOINT, formData, config).then((data) => {
        sessionStorage.setItem('keyName', data.data.keyName);
        sessionStorage.setItem('bucketName', data.data.bucketName);
        showNotification(
          NotificationTypes.success,
          'File Uploaded Successfully',
          'File imported successfully for data import.',
        );
      });
      history.push('/scholarships/new-data-import/match-fields');
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Importing File', 'Failure in sending data to server.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="new-data-import">
      <div className="main-header-sec">
        <Row justify="start">
          <Title data-cy="data-import-heading" level={2}>
            New Data Import
          </Title>
          <Divider className="divider-data" />
        </Row>
      </div>
      <div className="mainWhitebgWrapper">
        <Row>
          <Col span={17}>
            <p className="pageTitle">Indicate if this is a new import or will use settings from a saved import</p>
            <Row justify="start">
              <Radio.Group onChange={onChangeImportType} value={importType}>
                <Radio className="radio-item vertical" value={'new'}>
                  New Import
                </Radio>

                <Radio className="radio-item vertical" value={'saved'}>
                  Import using saved settings
                </Radio>
              </Radio.Group>
            </Row>
            <div className="section">
              <Row>
                Import type<span style={{ color: 'red' }}>&nbsp;*</span>
              </Row>
              <Row>
                <Col span={10}>
                  <Select placeholder="Select Import Type" className="select" onChange={handleImportTypeChange}>
                    {TestImportTypes.map((testType, key) => {
                      return (
                        <Option key={key} value={testType.value}>
                          {testType.text}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
              </Row>
            </div>
            {renderImportNameSection()}
            <div className="section mainUploadFileSection">
              <Row>
                <Col span="20">
                  <p className="uploadPara">
                    Upload your file by clicking the "Add File" button and selecting the text file containing your data.{' '}
                    <span>All data imports will be analyzed before importing any data into Naviance</span>
                  </p>
                </Col>
                <Col span="18">
                  <strong>
                    <a href="/">Click Here</a> if you want more detailed instructions.
                  </strong>
                </Col>
              </Row>

              <Row className="uploadSection">
                <Col span="18">
                  <h2 className="uploadHeading">Upload File</h2>
                </Col>

                <Col span="18">
                  <span className="uploadcont">Only filetypes .csv and .txt accepted</span>
                </Col>

                <Col span="18">
                  <span className="uploadcont">10 MB Max file size</span>
                </Col>

                <Col span="18">
                  <span className="uploadcont">Average processing time is currently less than 1 minute</span>
                </Col>

                <Col span="18">
                  <div className="fileUploadbtn">
                    <Col style={{ display: 'inline-block' }} span="4">
                      <Upload showUploadList={false} accept=".csv, .txt" customRequest={selectedFile}>
                        <Button>Add File</Button>
                      </Upload>
                    </Col>
                    <Col style={{ display: 'inline-block' }} span="4">
                      <span style={{ marginLeft: '7px' }}>{fileSelectedName}</span>
                    </Col>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section">
              <Row justify="start">
                <Col span={18}>
                  <span>Does the first Row of your data contains column heading?</span>
                </Col>
                <Col span={18}>
                  <div className="radioValue">
                    <Radio.Group onChange={onChangeContainsHeading} value={containsHeading}>
                      <Col span="auto">
                        <Radio className="radio-item vertical" value={'yes'}>
                          Yes
                        </Radio>
                        <Radio className="radio-item vertical" value={'no'}>
                          No
                        </Radio>
                      </Col>
                    </Radio.Group>
                  </div>
                </Col>
                <Col span={18}>
                  <h2 className="infoHeading uploadHeading">File Actions</h2>
                </Col>
                <Col span={18}>
                  <div className="radioImport">
                    <Radio.Group onChange={onChangeFileInfo} value={fileInfo}>
                      <Col span="auto">
                        <Radio className="radio-item vertical" value={'new'}>
                          Import Data, if no critical errors
                        </Radio>
                      </Col>
                      <Col span="auto">
                        <Radio className="radio-item vertical" value={'saved'}>
                          Test file only, will not import data into Naviance
                        </Radio>
                      </Col>
                    </Radio.Group>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section imprtRules">
              <Row>
                <Col span="24">
                  <h2 className="infoHeading uploadHeading">Import Rules</h2>
                </Col>

                <Col span="24">
                  <Checkbox className="checkbox" onChange={checkboxChange}>
                    Make existing records inactive if not in your file
                  </Checkbox>
                </Col>

                <Col span="24">
                  <Checkbox className="checkbox" onChange={checkboxChange}>
                    Overwrite records in Naviance if file contains blanks
                  </Checkbox>
                </Col>

                <Col span="24">
                  <Checkbox className="checkbox" onChange={checkboxChange}>
                    Only update when Naviance data is blank
                  </Checkbox>
                </Col>

                <Col span="24">
                  <Checkbox className="checkbox" onChange={checkboxChange}>
                    Remove students from district groups if not in your file
                  </Checkbox>
                </Col>

                <Col span="24">
                  <Checkbox className="checkbox" onChange={checkboxChange}>
                    Remove students from school groups if not in your file
                  </Checkbox>
                </Col>
              </Row>
            </div>
            <div className="btnsSection">
              <Space>
                <button className="button" onClick={() => history.push('/scholarships')}>
                  Cancel
                </button>
                <button className="continue-btn" onClick={continueClick} disabled={loading}>
                  Continue
                </button>
              </Space>
            </div>
          </Col>

          <Col span={7}>
            <div className="" style={{ textAlign: 'right' }}>
              <button className="button template-available-btn">Template Available</button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default NewDataImport;
