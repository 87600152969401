import axios from 'axios';
import { API_URL, HIGHSCHOOL_ID, PSError } from './constants';
import { NotificationTypes, showNotification } from '../components/Notifications';

const apiClient = axios.create({
  baseURL: API_URL,
  withCredentials: true,
  headers: { 'naviance-highschool-id': HIGHSCHOOL_ID },
});

apiClient.interceptors.response.use(
  function (response) {
    // show notifications
    if (response.data?.hash?.startsWith('psError')) {
      showNotification(NotificationTypes.error, 'SIS Server Error', response.data.message);
      throw new Error(PSError);
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  },
);

export default apiClient;
