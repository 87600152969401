import type {
  SisParentReconciled,
  SisStaffReconciled,
  SisStudentReconciled,
} from '../../../../../../libs/common-interfaces';
import { Col, List, Row, Spin, Alert } from 'antd';
import * as React from 'react';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import SisFailedReconcile from '../../components-v2/SisFailedReconcileJob';

const PSDataImportFailedJobReconcile = ({
  jobGuid,
  dataType,
}: {
  jobGuid: string;
  update: (updatedReconciled: SisStudentReconciled | SisStaffReconciled | SisParentReconciled) => void;
  post: () => void;
  reconcileDisabled: boolean;
  setReconcileDisabled: (updated: boolean) => void;
  dataType: string;
}): React.ReactElement => {
  const [toReconcile, setToReconcile] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>();
  const [totalRecords, setTotalRecords] = React.useState(0);

  const fetchToReconcile = async (page = 1, size = 3) => {
    try {
      setLoading(true);
      const { data } = await apiClient.get(`/data-ingest/sis/jobs/${jobGuid}/reconcile?page=${page}&size=${size}`);
      setToReconcile(data.records ?? []);
      setTotalRecords(data.total ?? 0);
      setLoading(false);
    } catch (error) {
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
    }
  };

  React.useEffect(() => {
    void fetchToReconcile();
  }, []);

  return (
    <>
      <br />
      <Row justify="space-between" align="middle">
        <Col span={20}>
          <h2>Resolve {totalRecords} Potential Updates</h2>
          <p>Displaying top three records as a reference. Download Log file for complete list.</p>
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={toReconcile}
            renderItem={(row: any) => (
              <SisFailedReconcile
                {...(row.student && { student: row.student })}
                {...(row.navStudents && { navStudents: row.navStudents })}
                {...(row.staff && { staff: row.staff })}
                {...(row.navStaffs && { navStaffs: row.navStaffs })}
                {...(row.parent && { parent: row.parent })}
                {...(row.navParents && { navParents: row.navParents })}
                key={
                  dataType === 'students' || dataType === 'alumni'
                    ? row.student.dcid
                    : dataType === 'parent-records'
                    ? row.parent.personDcid
                    : `${row?.staff?.dcid}-${row?.staff?.entityId}-${row?.staff?.role}`
                }
                dataType={dataType}
              />
            )}
            loading={loading}
            size="large"
          />
        </Col>
      </Row>
    </>
  );
};

export default PSDataImportFailedJobReconcile;
