import { Alert } from 'antd';
import Text from 'antd/lib/typography/Text';
import React from 'react';

enum type {
  success = 'success',
  warning = 'warning',
  error = 'error',
  info = 'info',
}

interface CustomAlertProps {
  description: string;
  text?: string;
  type?: type;
}

const CustomAlert = (props: CustomAlertProps): React.ReactElement => {
  return (
    <Alert
      className="alert"
      style={{ border: '0px', borderLeft: '2px solid #6BD7DB', backgroundColor: '#DEF6F7', marginBottom: '20px' }}
      description={
        <Text>
          { props.description  }
        </Text>
      }
      type={props.type || 'success'}
    />
  );
};

export default CustomAlert;