import * as React from 'react';
import SisDefineCodes from '.';
import AcademicYearDefineCode from '../../components-v2/SisDefineCodes/academicYear';
import GradeLevelDefineCode from '../../components-v2/SisDefineCodes/gradeLevelDefineCode';
import CreditTypeDefineCode from '../../components-v2/SisDefineCodes/creditTypeDefineCode';
import InstructionalLevelDefineCode from '../../components-v2/SisDefineCodes/instructionalLevel';
import CTECustom from '../../components-v2/SisDefineCodes/cteCustom';
import StateIdDefineCode from '../../components-v2/SisDefineCodes/stateId';
import SubjectAreaDefineCode, { SubjectAreaType } from '../../components-v2/SisDefineCodes/subjectArea';
import { WizardProps } from '../../components-v2/Wizard';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { getCurrentAcademicYear } from '../../utils/utils';
import { featureFlags } from '../../utils/featureFlags';
import { GradeLevelParameterGroup } from './common';

export interface AcademicYearParameterGroup {
  academicYear: number | string;
}

export interface StateIdParameterGroup {
  fromDigit: number;
  toDigit: number;
  fromTranslateConfig: string;
}

export interface InstructionalLevelMappingValue {
  navDisplay: string;
  sisCode: string;
}

export interface InstructionalLevelMappingItem {
  [navCode: string]: InstructionalLevelMappingValue;
}

export interface InstructionalLevelCustomMappingValue {
  navDisplay: string;
  sisCode: string[];
}

export interface InstructionalLevelItem {
  digit?: number;
  mapping?: InstructionalLevelMappingItem;
  mappingCustom?: InstructionalLevelCustomMappingValue;
}

export type InstructionalLevelParameterGroup = InstructionalLevelItem[];

export interface SubjectAreaSelectedOption {
  scedCode: number;
  sisCode: string | string[];
  rowValue: number | number[];
  navCode: string;
}

export interface ScedBasedSubjectAreaSelectedOption {
  scedCode: number;
  rowValue: number | number[];
  navCode: string;
}

export type SubjectAreaSelectionParameterGroup = {
  [rowId: string]: SubjectAreaSelectedOption;
};

export type ScedBasedSubjectAreaSelectionParameterGroup = {
  rowId: number;
  scedCode: number;
  description: string;
};

export interface SubjectAreaCustomFieldSettings {
  table: string;
  field: string;
}
export interface SubjectAreaParameterGroup {
  schedDepartment?: SubjectAreaSelectionParameterGroup;
  courseSubjectAreaCode?: SubjectAreaSelectionParameterGroup;
  customFieldSelection?: SubjectAreaSelectionParameterGroup;
  basedOnScedSubjectArea?: ScedBasedSubjectAreaSelectionParameterGroup[];
  fromTranslateConfig?: string;
  customField?: SubjectAreaCustomFieldSettings;
}

export interface AddedSubjectAreaItem {
  rowId: number;
  scedCode: number;
  description: string;
}

export interface CourseCatalogDefineCodesParameterGroup {
  subjectArea: SubjectAreaParameterGroup;
  stateId: StateIdParameterGroup;
  academicYear: AcademicYearParameterGroup;
  gradeLevelRange?: GradeLevelParameterGroup;
  instructionalLevel: InstructionalLevelParameterGroup;
  instructionalLevelFromTranslateConfig?: string;
  cteCustom?: string[];
  creditTypes?: Record<string, string[]>;
}

export interface CourseCatalogImportRulesParameterGroup {
  overwriteNavData: boolean;
  shouldInactivateRecords: boolean;
  shouldOverwriteCourseName: boolean;
}

export interface ValueMappingItem {
  toValue: string;
  fromValue: string | number | string[];
}

export interface CourseCatalogValueMappings {
  [columnName: string]: ValueMappingItem[];
}

export interface CourseCatalogTranslateConfigParameterGroup {
  valueMappings: CourseCatalogValueMappings;
  // eslint-disable-next-line @typescript-eslint/ban-types
  headerMappings: object;
  removeEmptyHeaders: boolean;
  removeUnmappedHeaders: boolean;
}

export interface PsStudentDataSyncExtensionColumn {
  name: string;
  outputName: string;
}

export interface PsStudentDataSyncExtensionItem {
  name: string;
  columns: PsStudentDataSyncExtensionColumn[];
  dcIdColumn: string;
}

export interface PsStudentDataSyncConfig {
  // ToDo: add types
  // eslint-disable-next-line @typescript-eslint/ban-types
  classRank: object;
  extensions: PsStudentDataSyncExtensionItem[];
}

export interface CourseCatalogParameterGroup {
  importRules: CourseCatalogImportRulesParameterGroup;
  translateConfig: CourseCatalogTranslateConfigParameterGroup;

  psStudentDataSyncConfig: PsStudentDataSyncConfig;
  defineCodes: CourseCatalogDefineCodesParameterGroup;
}

export const courseCatalogParameterGroupUrl = 'data-ingest/sis/parameter-group/course-catalog';
export const navianceSubjectAreaCodesUrl = 'data-ingest/sis/ps-nav-subject-areas';
export const navianceDeleteSubjectAreaCodesUrl = 'data-ingest/sis/ps-nav-subject-areas-delete';

const digitsPartOfCourseNumber = 'digits_part_of_course_number';
const instructionalLevel = 'Instructional_Level';
let cteCustomBool = false;
/* translate header mapping can use different names */
export const translateHeaders = {
  subjectArea: { saved: 'Subject_Area', internal: 'subjectArea' },
  stateId: { saved: 'State_ID', internal: 'stateId' },
  instructionalLevel: { saved: 'Instructional_Level', internal: 'instructionalLevel' },
  CTE: { saved: 'CTE', internal: 'cteCustom' },
};

export const translateValues = {
  schedDepartment: { saved: 'sched_department', internal: 'Sched_Department' },
  courseSubjectAreadCode: { saved: 'sched_coursesubjectareacode', internal: 'Sched_CourseSubjectAreaCode' },
  createSubjectAreaCode: { saved: 'create_based_on_sced_subjectArea', internal: 'create_based_on_sced_subjectArea' },
  subjectArea: { saved: 'Subject_Area', internal: 'subjectArea' },
  stateId: { saved: 'sid_digits_part_of_course_number', internal: digitsPartOfCourseNumber },
  instructionalLevel: { saved: 'il_digits_part_of_course_number', internal: digitsPartOfCourseNumber },
  instructionalLevelCustom: { saved: 'Instructional_Level', internal: instructionalLevel },
  cteCustom: { saved: 'CTE', internal: 'cte_custom' },
};

const translateValue = (what: string) => {
  for (const [key, value] of Object.entries(translateValues)) {
    if (what === value.saved) {
      return value.internal;
    }
  }
  return '';
};

const SisCourseCatalogDefineCodes = (props: WizardProps): React.ReactElement => {
  const [defineCodesSettings, setDefineCodesSettings] = React.useState<CourseCatalogDefineCodesParameterGroup>({
    subjectArea: {},
    stateId: { fromDigit: 1, toDigit: 1, fromTranslateConfig: '' },
    academicYear: { academicYear: getCurrentAcademicYear() },
    instructionalLevel: [],
    cteCustom: [],
    creditTypes: { creditTypes: [] },
    gradeLevelRange: { fromGrade: 9, toGrade: 12 }, // default grade level must be selected as 9 to 12 grade.
  });
  const [valueMappings, setValueMappings] = React.useState<CourseCatalogValueMappings>({});
  const [subjectAreaTypes, setSubjectAreaTypes] = React.useState<SubjectAreaType[]>([]);
  const [subjectAreaSource, setSubjectAreaSource] = React.useState<string>('');
  const [useCreditTypes, setUseCreditTypes] = React.useState<boolean>(false);

  const isDuplicateScedCodeInDefineSetting = (): boolean => {
    let scedTable = defineCodesSettings.subjectArea.basedOnScedSubjectArea;
    if (scedTable === undefined || scedTable.length!==subjectAreaTypes.length) {
      generateSubjectAreaForScedBased();
      scedTable = defineCodesSettings.subjectArea.basedOnScedSubjectArea;
    }
    const uniqueValues = new Set(scedTable.map((item) => item.scedCode));
    return uniqueValues.size < scedTable.length;
  };

  const generateSubjectAreaForScedBased = () => {
    const scedTable = [];
    for (const value of subjectAreaTypes) {
      const newSubjectAreaItem = { scedCode: value.scedCode, description: value.description };
      scedTable.push(newSubjectAreaItem);
    }
    defineCodesSettings.subjectArea.basedOnScedSubjectArea = scedTable;
  };

  const saveDefineCodesState = async () => {
    // save to parameter store for everything in "Define Codes" page
    const newDefineCodesSettings = JSON.parse(JSON.stringify(defineCodesSettings));
    if (!useCreditTypes) {
      delete newDefineCodesSettings.creditTypes;
    }
    setDefineCodesSettings(newDefineCodesSettings);
    setValueMappings({ ...valueMappings });
  };

  const saveDefineCodes = async (): Promise<boolean> => {
    // save to parameter store for everything in "Define Codes" page

    if (
      defineCodesSettings.subjectArea.fromTranslateConfig === 'create_based_on_sced_subjectArea' &&
      isDuplicateScedCodeInDefineSetting()
    ) {
      showNotification(
        NotificationTypes.error,
        `2 or more subject area has same SCED code`,
        "Make sure that 2 or more subject area doesn't have same SCED by changing SCED code of the subject area or deleting the subject area",
      );
      return;
    }

    try {
      const { data } = await apiClient.get<CourseCatalogParameterGroup>(courseCatalogParameterGroupUrl);
      const newDefineCodesSettings = JSON.parse(JSON.stringify(defineCodesSettings));
      if (!useCreditTypes) {
        delete newDefineCodesSettings.creditTypes;
      }
      setDefineCodesSettings(newDefineCodesSettings);
      // save subject area back to naviance
      await apiClient.patch(navianceSubjectAreaCodesUrl, subjectAreaTypes);

      // merge valueMappings
      data.translateConfig.valueMappings = { ...data.translateConfig.valueMappings, ...valueMappings };
      const newParamStore: CourseCatalogParameterGroup = { ...data, defineCodes: newDefineCodesSettings };
      await apiClient.patch(courseCatalogParameterGroupUrl, newParamStore);
      return true;
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Define Codes Settings', 'Server Error');
    }
    return false;
  };

  /**
   * Find out what headerMappings might have
   * @param translateConfigHeaderMapping
   * @param which
   */
  const detectHeaderMappings = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    translateConfigHeaderMapping: object,
    which: 'subjectArea' | 'stateId' | 'instructionalLevel' | 'CTE',
  ): string | null => {
    let subjectAreaSource: null | string;
    for (const [key, value] of Object.entries(translateConfigHeaderMapping)) {
      if (Array.isArray(value)) {
        for (const item of value) {
          if (item === translateHeaders[which].saved) {
            subjectAreaSource = translateValue(key);
          }
        }
      } else {
        if (value === translateHeaders[which].saved) {
          subjectAreaSource = translateValue(key);
        }
      }
    }
    return subjectAreaSource;
  };

  /**
   * Check if mapping exist
   * @param translateConfigHeaderMapping
   * @param which
   */
  const checkHeaderMappingExist = (
    // eslint-disable-next-line @typescript-eslint/ban-types
    translateConfigHeaderMapping: object,
    which: 'credittype',
  ): boolean => {
    let header_exist: boolean = false;
    for (const [key, value] of Object.entries(translateConfigHeaderMapping)) {
      if (key === which) {
        header_exist = true;
      }
    }
    return header_exist;
  };

  /**
   *
   * @param settings
   * @param paramGroup
   */
  const useSubjectAreaCustomField = (
    settings: CourseCatalogDefineCodesParameterGroup,
    paramGroup: CourseCatalogParameterGroup,
  ): null | { tableName: string; fieldName: string } => {
    if (settings.subjectArea.fromTranslateConfig === translateValues.subjectArea.internal) {
      for (const { name, columns } of paramGroup?.psStudentDataSyncConfig?.extensions ?? []) {
        for (const column of columns) {
          if (column.outputName === translateValues.subjectArea.saved) {
            return { tableName: name, fieldName: column.name };
          }
        }
      }
    }
    return null;
  };
  const checkForEmpty = (defineCodesSettings: CourseCatalogDefineCodesParameterGroup): boolean => {
    //if instruction level contains data for both custom as well as digits part, empty the array
    if (defineCodesSettings.instructionalLevel.length != 0) {
      for (let ele of defineCodesSettings.instructionalLevel) {
        let conditionForCustomHeaderDigitValue =
          defineCodesSettings.instructionalLevelFromTranslateConfig == 'digits_part_of_course_number' &&
          ele.hasOwnProperty('sisCode');
        let conditionForBothValues = ele.hasOwnProperty('mapping') && ele.hasOwnProperty('sisCode');
        let conditionForDigitHeaderCustomValue =
          defineCodesSettings.instructionalLevelFromTranslateConfig == 'Instructional_Level' &&
          ele.hasOwnProperty('mapping');
        let conditionForEmptyArray =
          conditionForBothValues || conditionForCustomHeaderDigitValue || conditionForDigitHeaderCustomValue;
        if (conditionForEmptyArray) {
          return true;
        }
      }
    }
    return false;
  };

  const loadDefineCodes = async () => {
    // get from parameter store for everything in "Define Codes" page
    if (Object.keys(defineCodesSettings.subjectArea).length > 0) {
      return; // already loaded.
    }
    try {
      const { data: paramGroup } = await apiClient.get<CourseCatalogParameterGroup>(courseCatalogParameterGroupUrl);
      const newSettings: CourseCatalogDefineCodesParameterGroup = { ...defineCodesSettings, ...paramGroup.defineCodes };

      // find out what the match fields page had
      const translateConfigHeaderMapping = paramGroup?.translateConfig?.headerMappings ?? {};
      newSettings.subjectArea.fromTranslateConfig = ''; // empty means do not import
      newSettings.stateId.fromTranslateConfig = ''; // empty means do not import
      const subjectAreaSource = detectHeaderMappings(translateConfigHeaderMapping, 'subjectArea');
      if (subjectAreaSource != null) {
        newSettings.subjectArea.fromTranslateConfig = subjectAreaSource;
        setSubjectAreaSource(subjectAreaSource);
      }
      const stateIdSource = detectHeaderMappings(translateConfigHeaderMapping, 'stateId');
      if (stateIdSource != null) {
        newSettings.stateId.fromTranslateConfig = stateIdSource;
      }
      const instructionalLevelSource = detectHeaderMappings(translateConfigHeaderMapping, 'instructionalLevel');
      if (instructionalLevelSource != null) {
        newSettings.instructionalLevelFromTranslateConfig = instructionalLevelSource;
      } else {
        newSettings.instructionalLevelFromTranslateConfig = '';
      }
      const instructEmpty = checkForEmpty(newSettings);
      if (instructEmpty) newSettings.instructionalLevel = [];

      const cteCustomSource = detectHeaderMappings(translateConfigHeaderMapping, 'CTE');
      if (cteCustomSource) {
        cteCustomBool = true;
      } else {
        delete newSettings.cteCustom;
        delete valueMappings['CTE'];
        cteCustomBool = false;
      }
      const creditTypeExist = checkHeaderMappingExist(translateConfigHeaderMapping, 'credittype');
      setUseCreditTypes(creditTypeExist);
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const useCustomField = useSubjectAreaCustomField(newSettings, paramGroup);

      if (useCustomField != null) {
        newSettings.subjectArea.customField = { table: useCustomField.tableName, field: useCustomField.fieldName };
      } else {
        if (defineCodesSettings.subjectArea.customField != null) {
          delete newSettings.subjectArea.customField;
        }
      }
      setDefineCodesSettings(newSettings);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Define Codes Settings', 'Server Error');
    }
  };

  return (
    <SisDefineCodes
      className="defineCodes subSections"
      codesToDefine={[
        featureFlags['feature.dataIngest.cplan.courseCatlogCreditTypeFilter'] && useCreditTypes ? (
          <CreditTypeDefineCode
            key="sis-credit-type"
            saveCreditTypes={(credits) => {
              defineCodesSettings.creditTypes = { creditTypes: credits };
              saveDefineCodesState();
            }}
            creditTypes={defineCodesSettings.creditTypes.creditTypes}
          />
        ) : (
          <React.Fragment />
        ),
        <GradeLevelDefineCode
          key="sis-grade-level-def-code"
          triggerSetState={saveDefineCodesState}
          gradeLevelRange={defineCodesSettings.gradeLevelRange}
        />,
        (defineCodesSettings?.instructionalLevelFromTranslateConfig === digitsPartOfCourseNumber ||
          (featureFlags['feature.dataIngest.cplan.courseCatalogCustomInstructionalLevel'] &&
            defineCodesSettings?.instructionalLevelFromTranslateConfig === instructionalLevel)) && (
          <InstructionalLevelDefineCode
            key="sis-sd-il-def-code"
            triggerSetState={saveDefineCodesState}
            defineCodesSettings={defineCodesSettings}
          />
        ),
        cteCustomBool && featureFlags['feature.dataIngest.cplan.courseCatalogCustomCTE'] && (
          <CTECustom
            key="sis-sd-id-def-code"
            triggerSetState={saveDefineCodesState}
            valueMappings={valueMappings}
            defineCodesSettings={defineCodesSettings}
          />
        ),
        defineCodesSettings?.stateId?.fromTranslateConfig === digitsPartOfCourseNumber && (
          <StateIdDefineCode
            key="sis-sd-id-def-code"
            triggerSetState={saveDefineCodesState}
            defineCodesSettings={defineCodesSettings}
          />
        ),
        <SubjectAreaDefineCode
          key="sis-sa-def-code"
          subjectAreaTypes={subjectAreaTypes}
          setSubjectAreaTypes={setSubjectAreaTypes}
          subjectAreaSource={subjectAreaSource}
          triggerSetState={saveDefineCodesState}
          defineCodesSettings={defineCodesSettings}
          valueMappings={valueMappings}
        />,
      ].filter((code) => !!code)}
      saveMapping={saveDefineCodes}
      getMappings={loadDefineCodes}
      {...props}
    />
  );
};

export default SisCourseCatalogDefineCodes;
