import React, { useEffect } from 'react';
import { Avatar, Col, Drawer, Input, Row, Select, Table, TablePaginationConfig } from 'antd';
import { IntegrationContext } from '../../utils/context';
import { SearchOutlined } from '@ant-design/icons';
import { DeactivatedStudent, debounceSearch, fetchDeactivatedStudents } from '../../utils/viewLogUtils';

export interface DeactivatedRecordsDrawerProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    selectedClassYear: number;
    setSelectedClassYear: (selectedClassYear: number) => void;
    classYearBasedDeactivatedStudents: { [key: number]: number[] };
    drawerDropDownClassYears: { label: string; value: string }[];
}

const DeactivatedRecordsDrawer = ({
    open,
    setOpen,
    selectedClassYear,
    setSelectedClassYear,
    classYearBasedDeactivatedStudents,
    drawerDropDownClassYears,
}: DeactivatedRecordsDrawerProps): React.ReactElement => {
    const { districtId, schoolMap } = React.useContext(IntegrationContext);
    const [searchInput, setSearchInput] = React.useState<string>('');
    const [deactivatedStudents, setDeactivatedStudents] = React.useState<DeactivatedStudent[]>([]);
    const [totalDeactivatedStudents, setTotalDeactivatedStudents] = React.useState(0);
    const [loading, setLoading] = React.useState<boolean>();
    const [pageSize, setPageSize] = React.useState<number>(10);
    const [currentPageNum, setCurrentPageNum] = React.useState<number>(1);

    const pagination: TablePaginationConfig = {
        position: ['bottomCenter'],
        size: "small",
        total: totalDeactivatedStudents,
        showSizeChanger: true,
        showQuickJumper: true,
        current: currentPageNum,
        pageSize: pageSize,
        showTotal: (total) => `${total} results`,
        pageSizeOptions: [10, 15],
        onShowSizeChange: async (currentPage, size) => {
            setPageSize(size);
            setCurrentPageNum(currentPage);
            await fetchDeactivatedStudents(
                searchInput,
                setLoading,
                classYearBasedDeactivatedStudents,
                selectedClassYear,
                districtId,
                schoolMap,
                setTotalDeactivatedStudents,
                setDeactivatedStudents,
                currentPage,
                size,
            );
        },
        onChange: async (page, size) => {
            setPageSize(size);
            setCurrentPageNum(page);
            await fetchDeactivatedStudents(
                searchInput,
                setLoading,
                classYearBasedDeactivatedStudents,
                selectedClassYear,
                districtId,
                schoolMap,
                setTotalDeactivatedStudents,
                setDeactivatedStudents,
                page,
                size,
            );
        }
    };

    const onClose = () => {
        setSearchInput('');
        setOpen(false);
    };

    const handleChange = (event) => {
        const newSearchInput = event.target.value;
        setLoading(true);
        setSearchInput(newSearchInput);
        debounceSearch(
            newSearchInput,
            setLoading,
            classYearBasedDeactivatedStudents,
            selectedClassYear,
            districtId,
            schoolMap,
            setTotalDeactivatedStudents,
            setDeactivatedStudents,
        );
    };

    useEffect(() => {
        if (selectedClassYear) {
            setLoading(true);
            setCurrentPageNum(1);
            fetchDeactivatedStudents(
                searchInput,
                setLoading,
                classYearBasedDeactivatedStudents,
                selectedClassYear,
                districtId,
                schoolMap,
                setTotalDeactivatedStudents,
                setDeactivatedStudents,
            );
        }
    }, [selectedClassYear]);

    const columns = React.useMemo(
        () => [
            {
                title: '',
                key: 'action',
                render: (row: any) => {
                    return row.photoUrl ? (
                        <Avatar src={<img src={row.photoUrl} alt="avatar" />} />
                    ) : (
                        <Avatar>{`${row.firstName.charAt(0)}${row.lastName.charAt(0)}`}</Avatar>
                    );
                },
            },
            {
                title: 'First name',
                dataIndex: 'firstName',
                key: 'name',
            },
            {
                title: 'Last name',
                dataIndex: 'lastName',
                key: 'lastName',
            },
            {
                title: 'Student ID',
                dataIndex: 'highSchoolStudentId',
                key: 'highSchoolStudentId',
            },
            {
                title: 'School',
                dataIndex: 'highSchoolName',
                key: 'highSchoolName',
            },
        ], []);

    return (
        <Drawer className='deactivatedDrawer' title="Deactivated Records" placement="right" width={'50%'} closable={true} onClose={onClose} open={open}>
            <>
                <Row>
                    <Col span={24}>
                        <Input
                            prefix={<SearchOutlined />}
                            allowClear={true}
                            placeholder="Search"
                            type="text"
                            value={searchInput}
                            onChange={handleChange}
                            style={{ width: 300 }}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>Class year</Col>
                </Row>
                <Row>
                    <Col span={24}>
                        <Select
                            value={selectedClassYear}
                            style={{ width: 150 }}
                            onChange={(value) => setSelectedClassYear(value)}
                            options={drawerDropDownClassYears}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col span={24}>
                        <Table dataSource={deactivatedStudents} columns={columns} pagination={pagination} loading={loading} />
                    </Col>
                </Row>
            </>
        </Drawer>
    );
};

export default DeactivatedRecordsDrawer;

